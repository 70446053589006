<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-08-19 16:25:04
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-09-14 11:51:37
 * @FilePath: \mobile_vue3\src\views\Home.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="home">
        <div class="menu-left" :style="menustyle">
            <div class="menu-head">
                <img src="../assets/head.png" alt />
                <div class="username">
                    <span>{{$store.state.username}}</span>
                    <el-dropdown>
                        <el-button type="primary" size="small" style="color:#fff">
                            <span>换肤</span>
                            <el-icon class="el-icon--right">
                                <arrow-down />
                            </el-icon>
                        </el-button>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item v-for="item in predefineColors" :key="item" @click="change_color(item)">
                                    <div class="color-item" :style="{backgroundColor:item}"></div>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </div>
            </div>
            <ul>
                <a class="ui-link" @click="go_betinfo">
                    <li>
                        <span class="iconL2"></span>下注明細
                    </li>
                </a>
                <a class="ui-link" @click="get_report">
                    <li>
                        <span class="iconL3"></span>結算報表
                    </li>
                </a>
                <a class="ui-link" @click="get_his">
                    <li>
                        <span class="iconL4"></span>歷史開獎
                    </li>
                </a>
                <a class="ui-link" @click="go_rule">
                    <li>
                        <span class="iconL5"></span>玩法規則
                    </li>
                </a>
                <a class="ui-link" @click="go_djgz">
                    <li>
                        <span class="iconL5"></span>兌獎規則
                    </li>
                </a>
                <a class="ui-link" @click="go_changepw">
                    <li>
                        <span class="iconL6"></span>修改密碼
                    </li>
                </a>
                <a class="ui-link" @click="get_logs">
                    <li>
                        <span class="iconL7"></span>登陸日誌
                    </li>
                </a>
                <a class="ui-link" @click="go_xieyi">
                    <li>
                        <span class="iconL8"></span>用戶協議
                    </li>
                </a>
                <a class="ui-link" @click="get_info">
                    <li>
                        <span class="iconL1"></span>信用資料
                    </li>
                </a>
                <a class="ui-link" @click="quit=true">
                    <li>
                        <span class="iconL9"></span>安全退出
                    </li>
                </a>
            </ul>
            <div class="usdt">{{usdt}}</div>
        </div>
        <div class="home-view" :style="homestyle" @click="show_menu(false)">
            <!-- 彩种 -->
            <div style="height:38px">
                <div class="header theme-bg">
                    <div class="menu topicon" @click.stop="show_menu"></div>
                    <div class="header-title">
                        <span v-if="!show_gametitle">{{headertext}}</span>
                        <div v-else class="userbet-gameplaying-list">
                            <div class="gametitle" @click="change_show_gamelist">
                                <span>{{gamebet.gname}}</span>
                                <ArrowDownBold style="width: 16px; height: 16px;" />
                            </div>
                            <div v-if="headertext!='信用資料'&&headertext!='玩法規則'&&headertext!='歷史開獎'" class="playtitle" @click="change_show_playlist">
                                <span>{{gamebet.pname}}</span>
                                <ArrowDownBold style="width: 16px; height: 16px;" />
                            </div>
                        </div>
                    </div>
                    <div v-if="!show_home_icon" class="quit topicon" @click="quit=true"></div>
                    <div v-else class="home-icon topicon" @click="$router.push('/home')"></div>
                    <div class="gamelist" v-if="show_gamelist">
                        <a v-for="game in gamebet.game_list" :key="game.id" :class="game.id==gamebet.gid?'active':''" @click="change_game(game.id,game.gname)">{{game.gname}}</a>
                    </div>
                    <div class="playlist" v-if="show_playlist">
                        <a v-for="paly in gamebet.plist" :key="paly" :class="paly.text==gamebet.pname?'active':''" @click="change_play(paly)">{{paly.text}}</a>
                    </div>
                </div>
            </div>
            <!-- 菜单路由 -->
            <div class="body-views">
                <!-- <router-view ref="child" @get_info="get_info" @change_game="change_game" /> -->
                <router-view v-slot="{ Component }">
                    <component ref="child" :is="Component" :activeName="gamebet.pname" @get_info="get_info" @change_game="change_game" @get_his="get_his" @go_betinfo="go_betinfo" @get_report="get_report" />
                </router-view>
            </div>
        </div>
        <!-- 退出 -->
        <el-dialog v-model="quit" width customClass="mydialogclass">
            <template #header>
                <span class="mydialogtitle">安全退出</span>
            </template>
            <span>您确认要退出吗？</span>
            <template #footer>
                <span class="dialog-footer">
                    <el-button style="width:40%" size @click="quit=false">取消</el-button>
                    <el-button style="width:40%" size type="warning" @click="confirm_quit">确认</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    import { set_playing } from '../plugin/set_playing'
    export default {
        components: {},
        data() {
            return {
                headertext: '遊戲大廳',
                quit: false,
                menustyle: {
                    transform: 'translateX(-112px)',
                },
                homestyle: {
                    transform: 'translateX(0)',
                },
                show_home_icon: false,
                color: '',
                predefineColors: [
                    'hsl(210deg,100%,63%)',
                    'hsl(100deg,54%,49%)',
                    'hsl(36deg,77%,57%)',
                    'hsl(0deg,87%,69%)',
                    'hsl(220deg,3%,39%)',
                    'hsl(87deg,97%,28%)',
                    'hsl(257deg,34%,55%)',
                    'hsl(15deg,28%,43%)',
                    'hsl(204deg,66%,44%)',
                ],
                show_gametitle: false,
                gamebet: {
                    gid: '',
                    gname: '',
                    game_list: [],
                    pname: '',
                    playing: '',
                    plist: [],
                },
                show_gamelist: false,
                show_playlist: false,
                usdt: '',
            }
        },
        watch: {
            $route(n, o) {
                console.log(n.name);
                if (n.name == "gameLobby") {
                    this.show_home_icon = false;
                    this.headertext = '遊戲大廳';
                    this.show_gametitle = false;
                }
                else if (n.name == "userbet" || n.name == "userinfo" || n.name == "rule" || n.name == "historyopen") {
                    this.show_home_icon = true;
                    this.show_gametitle = true;
                }
                else {
                    this.show_home_icon = true;
                    this.show_gametitle = false;
                }
                this.show_gamelist = false;
                this.show_playlist = false;
            }
        },
        created() {
            document.body.style.overflow = 'auto';
            this.show_home_icon = this.$route.name !== "Home";
            if (this.$store.state.userinfo== undefined) {
                window.location.replace('/');
            } else {
                this.color = this.$store.state.userinfo.color;
            }
            if (this.$route.name == 'home' || this.$route.name == 'gameLobby') {
                this.show_home_icon = false;
                this.headertext = '遊戲大廳';
            }
            if (this.$route.name == 'userinfo') {
                this.show_home_icon = true;
                this.show_gametitle = true;
                this.headertext = '信用資料';
            }
            if (this.$route.name == 'xieyi') {
                this.show_home_icon = true;
                this.headertext = '用戶協議';
            }
            if (this.$route.name == 'loginlog') {
                this.show_home_icon = true;
                this.headertext = '登陸日誌';
            }
            if (this.$route.name == 'changepass') {
                this.show_home_icon = true;
                this.headertext = '修改密碼';
            }
            if (this.$route.name == 'rule') {
                this.show_home_icon = true;
                this.show_gametitle = true;
                this.headertext = '玩法規則';
            }
            if (this.$route.name == 'duijiangguize') {
                this.show_home_icon = true;
                this.headertext = '兌獎規則';
            }
            if (this.$route.name == 'historyopen') {
                this.show_home_icon = true;
                this.show_gametitle = true;
                this.headertext = '歷史開獎';
            }
            if (this.$route.name == 'betinfo') {
                this.show_home_icon = true;
                this.headertext = '下注明細';
            }
            if (this.$route.name == 'report') {
                this.show_home_icon = true;
                this.headertext = '結算報表';
            }
            if (this.$route.name == 'userbet') {
                this.show_home_icon = true;
                this.show_gametitle = true;
            }
            this.gamebet.game_list = this.$store.state.game_list;
            this.gamebet.gid = this.$store.state.checked_gid;
            this.gamebet.gname = this.$store.state.checked_gname;
            this.gamebet.plist = this.$store.state.playing_list;
            this.gamebet.playing = this.$store.state.playing;
            this.gamebet.pname = this.$store.state.activeName;
            this.axios.post("/user/get_member_credit", { uid: this.$store.state.uid }).then(result => {
                if (result.data.status == 1 && result.data.msg == "请求成功") {
                    this.usdt = result.data.res.usdt;
                }
            })
        },

        methods: {
            change_show_gamelist() {
                this.show_playlist = false;
                this.show_gamelist = !this.show_gamelist;
            },
            change_show_playlist() {
                this.show_gamelist = false;
                this.show_playlist = !this.show_playlist;
            },
            change_play(play) {
                // console.log(play);
                this.show_playlist = false;
                this.$store.state.playing = this.gamebet.playing = play.play;
                this.$store.state.activeName = this.gamebet.pname = play.text;
                this.$nextTick(() => {
                    this.$refs.child.change_play();
                })
            },
            change_game(gid, gname) {
                console.log(this.$route.name);
                this.show_gamelist = false;
                this.gamebet.game_list = this.$store.state.game_list;
                this.$store.state.checked_gid = this.gamebet.gid = gid;
                this.$store.state.checked_gname = this.gamebet.gname = gname;
                if (this.$route.name == 'gameLobby') {
                    this.$store.state.playing_list = this.gamebet.plist = set_playing(gid);
                    this.$store.state.playing = this.gamebet.playing = this.$store.state.playing_list[0].play;
                    this.$store.state.activeName = this.gamebet.pname = this.$store.state.playing_list[0].text;
                    this.axios.get("/main/index_header_mobile?gid=" + `${gid != '' ? gid : this.$store.state.checked_gid}`).then(result => {
                        this.$router.push(
                            {
                                path: '/home/userbet',
                                name: 'userbet',
                                params: {
                                    data: JSON.stringify(result.data)
                                }
                            }
                        );
                    });
                }
                if (this.$route.name == 'userbet') {
                    this.$store.state.playing_list = this.gamebet.plist = set_playing(gid);
                    this.$store.state.playing = this.gamebet.playing = this.$store.state.playing_list[0].play;
                    this.$store.state.activeName = this.gamebet.pname = this.$store.state.playing_list[0].text;
                    this.$refs.child.change_game(gid);
                }
                if (this.$route.name == 'userinfo') {
                    this.$refs.child.get_credit_data(gid);
                }
                if (this.$route.name == 'rule') {
                    this.$refs.child.change_game(gname);
                }
                if (this.$route.name == 'historyopen') {
                    this.$refs.child.change_lottery(gid);
                }
            },
            get_report() {
                this.axios.post("/order/close_order", { user_id: this.$store.state.userinfo.user_id }).then((result) => {
                    if (result.data.status == 1 && result.data.msg === "请求成功") {
                        this.headertext = '結算報表';
                        this.show_menu(false);
                        this.$router.push(
                            {
                                path: '/home/report',
                                name: 'report',
                                params: {
                                    data: JSON.stringify(result.data.res)
                                }
                            }
                        );
                    }
                })
            },
            go_betinfo() {
                this.axios.get(`/order/open_order?limit=5&page=1`).then(result => {
                    if (result.data.status == 1 && result.data.msg === "请求成功！") {
                        this.headertext = '下注明細';
                        this.show_menu(false);
                        this.$router.push(
                            {
                                path: '/home/betinfo',
                                name: 'betinfo',
                                params: {
                                    data: JSON.stringify(result.data.res)
                                }
                            }
                        );
                    }
                })
            },
            get_his() {
                this.gamebet.gid = this.$store.state.game_list[0].id;
                this.gamebet.gname = this.$store.state.game_list[0].gname;
                this.axios.post("/betresult/index",
                    {
                        gid: this.gamebet.gid,
                        limit: 20,
                        page: 1,
                        start_time: this.$filters.time(new Date(new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 30)).getTime() / 1000),
                        end_time: this.$filters.time((new Date().getTime()) / 1000),
                    }
                ).then((result) => {
                    if (result.data.status == 1 && result.data.msg === "请求成功！") {
                        this.headertext = '歷史開獎';
                        this.show_menu(false);
                        this.$router.push(
                            {
                                path: '/home/historyopen',
                                name: 'historyopen',
                                params: {
                                    data: JSON.stringify(result.data.res)
                                }
                            }
                        );
                    }
                })
            },
            go_rule() {
                this.headertext = '玩法規則';
                this.show_menu(false);
                this.show_gamelist = false;
                this.gamebet.gid = this.$store.state.game_list[0].id;
                this.gamebet.gname = this.$store.state.game_list[0].gname;
                this.$router.push(
                    {
                        path: '/home/rule',
                        name: 'rule',
                        params: {}
                    }
                );
            },
            go_changepw() {
                this.headertext = '修改密碼';
                this.show_menu(false);
                this.$router.push(
                    {
                        path: '/home/changepass',
                        name: 'changepass',
                        params: {}
                    }
                );
            },
            get_logs() {
                this.axios.post("/log/login_log").then(result => {
                    if (result.status && result.data.msg === "请求成功") {
                        this.headertext = '用戶協議';
                        this.show_menu(false);
                        this.$router.push(
                            {
                                path: '/home/loginlog',
                                name: 'loginlog',
                                params: {
                                    data: JSON.stringify(result.data.res)
                                }
                            }
                        );
                    }
                });
            },
            go_xieyi() {
                this.headertext = '用戶協議';
                this.show_menu(false);
                this.$router.push('/home/xieyi');
            },
            get_info() {
                this.axios.post("/user/credit_data", { gid: this.$store.state.game_list[0].id }).then((result) => {
                    if (result.data.status == 1) {
                        this.headertext = '信用資料';
                        this.show_menu(false);
                        this.show_gamelist = false;
                        this.gamebet.gid = this.$store.state.game_list[0].id;
                        this.gamebet.gname = this.$store.state.game_list[0].gname;
                        this.$router.push(
                            {
                                path: '/home/userinfo',
                                name: 'userinfo',
                                params: {
                                    data: JSON.stringify(result.data.res)
                                }
                            }
                        );
                    }
                })
            },
            go_djgz() {
                this.axios.post("/game/rules", { user_id: this.$store.state.userinfo.user_id }).then((result) => {
                    if (result.data.status == 1) {
                        this.headertext = '兌獎規則';
                        this.show_menu(false);
                        this.$router.push(
                            {
                                path: '/home/duijiangguize',
                                name: 'duijiangguize',
                                params: {
                                    data: JSON.stringify(result.data.res)
                                }
                            }
                        );
                    }
                })
            },
            confirm_quit() {
                this.axios.post(`/login/logout`).then(result => {
                    if (result.data.status && result.data.msg === "已退出登录！") {
                        sessionStorage.clear();
                        this.$store.state.isLogin = false;
                        this.$store.state.userinfo = "";
                        this.$message({
                            type: "success",
                            message: "已退出!"
                        });
                        this.$emit('quit');
                        this.$router.replace('/');
                        window.setTimeout(() => {
                            window.location.reload()
                        }, 100);
                    }
                });
            },
            show_menu(show = true) {
                if (show && this.menustyle.transform == 'translateX(-112px)') {
                    document.documentElement.scrollTop = 0;
                    this.menustyle.transform = 'translateX(0)';
                    this.homestyle.transform = 'translateX(112px)';
                    this.menustyle.webkitTransform = 'translateX(0)';
                    this.homestyle.webkitTransform = 'translateX(112px)';
                    document.body.style.overflow = 'hidden';
                } else {
                    this.menustyle.transform = 'translateX(-112px)';
                    this.homestyle.transform = 'none';
                    this.menustyle.webkitTransform = 'translateX(-112px)';
                    this.homestyle.webkitTransform = 'none';
                    document.body.style.overflow = 'auto';
                }
            },
            change_color(color) {
                if (color == null) {
                    return;
                }
                let strs = color.split(',')
                let h = strs[0].match(/\d+/g)[0];
                let s = strs[1].split('%')[0];
                let l = strs[2].split('%')[0];
                if (l < 95) {
                    l = 90;
                }
                if (s > 35) {
                    s = 90;
                }
                let themeColorLight = `hsl(${h},${s}%,${l}%)`;
                let themeColorhalf = `hsl(${h},30%,75%)`;
                this.$emit('get_color', color, themeColorLight, themeColorhalf);
            },
        },
    }
</script>

<style scoped>
    .home {
        position: relative;
    }
    .home-view {
        width: 100%;
        transform: none;
        -webkit-transform: none;
        transition: all linear 0.2s;
    }
    .header {
        display: inline-block;
        position: fixed;
        width: 100%;
        text-align: center;
        box-shadow: 0 1px 2px 0 #333;
        z-index: 2000;
    }
    .topicon {
        background-color: rgba(0, 0, 0, 0.3);
        background-position: center center;
        background-repeat: no-repeat;
        display: inline-block;
        color: #fff;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        position: absolute;
        top: 4px;
    }
    .menu {
        left: 4px;
        background-image: url("../assets/infoBtn.png");
    }
    .quit {
        right: 4px;
        background-image: url("../assets/quit.png");
    }
    .home-icon {
        right: 4px;
        background-image: url("../assets/home.png");
    }
    .header-title {
        display: inline-block;
        color: #fff;
        height: 38px;
        line-height: 38px;
        font-weight: bold;
    }
    .header-title span {
        font-size: 16px;
    }
    .gametitle,
    .playtitle {
        display: inline-block;
    }
    .gametitle {
        margin-right: 10px;
    }
    .gamelist,
    .playlist {
        position: absolute;
        top: 38px;
        left: 0;
        width: 100%;
        background: rgba(0, 0, 0, 0.8);
        text-align: center;
    }
    .gamelist a,
    .playlist a {
        font-size: 14px;
        color: #fff;
        float: left;
        width: 33.33%;
        height: 34px;
        line-height: 32px;
        text-decoration: none;
        border-bottom: 1px solid #444;
        border-right: 1px solid #444;
        box-sizing: border-box;
        text-shadow: none;
        font-weight: bold;
    }
    .gamelist a.active,
    .playlist a.active {
        color: #ff0;
        background: rgba(0, 0, 0, 0.5);
        border-bottom: 2px solid #ff0;
    }
    .menu-left {
        position: absolute;
        top: 0;
        height: 100vh;
        width: 112px;
        background-image: url("../assets/leftBg.jpg");
        transform: translateX(-112px);
        -webkit-transform: translateX(-112px);
        transition: all linear 0.2s;
        z-index: 2000;
    }
    .menu-head {
        width: 40px;
        /* height: 120px; */
        margin: 13px 0 0 14px;
        color: #fff;
    }
    .username {
        height: 48px;
        line-height: 24px;
        text-align: center;
        color: #fff;
        width: 80px;
        font-weight: bold;
    }
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: inline;
    }
    ul a {
        color: #f6f6f6 !important;
        text-decoration: none;
        font-weight: 700;
    }
    ul li {
        width: 112px;
        height: 34px;
        line-height: 34px;
        margin-top: 1px;
        background: rgba(0, 0, 0, 0.1);
        font-size: 14px;
        font-weight: bold;
    }
    .iconL1,
    .iconL2,
    .iconL3,
    .iconL4,
    .iconL5,
    .iconL6,
    .iconL7,
    .iconL8,
    .iconL9,
    .icon-chat,
    .icon-wallet {
        width: 20px;
        height: 34px;
        background: url("../assets/iconL.png") no-repeat;
        float: left;
        margin: 0 5px;
    }
    .iconL2 {
        background-position: 0 -34px;
    }
    .iconL2 {
        background-position: 0 0px;
    }
    .iconL3 {
        background-position: 0 -68px;
    }
    .iconL4 {
        background-position: 0 -102px;
    }
    .iconL5 {
        background-position: 0 -136px;
    }
    .iconL6 {
        background-position: 0 -170px;
    }
    .iconL7 {
        background-position: 0 -204px;
    }
    .iconL8 {
        background-position: 0 -238px;
    }
    .iconL9 {
        background-position: 0 -272px;
    }
    .color-item {
        height: 20px;
        width: 30px;
        border-radius: 4px;
    }
    .usdt {
        color: #fff;
        text-align: center;
        margin-top: 50px;
    }
</style>