<template>
    <div class="wlian">
        <el-row type="flex">
            <el-col :span="24" v-for="item in wlian" :key="item.index">
                <div class="bet-title">
                    <span>{{item.ballname}}</span>
                </div>
                <div class="wlian_which_num">
                    <el-radio-group v-model="radio" fill="" size="small" text-color="#fff" @change="wlian_change_num">
                        <el-radio-button label="wlian2">二尾连</el-radio-button>
                        <el-radio-button label="wlian3">三尾连</el-radio-button>
                        <el-radio-button label="wlian4">四尾连</el-radio-button>
                        <el-radio-button label="wlian5">五尾连</el-radio-button>
                    </el-radio-group>
                    <el-radio-group class="type-radio" v-model="typeRadio" @change="reset">
                        <el-radio label="0">单注/复式</el-radio>
                        <el-radio label="1">胆拖</el-radio>
                    </el-radio-group>
                    <div v-if="typeRadio==1" style="margin-top:10px">
                        <div>
                            <span style="color:#F56C6C">已选胆：</span>
                            <span>{{dan.toString()}}</span>
                        </div>
                        <div>
                            <span style="color:#F56C6C">已选拖：</span>
                            <span>{{tuo.toString()}}</span>
                        </div>
                    </div>
                </div>
                <el-row class="bet-items" :gutter="6">
                    <el-col :span="24" v-for="i in item.betclass" :key="i.index">
                        <div class="bet-items-info" :style="{backgroundColor:i.bg}" @click="click_item(i)">
                            <span class="bet-name">{{i.name}} 尾</span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <span class="betclassname_lxiao" v-for="v in i.betclass_nums" :key="v.index">
                                <span v-if="v.color=='red'" class="wlian_nums_red">{{v.num}}</span>
                                <span v-if="v.color=='blue'" class="wlian_nums_blue">{{v.num}}</span>
                                <span v-if="v.color=='green'" class="wlian_nums_green">{{v.num}}</span>
                            </span>
                            <span v-show="i.bg=='#ffef3a'" class="select-icon"><Select style="width: 24px; height: 24px;" /></span>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                wlian: [
                    {
                        ballname: "尾连",
                        betclass: [
                            {
                                title: "尾连", name: "0", visible: false, static_bonus: "", val: "", disabled: false, subname: "12_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red", isChoose: false, bgcolor: "#fff",
                                betclass_nums: [{ num: 10, color: "blue" }, { num: 20, color: "blue" }, { num: 30, color: "red" }, { num: 40, color: "red" }], bg: '#F2F3F8', isSelected: false,
                            },
                            {
                                title: "尾连", name: "1", visible: false, static_bonus: "", val: "", disabled: false, subname: "12_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red", isChoose: false, bgcolor: "#fff",
                                betclass_nums: [{ num: 1, color: "red" }, { num: 11, color: "green" }, { num: 21, color: "green" }, { num: 31, color: "blue" }, { num: 41, color: "blue" }], bg: '#F2F3F8', isSelected: false,
                            },
                            {
                                title: "尾连", name: "2", visible: false, static_bonus: "", val: "", disabled: false, subname: "12_0_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue", isChoose: false, bgcolor: "#fff",
                                betclass_nums: [{ num: 2, color: "red" }, { num: 12, color: "red" }, { num: 22, color: "green" }, { num: 32, color: "green" }, { num: 42, color: "blue" }], bg: '#F2F3F8', isSelected: false,
                            },
                            {
                                title: "尾连", name: "3", visible: false, static_bonus: "", val: "", disabled: false, subname: "12_0_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue", isChoose: false, bgcolor: "#fff",
                                betclass_nums: [{ num: 3, color: "blue" }, { num: 13, color: "red" }, { num: 23, color: "red" }, { num: 33, color: "green" }, { num: 43, color: "green" }], bg: '#F2F3F8', isSelected: false,
                            },
                            {
                                title: "尾连", name: "4", visible: false, static_bonus: "", val: "", disabled: false, subname: "12_0_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green", isChoose: false, bgcolor: "#fff",
                                betclass_nums: [{ num: 4, color: "blue" }, { num: 14, color: "blue" }, { num: 24, color: "red" }, { num: 34, color: "red" }, { num: 44, color: "green" }], bg: '#F2F3F8', isSelected: false,
                            },
                            {
                                title: "尾连", name: "5", visible: false, static_bonus: "", val: "", disabled: false, subname: "12_0_5", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green", isChoose: false, bgcolor: "#fff",
                                betclass_nums: [{ num: 5, color: "green" }, { num: 15, color: "blue" }, { num: 25, color: "blue" }, { num: 35, color: "red" }, { num: 45, color: "red" }], bg: '#F2F3F8', isSelected: false,
                            },
                            {
                                title: "尾连", name: "6", visible: false, static_bonus: "", val: "", disabled: false, subname: "12_0_6", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red", isChoose: false, bgcolor: "#fff",
                                betclass_nums: [{ num: 6, color: "green" }, { num: 16, color: "green" }, { num: 26, color: "blue" }, { num: 36, color: "blue" }, { num: 46, color: "red" }], bg: '#F2F3F8', isSelected: false,
                            },
                            {
                                title: "尾连", name: "7", visible: false, static_bonus: "", val: "", disabled: false, subname: "12_0_7", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red", isChoose: false, bgcolor: "#fff",
                                betclass_nums: [{ num: 7, color: "red" }, { num: 17, color: "green" }, { num: 27, color: "green" }, { num: 37, color: "blue" }, { num: 47, color: "blue" }], bg: '#F2F3F8', isSelected: false,
                            },
                            {
                                title: "尾连", name: "8", visible: false, static_bonus: "", val: "", disabled: false, subname: "12_0_8", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue", isChoose: false, bgcolor: "#fff",
                                betclass_nums: [{ num: 8, color: "red" }, { num: 18, color: "red" }, { num: 28, color: "green" }, { num: 38, color: "green" }, { num: 48, color: "blue" }], bg: '#F2F3F8', isSelected: false,
                            },
                            {
                                title: "尾连", name: "9", visible: false, static_bonus: "", val: "", disabled: false, subname: "12_0_9", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue", isChoose: false, bgcolor: "#fff",
                                betclass_nums: [{ num: 9, color: "blue" }, { num: 19, color: "red" }, { num: 29, color: "red" }, { num: 39, color: "green" }, { num: 49, color: "green" }], bg: '#F2F3F8', isSelected: false,
                            },
                        ]
                    }
                ],
                radio: 'wlian2',
                typeRadio: '0',
                // 选择的个数，限制7个
                wlian_count: 0,
                /**尾连下注金额 */
                zhengxiao: "",
                zhengxiaopl: '',
                dan: [],//选中的胆
                tuo: [],//选中的拖
            }
        },
        methods: {
            wlian_change_num(label) {
                this.$emit('regainLoss', label)
                this.reset();
                this.get_lose();
            },
            /**显示赔率等数据 */
            get_lose() {
                let lose_data = this.$store.state.lose_data;
                //特码
                for (let j = 0; j < this.wlian[0].betclass.length; j++) {
                    lose_data.forEach(item => {
                        if (item.name.includes(this.wlian[0].betclass[j].name)) {
                            this.wlian[0].betclass[j].static_bonus = item.rate;
                            this.wlian[0].betclass[j].straightbet_high = item.other.straightbet_high;
                            this.wlian[0].betclass[j].straightbet_low = item.other.straightbet_low;
                        }
                    });
                }
            },
            /**检查是否满足快捷输入 */
            check_fast_val(val) {
                if (val) {
                    return val;
                }
                else {
                    val = this.$parent.get_fast();
                    return val;
                }
            },
            /**重填，重置 */
            reset() {
                this.wlian_val = "";
                this.wlian_count = 0;
                for (let i = 0; i < this.wlian[0].betclass.length; i++) {
                    this.wlian[0].betclass[i].val = false;
                    // this.lxiao[0].betclass[i].disabled=false;
                    this.wlian[0].betclass[i].isSelected = false;
                    this.wlian[0].betclass[i].bg = "#F2F3F8";
                }
                this.dan = [];
                this.tuo = [];
            },
            /**多选框点击 */
            click_item(ball) {
                if (this.typeRadio == '0') {
                    if (ball.isSelected) {
                        ball.isSelected = false;
                        ball.bg = '#F2F3F8';
                        this.wlian_count -= 1;
                    } else {
                        ball.bg = '#ffef3a';
                        ball.isSelected = true;
                        this.wlian_count += 1;
                    }
                } else if (this.typeRadio == '1') {
                    let length = this.radio[this.radio.length - 1] - 1;
                    if (!ball.isSelected) {
                        if (this.dan.length < length) {
                            this.dan.push(ball.name);
                            ball.bg = '#409eff';
                        } else {
                            this.tuo.push(ball.name);
                            ball.bg = '#ffef3a';
                        }
                        if (this.dan.length == length && this.tuo.length > 0) {
                            this.wlian[0].betclass.forEach(item => {
                                this.dan.forEach(i => {
                                    if (item.name == i) {
                                        item.bg = '#409eff';
                                        item.isSelected = true;
                                    }
                                })
                            });
                        }
                        ball.isSelected = true;
                        this.wlian_count += 1;
                    } else {
                        if (this.dan.length == length && this.tuo.length > 0) {
                            if (this.dan.includes(ball.name)) {
                                return this.$toast.fail('请先取消所选的拖，再重新选取胆');
                            }
                        }
                        if (this.dan.length < length && this.tuo.length == 0) {
                            this.dan.splice(this.dan.findIndex(item => item == ball.name), 1)
                        }
                        if (this.dan.length == length && this.tuo.length == 0) {
                            this.dan.splice(this.dan.findIndex(item => item == ball.name), 1)
                        }
                        if (this.dan.length == length && this.tuo.length > 0) {
                            this.tuo.splice(this.tuo.findIndex(item => item == ball.name), 1);
                        }
                        ball.isSelected = false;
                        ball.bg = '#F2F3F8';
                        this.wlian_count -= 1;
                    }
                }
            },
            tuo_dan_nums(dan = [], tuo = []) {
                let list = [];
                tuo.forEach(item => {
                    list.push((dan.toString() + `,${item}`).split(','));
                })
                return list;
            },
            //快速下注
            fast_sub_bet() {
                return this.sub_bet();
            },
            /**提交，下注 */
            sub_bet() {
                let list = [];
                let index = 0;
                let level = '';
                if (this.radio == "wlian2") {
                    index = 2;
                    level = '12_0_0';
                }
                if (this.radio == "wlian3") {
                    index = 3;
                    level = '12_1_0';
                }
                if (this.radio == "wlian4") {
                    index = 4;
                    level = '12_2_0';
                }
                if (this.radio == "wlian5") {
                    index = 5;
                    level = '12_3_0';
                }
                if (this.wlian_count < index) {
                    return `至少选择${index}个号码`;
                } else {
                    if (this.wlian_count > 0) {
                        for (let i = 0; i < this.wlian.length; i++) {
                            for (let j = 0; j < this.wlian[i].betclass.length; j++) {
                                if (this.wlian[i].betclass[j].isSelected) {
                                    // this.wlian[i].betclass[j].val = this.wlian_val;
                                    list.push(this.wlian[i].betclass[j]);
                                }
                            }
                        }
                        let choose_num = [];
                        for (let i = 0; i < list.length; i++) {
                            choose_num.push(list[i].name);
                        }
                        //多选号码，重新组合成注单
                        let bet_obj = list[0];
                        // console.log(bet_obj);
                        list = [];
                        let new_arr = this.typeRadio == '0' ? this.combination_arr_elem(choose_num, index) : this.tuo_dan_nums(this.dan, this.tuo);
                        for (let i = 0; i < new_arr.length; i++) {
                            list.push(
                                {
                                    title: `${index}尾连`,
                                    name: new_arr[i].toString(),
                                    visible: bet_obj.visible,
                                    static_bonus: bet_obj.static_bonus,
                                    val: bet_obj.val,
                                    subname: level,
                                    straightbet_high: bet_obj.straightbet_high,
                                    straightbet_low: bet_obj.straightbet_low,
                                    bonus_high: bet_obj.bonus_high,
                                    sub_popover: bet_obj.sub_popover,
                                    color: bet_obj.color,
                                    isChoose: bet_obj.isChoose,
                                }
                            )
                        }
                    }
                }
                //筛//筛选最小的赔率
                // console.log(list);
                for (let i = 0; i < list.length; i++) {
                    list[i].ball_list = list[i].name.split(',');
                    list[i].ball_loss_list = [];
                    // console.log(list[i].ball_list);
                    for (let k = 0; k < list[i].ball_list.length; k++) {
                        for (let j = 0; j < this.wlian[0].betclass.length; j++) {
                            // console.log(this.wlian[0].betclass[j].name, list[i].ball_list[k]);
                            if (this.wlian[0].betclass[j].name == list[i].ball_list[k]) {
                                list[i].ball_loss_list.push({
                                    loss: this.wlian[0].betclass[j].static_bonus,
                                    ball: this.wlian[0].betclass[j].name,
                                });
                            }
                        }
                    }
                    let sitem = list[i].ball_loss_list.sort((a, b) => a.loss - b.loss)[0];
                    // console.log(sitem);
                    list[i].ball_min = list[i].name.includes('0') ? 0 : sitem.ball;
                    list[i].static_bonus = list[i].name.includes('0') ? list[i].ball_loss_list.find(item => item.ball == 0).loss : sitem.loss;
                }
                // console.log(list)
                return list;
            },
            /**重复上次下单 */
            repeat_last_bet(list) {
                // console.log(list)
                for (let i = 0; i < list.length; i++) {
                    if (!Array.isArray(list[i].title)) {
                        list[i].title = list[i].title.split(",");
                    }
                    for (let j = 0; j < this.wlian.length; j++) {
                        for (let k = 0; k < this.wlian[j].betclass.length; k++) {
                            // console.log(this.wlian[j].betclass[k].name)
                            if (list[i].title.find(n => n == this.wlian[j].betclass[k].name)) {
                                this.wlian[j].betclass[k].val = true;
                            }
                        }
                    }
                }
                this.wlian_count = 0;
                for (let i = 0; i < this.wlian[0].betclass.length; i++) {
                    if (this.wlian[0].betclass[i].val) {
                        this.wlian_count += 1;
                    }
                }
                if (this.wlian_count >= 8) {
                    for (let i = 0; i < this.wlian[0].betclass.length; i++) {
                        if (this.wlian[0].betclass[i].val == false) {
                            this.wlian[0].betclass[i].disabled = true;
                        }
                    }
                }
            },
            combination_arr_elem(nu, groupl, res) {
                let result = res ? res : [];
                let nuc = nu.slice(0);
                let item = nuc.shift();
                item = item.constructor === Array ? item : [item];
                (function func(item, nuc) {
                    let itemc;
                    let nucc = nuc.slice(0);
                    let margin = groupl - item.length
                    if (margin == 0) {
                        result.push(item);
                        return;
                    }
                    if (margin == 1) {
                        for (let j in nuc) {
                            itemc = item.slice(0);
                            itemc.push(nuc[j]);
                            result.push(itemc);
                        }
                    }
                    if (margin > 1) {
                        itemc = item.slice(0);
                        itemc.push(nucc.shift());
                        func(itemc, nucc);
                        if (item.length + nucc.length >= groupl) {
                            func(item, nucc);
                        }
                    }
                })(item, nuc);
                if (nuc.length >= groupl) {
                    return this.combination_arr_elem(nuc, groupl, result);
                } else {
                    return result;
                }
            },
        },
    }
</script>

<style scoped>
    .wlian_which_num {
        text-align: center;
        padding: 10px;
    }
    .wlian_nums_red,
    .wlian_nums_blue,
    .wlian_nums_green {
        display: inline-block;
        width: 24px;
        height: 24px;
        line-height: 24px;
        border-radius: 50%;
        color: #fff;
        text-align: center;
        margin-left: 5px;
        font-weight: bold;
    }
    .wlian_nums_red {
        background-color: #f56c6c;
    }
    .wlian_nums_blue {
        background-color: #409eff;
    }
    .wlian_nums_green {
        background-color: #67c23a;
    }
    .wlian-val {
        width: 120px !important;
        margin: 0 20px;
    }

    .wlian .bet-items-one .bet-name {
        float: none;
    }
    .wlian .bet-items-one .wlian-input {
        float: none;
        width: 100px;
    }
    .wlian .bet-items-one .bet-lose {
        text-align: center;
        width: 60px;
    }
    .type-radio {
        width: 100%;
        padding: 10px 25% 0 25%;
    }
</style>