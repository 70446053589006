<template>
<div class="bao3">
    <el-row type="flex">
        <el-col :span="24" v-for="item in bao3" :key="item.index">
            <div class="bet-title">
                <span>{{item.ballname}}</span>
            </div>
            <el-row class="bet-items" :gutter="6">
                <el-col :span="8" v-for="i in item.betclass" :key="i.index">
                    <div class="bet-items-info" :style="{backgroundColor:i.bg}" @click="click_item(i)">
                    <span class="bet-name">
                        <span class="betclassname_old_pcdd" :style="{'background-color':i.color}">{{i.name}}</span>
                    </span>
                    <span class="bet-lose">{{i.static_bonus}}</span>
                    <!-- <el-checkbox label="" class="bao3" v-model="i.val" @change="checkbox_change" :disabled="i.disabled"></el-checkbox> -->
                    </div>
                </el-col>
            </el-row>
            <!-- <div style="background-color:#fff;padding:6px 0;">
                <span>下注金额：</span>
                <el-input class="bao3-val" v-model="bao3_val" size="mini" v-enter-number v-on:focus="bao3_val=check_fast_val(bao3_val)"></el-input>
            </div> -->
        </el-col>
    </el-row>
</div>
</template>

<script>
import { link } from 'fs';
export default {
    data() {
        return {
            bao3: [
                {
                ballname: "特码包三",
                betclass: [
                    { title: "特码包三", bg:'#F2F3F8', name: "0", isSelected: false, static_bonus: "", val:'' , subname:"2_0_0", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#909399"},
                    { title: "特码包三", bg:'#F2F3F8', name: "1", isSelected: false, static_bonus: "", val:'' , subname:"2_0_0", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#67C23A"},
                    { title: "特码包三", bg:'#F2F3F8', name: "2", isSelected: false, static_bonus: "", val:'' , subname:"2_0_0", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#409EFF"},
                    { title: "特码包三", bg:'#F2F3F8', name: "3", isSelected: false, static_bonus: "", val:'' , subname:"2_0_0", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#F56C6C"},
                    { title: "特码包三", bg:'#F2F3F8', name: "4", isSelected: false, static_bonus: "", val:'' , subname:"2_0_0", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#67C23A"},
                    { title: "特码包三", bg:'#F2F3F8', name: "5", isSelected: false, static_bonus: "", val:'' , subname:"2_0_0", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#409EFF"},
                    { title: "特码包三", bg:'#F2F3F8', name: "6", isSelected: false, static_bonus: "", val:'' , subname:"2_0_0", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#F56C6C"},
                    { title: "特码包三", bg:'#F2F3F8', name: "7", isSelected: false, static_bonus: "", val:'' , subname:"2_0_0", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#67C23A"},
                    { title: "特码包三", bg:'#F2F3F8', name: "8", isSelected: false, static_bonus: "", val:'' , subname:"2_0_0", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#409EFF"},
                    { title: "特码包三", bg:'#F2F3F8', name: "9", isSelected: false, static_bonus: "", val:'' , subname:"2_0_0", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#F56C6C"},
                    { title: "特码包三", bg:'#F2F3F8', name: "10", isSelected: false, static_bonus: "", val:'' , subname:"2_0_0", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#67C23A"},
                    { title: "特码包三", bg:'#F2F3F8', name: "11", isSelected: false, static_bonus: "", val:'' , subname:"2_0_0", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#409EFF"},
                    { title: "特码包三", bg:'#F2F3F8', name: "12", isSelected: false, static_bonus: "", val:'' , subname:"2_0_0", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#F56C6C"},
                    { title: "特码包三", bg:'#F2F3F8', name: "13", isSelected: false, static_bonus: "", val:'' , subname:"2_0_0", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#909399"},
                    { title: "特码包三", bg:'#F2F3F8', name: "14", isSelected: false, static_bonus: "", val:'' , subname:"2_0_0", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#909399"},
                    { title: "特码包三", bg:'#F2F3F8', name: "15", isSelected: false, static_bonus: "", val:'' , subname:"2_0_0", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#F56C6C"},
                    { title: "特码包三", bg:'#F2F3F8', name: "16", isSelected: false, static_bonus: "", val:'' , subname:"2_0_0", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#67C23A"},
                    { title: "特码包三", bg:'#F2F3F8', name: "17", isSelected: false, static_bonus: "", val:'' , subname:"2_0_0", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#409EFF"},
                    { title: "特码包三", bg:'#F2F3F8', name: "18", isSelected: false, static_bonus: "", val:'' , subname:"2_0_0", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#F56C6C"},
                    { title: "特码包三", bg:'#F2F3F8', name: "19", isSelected: false, static_bonus: "", val:'' , subname:"2_0_0", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#67C23A"},
                    { title: "特码包三", bg:'#F2F3F8', name: "20", isSelected: false, static_bonus: "", val:'' , subname:"2_0_0", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#409EFF"},
                    { title: "特码包三", bg:'#F2F3F8', name: "21", isSelected: false, static_bonus: "", val:'' , subname:"2_0_0", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#F56C6C"},
                    { title: "特码包三", bg:'#F2F3F8', name: "22", isSelected: false, static_bonus: "", val:'' , subname:"2_0_0", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#67C23A"},
                    { title: "特码包三", bg:'#F2F3F8', name: "23", isSelected: false, static_bonus: "", val:'' , subname:"2_0_0", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#409EFF"},
                    { title: "特码包三", bg:'#F2F3F8', name: "24", isSelected: false, static_bonus: "", val:'' , subname:"2_0_0", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#F56C6C"},
                    { title: "特码包三", bg:'#F2F3F8', name: "25", isSelected: false, static_bonus: "", val:'' , subname:"2_0_0", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#67C23A"},
                    { title: "特码包三", bg:'#F2F3F8', name: "26", isSelected: false, static_bonus: "", val:'' , subname:"2_0_0", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#409EFF"},
                    { title: "特码包三", bg:'#F2F3F8', name: "27", isSelected: false, static_bonus: "", val:'' , subname:"2_0_0", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#909399"},
                ]
                }
            ],
            // 选择的个数，限制7个
            bao3_count:0,
        }
    },

    methods: {
        //选取投注项
        click_item(item){
            if(item.isSelected){
                item.isSelected=false;
                item.bg='#F2F3F8';
                this.bao3_count-=1;
            }else{
                if(this.bao3_count>=3){
                    this.$toast('每次只能选择3个号码');
                }else{
                    item.bg='#ffef3a';
                    item.isSelected=true;
                    this.bao3_count+=1;
                }
            }
                // console.log(this.bao3_count)
            return item
        },
        //快速下注
        fast_sub_bet(){
            let list=[];
            //特码
            for(let i=0;i<this.bao3.length;i++){
                for(let j=0;j<this.bao3[i].betclass.length;j++){
                    if(this.bao3[i].betclass[j].isSelected){
                        list.push(this.bao3[i].betclass[j]);
                    }
                }
            }
            let name=`${list[0].name},${list[1].name},${list[2].name}`;
            list.length=1;
            let arr=JSON.parse(JSON.stringify(list));
            arr[0].name=name;
            return arr;
        },
        /**显示赔率等数据 */
        get_lose(){
            let bonus_high=this.$store.state.lottery.lose_data.bonus_high;
            let others=this.$store.state.lottery.lose_data.others;
            let static_bonus=this.$store.state.lottery.lose_data.static_bonus;
            //两面
            for(let i=0;i<this.bao3.length;i++){
                for(let j=0;j<this.bao3[i].betclass.length;j++){
                    this.bao3[i].betclass[j].static_bonus=static_bonus[0];
                    this.bao3[i].betclass[j].straightbet_high=others[0].straightbet_high;
                    this.bao3[i].betclass[j].straightbet_low=others[0].straightbet_low;
                }
            }
        },
        /**检查是否满足快捷输入 */
        check_fast_val(val){
            if(val){
                return val;
            }
            else{
                val=this.$parent.get_fast();
                return val;
            }
        },
        /**重填，重置 */
        reset(){
            this.bao3_count=0;
            for(let i=0;i<this.bao3[0].betclass.length;i++){
                // this.bao3[0].betclass[i].isSelected=false;
                // this.bao3[0].betclass[i].disabled=false;
                this.bao3[0].betclass[i].val="";
                this.bao3[0].betclass[i].isSelected=false;
                this.bao3[0].betclass[i].bg="#F2F3F8";
            }
        },
        /**多选框点击 */
        checkbox_change(v){
            v?this.bao3_count++:this.bao3_count--;
            //限制最多选取7个号码
            if(this.bao3_count==7){
                for(let i=0;i<this.bao3[0].betclass.length;i++){
                    if(this.bao3[0].betclass[i].val==false){
                        this.bao3[0].betclass[i].disabled=true
                    }
                }
            }
            else if(this.bao3_count<8){
                for(let i=0;i<this.bao3[0].betclass.length;i++){
                    if(this.bao3[0].betclass[i].val==false){
                        this.bao3[0].betclass[i].disabled=false
                    }
                }
            }
        },
        /**提交，下注 */
        sub_bet(){
            let list=[];
            if(this.bao3_count>0){
                for(let i=0;i<this.bao3.length;i++){
                    for(let j=0;j<this.bao3[i].betclass.length;j++){
                        if(this.bao3[i].betclass[j].isSelected){
                            list.push(this.bao3[i].betclass[j]);
                        }
                    }
                }
            }
            let name=`${list[0].name},${list[1].name},${list[2].name}`;
            list.length=1;
            let arr=JSON.parse(JSON.stringify(list));
            arr[0].name=name;
            return arr;
        },
        /**重复上次下单 */
        repeat_last_bet(list) {
            // console.log(list)
            for(let i=0;i<list.length;i++){
                for(let j=0;j<this.bao3.length;j++){
                    for(let k=0;k<this.bao3[j].betclass.length;k++){
                        if(this.bao3[j].betclass[k].title==list[i].title&&this.bao3[j].betclass[k].name==list[i].name){
                            this.bao3[j].betclass[k].val=true;
                        }
                    }
                }
            }
        },
    },
}
</script>

<style scoped>

/* .bao3-val{
    width: 120px !important;
    margin: 0 auto !important;
} */
</style>