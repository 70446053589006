<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-08-23 00:12:34
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-08-23 00:31:42
 * @FilePath: \mobile_vue3\src\components\menuitems\xieyi.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="xieyi">
        <ul>
            <li>
                <span>1、</span>
                <label>使用本公司網站的客戶，請留意閣下所在的國家或居住地的相關法律規定，如有疑問應就相關問題，尋求當地法律意見。</label>
            </li>
            <li>
                <span>2、</span>
                <label>若發生遭駭客入侵破壞行為或不可抗拒之災害導致網站故障或資料損壞、資料丟失等情況，我們將以本公司之後備資料為最後處理依據；為確保各方利益，請各會員投注後列印資料。本公司不會接受沒有列印資料的投訴。</label>
            </li>
            <li>
                <span>3、</span>
                <label>為避免糾紛，各會員在投注之後，務必進入下注狀況檢查及列印資料。若發現任何異常，請立即與代理商聯繫查證，一切投注將以本公司資料庫的資料為准，不得異議。如出现特殊网络情况或线路不稳定导致不能下注或下注失败。本公司概不负责。</label>
            </li>
            <li>
                <span>4、</span>
                <label>單一注單最高派彩上限為一百萬。</label>
            </li>
            <li>
                <span>5、</span>
                <label>開獎結果以官方公佈的結果為准。</label>
            </li>
            <li>
                <span>6、</span>
                <label>我們將竭力提供準確而可靠的開獎統計等資料，但並不保證資料絕對無誤，統計資料只供參考，並非是對客戶行為的指引，本公司也不接受關於統計數據產生錯誤而引起的相關投訴。</label>
            </li>
            <li>
                <span>7、</span>
                <label>本公司擁有一切判決及註消任何涉嫌以非正常方式下註之權利，在進行更深入調查期間將停止發放與其有關之任何彩金。客戶有責任確保自己的帳戶及密碼保密，如果客戶懷疑自己的資料被盜用，應立即通知本公司，並須更改其個人詳細資料。所有被盜用帳號之損失將由客戶自行負責。在某種特殊情況下，客人之信用額可能會出現透支。</label>
            </li>
            <li style="text-align:right;">管理層 敬啟</li>
        </ul>
        <div class="agree">我瞭解以並同意以上列明的協定和規則。</div>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {

            }
        },
        created() { },
        methods: {

        },
    }
</script>

<style scoped>
    .xieyi {
        background: #eceff0;
        padding: 0 5px;
        margin: 10px 0;
        font-size: 13px;
    }
    ul {
        padding: 0;
        margin: 0;
        float: left;
        list-style: none;
        width: 100%;
    }
    li {
        float: left;
        width: 100%;
        margin: 0;
        padding: 0;
        display: inline;
        margin-bottom: 7px;
        line-height: 18px;
    }
    li span {
        float: left;
        width: 5%;
        font-weight: 700;
        text-align: right;
    }
    li label {
        float: left;
        width: 93%;
        padding-left: 4px;
    }
    .agree {
        text-align: center;
        color: #409eff;
    }
</style>