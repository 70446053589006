<template>
    <div class="hongheima">
        <!-- 红黑 -->
        <el-row>
            <el-col :span="24" v-for="item in hhm" :key="item.index">
                <div class="bet-title">
                    <span>{{item.ballname}}</span>
                </div>
                <div style="margin:10px auto;text-align: center;">请先选择下注类型，再选择相应个数的号码</div>
                <el-row class="bet-items" :gutter="6">
                    <el-col :span="8" v-for="i in item.betclass" :key="i.index">
                        <div class="bet-items-info" :style="{backgroundColor:i.bg}" @click="click_item(i)">
                            <span class="bet-name">{{i.name}}</span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <span v-show="i.bg=='#ffef3a'" class="select-icon"><Select style="width: 24px; height: 24px;" /></span>
                        </div>
                    </el-col>
                    <el-col :span="24" style="margin-left:20px">
                        <el-checkbox-group  :disabled="!(hhm[0].betclass[0].isSelected||hhm[0].betclass[1].isSelected||hhm[0].betclass[2].isSelected)" v-model="selected_nums" @change="selected_nums_change">
                            <el-checkbox-button v-for="item of nums" :label="item" :key="item.num" :disabled="item.disabled">{{item.num}}</el-checkbox-button>
                        </el-checkbox-group>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import { link } from 'fs';
    export default {
        data() {
            return {
                /**红黑 */
                hhm: [
                    {
                        ballname: "红黑码",
                        betclass: [
                            { title: "红黑码", bg: '#F2F3F8', name: "四码红", isSelected: false, static_bonus: "", val: "", subname: "8_2_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "红黑码", bg: '#F2F3F8', name: "四码黑", isSelected: false, static_bonus: "", val: "", subname: "8_2_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "红黑码", bg: '#F2F3F8', name: "五码黑", isSelected: false, static_bonus: "", val: "", subname: "8_2_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    }
                ],
                nums: [{ num: 1, disabled: false }, { num: 2, disabled: false }, { num: 3, disabled: false }, { num: 4, disabled: false }, { num: 5, disabled: false }, { num: 6, disabled: false }],
                selected_nums: [],
                selected_name: '',
            }
        },

        methods: {
            //选取投注项
            click_item(item) {
                if (item.isSelected) {
                    item.isSelected = false;
                    item.bg = '#F2F3F8';
                } else {
                    this.reset();
                    item.bg = '#ffef3a';
                    item.isSelected = true;
                }
                return item
            },
            //快速下注
            fast_sub_bet() {
                let a = this.hhm[0].betclass.find(item => item.isSelected === true) || false;
                if (!a) {
                    return '选择下注类型'
                } else {
                    // console.log(this.selected_name)
                    if (a.name === '四码红' || a.name === '四码黑') {
                        if (this.selected_nums.length < 4) {
                            return '请选择4个号码';
                        }
                    } else {
                        if (this.selected_nums.length < 5) {
                            return '请选择5个号码';
                        }
                    }
                }
                let list = [];
                //红黑
                for (let i = 0; i < this.hhm.length; i++) {
                    for (let j = 0; j < this.hhm[i].betclass.length; j++) {
                        if (this.hhm[i].betclass[j].isSelected) {
                            list.push(this.hhm[i].betclass[j]);
                        }
                    }
                }
                let str = this.selected_nums.map(item => item.num).sort((a, b) => a - b);
                list = JSON.parse(JSON.stringify(list));
                list[0].name += `[${str.toString()}]`
                // console.log(list)
                return list;
            },
            //选择号码
            selected_nums_change(v) {
                // console.log(v)
                let len = v.length;
                let name = this.hhm[0].betclass.find(item => item.isSelected === true).name;
                let arr = this.nums.filter(item => {
                    if (!v.includes(item))
                        return item
                })
                if ((name === '四码红' || name === '四码黑')) {
                    len >= 4 ? arr.forEach(item => item.disabled = true) : arr.forEach(item => item.disabled = false);
                } else {
                    len >= 5 ? arr.forEach(item => item.disabled = true) : arr.forEach(item => item.disabled = false);
                }
                this.selected_name = name;
            },
            /**显示赔率等数据 */
            get_lose() {
                let bonus_high = this.$store.state.lottery.lose_data.bonus_high;
                let others = this.$store.state.lottery.lose_data.others;
                let static_bonus = this.$store.state.lottery.lose_data.static_bonus;
                //红黑
                // console.log(static_bonus)
                for (let i = 0; i < this.hhm.length; i++) {
                    for (let j = 0; j < this.hhm[i].betclass.length; j++) {
                        this.hhm[i].betclass[j].static_bonus = static_bonus[j];
                        this.hhm[i].betclass[j].straightbet_high = others[j].straightbet_high;
                        this.hhm[i].betclass[j].straightbet_low = others[j].straightbet_low;
                    }
                }
            },
            /**检查是否满足快捷输入 */
            check_fast_val(val) {
                if (val) {
                    return val;
                }
                else {
                    val = this.$parent.get_fast();
                    this.nums.forEach(item => item.disabled = false);
                    this.selected_nums = [];
                    return val;
                }
            },
            /**重填，重置 */
            reset() {
                //红黑
                for (let i = 0; i < this.hhm.length; i++) {
                    for (let j = 0; j < this.hhm[i].betclass.length; j++) {
                        this.hhm[i].betclass[j].val = "";
                        this.hhm[i].betclass[j].isSelected = false;
                        this.hhm[i].betclass[j].bg = "#F2F3F8";
                    }
                }
                this.nums.forEach(item => item.disabled = false);
                this.selected_nums = [];
            },
            /**提交，下注 */
            sub_bet() {
                let a = this.hhm[0].betclass.find(item => item.isSelected === true) || false;
                if (!a) {
                    this.$toast.fail('请选下注类型');
                    return [];
                }
                if (a.name === '四码红' || a.name === '四码黑') {
                    if (this.selected_nums.length !== 4) {
                        this.$toast.fail('请选择4个号码');
                        return [];
                    }
                } else {
                    if (this.selected_nums.length !== 5) {
                        this.$toast.fail('请选择5个号码');
                        return [];
                    }
                }
                let list = [];
                //红黑
                for (let i = 0; i < this.hhm.length; i++) {
                    for (let j = 0; j < this.hhm[i].betclass.length; j++) {
                        if (this.hhm[i].betclass[j].isSelected) {
                            list.push(this.hhm[i].betclass[j]);
                        }
                    }
                }
                let str = this.selected_nums.map(item => item.num).sort((a, b) => a - b);
                list = JSON.parse(JSON.stringify(list));
                list[0].name += `[${str.toString()}]`
                // console.log(list)
                return list;
            },
            /**重复上次下单 */
            repeat_last_bet(list) {
                // console.log(list)
                for (let i = 0; i < list.length; i++) {
                    //红黑
                    for (let j = 0; j < this.hhm.length; j++) {
                        for (let k = 0; k < this.hhm[j].betclass.length; k++) {
                            if (this.hhm[j].betclass[k].title == list[i].title && this.hhm[j].betclass[k].name == list[i].name) {
                                this.hhm[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                }
            },
        },
    }
</script>

<style scoped>
    .nums {
        text-align: center;
        margin: 5px;
    }
</style>