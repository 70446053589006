<template>
    <div class="k3-lm">
        <!-- 和值 -->
        <el-row>
            <el-col :span="24" v-for="item in sum_value" :key="item.index">
                <div class="bet-title">
                    <span>{{item.ballname}}</span>
                </div>
                <el-row class="bet-items" :gutter="6">
                    <el-col :span="8" v-for="i in item.betclass" :key="i.index">
                        <div class="bet-items-info" :style="{backgroundColor:i.bg}" @click="click_item(i)">
                            <span class="bet-name" style="color:#fff">{{i.name}}</span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <span v-show="i.bg=='#ffef3a'" class="select-icon"><Select style="width: 24px; height: 24px;" /></span>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <!-- 总和 -->
        <el-row>
            <el-col :span="24" v-for="item in sum" :key="item.index">
                <div class="bet-title">
                    <span>{{item.ballname}}</span>
                </div>
                <el-row class="bet-items" :gutter="6">
                    <el-col :span="8" v-for="i in item.betclass" :key="i.index">
                        <div class="bet-items-info" :style="{backgroundColor:i.bg}" @click="click_item(i)">
                            <span class="bet-name sum">{{i.name}}</span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <span v-show="i.bg=='#ffef3a'" class="select-icon"><Select style="width: 24px; height: 24px;" /></span>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <!-- 跨度 -->
        <el-row>
            <el-col :span="24" v-for="item in kd" :key="item.index">
                <div class="bet-title">
                    <span>{{item.ballname}}</span>
                </div>
                <el-row class="bet-items" :gutter="6">
                    <el-col :span="8" v-for="i in item.betclass" :key="i.index">
                        <div class="bet-items-info" :style="{backgroundColor:i.bg}" @click="click_item(i)">
                            <span class="bet-name kd">{{i.name}}</span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <span v-show="i.bg=='#ffef3a'" class="select-icon"><Select style="width: 24px; height: 24px;" /></span>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <!-- 组六 -->
        <el-row>
            <el-col :span="24" v-for="item in zl" :key="item.index">
                <div class="bet-title">
                    <span>{{item.ballname}}</span>
                </div>
                <el-row class="bet-items" :gutter="6">
                    <el-col :span="8" v-for="i in item.betclass" :key="i.index">
                        <div class="bet-items-info" :style="{backgroundColor:i.bg}" @click="click_item(i)">
                            <span class="bet-name kd">{{i.name=='杂六'?'杂':i.name}}</span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <span v-show="i.bg=='#ffef3a'" class="select-icon"><Select style="width: 24px; height: 24px;" /></span>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <!-- 三军 -->
        <el-row type="flex">
            <el-col :span="24" v-for="item in sj" :key="item.index">
                <div class="bet-title">
                    <span>{{item.ballname}}</span>
                </div>
                <el-row class="bet-items" :gutter="6">
                    <el-col :span="8" v-for="i in item.betclass" :key="i.index">
                        <div class="bet-items-info" :style="{backgroundColor:i.bg}" @click="click_item(i)">
                            <span class="bet-name kd">{{i.name}}</span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <span v-show="i.bg=='#ffef3a'" class="select-icon"><Select style="width: 24px; height: 24px;" /></span>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import { link } from 'fs';
    export default {
        data() {
            return {
                /**和值 */
                sum_value: [
                    {
                        ballname: "和值",
                        betclass: [
                            { title: "和值", bg: '#F2F3F8', name: "3", isSelected: false, static_bonus: "", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", bg: '#F2F3F8', name: "4", isSelected: false, static_bonus: "", val: "", subname: "0_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", bg: '#F2F3F8', name: "5", isSelected: false, static_bonus: "", val: "", subname: "0_0_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", bg: '#F2F3F8', name: "6", isSelected: false, static_bonus: "", val: "", subname: "0_0_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", bg: '#F2F3F8', name: "7", isSelected: false, static_bonus: "", val: "", subname: "0_0_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", bg: '#F2F3F8', name: "8", isSelected: false, static_bonus: "", val: "", subname: "0_0_5", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", bg: '#F2F3F8', name: "9", isSelected: false, static_bonus: "", val: "", subname: "0_0_6", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", bg: '#F2F3F8', name: "10", isSelected: false, static_bonus: "", val: "", subname: "0_0_7", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", bg: '#F2F3F8', name: "11", isSelected: false, static_bonus: "", val: "", subname: "0_0_7", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", bg: '#F2F3F8', name: "12", isSelected: false, static_bonus: "", val: "", subname: "0_0_6", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", bg: '#F2F3F8', name: "13", isSelected: false, static_bonus: "", val: "", subname: "0_0_5", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", bg: '#F2F3F8', name: "14", isSelected: false, static_bonus: "", val: "", subname: "0_0_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", bg: '#F2F3F8', name: "15", isSelected: false, static_bonus: "", val: "", subname: "0_0_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", bg: '#F2F3F8', name: "16", isSelected: false, static_bonus: "", val: "", subname: "0_0_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", bg: '#F2F3F8', name: "17", isSelected: false, static_bonus: "", val: "", subname: "0_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", bg: '#F2F3F8', name: "18", isSelected: false, static_bonus: "", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    }
                ],
                /**总和 */
                sum: [
                    {
                        ballname: "总和",
                        betclass: [
                            { title: "总和", bg: '#F2F3F8', name: "大", isSelected: false, static_bonus: "", val: "", subname: "8_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "总和", bg: '#F2F3F8', name: "小", isSelected: false, static_bonus: "", val: "", subname: "8_0_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "总和", bg: '#F2F3F8', name: "单", isSelected: false, static_bonus: "", val: "", subname: "8_0_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "总和", bg: '#F2F3F8', name: "双", isSelected: false, static_bonus: "", val: "", subname: "8_0_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "总和", bg: '#F2F3F8', name: "单大", isSelected: false, static_bonus: "", val: "", subname: "8_0_5", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "总和", bg: '#F2F3F8', name: "单小", isSelected: false, static_bonus: "", val: "", subname: "8_0_6", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "总和", bg: '#F2F3F8', name: "双大", isSelected: false, static_bonus: "", val: "", subname: "8_0_7", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "总和", bg: '#F2F3F8', name: "双小", isSelected: false, static_bonus: "", val: "", subname: "8_0_8", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    }
                ],
                /**跨度 */
                kd: [
                    {
                        ballname: "跨度",
                        betclass: [
                            { title: "跨度", bg: '#F2F3F8', name: "跨度[1]", isSelected: false, static_bonus: "", val: "", subname: "8_0_9", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "跨度", bg: '#F2F3F8', name: "跨度[2]", isSelected: false, static_bonus: "", val: "", subname: "8_0_10", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "跨度", bg: '#F2F3F8', name: "跨度[3]", isSelected: false, static_bonus: "", val: "", subname: "8_0_11", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "跨度", bg: '#F2F3F8', name: "跨度[4]", isSelected: false, static_bonus: "", val: "", subname: "8_0_12", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "跨度", bg: '#F2F3F8', name: "跨度[5]", isSelected: false, static_bonus: "", val: "", subname: "8_0_13", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    }
                ],
                /**组六 */
                zl: [
                    {
                        ballname: "组六",
                        betclass: [
                            { title: "总和", bg: '#F2F3F8', name: "半顺", isSelected: false, static_bonus: "", val: "", subname: "8_0_14", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "总和", bg: '#F2F3F8', name: "杂六", isSelected: false, static_bonus: "", val: "", subname: "8_0_15", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "总和", bg: '#F2F3F8', name: "全顺", isSelected: false, static_bonus: "", val: "", subname: "8_0_22", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "总和", bg: '#F2F3F8', name: "豹子通", isSelected: false, static_bonus: "", val: "", subname: "8_0_23", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    }
                ],
                /**三军 */
                sj: [
                    {
                        ballname: "三军",
                        betclass: [
                            { title: "三军", bg: '#F2F3F8', name: "1", isSelected: false, static_bonus: "", val: "", subname: "8_0_16", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "三军", bg: '#F2F3F8', name: "2", isSelected: false, static_bonus: "", val: "", subname: "8_0_17", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "三军", bg: '#F2F3F8', name: "3", isSelected: false, static_bonus: "", val: "", subname: "8_0_18", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "三军", bg: '#F2F3F8', name: "4", isSelected: false, static_bonus: "", val: "", subname: "8_0_19", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "三军", bg: '#F2F3F8', name: "5", isSelected: false, static_bonus: "", val: "", subname: "8_0_20", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "三军", bg: '#F2F3F8', name: "6", isSelected: false, static_bonus: "", val: "", subname: "8_0_21", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    }
                ],
            }
        },

        methods: {
            //选取投注项
            click_item(item) {
                if (item.isSelected) {
                    item.isSelected = false;
                    item.bg = '#F2F3F8';
                } else {
                    item.bg = '#ffef3a';
                    item.isSelected = true;
                }
                return item
            },
            //快速下注
            fast_sub_bet() {
                let list = [];
                //和值
                for (let i = 0; i < this.sum_value.length; i++) {
                    for (let j = 0; j < this.sum_value[i].betclass.length; j++) {
                        if (this.sum_value[i].betclass[j].isSelected) {
                            list.push(this.sum_value[i].betclass[j]);
                        }
                    }
                }
                //总和
                for (let i = 0; i < this.sum.length; i++) {
                    for (let j = 0; j < this.sum[i].betclass.length; j++) {
                        if (this.sum[i].betclass[j].isSelected) {
                            list.push(this.sum[i].betclass[j]);
                        }
                    }
                }
                //跨度
                for (let i = 0; i < this.kd.length; i++) {
                    for (let j = 0; j < this.kd[i].betclass.length; j++) {
                        if (this.kd[i].betclass[j].isSelected) {
                            list.push(this.kd[i].betclass[j]);
                        }
                    }
                }
                //组六
                for (let i = 0; i < this.zl.length; i++) {
                    for (let j = 0; j < this.zl[i].betclass.length; j++) {
                        if (this.zl[i].betclass[j].isSelected) {
                            list.push(this.zl[i].betclass[j]);
                        }
                    }
                }
                //三军
                for (let i = 0; i < this.sj.length; i++) {
                    for (let j = 0; j < this.sj[i].betclass.length; j++) {
                        if (this.sj[i].betclass[j].isSelected) {
                            list.push(this.sj[i].betclass[j]);
                        }
                    }
                }
                // console.log(list)
                return list;
            },
            /**显示赔率等数据 */
            get_lose() {
                let lose_data = this.$store.state.lose_data;
                lose_data.forEach(item => {
                    item.subname = `${item.level_two}_${item.level_three}_${item.level_four}`
                })
                //和值
                for (let i = 0; i < this.sum_value.length; i++) {
                    for (let j = 0; j < this.sum_value[i].betclass.length; j++) {
                        lose_data.forEach(item => {
                            if (item.subname == this.sum_value[i].betclass[j].subname) {
                                this.sum_value[i].betclass[j].static_bonus = item.rate;
                                this.sum_value[i].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.sum_value[i].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                }
                //总和
                for (let i = 0; i < this.sum.length; i++) {
                    for (let j = 0; j < this.sum[i].betclass.length; j++) {
                        lose_data.forEach(item => {
                            if (item.subname == this.sum[i].betclass[j].subname) {
                                this.sum[i].betclass[j].static_bonus = item.rate;
                                this.sum[i].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.sum[i].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                }
                //跨度
                for (let i = 0; i < this.kd.length; i++) {
                    for (let j = 0; j < this.kd[i].betclass.length; j++) {
                        lose_data.forEach(item => {
                            if (item.subname == this.kd[i].betclass[j].subname) {
                                this.kd[i].betclass[j].static_bonus = item.rate;
                                this.kd[i].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.kd[i].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                }
                //组六
                for (let i = 0; i < this.zl.length; i++) {
                    for (let j = 0; j < this.zl[i].betclass.length; j++) {
                        lose_data.forEach(item => {
                            if (item.subname == this.zl[i].betclass[j].subname) {
                                this.zl[i].betclass[j].static_bonus = item.rate;
                                this.zl[i].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.zl[i].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                }
                //三军
                for (let i = 0; i < this.sj.length; i++) {
                    for (let j = 0; j < this.sj[i].betclass.length; j++) {
                        lose_data.forEach(item => {
                            if (item.subname == this.sj[i].betclass[j].subname) {
                                this.sj[i].betclass[j].static_bonus = item.rate;
                                this.sj[i].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.sj[i].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                }
            },
            /**检查是否满足快捷输入 */
            check_fast_val(val) {
                if (val) {
                    return val;
                }
                else {
                    val = this.$parent.get_fast();
                    return val;
                }
            },
            /**重填，重置 */
            reset() {
                //和值
                for (let i = 0; i < this.sum_value.length; i++) {
                    for (let j = 0; j < this.sum_value[i].betclass.length; j++) {
                        this.sum_value[i].betclass[j].val = "";
                        this.sum_value[i].betclass[j].isSelected = false;
                        this.sum_value[i].betclass[j].bg = "#F2F3F8";
                    }
                }
                //总和
                for (let i = 0; i < this.sum.length; i++) {
                    for (let j = 0; j < this.sum[i].betclass.length; j++) {
                        this.sum[i].betclass[j].val = "";
                        this.sum[i].betclass[j].isSelected = false;
                        this.sum[i].betclass[j].bg = "#F2F3F8";
                    }
                }
                //跨度
                for (let i = 0; i < this.kd.length; i++) {
                    for (let j = 0; j < this.kd[i].betclass.length; j++) {
                        this.kd[i].betclass[j].val = "";
                        this.kd[i].betclass[j].isSelected = false;
                        this.kd[i].betclass[j].bg = "#F2F3F8";
                    }
                }
                //组六
                for (let i = 0; i < this.zl.length; i++) {
                    for (let j = 0; j < this.zl[i].betclass.length; j++) {
                        this.zl[i].betclass[j].val = "";
                        this.zl[i].betclass[j].isSelected = false;
                        this.zl[i].betclass[j].bg = "#F2F3F8";
                    }
                }
                //三军
                for (let i = 0; i < this.sj.length; i++) {
                    for (let j = 0; j < this.sj[i].betclass.length; j++) {
                        this.sj[i].betclass[j].val = "";
                        this.sj[i].betclass[j].isSelected = false;
                        this.sj[i].betclass[j].bg = "#F2F3F8";
                    }
                }
            },
            /**提交，下注 */
            sub_bet() {
                let list = [];
                //和值
                for (let i = 0; i < this.sum_value.length; i++) {
                    for (let j = 0; j < this.sum_value[i].betclass.length; j++) {
                        if (this.sum_value[i].betclass[j].isSelected) {
                            list.push(this.sum_value[i].betclass[j]);
                        }
                    }
                }
                //总和
                for (let i = 0; i < this.sum.length; i++) {
                    for (let j = 0; j < this.sum[i].betclass.length; j++) {
                        if (this.sum[i].betclass[j].isSelected) {
                            list.push(this.sum[i].betclass[j]);
                        }
                    }
                }
                //跨度
                for (let i = 0; i < this.kd.length; i++) {
                    for (let j = 0; j < this.kd[i].betclass.length; j++) {
                        if (this.kd[i].betclass[j].isSelected) {
                            list.push(this.kd[i].betclass[j]);
                        }
                    }
                }
                //组六
                for (let i = 0; i < this.zl.length; i++) {
                    for (let j = 0; j < this.zl[i].betclass.length; j++) {
                        if (this.zl[i].betclass[j].isSelected) {
                            list.push(this.zl[i].betclass[j]);
                        }
                    }
                }
                //三军
                for (let i = 0; i < this.sj.length; i++) {
                    for (let j = 0; j < this.sj[i].betclass.length; j++) {
                        if (this.sj[i].betclass[j].isSelected) {
                            list.push(this.sj[i].betclass[j]);
                        }
                    }
                }
                // console.log(list)
                return list;
            },
            /**重复上次下单 */
            repeat_last_bet(list) {
                // console.log(list)
                for (let i = 0; i < list.length; i++) {
                    //和值
                    for (let j = 0; j < this.sum_value.length; j++) {
                        for (let k = 0; k < this.sum_value[j].betclass.length; k++) {
                            if (this.sum_value[j].betclass[k].title == list[i].title && this.sum_value[j].betclass[k].name == list[i].name) {
                                this.sum_value[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                    //总和
                    for (let j = 0; j < this.sum.length; j++) {
                        for (let k = 0; k < this.sum[j].betclass.length; k++) {
                            if (this.sum[j].betclass[k].title == list[i].title && this.sum[j].betclass[k].name == list[i].name) {
                                this.sum[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                    //跨度
                    for (let j = 0; j < this.kd.length; j++) {
                        for (let k = 0; k < this.kd[j].betclass.length; k++) {
                            if (this.kd[j].betclass[k].title == list[i].title && this.kd[j].betclass[k].name == list[i].name) {
                                this.kd[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                    //组六
                    for (let j = 0; j < this.zl.length; j++) {
                        for (let k = 0; k < this.zl[j].betclass.length; k++) {
                            if (this.zl[j].betclass[k].title == list[i].title && this.zl[j].betclass[k].name == list[i].name) {
                                this.zl[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                    //三军
                    for (let j = 0; j < this.sj.length; j++) {
                        for (let k = 0; k < this.sj[j].betclass.length; k++) {
                            if (this.sj[j].betclass[k].title == list[i].title && this.sj[j].betclass[k].name == list[i].name) {
                                this.sj[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                }
            },
        },
    }
</script>

<style scoped>
    .bet-name {
        width: 22px !important;
        height: 22px ;
        text-align: center !important;
        line-height: 22px  !important;
        background: #f56c6c;
        border-radius: 50%;
        padding: 0 !important;
        margin-top: 3px;
        margin-left: 3px;
        font-weight: bold;
        color: #fff;
    }
    .sum {
        background: none;
        color: #666;
        width: 20px !important;
    }
    .kd {
        background: none;
        color: #666;
        width: 30px !important;
    }
</style>