<!--historyopen
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-07-13 17:16:22
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-08-28 14:47:49
 * @FilePath: \pc\src\components\menuitems\history.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="historyopen">
        <div style="text-align: center;">
            <el-row>
                <el-col class="light-bg list-title" :span="6">期数</el-col>
                <el-col class="light-bg list-title" :span="14">号码</el-col>
                <el-col class="light-bg list-title" :span="4">
                    <el-link href="https://m1.1881kk.com">开 奖 网</el-link>
                </el-col>
            </el-row>

            <!-- 重庆时时彩 -->
            <div v-if="gid == 1||gid == 4|| gid == 7||gid == 10|| gid == 18|| gid == 38 || gid == 39|| gid == 44|| gid == 45|| gid == 46">
                <template v-for="item in history.resdata.list" :key="item.id">
                    <el-row class="item" @click="item.show_others=!item.show_others">
                        <el-col :span="6" class="item-qnum">
                            <div>{{item.qnum}}</div>
                            <div>{{item.lottery_open.split(' ')[1]}}</div>
                        </el-col>
                        <el-col :span="18" class="item-nums">
                            <span v-for="num in item.res" :key="num.index">
                                <span :class="gid == 4|| gid == 10?'cqssc_nums_p':'cqssc_nums'">{{num}}</span>
                            </span>
                        </el-col>
                    </el-row>
                    <el-row class="item-others" v-show="item.show_others">
                        <el-col :span="6" class="item-others-col">
                            <div class="item-others-label">总和</div>
                            <div class="item-others-value">
                                <!-- {{item.res_info[0]}} -->
                                <span>{{item.sum}}</span>&nbsp;
                                <span v-if="item.sum_big=='大'" style="color:red">{{ item.sum_big }}</span>
                                <span v-else style="color: #409EFF">{{ item.sum_big }}</span>&nbsp;
                                <span v-if="item.sum_single=='雙'" style="color:red">{{ item.sum_single }}</span>
                                <span v-else style="color: #409EFF">{{ item.sum_single }}</span>&nbsp;
                                <span v-if="item.dragon_tiger=='龙'" style="color:red">{{ item.dragon_tiger }}</span>
                                <span v-else-if="item.dragon_tiger=='和'" style="color:green">{{ item.dragon_tiger }}</span>
                                <span v-else style="color: #409EFF">{{ item.dragon_tiger }}</span>
                            </div>
                        </el-col>
                        <el-col :span="6" class="item-others-col">
                            <div class="item-others-label">前三</div>
                            <div class="item-others-value">{{item.front}}</div>
                        </el-col>
                        <el-col :span="6" class="item-others-col" v-if="gid!= 4">
                            <div class="item-others-label">中三</div>
                            <div class="item-others-value">{{item.in}}</div>
                        </el-col>
                        <el-col :span="6" class="item-others-col" v-if="gid!= 4">
                            <div class="item-others-label">后三</div>
                            <div class="item-others-value">{{item.back}}</div>
                        </el-col>
                        <el-col :span="24">
                            <div class="item-others-txid">
                                <span v-for="t in item.djm" :key="t.index" :style="{color:t.color}">{{t.text}}</span>
                            </div>
                        </el-col>
                    </el-row>
                </template>
            </div>
            <!-- 北京PK10 -->
            <div v-if="gid == 3|| gid == 19|| gid == 24|| gid == 28|| gid == 37|| gid == 40|| gid == 43|| gid == 47">
                <template v-for="item in history.resdata.list" :key="item.id">
                    <el-row class="item" @click="item.show_others=!item.show_others">
                        <el-col :span="6" class="item-qnum">
                            <div>{{item.qnum}}</div>
                            <div>{{item.lottery_open.split(' ')[1]}}</div>
                        </el-col>
                        <el-col :span="18" class="item-nums">
                            <template v-for="num in item.res" :key="num.index">
                                <span :class="`lottery_num_pk10 lottery_num_pk10_num${num}`">{{num*1}}</span>
                            </template>
                        </el-col>
                    </el-row>
                    <el-row class="item-others" v-show="item.show_others">
                        <el-col :span="8" class="item-others-col">
                            <div class="item-others-label">冠亚和</div>
                            <div class="item-others-value">
                                <span>{{item.res_info[0]}}</span>&nbsp;
                                <span v-if="item.res_info[2]=='双'" style="color:red">{{ item.res_info[2] }}</span>
                                <span v-else style="color: #409EFF">{{ item.res_info[2]}}</span>&nbsp;
                                <span v-if="item.res_info[1]=='大'" style="color:red">{{ item.res_info[1] }}</span>
                                <span v-else style="color: #409EFF">{{ item.res_info[1] }}</span>
                            </div>
                        </el-col>
                        <el-col :span="16" class="item-others-col">
                            <div class="item-others-label">1-5龙虎</div>
                            <div class="item-others-value">
                                <div class="pk10_1_5">
                                    <span v-if="item.res_info[3]=='龙'" style="color:red">{{ item.res_info[3] }}</span>
                                    <span v-else-if="item.dragon_tiger=='和'" style="color:green">{{ item.res_info[3] }}</span>
                                    <span v-else style="color: #409EFF">{{ item.res_info[3] }}</span>
                                    &nbsp;
                                    <span v-if="item.res_info[4]=='龙'" style="color:red">{{ item.res_info[4] }}</span>
                                    <span v-else-if="item.dragon_tiger=='和'" style="color:green">{{ item.res_info[4] }}</span>
                                    <span v-else style="color: #409EFF">{{ item.res_info[4] }}</span>
                                    &nbsp;
                                    <span v-if="item.res_info[5]=='龙'" style="color:red">{{ item.res_info[5] }}</span>
                                    <span v-else-if="item.dragon_tiger=='和'" style="color:green">{{ item.res_info[5] }}</span>
                                    <span v-else style="color: #409EFF">{{ item.res_info[5] }}</span>
                                    &nbsp;
                                    <span v-if="item.res_info[6]=='龙'" style="color:red">{{ item.res_info[6] }}</span>
                                    <span v-else-if="item.dragon_tiger=='和'" style="color:green">{{ item.res_info[6] }}</span>
                                    <span v-else style="color: #409EFF">{{ item.res_info[6] }}</span>
                                    &nbsp;
                                    <span v-if="item.res_info[7]=='龙'" style="color:red">{{ item.res_info[7] }}</span>
                                    <span v-else-if="item.dragon_tiger=='和'" style="color:green">{{ item.res_info[7] }}</span>
                                    <span v-else style="color: #409EFF">{{ item.res_info[7] }}</span>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="24">
                            <div class="item-others-txid">
                                <span v-for="t in item.djm" :key="t.index" :style="{color:t.color}">{{t.text}}</span>
                            </div>
                        </el-col>
                    </el-row>
                </template>
            </div>
            <!-- 六合彩 -->
            <div class="lhc" :span="24" v-if="gid==20||gid==34||gid==48">
                <template v-for="item in history.resdata.list" :key="item.id">
                    <el-row class="item" @click="item.show_others=!item.show_others">
                        <el-col :span="6" class="item-qnum">
                            <div>{{item.qnum}}</div>
                            <div>{{item.lottery_open.split(' ')[1]}}</div>
                        </el-col>
                        <el-col :span="18" class="item-nums">
                            <div class="lhc_nums" v-for="num in item.res_s" :key="num.index">
                                <span class="num" :style="{'background-color':num.color}">{{num.num}}</span>
                                <span class="lhcsx">{{num.sx}}</span>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row class="item-others" v-show="item.show_others">
                        <el-col :span="8" class="item-others-col">
                            <div class="item-others-label">合计</div>
                            <div class="item-others-value">{{item.res_info[0]}}</div>
                        </el-col>
                        <el-col :span="8" class="item-others-col">
                            <div class="item-others-label">单双</div>
                            <div class="item-others-value">{{item.res_info[0]}}</div>
                        </el-col>
                        <el-col :span="8" class="item-others-col">
                            <div class="item-others-label">大小</div>
                            <div class="item-others-value">{{item.res_info[0]}}</div>
                        </el-col>
                        <el-col :span="24">
                            <div class="item-others-txid">
                                <div class="tl" v-for="tl in item.djm" :key="tl.index">
                                    <span v-for="t in tl" :key="t.index" :style="{color:t.color}">{{t.text}}</span>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </template>
            </div>
            <!-- pc蛋蛋，加拿大28，幸运28 -->
            <div v-if="gid==22||gid==23">
                <template v-for="item in history.resdata.list" :key="item.id">
                    <el-row class="item" @click="item.show_others=!item.show_others">
                        <el-col :span="6" class="item-qnum">
                            <div>{{item.qnum}}</div>
                            <div>{{item.lottery_open.split(' ')[1]}}</div>
                        </el-col>
                        <el-col :span="18" class="item-nums">
                            <span class="betclassname_old_pcdd" :style="{'background-color':item.res[0].color}">{{item.res[0].num}}</span>+
                            <span class="betclassname_old_pcdd" :style="{'background-color':item.res[1].color}">{{item.res[1].num}}</span>+
                            <span class="betclassname_old_pcdd" :style="{'background-color':item.res[2].color}">{{item.res[2].num}}</span>=
                            <span class="betclassname_old_pcdd" :style="{'background-color':item.sum.color}">{{item.sum.num}}</span>
                        </el-col>
                    </el-row>
                    <el-row class="item-others" v-show="item.show_others">
                        <el-col :span="6" class="item-others-col">
                            <div class="item-others-label">大小/单双</div>
                            <div class="item-others-value">{{item.sum_big}}/{{item.sum_single}}</div>
                        </el-col>
                        <el-col :span="6" class="item-others-col">
                            <div class="item-others-label">波色</div>
                            <div class="item-others-value">
                                <span v-if="item.sum.color=='#F56C6C'" :style="{'color':'#F56C6C'}">紅波</span>
                                <span v-else-if="item.sum.color=='#67C23A'" :style="{'color':'#67C23A'}">绿波</span>
                                <span v-else-if="item.sum.color=='#409EFF'" :style="{'color':'#409EFF'}">蓝波</span>
                                <span v-else>-</span>
                            </div>
                        </el-col>
                        <el-col :span="6" class="item-others-col">
                            <div class="item-others-label">极大小</div>
                            <div class="item-others-value">
                                <span v-if="item.sum.num<=4">极小</span>
                                <span v-else-if="item.sum.num>=23">极大</span>
                                <span v-else>-</span>
                            </div>
                        </el-col>
                        <el-col :span="6" class="item-others-col">
                            <div class="item-others-label">豹子</div>
                            <div class="item-others-value">
                                <span v-if="item.res[0].num==item.res[1].num&&item.res[0].num==item.res[2].num">豹子</span>
                                <span v-else>-</span>
                            </div>
                        </el-col>
                        <el-col :span="24">
                            <div class="item-others-txid">
                                <span v-for="t in item.txid" :key="t.index" :style="{color:t.color}">{{t.text}}</span>
                            </div>
                        </el-col>
                    </el-row>
                </template>
            </div>
            <!-- 快三 -->
            <div v-if="gid==6||gid==29||gid==30||gid==31||gid==32||gid==36">
                <template v-for="item in history.resdata.list" :key="item.id">
                    <el-row class="item" @click="item.show_others=!item.show_others">
                        <el-col :span="6" class="item-qnum">
                            <div>{{item.qnum}}</div>
                            <div>{{item.lottery_open.split(' ')[1]}}</div>
                        </el-col>
                        <el-col :span="18" class="item-nums">
                            <span v-for="num in item.res" :key="num.index">
                                <span class="k3_nums">{{num}}</span>
                            </span>
                        </el-col>
                    </el-row>
                    <el-row class="item-others" v-show="item.show_others">
                        <el-col :span="8" class="item-others-col">
                            <div class="item-others-label">总和</div>
                            <div class="item-others-value">{{item.res_info[0]}}</div>
                        </el-col>
                        <el-col :span="8" class="item-others-col">
                            <div class="item-others-label">总和单双</div>
                            <div class="item-others-value">{{item.res_info[1]}}</div>
                        </el-col>
                        <el-col :span="8" class="item-others-col">
                            <div class="item-others-label">总和大小</div>
                            <div class="item-others-value">{{item.big}}</div>
                        </el-col>
                        <el-col :span="24">
                            <div class="item-others-txid">
                                <span v-for="t in item.djm" :key="t.index" :style="{color:t.color}">{{t.text}}</span>
                            </div>
                        </el-col>
                    </el-row>
                </template>
            </div>
            <!-- 分页 -->
            <div style="margin:0 auto;" v-if="$route.name!='userbet'">
                <el-pagination small @current-change="historyHandleCurrentChange" :current-page="page" :pager-count="5" :total="history.resdata.nums*1" hide-on-single-page layout="total,prev,pager,next" prev-text="<" next-text=">"></el-pagination>
            </div>
        </div>
        <el-affix position="bottom" v-show="$route.name!='userbet'">
            <el-button class="refs-btn" type="primary" @click="get_data(gid)">点击刷新</el-button>
        </el-affix>
    </div>
</template>

<script>
    import { pcddNum } from '../../plugin/pcddNum';
    import { get_lhc_sx } from "../../plugin/sx.js";
    import { lhcNum } from "../../plugin/lhcNum";
    import { duijiangma } from "../../plugin/duijiangma";
    export default {
        props: ['from'],
        data() {
            return {
                /**彩种列表 */
                lottery_list: [],
                logs: [],
                /**历史开奖 */
                history: {
                    /**控制历史开奖dialog的显示 */
                    LotteryVisible: false,
                    /**默认选中的彩种，这里应该和主页里彩种一样 */
                    default: "",
                    /**彩种列表 */
                    list: [],
                    /**获取当前所选中的彩种的所有数据，后台接口获取 */
                    resdata: {
                        list: [],
                    },
                },
                /**所选种的彩种的ID */
                gid: '',
                page: 1,
                date: {
                    start: "",
                    end: "",
                },
                showType: '显示号码',
                showall: true,
                gid: '',
            }
        },
        created() {
            if (this.$route.name != 'userbet') {
                if (this.$route.params.data != undefined) {
                    let data = JSON.parse(this.$route.params.data);
                    this.getdata(data);
                } else {
                    this.gid = this.$store.state.checked_gid;
                    this.get_data(this.gid);
                }
            } else {

            }
        },
        methods: {
            /**历史开奖彩种切换 ，e为当前所切换的彩种ID*/
            change_lottery(e) {
                this.page = 1;
                this.get_data(e);
            },
            /**历史开奖页码切换，参数val为选中的页码*/
            historyHandleCurrentChange(val) {
                this.page = val;
                this.get_data(this.gid);
            },
            get_data(e) {
                this.axios.post("/betresult/index",
                    {
                        limit: 20,
                        gid: e,
                        page: this.page,
                        start_time: this.$filters.time(new Date(new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 30)).getTime() / 1000),
                        end_time: this.$filters.time((new Date().getTime()) / 1000),
                    }
                ).then(result => {
                    // console.log(result.data);
                    if (result.data.status && result.data.msg === "请求成功！") {
                        this.history.resdata = result.data.res;
                        let list = result.data.res.list;
                        //处理是pc蛋蛋的结果
                        if (result.data.res.gname.id == 22 || result.data.res.gname.id == 23) {
                            for (let i = 0; i < list.length; i++) {
                                for (let j = 0; j < list[i].res.length; j++) {
                                    list[i].res[j] = pcddNum(list[i].res[j])
                                }
                                list[i].sum = pcddNum(list[i].sum);
                            }
                        }
                        //处理六合彩
                        if (result.data.res.gname.id == 20 || result.data.res.gname.id == 34 || result.data.res.gname.id == 48) {
                            for (let i = 0; i < list.length; i++) {
                                let arr = [];
                                let lottery_open = list[i].lottery_open.split(' ')[0];
                                for (let j = 0; j < list[i].res.length; j++) {
                                    arr.push(lhcNum(list[i].res[j], lottery_open))
                                }
                                list[i].res_s = get_lhc_sx("开奖", arr);
                            }
                        }
                        duijiangma(list, e)
                        this.history.resdata.list = list
                        this.gid = e;
                    }
                });
            },
            getdata(data) {
                this.lottery_list = [];
                this.gid = data.gname.id;
                let list = data.list;
                //处理是pc蛋蛋的结果
                if (this.gid == 22 || this.gid == 23) {
                    for (let i = 0; i < list.length; i++) {
                        for (let j = 0; j < list[i].res.length; j++) {
                            list[i].res[j] = pcddNum(list[i].res[j])
                        }
                        list[i].sum = pcddNum(list[i].sum);
                    }
                }
                //处理六合彩
                if (this.gid == 20 || this.gid == 34 || this.gid == 48) {
                    for (let i = 0; i < list.length; i++) {
                        let arr = [];
                        let lottery_open = list[i].lottery_open.split(' ')[0];
                        for (let j = 0; j < list[i].res.length; j++) {
                            arr.push(lhcNum(list[i].res[j], lottery_open))
                        }
                        list[i].res_s = get_lhc_sx("开奖", arr);
                    }
                }
                list.forEach(item => {
                    if (this.history.resdata?.list.length > 0) {
                        this.history.resdata.list.forEach(e => {
                            if (e.id == item.id) {
                                item.show_others = e.show_others;
                            }
                        })
                    } else {
                        item.show_others = false;
                    }
                });
                duijiangma(list, this.gid);
                this.history.resdata.list = JSON.parse(JSON.stringify(list));
                this.history.resdata.nums = data.nums || 10;
            }
        },
    }
</script>

<style scoped>
    .historyopen {
        overflow-y: auto;
    }
    .refs-btn {
        width: 100%;
    }
    .list-title {
        font-size: 14px;
        font-weight: bold;
        padding: 5px 0;
    }
    .item {
        border-top: 1px solid #aaa;
        border-bottom: 1px solid #aaa;
    }
    .item-qnum {
        background: #fff;
        line-height: 20px;
    }
    .item-nums {
        background: #fff;
        line-height: 40px;
    }
    .item-others-col {
        border-left: 1px solid #ddd;
    }
    .item-others-label {
        font-size: 14px;
        font-weight: bold;
        line-height: 26px;
        border-bottom: 1px solid #ddd;
    }
    .item-others-value {
        background: #fff;
        line-height: 26px;
    }
    .item-others-txid {
        word-break: break-all;
        text-align: left;
        padding: 5px;
    }
    .item-others-txid .tl {
        border-bottom: 1px solid #eee;
    }
    .item-others-txid .tl:last-child {
        border: 0;
    }
    /* 彩种 */
    .cqssc_nums {
        display: inline-block;
        color: #000;
        line-height: 20px;
        font-weight: bold;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        margin-left: 2px;
        text-align: center;
        border: 2px solid #409eff;
    }
    .cqssc_nums_p {
        display: inline-block;
        color: #fff;
        font-size: 12px;
        line-height: 20px;
        font-weight: bold;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        margin-left: 2px;
        text-align: center;
    }
    .k3_nums {
        display: inline-block;
        color: #000;
        line-height: 20px;
        font-weight: bold;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        margin-left: 2px;
        text-align: center;
        border: 2px solid #f56c6c;
    }
    .pk10_1_5 span {
        display: inline-block;
    }

    .lottery_num_pk10 {
        display: inline-block;
        height: 20px;
        width: 20px;
        line-height: 20px;
        margin: 0 1px 0 0;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        border-radius: 4px;
        color: #fff;
        text-shadow: 1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
            1px 1px 0 #000;
    }

    .lottery_num_pk10_num01 {
        background: rgb(228, 228, 44);
    }

    .lottery_num_pk10_num02 {
        background: #0089ff;
    }

    .lottery_num_pk10_num03 {
        background: #4d4d4d;
    }

    .lottery_num_pk10_num04 {
        background: #ff7300;
    }

    .lottery_num_pk10_num05 {
        background: #01e9e9;
    }

    .lottery_num_pk10_num06 {
        background: #5200ff;
    }

    .lottery_num_pk10_num07 {
        background: #cfcfcf;
    }

    .lottery_num_pk10_num08 {
        background: #f00;
    }

    .lottery_num_pk10_num09 {
        background: #760000;
    }

    .lottery_num_pk10_num10 {
        background: #28c200;
    }
    .lhc_nums {
        display: inline-block;
        text-align: center;
        margin: 0 2px 0 0;
    }
    .lhc_nums .num {
        display: inline-block;
        width: 20px;
        height: 20px;
        line-height: 20px;
        border-radius: 50%;
        font-size: 12px;
        color: #fff;
        margin: 0 1px;
    }
    .betclassname_old_pcdd {
        display: inline-block;
        width: 24px;
        height: 24px;
        line-height: 24px;
        border-radius: 50%;
        color: #fff;
        text-align: center;
        margin: 0 6px;
        font-weight: bold;
    }
</style>
