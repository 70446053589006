<!--
 * @Author: your name
 * @Date: 2020-06-18 14:36:26
 * @LastEditTime: 2022-08-31 01:48:06
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: In User Settings Edit
 * @FilePath: \luck_m\src\components\betPage\k3\k3.vue
-->
<template>
    <div class="k3">
        <lm v-if="activeName==='两面'" ref="lm"></lm>
        <hh v-if="activeName==='红黑'" ref="hh"></hh>
        <hhm v-if="activeName==='红黑码'" ref="hhm"></hhm>
        <ethdx v-if="activeName==='二同号单选'" ref="ethdx"></ethdx>
        <ethfx v-if="activeName==='二同号复选'" ref="ethfx"></ethfx>
        <ebth v-if="activeName==='二不同号'" ref="ebth"></ebth>
        <sthdx v-if="activeName==='三同号单选'" ref="sthdx"></sthdx>
        <sthtx v-if="activeName==='三同号通选'" ref="sthtx"></sthtx>
        <sbth v-if="activeName==='三不同号'" ref="sbth"></sbth>
        <slhtx v-if="activeName==='三连号通选'" ref="slhtx"></slhtx>
    </div>
</template>

<script>
    import lm from "./lm";
    import hh from "./hh";
    import hhm from "./hhm";
    import ethdx from "./ethdx";
    import ethfx from "./ethfx";
    import ebth from "./ebth";
    import sthdx from "./sthdx";
    import sthtx from "./sthtx";
    import slhtx from "./slhtx";
    import sbth from "./sbth";
    export default {
        components: { lm, hh, hhm, ethdx, ethfx, ebth, sthdx, slhtx, sthtx, sbth },
        props: ["activeName"],
        data() {
            return {};
        },
        methods: {
            //快速下注
            fast_sub_bet() {
                if (this.activeName === "两面") {
                    return this.$refs.lm.fast_sub_bet();
                }
                if (this.activeName === "红黑") {
                    return this.$refs.hh.fast_sub_bet();
                }
                if (this.activeName === "红黑码") {
                    return this.$refs.hhm.fast_sub_bet(this.activeName);
                }
                if (this.activeName === "二同号单选") {
                    return this.$refs.ethdx.fast_sub_bet();
                }
                if (this.activeName === "二同号复选") {
                    return this.$refs.ethfx.fast_sub_bet();
                }
                if (this.activeName === "二不同号") {
                    return this.$refs.ebth.fast_sub_bet();
                }
                if (this.activeName === "三同号单选") {
                    return this.$refs.sthdx.fast_sub_bet();
                }
                if (this.activeName === "三同号通选") {
                    return this.$refs.sthtx.fast_sub_bet();
                }
                if (this.activeName === "三连号通选") {
                    return this.$refs.slhtx.fast_sub_bet();
                }
                if (this.activeName === "三不同号") {
                    return this.$refs.sbth.fast_sub_bet();
                }
            },
            /**显示赔率 */
            set_lose() {
                if (this.activeName === "两面") {
                    this.$refs.lm.get_lose();
                }
                if (this.activeName === "红黑") {
                    this.$refs.hh.get_lose();
                }
                if (this.activeName === "红黑码") {
                    this.$refs.hhm.get_lose(this.activeName);
                }
                if (this.activeName === "二同号单选") {
                    return this.$refs.ethdx.get_lose();
                }
                if (this.activeName === "二同号复选") {
                    return this.$refs.ethfx.get_lose();
                }
                if (this.activeName === "二不同号") {
                    return this.$refs.ebth.get_lose();
                }
                if (this.activeName === "三同号单选") {
                    return this.$refs.sthdx.get_lose();
                }
                if (this.activeName === "三同号通选") {
                    return this.$refs.sthtx.get_lose();
                }
                if (this.activeName === "三连号通选") {
                    return this.$refs.slhtx.get_lose();
                }
                if (this.activeName === "三不同号") {
                    return this.$refs.sbth.get_lose();
                }
            },
            /**获取快捷下注金额 */
            get_fast() {
                // console.log(this.$parent.get_fast())
                return this.$parent.get_fast();
            },
            /**重填，重置 */
            reset() {
                if (this.activeName === "两面") {
                    this.$refs.lm.reset();
                }
                if (this.activeName === "红黑") {
                    this.$refs.hh.reset();
                }
                if (this.activeName === "红黑码") {
                    this.$refs.hhm.reset(this.activeName);
                }
                if (this.activeName === "二同号单选") {
                    return this.$refs.ethdx.reset();
                }
                if (this.activeName === "二同号复选") {
                    return this.$refs.ethfx.reset();
                }
                if (this.activeName === "二不同号") {
                    return this.$refs.ebth.reset();
                }
                if (this.activeName === "三同号单选") {
                    return this.$refs.sthdx.reset();
                }
                if (this.activeName === "三同号通选") {
                    return this.$refs.sthtx.reset();
                }
                if (this.activeName === "三连号通选") {
                    return this.$refs.slhtx.reset();
                }
                if (this.activeName === "三不同号") {
                    return this.$refs.sbth.reset();
                }
            },
            /**提交，下注 */
            sub_bet() {
                if (this.activeName === "两面") {
                    return this.$refs.lm.sub_bet();
                }
                if (this.activeName === "红黑") {
                    return this.$refs.hh.sub_bet();
                }
                if (this.activeName === "红黑码") {
                    return this.$refs.hhm.sub_bet(this.activeName);
                }
                if (this.activeName === "二同号单选") {
                    return this.$refs.ethdx.sub_bet();
                }
                if (this.activeName === "二同号复选") {
                    return this.$refs.ethfx.sub_bet();
                }
                if (this.activeName === "二不同号") {
                    return this.$refs.ebth.sub_bet();
                }
                if (this.activeName === "三同号单选") {
                    return this.$refs.sthdx.sub_bet();
                }
                if (this.activeName === "三同号通选") {
                    return this.$refs.sthtx.sub_bet();
                }
                if (this.activeName === "三连号通选") {
                    return this.$refs.slhtx.sub_bet();
                }
                if (this.activeName === "三不同号") {
                    return this.$refs.sbth.sub_bet();
                }
            },
            /**重复上次下单 */
            repeat_last_bet(playing, list) {
                if (playing === "lmian") {
                    this.$refs.lm.repeat_last_bet(list);
                } else if (playing === "hongh") {
                    this.$refs.hh.repeat_last_bet(list);
                } else {
                    this.$refs.hhm.repeat_last_bet(list);
                }
            }
        }
    };
</script>