<template>
    <div class="cqssc-d1-3">
        <el-row :gutter="10">
            <el-col :span="24" v-for="item in balls" :key="item.index">
                <div class="bet-title">
                    <span>{{item.ballname}}</span>
                </div>
                <el-row class="bet-items" :gutter="6">
                    <el-col :span="8" v-for="i in item.betclass" :key="i.index">
                        <div class="bet-items-info" :style="{backgroundColor:i.bg}" @click="click_item(i)">
                            <span class="last-nums-cqssc">{{i.name}}</span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    data() {
        return {
            balls: [
                {
                    ballname: "第一球",
                    betclass: [
                        { title: "第一球", bg:'#F2F3F8', name: "0", isSelected: false, static_bonus: "", val: "" ,subname:"1_0_0", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false},
                        { title: "第一球", bg:'#F2F3F8', name: "1", isSelected: false, static_bonus: "", val: "" ,subname:"1_0_1", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false},
                        { title: "第一球", bg:'#F2F3F8', name: "2", isSelected: false, static_bonus: "", val: "" ,subname:"1_0_2", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false},
                        { title: "第一球", bg:'#F2F3F8', name: "3", isSelected: false, static_bonus: "", val: "" ,subname:"1_0_3", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false},
                        { title: "第一球", bg:'#F2F3F8', name: "4", isSelected: false, static_bonus: "", val: "" ,subname:"1_0_4", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false},
                        { title: "第一球", bg:'#F2F3F8', name: "5", isSelected: false, static_bonus: "", val: "" ,subname:"1_0_5", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false},
                        { title: "第一球", bg:'#F2F3F8', name: "6", isSelected: false, static_bonus: "", val: "" ,subname:"1_0_6", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false},
                        { title: "第一球", bg:'#F2F3F8', name: "7", isSelected: false, static_bonus: "", val: "" ,subname:"1_0_7", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false},
                        { title: "第一球", bg:'#F2F3F8', name: "8", isSelected: false, static_bonus: "", val: "" ,subname:"1_0_8", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false},
                        { title: "第一球", bg:'#F2F3F8', name: "9", isSelected: false, static_bonus: "", val: "" ,subname:"1_0_9", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false},
                    ]
                },
                {
                    ballname: "第二球",
                    betclass: [
                        { title: "第二球", bg:'#F2F3F8', name: "0", isSelected: false, static_bonus: "", val: "" ,subname:"1_1_0", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false},
                        { title: "第二球", bg:'#F2F3F8', name: "1", isSelected: false, static_bonus: "", val: "" ,subname:"1_1_1", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false},
                        { title: "第二球", bg:'#F2F3F8', name: "2", isSelected: false, static_bonus: "", val: "" ,subname:"1_1_2", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false},
                        { title: "第二球", bg:'#F2F3F8', name: "3", isSelected: false, static_bonus: "", val: "" ,subname:"1_1_3", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false},
                        { title: "第二球", bg:'#F2F3F8', name: "4", isSelected: false, static_bonus: "", val: "" ,subname:"1_1_4", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false},
                        { title: "第二球", bg:'#F2F3F8', name: "5", isSelected: false, static_bonus: "", val: "" ,subname:"1_1_5", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false},
                        { title: "第二球", bg:'#F2F3F8', name: "6", isSelected: false, static_bonus: "", val: "" ,subname:"1_1_6", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false},
                        { title: "第二球", bg:'#F2F3F8', name: "7", isSelected: false, static_bonus: "", val: "" ,subname:"1_1_7", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false},
                        { title: "第二球", bg:'#F2F3F8', name: "8", isSelected: false, static_bonus: "", val: "" ,subname:"1_1_8", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false},
                        { title: "第二球", bg:'#F2F3F8', name: "9", isSelected: false, static_bonus: "", val: "" ,subname:"1_1_9", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false},
                    ]
                },
                {
                    ballname: "第三球",
                    betclass: [
                        { title: "第三球", bg:'#F2F3F8', name: "0", isSelected: false, static_bonus: "", val: "" ,subname:"1_2_0", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false},
                        { title: "第三球", bg:'#F2F3F8', name: "1", isSelected: false, static_bonus: "", val: "" ,subname:"1_2_1", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false},
                        { title: "第三球", bg:'#F2F3F8', name: "2", isSelected: false, static_bonus: "", val: "" ,subname:"1_2_2", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false},
                        { title: "第三球", bg:'#F2F3F8', name: "3", isSelected: false, static_bonus: "", val: "" ,subname:"1_2_3", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false},
                        { title: "第三球", bg:'#F2F3F8', name: "4", isSelected: false, static_bonus: "", val: "" ,subname:"1_2_4", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false},
                        { title: "第三球", bg:'#F2F3F8', name: "5", isSelected: false, static_bonus: "", val: "" ,subname:"1_2_5", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false},
                        { title: "第三球", bg:'#F2F3F8', name: "6", isSelected: false, static_bonus: "", val: "" ,subname:"1_2_6", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false},
                        { title: "第三球", bg:'#F2F3F8', name: "7", isSelected: false, static_bonus: "", val: "" ,subname:"1_2_7", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false},
                        { title: "第三球", bg:'#F2F3F8', name: "8", isSelected: false, static_bonus: "", val: "" ,subname:"1_2_8", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false},
                        { title: "第三球", bg:'#F2F3F8', name: "9", isSelected: false, static_bonus: "", val: "" ,subname:"1_2_9", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false},
                    ]
                },
            ],
        }
    },
    methods: {
        //选取投注项
        click_item(item){
            if(item.isSelected){
                item.isSelected=false;
                item.bg='#F2F3F8';
            }else{
                item.bg='#E6A23C';
                item.isSelected=true;
            }
            return item
        },
        //快速下注
        fast_sub_bet(){
            let list=[];
            for(let i=0;i<this.balls.length;i++){
                for(let j=0;j<this.balls[i].betclass.length;j++){
                    if(this.balls[i].betclass[j].isSelected){
                        list.push(this.balls[i].betclass[j]);
                    }
                }
            }
            // console.log(list)
            return list;
        },
        get_lose(){
            let bonus_high=this.$store.state.lottery.lose_data.bonus_high;
            let others=this.$store.state.lottery.lose_data.others;
            let static_bonus=this.$store.state.lottery.lose_data.static_bonus;
            for(let i=0;i<this.balls.length;i++){
                for(let j=0;j<this.balls[i].betclass.length;j++){
                    this.balls[i].betclass[j].static_bonus=static_bonus[j];
                    this.balls[i].betclass[j].straightbet_high=others[j].straightbet_high;
                    this.balls[i].betclass[j].straightbet_low=others[j].straightbet_low;
                }
                static_bonus=static_bonus.slice(10);
                others=others.slice(10);
            }
        },
                /**检查是否满足快捷输入 */
        check_fast_val(val){
            if(val){
                return val;
            }
            else{
                val=this.$parent.get_fast();
                return val;
            }
        },
        /**重填，重置 */
        reset(){
            for(let i=0;i<this.balls.length;i++){
                for(let j=0;j<this.balls[i].betclass.length;j++){
                    this.balls[i].betclass[j].val="";
                    this.balls[i].betclass[j].isSelected=false;
                    this.balls[i].betclass[j].bg="#F2F3F8";
                }
            }
        },
        /**提交，下注 */
        sub_bet(){
            let list=[];
            for(let i=0;i<this.balls.length;i++){
                for(let j=0;j<this.balls[i].betclass.length;j++){
                    if(this.balls[i].betclass[j].isSelected){
                        list.push(this.balls[i].betclass[j]);
                    }
                }
            }
            // console.log(list)
            return list;
        },
        /**重复上次下单 */
        repeat_last_bet(list) {
            // console.log(list)
            for(let i=0;i<list.length;i++){
                for(let j=0;j<this.balls.length;j++){
                    for(let k=0;k<this.balls[j].betclass.length;k++){
                        if(this.balls[j].betclass[k].title==list[i].title&&this.balls[j].betclass[k].name==list[i].name){
                            this.balls[j].betclass[k].val=list[i].val;
                        }
                    }
                }
            }
        },
    },
}
</script>
