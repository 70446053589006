<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-08-29 17:43:37
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-09-13 10:55:02
 * @FilePath: \mobile_vue3\src\components\bet\userbet.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="userbet">
        <div class="gameinfo">
            <!-- 停售 -->
            <div class="order-close" v-if="stop_sale">
                <div class="DDtitle">系統提示</div>
                <div class="DDbox">
                    <div class="tipsBox">
                        <div class="warning"></div>
                        <span>很抱歉！當前未開盤！</span>
                    </div>
                </div>
                <el-button type="primary" size="default" @click="$router.push('/home')">返回</el-button>
            </div>
            <template v-else>
                <!-- 头部信息 -->
                <div class="kai-nums" style="margin-top:5px">
                    <span class="green-color">{{lottery_info.old_qnum}}</span>
                    <span>期</span>
                    <span class="last-num" v-if="lottery_info.old_qnum">
                        <!-- 判断彩种，进行不同样式来渲染开奖号码 -->
                        <!-- 重庆时时彩 -->
                        <span v-if="gid == 1|| gid == 7|| gid == 18|| gid == 38 || gid == 39|| gid == 44|| gid == 45|| gid == 46">
                            <span class="last-nums-cqssc" v-for="item in old_res" :key="item.index">{{item}}</span>
                        </span>
                        <!-- 北京PK10 -->
                        <span v-if="gid==3||gid == 19||gid == 24||gid == 28||gid == 37||gid == 40||gid == 43||gid == 47">
                            <span class="pk10-nums" v-for="item in old_res" :key="item.index">
                                <span v-if="item==1" class="pk10_num1">{{item*1}}</span>
                                <span v-if="item==2" class="pk10_num2">{{item*1}}</span>
                                <span v-if="item==3" class="pk10_num3">{{item*1}}</span>
                                <span v-if="item==4" class="pk10_num4">{{item*1}}</span>
                                <span v-if="item==5" class="pk10_num5">{{item*1}}</span>
                                <span v-if="item==6" class="pk10_num6">{{item*1}}</span>
                                <span v-if="item==7" class="pk10_num7">{{item*1}}</span>
                                <span v-if="item==8" class="pk10_num8">{{item*1}}</span>
                                <span v-if="item==9" class="pk10_num9">{{item*1}}</span>
                                <span v-if="item==10" class="pk10_num10">{{item*1}}</span>
                            </span>
                        </span>
                        <!-- 六合彩,极速六合彩 -->
                        <span v-if="gid==20||gid==34||gid==48">
                            <span v-for="item in old_res" :key="item.index">
                                <span class="last-nums-lhc" :style="{'background-color':item.color}">{{item.num}}</span>
                                <span>{{item.sx}}</span>
                            </span>
                        </span>
                        <!-- pc蛋蛋，加拿大28，幸运28 -->
                        <span v-if="gid==22&&old_res||gid==23&&old_res" style="padding-top:2px">
                            <span class="betclassname_old_pcdd" :style="{'background-color':old_res[0].color}">{{old_res[0].num}}</span>＋
                            <span class="betclassname_old_pcdd" :style="{'background-color':old_res[1].color}">{{old_res[1].num}}</span>＋
                            <span class="betclassname_old_pcdd" :style="{'background-color':old_res[2].color}">{{old_res[2].num}}</span>＝
                            <span class="betclassname_old_pcdd" :style="{'background-color':old_res[3].color}">{{old_res[3].num}}</span>
                        </span>
                        <!-- 快三 -->
                        <span v-if="gid == 4||gid==6||gid == 10||gid==29||gid==30||gid==31||gid==32||gid==36">
                            <span class="nums-k3" v-for="item of old_res" :key="item.index">{{item}}</span>
                        </span>
                    </span>
                    <span v-else class="last-num">暂无开奖信息</span>
                    <span v-if="gid!=20&&gid!=34&&gid!=48" class="go-his" @click="go_his">开奖历史</span>
                </div>
                <div v-show="show_his">
                    <historyopen ref="historyopen" />
                </div>
                <div class="kai-time">
                    <span class="new-qnum">{{lottery_info.new_qnum}}</span>
                    <span>期&nbsp;&nbsp;</span>
                    <span style="color:red">
                        <span>{{countDownText}}&nbsp;</span>
                        <span class="time-nums">{{countDown_h}}</span>
                        <span class="time-dw">:</span>
                        <span class="time-nums">{{countDown_m}}</span>
                        <span class="time-dw">:</span>
                        <span class="time-nums">{{countDown_s}}</span>
                        <!-- <span class="time-dw">秒</span> -->
                    </span>
                    <span v-if="gid==20||gid==34||gid==48" class="go-his" style="float:right;margin-right:10px" @click="go_his">开奖历史</span>
                </div>
            </template>
        </div>

        <!-- 彩种下注页面-->
        <betPage v-show="!sub_list_dialog&&!show_subsuccess&&!stop_sale" ref="betPage" :activeName="activeName" @regainLoss="regainLoss" />
        <div v-show="!sub_list_dialog&&!show_subsuccess&&!stop_sale" class="subbtn" @click="sub_bet">下註</div>
        <!-- 下注列表 -->
        <div v-show="sub_list_dialog">
            <div class="betsumtitle">
                <span style="font-size:16px">合计</span>
                <span style="font-size:16px;color:red">{{sub_list_sum}}</span>
                <span>&nbsp;[共</span>
                <span style="color:red">
                    <span v-if="sub_list[0].title.includes('对碰')">{{sub_list[0].bet_count}}</span>
                    <span v-else>{{sub_list.length}}</span>
                </span>
                <span>注]</span>
            </div>
            <el-table :data="sub_list" size="small" border>
                <el-table-column label="注单" align="center">
                    <template #default="scope">
                        <span>
                            {{scope.row.title}}
                            <i class="el-icon-caret-right"></i>
                            {{scope.row.name}}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column property="static_bonus" label="赔率" align="center"></el-table-column>
                <el-table-column property="val" label="下注金额" align="center">
                    <template #default="scope">
                        <el-popover popper-class="err-bet-val" placement="top-start" trigger="manual" :content="sub_err_msg" v-model="scope.row.sub_popover">
                            <template #reference>
                                <el-input size="small" style="text-align:center" v-model="scope.row.val" :disabled="scope.row.changeInput=='cant'" @input="reset_val(scope.row)"></el-input>
                            </template>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column property label="操作/删除" align="center">
                    <template #default="scope">
                        <el-button size="small" type="danger" circle plain @click="delete_bet(scope.$index)">x</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="text-align: center;margin:10px 0 46px 0">
                <el-button style="width:25%" type="primary" size="default" @click="confirm_sub_bet" :disabled="sub_btn.disabled">确认</el-button>
                <el-button style="width:25%" size="default" @click="reset">取消</el-button>
            </div>
        </div>
        <!-- 下注成功 -->
        <div class="subsuccess" v-if="show_subsuccess">
            <div>
                <img src="../../assets/success.png" alt />
            </div>
            <div class="successtext">恭喜您!投注成功！</div>
            <div class="mx" @click="go_betinfo">>>查看下注明細</div>
            <el-button type="primary" size="default" @click="reset">返回</el-button>
        </div>
        <!-- 下註提示 -->
        <el-dialog v-model="beterr" customClass="mydialogclass">
            <template #header>
                <span class="mydialogtitle">下註提示</span>
            </template>
            <span>请先点击选择号码后再下注！</span>
            <template #footer>
                <span class="dialog-footer">
                    <el-button style="width:100%" type="primary" @click="beterr=false">确认</el-button>
                </span>
            </template>
        </el-dialog>
        <!-- 下注金额 -->
        <el-dialog v-model="show_betvalue" customClass="mydialogclass-betinput" top="0" width="100%">
            <el-row :gutter="20">
                <el-col :span="4" class="input-text">金额</el-col>
                <el-col :span="10">
                    <el-input ref="betvalue" v-model="bet_value" type="number" min="0" autofocus placeholder="请输入下注金额" @input="bet_value=bet_value.replace(/^(0+)|[^\d]+/g,'')" />
                </el-col>
                <el-col :span="6" class="input-text input-subbtn" @click="sub_bet('inputsub')">提交</el-col>
                <el-col :span="4" style="color:#fff">
                    <img class="quit1" src="../../assets/quit1.png" @click="show_betvalue=false" />
                </el-col>
            </el-row>
        </el-dialog>
        <!-- 底部 余额-->
        <div class="footer theme-bg">
            <el-row>
                <el-col :span="12">
                    <div class="yue">彩票余额:{{balance}}</div>
                </el-col>
                <el-col :span="12">
                    <span class="shuying">今日彩票输赢:{{$store.state.userinfo.win_and_lose}}</span>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
    import { get_lhc_sx } from "../../plugin/sx";
    import { lhcNum } from "../../plugin/lhcNum";
    import { pcddNum } from "../../plugin/pcddNum";
    import betPage from "./betPage.vue";
    import { postObj } from "../../plugin/postFormData";
    import md5 from 'js-md5'
    import historyopen from "../menuitems/history.vue";
    export default {
        components: { betPage, historyopen },
        props: ['activeName'],
        data() {
            return {
                gid: '',
                lottery_info: {
                    old_qnum: '',
                },
                old_res: "",/**上一期开奖结果 */
                /**倒计时 */
                countDown_d: "--",
                countDown_h: "--",
                countDown_m: "--",
                countDown_s: "--",
                ahead_time: 0,/**提前封盘时间 */
                countDownText: "距离封盘",/**提示文字 */
                timer1: "",
                timer2: "",
                set_oldres_sid: '',
                poll_data_id: '',
                beterr: false,
                show_betvalue: false,
                bet_value: '',
                sub_list: [{ title: '' }],
                //下注金额总计
                sub_list_sum: 0,
                //下注金额不符合提示文字
                sub_err_msg: "",
                /**确认下注单dialog显示隐藏 */
                sub_list_dialog: false,
                // sub_repeat_time: 0,
                // sub_repeat_time_sid: "",
                sub_btn: {
                    stop: false,
                    text: "提交",
                    disabled: false,
                },
                balance: '0',
                get_user_info_id: '',
                show_subsuccess: false,
                stop_sale: false,
                show_his: false,
                is_stop_bet: false,//是否停止下注
            }
        },
        created() {
            let _this = this;
            const pageVisibility = document.visibilityState;
            const pageVisibilityChange = document.addEventListener('visibilitychange', function () {
                if (pageVisibility === 'visible') {
                    _this.get_lottery_info(_this.$store.state.checked_gid);
                } else if (pageVisibility === 'hidden') {
                    _this.clear();
                }
            });
            this.clear();
            this.gid = this.$store.state.checked_gid;
            if (this.$route.params.data == undefined) {
                this.get_lottery_info(this.gid);
            } else {
                this.getdata(JSON.parse(this.$route.params.data));
            }
            this.getRecentlyLottery(this.$store.state.checked_gid);
            this.get_user_info();
            this.get_user_info_id = window.setInterval(() => {
                this.get_user_info();
            }, 10000);
            this.set_oldres_sid = setInterval(() => {
                this.getRecentlyLottery(this.$store.state.checked_gid);
            }, 5000);
        },
        beforeUnmount() {
            this.clear();
        },
        methods: {
            //六合彩正特，连码，自选不中，根据小玩法重新获取赔率
            regainLoss(playStr) {
                this.$store.state.playing = playStr;
                this.playing = playStr;
                this.get_lose_data();
            },
            reset() {
                this.bet_value = "";
                this.$refs['betPage'].reset();
                this.sub_list_dialog = false;
                this.show_subsuccess = false;
            },
            /**dialog确认下注,最后提交 */
            confirm_sub_bet(type = '') {
                //判断是否满足最小，最大下注金额
                for (let i = 0; i < this.sub_list.length; i++) {
                    if (this.sub_list[i].title.includes('对碰')) {
                        if (this.sub_list[i].val < this.sub_list[i].straightbet_low * this.sub_list[i].bet_count) {
                            this.sub_err_msg = "✖ 错误，下注金额不能小于最小投注金额：" + this.sub_list[i].straightbet_low * this.sub_list[i].bet_count;
                            this.sub_list[i].sub_popover = true;
                            this.$message.error(this.sub_list[i].title + this.sub_list[i].name + "，下注金额不能小于最小投注金额：" + this.sub_list[i].straightbet_low * this.sub_list[i].bet_count)
                            return;
                        }
                        if (this.sub_list[i].val > this.sub_list[i].straightbet_high * this.sub_list[i].bet_count) {
                            this.sub_err_msg = "✖ 错误，下注金额不能大于最大投注金额：" + this.sub_list[i].straightbet_high * this.sub_list[i].bet_count;
                            this.sub_list[i].sub_popover = true;
                            this.$message.error(this.sub_list[i].title + this.sub_list[i].name + "，下注金额不能大于最大投注金额：" + this.sub_list[i].straightbet_high * this.sub_list[i].bet_count)
                            return;
                        }
                    } else {
                        if (this.sub_list[i].val < this.sub_list[i].straightbet_low * 1) {
                            this.sub_err_msg = "✖ 错误，下注金额不能小于最小投注金额：" + this.sub_list[i].straightbet_low;
                            this.sub_list[i].sub_popover = true;
                            this.$message.error(this.sub_list[i].title + this.sub_list[i].name + "，下注金额不能小于最小投注金额：" + this.sub_list[i].straightbet_low)
                            return;
                        }
                        if (this.sub_list[i].val > this.sub_list[i].straightbet_high * 1) {
                            this.sub_err_msg = "✖ 错误，下注金额不能大于最大投注金额：" + this.sub_list[i].straightbet_high;
                            this.sub_list[i].sub_popover = true;
                            this.$message.error(this.sub_list[i].title + this.sub_list[i].name + "，下注金额不能大于最大投注金额：" + this.sub_list[i].straightbet_high)
                            return;
                        }
                    }
                }
                //格式化数据
                let checked_gid = this.$store.state.checked_gid;
                let playing = this.$store.state.playing;
                let new_qnum = this.$store.state.new_qnum;
                let new_qnum_id = this.$store.state.new_qnum_id;
                let activeName = this.$store.state.activeName;
                let uid = this.$store.state.userinfo.user_id;
                let post_data = postObj(
                    this.sub_list,
                    {
                        id: checked_gid,
                        activeName: activeName,
                        new_qnum: new_qnum,
                        new_qnum_id: new_qnum_id
                    }
                );
                // console.log(this.sub_list)
                //md5
                for (let i = 0; i < this.sub_list.length; i++) {
                    //b_sign
                    let game_multiple = this.sub_list[i].bet_count != undefined ? this.sub_list[i].val / this.sub_list[i].bet_count : this.sub_list[i].val;
                    let bet_count = this.sub_list[i].bet_count != undefined ? this.sub_list[i].bet_count : 1;
                    post_data.b_sign.push(
                        md5(`${checked_gid}0NhGGgggiK3a7O7jEeoZ${this.sub_list[i].subname}${this.$store.state.new_qnum_id}${this.$store.state.new_qnum}${uid}${post_data.place[i]}1${game_multiple}0${bet_count}`
                        )
                    )
                    //c_sign
                    //六合彩的加密字段
                    if (checked_gid == 34 || checked_gid == 20 || checked_gid == 48) {
                        // console.log(this.sub_list[i].betclass_nums)
                        // console.log(this.sub_list[i].name +this.sub_list[i].betclass_nums.toString()+ post_data.place[i] + "0NhGGgggiK3a7O7jEeoZ" + uid)
                        if (playing == "sebo") {
                            post_data.c_sign.push(md5(this.sub_list[i].name + this.sub_list[i].betclass_nums.toString() + post_data.place[i] + "0NhGGgggiK3a7O7jEeoZ" + uid));
                        }
                        else if (playing == "texiao" || playing == "yxiao" || playing == "yxiaobz") {
                            // console.log(this.sub_list[i].name +this.sub_list[i].other.toString()+ post_data.place[i] + "0NhGGgggiK3a7O7jEeoZ" + uid)
                            post_data.c_sign.push(md5(this.sub_list[i].name + this.sub_list[i].other.toString() + post_data.place[i] + "0NhGGgggiK3a7O7jEeoZ" + uid));
                        }
                        else {
                            post_data.c_sign.push(md5(this.sub_list[i].title + this.sub_list[i].name + post_data.place[i] + "0NhGGgggiK3a7O7jEeoZ" + uid));
                        }
                    }
                    //快3 C加密
                    else if (checked_gid == 6 || checked_gid == 29 || checked_gid == 30 || checked_gid == 31 || checked_gid == 32 || checked_gid == 36) {
                        if (this.sub_list[i].title === '和值' || this.sub_list[i].title === '红黑码' || this.sub_list[i].title === '三军') {
                            post_data.c_sign.push(md5(post_data.place[i] + "0NhGGgggiK3a7O7jEeoZ" + uid));
                        } else {
                            post_data.c_sign.push(md5(this.sub_list[i].title + this.sub_list[i].name + post_data.place[i] + "0NhGGgggiK3a7O7jEeoZ" + uid));
                        }
                    }
                    else {
                        post_data.c_sign.push(md5(this.sub_list[i].title + this.sub_list[i].name + post_data.place[i] + "0NhGGgggiK3a7O7jEeoZ" + uid));
                    }
                    // console.log('title：' + this.sub_list[i].title + '\n name：' + this.sub_list[i].name + '\n place：' + post_data.place[i] + '\n salt：' + "0NhGGgggiK3a7O7jEeoZ" + '\n uid：' + uid)
                }
                // console.log(post_data)
                //避免重复提交
                // this.sub_repeat_time = 3;
                // let self = this;
                // this.sub_repeat_time_sid = window.setInterval(() => {
                //     self.sub_repeat_time -= 1;
                //     if (self.sub_repeat_time === 0) {
                //         window.clearInterval(self.sub_repeat_time_sid);
                //     }
                // }, 1000)
                this.sub_btn.disabled = true;
                //发送后台验证
                // console.log(post_data)
                this.axios.post("order/checkout_bet", post_data).then(result => {
                    // console.log(result.data)
                    if (result.data.status == 1 && result.data.msg == "投注成功") {
                        //保存为上次投注
                        this.$store.state.last_bet.list = [];
                        for (let i = 0; i < this.sub_list.length; i++) {
                            // console.log(this.sub_list[i].val)
                            this.$store.state.last_bet.list.push({
                                title: this.sub_list[i].title,
                                name: this.sub_list[i].name,
                                val: this.sub_list[i].val
                            });
                        }
                        this.$store.state.last_bet.gid = checked_gid;
                        this.$store.state.last_bet.playing = playing;
                        // console.log(this.$store.state.lottery.last_bet.list)
                        this.sub_list = [{ title: '' }];
                        //清空已输入
                        //刷新余额
                        this.get_user_info();
                        this.sub_list_dialog = false;
                        this.show_subsuccess = true;
                        this.bet_value = '';
                    } else {
                        this.$alert(result.data.msg, "错误", {
                            confirmButtonText: "确定",
                            type: "error",
                        });
                    }
                    // console.log(post_data)
                })
            },
            /**dialog确认下注,修改金额 */
            reset_val(item) {
                //判断关闭/显示，最小最大限额pop
                if (item.val >= item.straightbet_low && item.val <= item.straightbet_high) {
                    item.sub_popover = false;
                } else {
                    item.sub_popover = true;
                    let errMsg = '';
                    if (item.val < item.straightbet_low) {
                        errMsg = "✖ 错误，下注金额不能小于最小投注金额：" + item.straightbet_low;
                    }
                    if (item.val > item.straightbet_high) {
                        errMsg = "✖ 错误，下注金额不能大于最大投注金额：" + item.straightbet_high;
                    }
                    if (errMsg !== '') {
                        this.sub_err_msg = errMsg;
                    }
                }
                //计算总计
                this.sub_list_sum = 0;
                for (let i = 0; i < this.sub_list.length; i++) {
                    this.sub_list_sum += this.sub_list[i].val * 1;
                }
                // console.log(this.sub_list);
            },
            /**dialog确认下注,删除注单操作 */
            delete_bet(index) {
                //如果全部删除，隐藏dialog
                if (this.sub_list.length == 1) {
                    return this.sub_list_dialog = false;
                }
                // console.log(index)
                this.sub_list.splice(index, 1);
                //计算总计
                this.sub_list_sum = 0;
                for (let i = 0; i < this.sub_list.length; i++) {
                    this.sub_list_sum += this.sub_list[i].val * 1;
                }
                // console.log(this.sub_list.length)
            },
            // 下注
            sub_bet(from = '') {
                if (this.is_stop_bet) {
                    this.$alert('下注已封盘', '提示');
                    setTimeout(() => {
                        this.$msgbox.close();
                    }, 2000);
                    return;
                }
                let sub_list = this.$refs['betPage'].sub_bet();
                // console.log(sub_list);
                if (typeof (sub_list) == 'string') {
                    return this.$message.error(sub_list);
                }
                if (from == 'inputsub') {
                    // console.log(sub_list);
                    sub_list.forEach(item => {
                        item.val = this.bet_value * 1;
                    })
                    this.sub_list = sub_list;
                    if (this.sub_list.type == 'error') {
                        this.$message.error(this.sub_list.msg);
                        this.sub_list = [{ title: '' }];
                        return;
                    }
                    let playing = this.$store.state.playing;
                    if (playing === 'honghm' && this.sub_list.length === 0) { return false }
                    if (this.sub_list.length === 0) {
                        this.$alert('您没有选择下注项！', '提示');
                        setTimeout(() => {
                            this.$msgbox.close();
                        }, 2000);
                        this.sub_list = [{ title: '' }];
                    } else {
                        //计算总计
                        this.sub_list_sum = 0;
                        for (let i = 0; i < this.sub_list.length; i++) {
                            this.sub_list_sum += this.sub_list[i].val * 1;
                        }
                        if (this.sub_list_sum == 0) {
                            this.$alert('抱歉，您还没有填写下注金额！', '提示');
                            setTimeout(() => {
                                this.$msgbox.close();
                            }, 2000);
                            this.sub_list = [{ title: '' }];
                        } else {
                            this.sub_list_dialog = true;
                            this.show_betvalue = false;
                            this.sub_btn.disabled = false;
                        }
                    }
                } else {
                    if (sub_list.length == 0) {
                        this.$alert('请先选择号码后再下注', '提示');
                        setTimeout(() => {
                            this.$msgbox.close()
                        }, 2000);
                    } else {
                        this.show_betvalue = true;
                        this.$nextTick(() => {
                            this.$refs['betvalue'].focus()
                        })
                    }

                }
            },
            /**判断是否轮询赔率 */
            poll_lose_data(s) {
                window.clearInterval(this.poll_data_id);
                if (s) {
                    this.get_lose_data();
                    this.poll_data_id = setInterval(() => {
                        this.get_lose_data();
                    }, 5000);
                }
            },
            change_play(play) {
                this.get_lose_data();
            },
            change_game(gid) {
                window.clearInterval(this.poll_data_id);
                this.old_res = [];
                this.$refs['betPage'].choose_lottery();
                this.get_lottery_info(gid).then(res => {
                    this.getRecentlyLottery(this.$store.state.checked_gid);
                });
            },
            get_lose_data() {
                return new Promise((resolve, reject) => {
                    let gid = this.$store.state.checked_gid;
                    let uid = this.$store.state.userinfo.user_id;
                    let qnum = this.lottery_info.new_qnum;
                    let playing = this.$store.state.playing;
                    this.axios.post("/main/index_body_new",
                        {
                            gid: gid,
                            playing: playing,
                            uid: uid,
                            qnum: qnum,
                        }
                    ).then(result => {
                        // console.log(result.data)
                        if (result.data.status && result.data.msg === "请求成功！" && result.data.res != null && result.data.res.length > 0) {
                            let res = result.data.res;
                            this.$store.state.lose_data = res || [];
                            //设置赔率
                            this.$nextTick(() => {
                                if (!this.is_stop_bet) {
                                    this.$refs['betPage'].set_lose();
                                }
                            });
                            resolve();
                        }
                    });
                })
            },
            /**获取当前彩种信息 */
            get_lottery_info(gid = this.$store.state.checked_gid) {
                return new Promise((resolve, reject) => {
                    this.axios.get("/main/index_header_mobile?gid=" + `${gid != '' ? gid : this.$store.state.checked_gid}`).then(result => {
                        if (result.data.status && result.data.msg === "请求成功！") {
                            this.stop_sale = false;
                            this.lottery_info = result.data.res;
                            this.closetime = result.data.res.closetime * 1;
                            this.now = result.data.res.now * 1;
                            this.ahead_time = result.data.res.ahead_time * 1;
                            this.$store.state.new_qnum = result.data.res.new_qnum;
                            this.$store.state.new_qnum_id = result.data.res.new_qnum_id;
                            // this.gameinfo_loading = false;
                            let t = this.closetime - this.now;
                            // console.log(t);
                            if (t <= this.lottery_info.qnum_time && t > this.ahead_time) {
                                this.is_stop_bet = false;
                                let ct = this.closetime - this.ahead_time;
                                this.countdowm(ct, this.now, "start");
                                this.poll_lose_data(true);
                            } else {
                                this.is_stop_bet = true;
                                this.sub_list_dialog = false;
                                this.show_betvalue = false;
                                this.countdowm_ahead(this.closetime - result.data.res.qnum_time, this.now, "stop");
                                this.poll_lose_data(false);
                            }
                        } else if (result.data.msg === '彩种停售') {
                            // this.loadImg = true;
                            this.lottery_info.new_qnum = '...';
                            this.lottery_info.old_qnum = '...';
                            this.lottery_info.is_open = '...';
                            this.lottery_info.not_open = '...';
                            this.lottery_info.qnum_time = 0;
                            this.lottery_info.opentime = '-';
                            this.lottery_info.stoptime = '-';
                            this.old_res = [];
                            this.lottery_info.gname = this.$store.state.game_list.find(item => item.id === this.$store.state.checked_gid).gname;
                            this.clear();
                            this.poll_lose_data(false);
                            this.stop_sale = true;
                            this.is_stop_bet = true;
                            this.sub_list_dialog = false;
                            this.show_betvalue = false;
                        }
                        let list = this.lottery_info.old_res.split(',');
                        if (gid == 20 || gid == 34 || gid == 48) {
                            let time = this.$filters.time(this.lottery_info.old_starttime, 'notime')
                            let arr = [];
                            for (let i = 0; i < list.length; i++) {
                                arr.push(lhcNum(list[i], time));
                            }
                            arr = get_lhc_sx("开奖", arr);
                            this.old_res = arr;
                        } else {
                            this.old_res = list;
                        }
                        this.gid = this.$store.state.checked_gid;
                        resolve();
                    });
                })
            },
            getdata(data) {
                if (data.status && data.msg === "请求成功！") {
                    this.stop_sale = false;
                    this.lottery_info = data.res;
                    this.closetime = data.res.closetime * 1;
                    this.now = data.res.now * 1;
                    this.ahead_time = data.res.ahead_time * 1;
                    this.$store.state.new_qnum = data.res.new_qnum;
                    this.$store.state.new_qnum_id = data.res.new_qnum_id;
                    let t = this.closetime - this.now;
                    // console.log(t);
                    if (t <= this.lottery_info.qnum_time && t > this.ahead_time) {
                        this.is_stop_bet = false;
                        let ct = this.closetime - this.ahead_time;
                        this.countdowm(ct, this.now, "start");
                        this.poll_lose_data(true);
                    } else {
                        this.is_stop_bet = true;
                        this.sub_list_dialog = false;
                        this.show_betvalue = false;
                        this.countdowm_ahead(this.closetime - data.res.qnum_time, this.now, "stop");
                        this.poll_lose_data(false);
                    }
                    let list = this.lottery_info.old_res.split(',');
                    if (this.gid == 20 || this.gid == 34 || this.gid == 48) {
                        let time = this.$filters.time(this.lottery_info.old_starttime, 'notime')
                        let arr = [];
                        for (let i = 0; i < list.length; i++) {
                            arr.push(lhcNum(list[i], time));
                        }
                        arr = get_lhc_sx("开奖", arr);
                        this.old_res = arr;
                    } else {
                        this.old_res = list;
                    }
                } else if (data.msg === '彩种停售') {
                    this.lottery_info.new_qnum = '...';
                    this.lottery_info.old_qnum = '...';
                    this.lottery_info.is_open = '...';
                    this.lottery_info.not_open = '...';
                    this.lottery_info.qnum_time = 0;
                    this.lottery_info.opentime = '-';
                    this.lottery_info.stoptime = '-';
                    this.old_res = [];
                    this.lottery_info.gname = this.$store.state.game_list.find(item => item.id === this.$store.state.checked_gid).gname;
                    this.poll_lose_data(false);
                    this.stop_sale = true;
                }
            },
            /**投注倒计时 */
            countdowm(closetime, now, text) {
                window.clearInterval(this.timer1);
                this.countDownText = "距离封盘";
                let self = this;
                let t = (closetime - now) * 1000;
                // this.$emit('stop_bet', false, '提交');
                this.timer1 = setInterval(function () {
                    t -= 1000;
                    if (t >= 0) {
                        let day = Math.floor(t / 86400000);
                        let hour = Math.floor((t / 3600000) % 24);
                        let min = Math.floor((t / 60000) % 60);
                        let sec = Math.floor((t / 1000) % 60);
                        if (day > 0) {
                            hour += day * 24;
                        }
                        hour = hour < 10 ? "0" + hour : hour;
                        min = min < 10 ? "0" + min : min;
                        sec = sec < 10 ? "0" + sec : sec;
                        // self.countDown_d = day;
                        self.countDown_h = hour;
                        self.countDown_m = min;
                        self.countDown_s = sec;
                        // console.log("距離封盤--"+min+":"+sec+" "+text)
                    } else {
                        window.clearInterval(self.timer1);
                        self.endcallback(text);
                        //封盘去掉赔率
                        window.clearInterval(this.poll_data_id);
                        self.$refs['betPage'].reset();
                        self.$store.state.lose_data.forEach(item => item.rate = '');
                        self.$refs['betPage'].set_lose();
                        self.is_stop_bet = true;
                        self.show_betvalue = false;
                    }
                }, 1000);
            },
            /**封盘倒计时 */
            countdowm_ahead(closetime, now, text) {
                // this.$emit('stop_bet', true, '封盘');
                window.clearInterval(this.timer2);
                this.countDownText = "距离开盘"
                let self = this;
                let t = (closetime - now) * 1000;
                this.timer2 = setInterval(function () {
                    t -= 1000;
                    if (t >= 0) {
                        let day = Math.floor(t / 86400000);
                        let hour = Math.floor((t / 3600000) % 24);
                        let min = Math.floor((t / 60000) % 60);
                        let sec = Math.floor((t / 1000) % 60);
                        hour = hour < 10 ? "0" + hour : hour;
                        min = min < 10 ? "0" + min : min;
                        sec = sec < 10 ? "0" + sec : sec;
                        self.countDown_h = hour;
                        self.countDown_m = min;
                        self.countDown_s = sec;
                        // console.log("距離開盤--"+min+":"+sec+" "+text)
                    } else {
                        // self.loadImg = true;
                        window.clearInterval(self.timer2);
                        self.endcallback_ahead();
                    }
                }, 1000);
            },
            endcallback() {
                this.countDownText = "距离开盘";
                this.get_lottery_info(this.$store.state.checked_gid)
            },
            endcallback_ahead() {
                window.clearInterval(this.timer1);
                window.clearInterval(this.timer2);
                // this.$emit("stopBet", "start")
                this.countDownText = "距离封盘";
                this.get_lottery_info(this.$store.state.checked_gid);
            },
            /**获取近期开奖 ,设置第一组为最新开奖号码*/
            getRecentlyLottery(gid = this.$store.state.checked_gid) {
                this.axios.get("/betresult/index?gid=" + gid + "&recent=1").then(result => {
                    // console.log(result.data);
                    if (result.data.status && result.data.msg === "请求成功！") {
                        if (result.data.res.list.length > 0) {
                            let strs = result.data.res.list[0]
                            let arr = [];
                            /**判断解析六合彩球号，颜色，生肖 */
                            if (result.data.res.gname.id == 20 || result.data.res.gname.id == 34 || result.data.res.gname.id == 48) {
                                let lottery_open = strs.lottery_open.split(' ')[0];
                                for (let i = 0; i < strs.res.length; i++) {
                                    arr.push(lhcNum(strs.res[i], lottery_open));
                                }
                                arr = get_lhc_sx("开奖", arr)
                            }
                            //pc蛋蛋，加拿大28，幸运28
                            else if (result.data.res.gname.id == 22 || result.data.res.gname.id == 23) {
                                let list = result.data.res.list;
                                for (let i = 0; i < strs.res.length; i++) {
                                    arr.push(pcddNum(strs.res[i]));
                                }
                                arr.push(pcddNum(strs.sum));
                                for (let i = 0; i < list.length; i++) {
                                    for (let j = 0; j < list[i].res.length; j++) {
                                        list[i].res[j] = pcddNum(list[i].res[j])
                                    }
                                    list[i].sum = pcddNum(list[i].sum)
                                }
                            }
                            /**其他彩种球号，cqssc,pk10,k3 */
                            else {
                                for (let i = 0; i < strs.res.length; i++) {
                                    arr.push(strs.res[i]);
                                }
                            }
                            this.old_res = arr;
                            this.lottery_info.old_qnum = strs.qnum;
                            result.data.res.nums = 10;
                            this.$refs['historyopen'].getdata(result.data.res);
                        }
                    }
                });
            },
            go_his() {
                this.getRecentlyLottery(this.$store.state.checked_gid);
                this.show_his = !this.show_his;
            },
            clear() {
                window.clearInterval(this.timer1);
                window.clearInterval(this.timer2);
                window.clearInterval(this.set_oldres_sid);
                window.clearInterval(this.poll_data_id);
                window.clearInterval(this.get_user_info_id);
            },
            get_user_info() {
                this.axios.post("user/get_member_credit", { uid: this.$store.state.uid }).then(result => {
                    // console.log(result);
                    if (result.data.status == 1 && result.data.msg == "请求成功") {
                        this.$store.state.userinfo.balance = result.data.res.user_data[0].balance * 1;
                        this.$store.state.userinfo.kuaicai_balance = result.data.res.user_data[0].kuaicai_balance * 1;
                        this.$store.state.userinfo.win_and_lose = result.data.res.win_and_lose;
                        this.balance = result.data.res.user_data[0].credit_balance * 1;
                        // this.kuaicai_balance = result.data.res.user_data[0].kuaicai_balance * 1;
                        // this.is_kuaicai = result.data.res.user_data[0].is_kuaicai;
                        // this.is_liuhe = result.data.res.user_data[0].is_liuhe;
                        this.usdt = result.data.res.usdt;
                    }
                })
            },
            go_betinfo() {
                this.axios.get(`/order/open_order?limit=5&page=1`).then(result => {
                    if (result.data.status == 1 && result.data.msg === "请求成功！") {
                        this.reset();
                        this.clear();
                        this.$router.push(
                            {
                                path: '/home/betinfo',
                                name: 'betinfo',
                                params: {
                                    data: JSON.stringify(result.data.res)
                                }
                            }
                        );
                    }
                })
            },
        },
    }
</script>

<style scoped>
    .kai-nums,
    .kai-time {
        height: 26px;
        line-height: 24px;
        font-weight: bold;
        padding-left: 3px;
        padding-top: 2px;
        border-bottom: 1px solid #cfcfcf;
        background-color: #fff;
    }
    .new-qnum {
        color: #14ac08;
    }
    .go-his {
        color: #409eff;
        text-decoration: underline;
        margin-left: 2px;
    }
    .subbtn {
        position: fixed;
        top: 20%;
        right: 2%;
        height: 50px;
        width: 50px;
        text-align: center;
        line-height: 50px;
        background: #000;
        -moz-border-radius: 50px;
        -webkit-border-radius: 50px;
        border-radius: 50px;
        z-index: 999;
        color: #ff0;
        font-weight: 900;
        font-size: 18px;
        border: 1px solid transparent;
        background: rgba(0, 0, 0, 0.7);
    }
    .input-text {
        line-height: 30px;
        font-size: 16px;
        font-weight: bold;
        color: #fff;
        text-align: center !important;
    }
    .input-subbtn {
        border-radius: 5px;
        background: rgba(0, 0, 0, 0.2);
    }
    .quit1 {
        background: rgba(0, 0, 0, 0.2);
        padding: 6px;
        border-radius: 36px;
        vertical-align: middle;
    }
    .betsumtitle {
        background-image: linear-gradient(180deg, #fdfdfd 25%, #dcdfe6 100%);
        color: #fff;
        height: 26px;
        line-height: 26px;
        text-align: center;
        color: #333;
        font-weight: bold;
        border-top: 1px solid #e4e7ed;
        margin-top: 5px;
    }
    .footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 1;
        color: #fff;
        font-weight: bold;
        font-size: 14px;
        text-shadow: 0 1px 0 #888;
        box-shadow: 0 1px 2px 0 #888;
        height: 36px;
    }
    .yue {
        padding-left: 10px;
        line-height: 36px;
    }
    .shuying {
        float: right;
        padding-right: 10px;
        line-height: 36px;
    }
    .subsuccess {
        background: #fff;
        text-align: center;
        padding: 0 0 38px 0;
    }
    .subsuccess img {
        width: 74px;
        height: 74px;
        margin: 20px auto 5px;
    }
    .subsuccess .successtext {
        font-size: 16px;
        color: green;
        font-weight: bold;
        margin-bottom: 20px;
    }
    .subsuccess .mx {
        font-size: 16px;
        color: #409eff;
        font-weight: bold;
        margin-bottom: 20px;
        text-decoration: underline;
    }
    .order-close {
        text-align: center;
    }
    .DDtitle {
        margin-top: 7px;
        text-align: center;
        height: 30px;
        border-top: 1px solid #dcdcdc;
        border-bottom: 1px solid #dcdcdc;
        line-height: 30px;
        font-weight: 700;
        background: -moz-linear-gradient(top, #fefeff, #eaf1f8);
        background: linear-gradient(top, #fefeff, #eaf1f8);
        background: -webkit-linear-gradient(top, #fefeff, #eaf1f8);
    }
    .tipsBox {
        width: 100%;
        background: #fff;
        border-bottom: 1px solid #dcdcdc;
        float: left;
        -moz-box-shadow: 0 1px 1px 0 #e3e3e3;
        -webkit-box-shadow: 0 1px 1px 0 #e3e3e3;
        box-shadow: 0 1px 1px 0 #e3e3e3;
        margin-bottom: 3%;
    }
    .warning {
        width: 74px;
        height: 74px;
        margin: 20px auto 5px;
        background: url("../../assets/warning.png") no-repeat;
    }
    .tipsBox span {
        width: 100%;
        text-align: center;
        font-size: 20px;
        font-weight: 700;
        float: left;
        margin-bottom: 20px;
        color: #cb9c00;
    }
</style>