<template>
<div class="pcdd-tema">
    <!-- 两面 -->
    <el-row>
        <el-col :span="24" v-for="item in tema" :key="item.index">
            <div class="bet-title">
                <span>{{item.ballname}}</span>
            </div>
            <el-row class="bet-items" :gutter="6">
                <el-col :span="8" v-for="i in item.betclass" :key="i.index">
                    <div class="bet-items-info" :style="{backgroundColor:i.bg}" @click="click_item(i)">
                    <span class="bet-name">
                        <span class="betclassname_old_pcdd" :style="{'background-color':i.color}">{{i.name}}</span>
                    </span>
                    <span class="bet-lose">{{i.static_bonus}}</span>
                    <!-- <el-input v-model="i.val" size="mini" v-enter-number v-on:focus="i.val=check_fast_val(i.val)"></el-input> -->
                    <!-- <div style="clear:both"></div> -->
                    </div>
                </el-col>
            </el-row>
        </el-col>
    </el-row>
</div>
</template>

<script>
import { link } from 'fs';
export default {
    data() {
        return {
            tema: [
                {
                    ballname: "特码",
                    betclass: [
                        { title: "特码", bg:'#F2F3F8', name: "0", isSelected: false, static_bonus: "", val: "" ,subname:"0_0_0", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#909399"},
                        { title: "特码", bg:'#F2F3F8', name: "1", isSelected: false, static_bonus: "", val: "" ,subname:"0_0_1", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#67C23A"},
                        { title: "特码", bg:'#F2F3F8', name: "2", isSelected: false, static_bonus: "", val: "" ,subname:"0_0_2", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#409EFF"},
                        { title: "特码", bg:'#F2F3F8', name: "3", isSelected: false, static_bonus: "", val: "" ,subname:"0_0_3", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#F56C6C"},
                        { title: "特码", bg:'#F2F3F8', name: "4", isSelected: false, static_bonus: "", val: "" ,subname:"0_0_4", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#67C23A"},
                        { title: "特码", bg:'#F2F3F8', name: "5", isSelected: false, static_bonus: "", val: "" ,subname:"0_0_5", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#409EFF"},
                        { title: "特码", bg:'#F2F3F8', name: "6", isSelected: false, static_bonus: "", val: "" ,subname:"0_0_6", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#F56C6C"},
                        { title: "特码", bg:'#F2F3F8', name: "7", isSelected: false, static_bonus: "", val: "" ,subname:"0_0_7", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#67C23A"},
                        { title: "特码", bg:'#F2F3F8', name: "8", isSelected: false, static_bonus: "", val: "" ,subname:"0_0_8", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#409EFF"},
                        { title: "特码", bg:'#F2F3F8', name: "9", isSelected: false, static_bonus: "", val: "" ,subname:"0_0_9", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#F56C6C"},
                        { title: "特码", bg:'#F2F3F8', name: "10", isSelected: false, static_bonus: "", val: "" ,subname:"0_0_10", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#67C23A"},
                        { title: "特码", bg:'#F2F3F8', name: "11", isSelected: false, static_bonus: "", val: "" ,subname:"0_0_11", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#409EFF"},
                        { title: "特码", bg:'#F2F3F8', name: "12", isSelected: false, static_bonus: "", val: "" ,subname:"0_0_12", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#F56C6C"},
                        { title: "特码", bg:'#F2F3F8', name: "13", isSelected: false, static_bonus: "", val: "" ,subname:"0_0_13", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#909399"},
                        { title: "特码", bg:'#F2F3F8', name: "14", isSelected: false, static_bonus: "", val: "" ,subname:"0_0_14", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#909399"},
                        { title: "特码", bg:'#F2F3F8', name: "15", isSelected: false, static_bonus: "", val: "" ,subname:"0_0_15", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#F56C6C"},
                        { title: "特码", bg:'#F2F3F8', name: "16", isSelected: false, static_bonus: "", val: "" ,subname:"0_0_16", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#67C23A"},
                        { title: "特码", bg:'#F2F3F8', name: "17", isSelected: false, static_bonus: "", val: "" ,subname:"0_0_17", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#409EFF"},
                        { title: "特码", bg:'#F2F3F8', name: "18", isSelected: false, static_bonus: "", val: "" ,subname:"0_0_18", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#F56C6C"},
                        { title: "特码", bg:'#F2F3F8', name: "19", isSelected: false, static_bonus: "", val: "" ,subname:"0_0_19", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#67C23A"},
                        { title: "特码", bg:'#F2F3F8', name: "20", isSelected: false, static_bonus: "", val: "" ,subname:"0_0_20", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#409EFF"},
                        { title: "特码", bg:'#F2F3F8', name: "21", isSelected: false, static_bonus: "", val: "" ,subname:"0_0_21", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#F56C6C"},
                        { title: "特码", bg:'#F2F3F8', name: "22", isSelected: false, static_bonus: "", val: "" ,subname:"0_0_22", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#67C23A"},
                        { title: "特码", bg:'#F2F3F8', name: "23", isSelected: false, static_bonus: "", val: "" ,subname:"0_0_23", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#409EFF"},
                        { title: "特码", bg:'#F2F3F8', name: "24", isSelected: false, static_bonus: "", val: "" ,subname:"0_0_24", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#F56C6C"},
                        { title: "特码", bg:'#F2F3F8', name: "25", isSelected: false, static_bonus: "", val: "" ,subname:"0_0_25", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#67C23A"},
                        { title: "特码", bg:'#F2F3F8', name: "26", isSelected: false, static_bonus: "", val: "" ,subname:"0_0_26", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#409EFF"},
                        { title: "特码", bg:'#F2F3F8', name: "27", isSelected: false, static_bonus: "", val: "" ,subname:"0_0_27", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#909399"},
                    ]
                }
            ]
        }
    },

    methods: {
        //选取投注项
        click_item(item){
            if(item.isSelected){
                item.isSelected=false;
                item.bg='#F2F3F8';
            }else{
                item.bg='#ffef3a';
                item.isSelected=true;
            }
            return item
        },
        //快速下注
        fast_sub_bet(){
            let list=[];
            //特码
            for(let i=0;i<this.tema.length;i++){
                for(let j=0;j<this.tema[i].betclass.length;j++){
                    if(this.tema[i].betclass[j].isSelected){
                        list.push(this.tema[i].betclass[j]);
                    }
                }
            }
            // console.log(list)
            return list;
        },
        /**显示赔率等数据 */
        get_lose(){
            let bonus_high=this.$store.state.lottery.lose_data.bonus_high;
            let others=this.$store.state.lottery.lose_data.others;
            let static_bonus=this.$store.state.lottery.lose_data.static_bonus;
            //特码
            for(let i=0;i<this.tema.length;i++){
                for(let j=0;j<this.tema[i].betclass.length;j++){
                    this.tema[i].betclass[j].static_bonus=static_bonus[j];
                    this.tema[i].betclass[j].straightbet_high=others[0].straightbet_high;
                    this.tema[i].betclass[j].straightbet_low=others[0].straightbet_low;
                }
            }
        },
        /**检查是否满足快捷输入 */
        check_fast_val(val){
            if(val){
                return val;
            }
            else{
                val=this.$parent.get_fast();
                return val;
            }
        },
        /**重填，重置 */
        reset(){
            //特码
            for(let i=0;i<this.tema.length;i++){
                for(let j=0;j<this.tema[i].betclass.length;j++){
                    this.tema[i].betclass[j].val="";
                    this.tema[i].betclass[j].isSelected=false;
                    this.tema[i].betclass[j].bg="#F2F3F8";
                }
            }
        },
        /**提交，下注 */
        sub_bet(){
            let list=[];
            //特码
            for(let i=0;i<this.tema.length;i++){
                for(let j=0;j<this.tema[i].betclass.length;j++){
                    if(this.tema[i].betclass[j].isSelected){
                        list.push(this.tema[i].betclass[j]);
                    }
                }
            }
            // console.log(list)
            return list;
        },
        /**重复上次下单 */
        repeat_last_bet(list) {
            // console.log(list)
            for(let i=0;i<list.length;i++){
                //特码
                for(let j=0;j<this.tema.length;j++){
                    for(let k=0;k<this.tema[j].betclass.length;k++){
                        if(this.tema[j].betclass[k].title==list[i].title&&this.tema[j].betclass[k].name==list[i].name){
                            this.tema[j].betclass[k].val=list[i].val;
                        }
                    }
                }
            }
        },
    },
}
</script>

<style scoped>
.betclassname_old_pcdd{
    /* float: left; */
    /* display: inline-block; */
    width: 1.4rem;
    height: 1.4rem;
    /* padding: 0 !important; */
    border-radius: 50%;
    color: #fff;
    /* text-align: center !important; */
    font-weight: bold;
    margin-left:-0.6rem;
    margin-top:.2rem;
    line-height: 1.4rem;
}
</style>


