/*
 * @Author: your name
 * @Date: 2022-03-11 16:28:34
 * @LastEditTime: 2022-09-04 00:31:32
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \pc_admin\src\router\index.js
 */
import { createRouter, createWebHashHistory } from 'vue-router'
const routes = [
    {
        path: '/',
        name: '/',
        component: () => import('../views/Login.vue')
    },
    {
        path: '/notice',
        name: 'notice',
        component: () => import('../views/notice.vue'),
    },
    {
        path: '/home',
        name: 'Home',
        component: () => import('../views/Home.vue'),
        children:[
            {
                path: '',
                name: 'gameLobby',
                component: () => import('../components/menuitems/gameLobby.vue'),
            },
            {
                path: 'userbet',
                name: 'userbet',
                component: () => import('../components/bet/userbet.vue'),
            },
            {
                path: 'userinfo',
                name: 'userinfo',
                component: () => import('../components/menuitems/userinfo.vue'),
            },
            {
                path: 'xieyi',
                name: 'xieyi',
                component: () => import('../components/menuitems/xieyi.vue'),
            },
            {
                path: 'loginlog',
                name: 'loginlog',
                component: () => import('../components/menuitems/loginlog.vue'),
            },
            {
                path: 'changepass',
                name: 'changepass',
                component: () => import('../components/menuitems/changepass.vue'),
            },
            {
                path: 'rule',
                name: 'rule',
                component: () => import('../components/menuitems/rule.vue'),
            },
            {
                path: 'historyopen',
                name: 'historyopen',
                component: () => import('../components/menuitems/history.vue'),
            },
            {
                path: 'report',
                name: 'report',
                component: () => import('../components/menuitems/report.vue'),
            },
            {
                path: 'betinfo',
                name: 'betinfo',
                component: () => import('../components/menuitems/betinfo.vue'),
            },
            {
                path: 'duijiangguize',
                name: 'duijiangguize',
                component: () => import('../components/menuitems/duijiangguize.vue'),
            },
        ]
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})
router.beforeEach((to, from, next) => {
    const isLogin = sessionStorage.getItem("islogin") ? "true" : "false"
    if (to.path == '/' || to.path == '/login') {
        next()
    } else {
        if (isLogin) {
            next()
        } else {
            next({ name: 'login' });
        }
    }
})

export default router
