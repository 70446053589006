<template>
    <div class="zama1_6">
        <!-- 1-6 -->
        <el-row>
            <el-col :span="24" v-for="item in balls" :key="item.index">
                <div class="bet-title">
                    <span>{{item.ballname}}</span>
                </div>
                <el-row class="bet-items" :gutter="6">
                    <el-col :span="8" v-for="i in item.betclass" :key="i.index">
                        <div class="bet-items-info" :style="{backgroundColor:i.bg}" @click="click_item(i)">
                            <span class="bet-name" :style="{'color':i.name=='红波'?'red':i.name=='绿波'?'#67c23a':i.name=='蓝波'?'#409eff':'#333'}">{{i.name}}</span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <span v-show="i.bg=='#ffef3a'" class="select-icon"><Select style="width: 24px; height: 24px;" /></span>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import { link } from 'fs';
    export default {
        data() {
            return {
                balls: [
                    {
                        ballname: "正码1",
                        betclass: [
                            { title: "正码1", bg: '#F2F3F8', name: "大", isSelected: false, static_bonus: "", val: "", subname: "16_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码1", bg: '#F2F3F8', name: "小", isSelected: false, static_bonus: "", val: "", subname: "16_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码1", bg: '#F2F3F8', name: "单", isSelected: false, static_bonus: "", val: "", subname: "16_0_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码1", bg: '#F2F3F8', name: "双", isSelected: false, static_bonus: "", val: "", subname: "16_0_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码1", bg: '#F2F3F8', name: "合大", isSelected: false, static_bonus: "", val: "", subname: "16_0_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码1", bg: '#F2F3F8', name: "合小", isSelected: false, static_bonus: "", val: "", subname: "16_0_5", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码1", bg: '#F2F3F8', name: "合单", isSelected: false, static_bonus: "", val: "", subname: "16_0_6", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码1", bg: '#F2F3F8', name: "合双", isSelected: false, static_bonus: "", val: "", subname: "16_0_7", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码1", bg: '#F2F3F8', name: "尾大", isSelected: false, static_bonus: "", val: "", subname: "16_0_8", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码1", bg: '#F2F3F8', name: "尾小", isSelected: false, static_bonus: "", val: "", subname: "16_0_9", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码1", bg: '#F2F3F8', name: "红波", isSelected: false, static_bonus: "", val: "", subname: "16_0_10", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码1", bg: '#F2F3F8', name: "绿波", isSelected: false, static_bonus: "", val: "", subname: "16_0_11", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码1", bg: '#F2F3F8', name: "蓝波", isSelected: false, static_bonus: "", val: "", subname: "16_0_12", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    },
                    {
                        ballname: "正码2",
                        betclass: [
                            { title: "正码2", bg: '#F2F3F8', name: "大", isSelected: false, static_bonus: "", val: "", subname: "16_1_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码2", bg: '#F2F3F8', name: "小", isSelected: false, static_bonus: "", val: "", subname: "16_1_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码2", bg: '#F2F3F8', name: "单", isSelected: false, static_bonus: "", val: "", subname: "16_1_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码2", bg: '#F2F3F8', name: "双", isSelected: false, static_bonus: "", val: "", subname: "16_1_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码2", bg: '#F2F3F8', name: "合大", isSelected: false, static_bonus: "", val: "", subname: "16_1_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码2", bg: '#F2F3F8', name: "合小", isSelected: false, static_bonus: "", val: "", subname: "16_1_5", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码2", bg: '#F2F3F8', name: "合单", isSelected: false, static_bonus: "", val: "", subname: "16_1_6", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码2", bg: '#F2F3F8', name: "合双", isSelected: false, static_bonus: "", val: "", subname: "16_1_7", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码2", bg: '#F2F3F8', name: "尾大", isSelected: false, static_bonus: "", val: "", subname: "16_1_8", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码2", bg: '#F2F3F8', name: "尾小", isSelected: false, static_bonus: "", val: "", subname: "16_1_9", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码2", bg: '#F2F3F8', name: "红波", isSelected: false, static_bonus: "", val: "", subname: "16_1_10", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码2", bg: '#F2F3F8', name: "绿波", isSelected: false, static_bonus: "", val: "", subname: "16_1_11", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码2", bg: '#F2F3F8', name: "蓝波", isSelected: false, static_bonus: "", val: "", subname: "16_1_12", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    },
                    {
                        ballname: "正码3",
                        betclass: [
                            { title: "正码3", bg: '#F2F3F8', name: "大", isSelected: false, static_bonus: "", val: "", subname: "16_2_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码3", bg: '#F2F3F8', name: "小", isSelected: false, static_bonus: "", val: "", subname: "16_2_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码3", bg: '#F2F3F8', name: "单", isSelected: false, static_bonus: "", val: "", subname: "16_2_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码3", bg: '#F2F3F8', name: "双", isSelected: false, static_bonus: "", val: "", subname: "16_2_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码3", bg: '#F2F3F8', name: "合大", isSelected: false, static_bonus: "", val: "", subname: "16_2_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码3", bg: '#F2F3F8', name: "合小", isSelected: false, static_bonus: "", val: "", subname: "16_2_5", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码3", bg: '#F2F3F8', name: "合单", isSelected: false, static_bonus: "", val: "", subname: "16_2_6", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码3", bg: '#F2F3F8', name: "合双", isSelected: false, static_bonus: "", val: "", subname: "16_2_7", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码3", bg: '#F2F3F8', name: "尾大", isSelected: false, static_bonus: "", val: "", subname: "16_2_8", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码3", bg: '#F2F3F8', name: "尾小", isSelected: false, static_bonus: "", val: "", subname: "16_2_9", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码3", bg: '#F2F3F8', name: "红波", isSelected: false, static_bonus: "", val: "", subname: "16_2_10", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码3", bg: '#F2F3F8', name: "绿波", isSelected: false, static_bonus: "", val: "", subname: "16_2_11", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码3", bg: '#F2F3F8', name: "蓝波", isSelected: false, static_bonus: "", val: "", subname: "16_2_12", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    },
                    {
                        ballname: "正码4",
                        betclass: [
                            { title: "正码4", bg: '#F2F3F8', name: "大", isSelected: false, static_bonus: "", val: "", subname: "16_3_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码4", bg: '#F2F3F8', name: "小", isSelected: false, static_bonus: "", val: "", subname: "16_3_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码4", bg: '#F2F3F8', name: "单", isSelected: false, static_bonus: "", val: "", subname: "16_3_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码4", bg: '#F2F3F8', name: "双", isSelected: false, static_bonus: "", val: "", subname: "16_3_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码4", bg: '#F2F3F8', name: "合大", isSelected: false, static_bonus: "", val: "", subname: "16_3_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码4", bg: '#F2F3F8', name: "合小", isSelected: false, static_bonus: "", val: "", subname: "16_3_5", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码4", bg: '#F2F3F8', name: "合单", isSelected: false, static_bonus: "", val: "", subname: "16_3_6", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码4", bg: '#F2F3F8', name: "合双", isSelected: false, static_bonus: "", val: "", subname: "16_3_7", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码4", bg: '#F2F3F8', name: "尾大", isSelected: false, static_bonus: "", val: "", subname: "16_3_8", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码4", bg: '#F2F3F8', name: "尾小", isSelected: false, static_bonus: "", val: "", subname: "16_3_9", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码4", bg: '#F2F3F8', name: "红波", isSelected: false, static_bonus: "", val: "", subname: "16_3_10", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码4", bg: '#F2F3F8', name: "绿波", isSelected: false, static_bonus: "", val: "", subname: "16_3_11", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码4", bg: '#F2F3F8', name: "蓝波", isSelected: false, static_bonus: "", val: "", subname: "16_3_12", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    },
                    {
                        ballname: "正码5",
                        betclass: [
                            { title: "正码5", bg: '#F2F3F8', name: "大", isSelected: false, static_bonus: "", val: "", subname: "16_4_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码5", bg: '#F2F3F8', name: "小", isSelected: false, static_bonus: "", val: "", subname: "16_4_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码5", bg: '#F2F3F8', name: "单", isSelected: false, static_bonus: "", val: "", subname: "16_4_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码5", bg: '#F2F3F8', name: "双", isSelected: false, static_bonus: "", val: "", subname: "16_4_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码5", bg: '#F2F3F8', name: "合大", isSelected: false, static_bonus: "", val: "", subname: "16_4_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码5", bg: '#F2F3F8', name: "合小", isSelected: false, static_bonus: "", val: "", subname: "16_4_5", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码5", bg: '#F2F3F8', name: "合单", isSelected: false, static_bonus: "", val: "", subname: "16_4_6", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码5", bg: '#F2F3F8', name: "合双", isSelected: false, static_bonus: "", val: "", subname: "16_4_7", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码5", bg: '#F2F3F8', name: "尾大", isSelected: false, static_bonus: "", val: "", subname: "16_4_8", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码5", bg: '#F2F3F8', name: "尾小", isSelected: false, static_bonus: "", val: "", subname: "16_4_9", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码5", bg: '#F2F3F8', name: "红波", isSelected: false, static_bonus: "", val: "", subname: "16_4_10", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码5", bg: '#F2F3F8', name: "绿波", isSelected: false, static_bonus: "", val: "", subname: "16_4_11", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码5", bg: '#F2F3F8', name: "蓝波", isSelected: false, static_bonus: "", val: "", subname: "16_4_12", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    },
                    {
                        ballname: "正码6",
                        betclass: [
                            { title: "正码6", bg: '#F2F3F8', name: "大", isSelected: false, static_bonus: "", val: "", subname: "16_5_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码6", bg: '#F2F3F8', name: "小", isSelected: false, static_bonus: "", val: "", subname: "16_5_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码6", bg: '#F2F3F8', name: "单", isSelected: false, static_bonus: "", val: "", subname: "16_5_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码6", bg: '#F2F3F8', name: "双", isSelected: false, static_bonus: "", val: "", subname: "16_5_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码6", bg: '#F2F3F8', name: "合大", isSelected: false, static_bonus: "", val: "", subname: "16_5_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码6", bg: '#F2F3F8', name: "合小", isSelected: false, static_bonus: "", val: "", subname: "16_5_5", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码6", bg: '#F2F3F8', name: "合单", isSelected: false, static_bonus: "", val: "", subname: "16_5_6", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码6", bg: '#F2F3F8', name: "合双", isSelected: false, static_bonus: "", val: "", subname: "16_5_7", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码6", bg: '#F2F3F8', name: "尾大", isSelected: false, static_bonus: "", val: "", subname: "16_5_8", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码6", bg: '#F2F3F8', name: "尾小", isSelected: false, static_bonus: "", val: "", subname: "16_5_9", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码6", bg: '#F2F3F8', name: "红波", isSelected: false, static_bonus: "", val: "", subname: "16_5_10", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码6", bg: '#F2F3F8', name: "绿波", isSelected: false, static_bonus: "", val: "", subname: "16_5_11", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码6", bg: '#F2F3F8', name: "蓝波", isSelected: false, static_bonus: "", val: "", subname: "16_5_12", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    },

                ],
            }
        },
        methods: {
            //选取投注项
            click_item(item) {
                if (item.isSelected) {
                    item.isSelected = false;
                    item.bg = '#F2F3F8';
                } else {
                    item.bg = '#ffef3a';
                    item.isSelected = true;
                }
                return item
            },
            //快速下注
            fast_sub_bet() {
                let list = [];
                //1-5
                for (let i = 0; i < this.balls.length; i++) {
                    for (let j = 0; j < this.balls[i].betclass.length; j++) {
                        if (this.balls[i].betclass[j].isSelected) {
                            list.push(this.balls[i].betclass[j]);
                        }
                    }
                }
                // console.log(list)
                return list;
            },
            /**显示赔率等数据 */
            get_lose() {
                let lose_data = this.$store.state.lose_data;
                //1-5
                lose_data.forEach(item => {
                    for (let i = 0; i < this.balls.length; i++) {
                        for (let j = 0; j < this.balls[i].betclass.length; j++) {
                            if (item.name==this.balls[i].betclass[j].name&&item.title==this.balls[i].betclass[j].title) {
                                this.balls[i].betclass[j].static_bonus = item.rate;
                                this.balls[i].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.balls[i].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        }
                    }
                });
            },
            /**检查是否满足快捷输入 */
            check_fast_val(val) {
                if (val) {
                    return val;
                }
                else {
                    val = this.$parent.get_fast();
                    return val;
                }
            },
            /**重填，重置 */
            reset() {
                //1-5
                for (let i = 0; i < this.balls.length; i++) {
                    for (let j = 0; j < this.balls[i].betclass.length; j++) {
                        this.balls[i].betclass[j].val = "";
                        this.balls[i].betclass[j].isSelected = false;
                        this.balls[i].betclass[j].bg = "#F2F3F8";
                    }
                }
            },
            /**提交，下注 */
            sub_bet() {
                let list = [];
                //1-5
                for (let i = 0; i < this.balls.length; i++) {
                    for (let j = 0; j < this.balls[i].betclass.length; j++) {
                        if (this.balls[i].betclass[j].isSelected) {
                            list.push(this.balls[i].betclass[j]);
                        }
                    }
                }
                return list;
            },
            /**重复上次下单 */
            repeat_last_bet(list) {
                // console.log(list)
                for (let i = 0; i < list.length; i++) {
                    //1-5
                    for (let j = 0; j < this.balls.length; j++) {
                        for (let k = 0; k < this.balls[j].betclass.length; k++) {
                            if (this.balls[j].betclass[k].title == list[i].title && this.balls[j].betclass[k].name == list[i].name) {
                                this.balls[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                }
            },
        },
    }
</script>