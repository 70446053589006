<template>
    <div class="jslhc">
        <!-- <van-button v-if="activeName=='特码'||activeName=='特码B'||activeName=='正码'||activeName=='正特'" style="width:100%;background-color:#e6a23c;color:#fff" @click="open_fastsub">快速下注</van-button>
        <van-popup v-model="fastsub.visible" @close="close_fastsubcp" :lock-scroll="true" :show-close="false" class="fastsub-dialog" get-container="body">
            <van-nav-bar left-text="返回" title="快速投注" left-arrow class="sub-dialog-title" @click-left="close_fastsubcp" />
            <fastsubcp ref="fastsubcp" :nums="fastsub.nums" />
            <div class="dialog-footer">
                <van-button style="width:50%" @click="close_fastsubcp">取 消</van-button>
                <van-button style="width:50%" type="primary" @click="subBet">提 交</van-button>
            </div>
        </van-popup> -->
        <lmian v-if="activeName=='两面'" ref="lm"></lmian>
        <tema v-if="activeName=='特码'" ref="tema"></tema>
        <temab v-if="activeName=='特码B'" ref="temab"></temab>
        <sebo v-if="activeName=='色波'" ref="sebo"></sebo>
        <texiao v-if="activeName=='特肖'" ref="texiao"></texiao>
        <zma v-if="activeName=='正码'" ref="zma"></zma>
        <zma1_6 v-if="activeName=='正码1-6'" ref="zma1_6"></zma1_6>
        <zte v-if="activeName=='正特'" ref="zte" @regainLoss="regainLoss"></zte>
        <lma v-if="activeName=='连码'" ref="lma" @regainLoss="regainLoss"></lma>
        <yxiao v-if="activeName=='一肖尾数'" ref="yxiao"></yxiao>
        <zxbz v-if="activeName=='自选不中'" ref="zxbz" @regainLoss="regainLoss"></zxbz>
        <lxiao v-if="activeName=='连肖'" ref="lxiao" @regainLoss="regainLoss"></lxiao>
        <wlian v-if="activeName=='尾连'" ref="wlian" @regainLoss="regainLoss"></wlian>
    </div>
</template>

<script>
    import lmian from "./lmian";
    import lma from "./lma";
    import lxiao from "./lxiao";
    import sebo from "./sebo";
    import tema from "./tema";
    import temab from "./temab";
    import texiao from "./texiao";
    import yxiao from "./yxiao";
    import zma from "./zma";
    import zma1_6 from "./zma1_6";
    import zte from "./zte";
    import zxbz from "./zxbz";
    import wlian from "./wlian";
    import fastsubcp from "./fastsubcp";
    export default {
        components: {
            lmian,
            tema,
            temab,
            sebo,
            texiao,
            zma,
            zma1_6,
            zte,
            lma,
            yxiao,
            zxbz,
            lxiao,
            wlian,
            fastsubcp,
        },
        props: ["activeName"],
        data() {
            return {
                fastsub: {
                    visible: false,
                    nums: [],
                }
            }
        },
        methods: {
            close_fastsubcp() {
                this.$refs['fastsubcp'].reset_fast_sub();
                this.fastsub.visible = false;
                let play = '';
                if (this.activeName == '特码') {
                    play = 'tema'
                }
                if (this.activeName == '特码B') {
                    play = 'temab'
                }
                if (this.activeName == '正码') {
                    play = 'zma'
                }
                if (this.activeName == '正特') {
                    play = 'zte'
                }
                this.$emit('rest_gameplaying', { play: play, text: this.activeName });
            },
            reset_fast_sub() {
                this.$refs['fastsubcp'].reset_fast_sub();
            },
            //快速投注提交
            subBet() {
                let list = this.$refs['fastsubcp'].subList;
                if (list.length > 0) {
                    this.$emit('fastsub', list);
                }
            },
            //打开快速投注
            open_fastsub() {
                if (this.activeName == "特码") {
                    this.fastsub.nums = this.$refs['tema'].tema[0].betclass;
                } else if (this.activeName == "特码B") {
                    this.fastsub.nums = this.$refs['temab'].temab[0].betclass;
                } else if (this.activeName == "正码") {
                    this.fastsub.nums = this.$refs['zma'].zma[0].betclass;
                } else if (this.activeName == "正特") {
                    this.fastsub.nums = this.$refs['zte'].zte[0].betclass;
                }
                this.fastsub.visible = true;
            },
            //六合彩正特，连码，自选不中，根据小玩法重新获取赔率
            regainLoss(playStr) {
                this.$emit('regainLoss', playStr)
            },
            //快速下注
            fast_sub_bet() {
                if (this.activeName == "两面") { return this.$refs.lm.fast_sub_bet(); }
                if (this.activeName == "特码") { return this.$refs.tema.fast_sub_bet(); }
                if (this.activeName == "特码B") { return this.$refs.temab.fast_sub_bet(); }
                if (this.activeName == "色波") { return this.$refs.sebo.fast_sub_bet(); }
                if (this.activeName == "特肖") { return this.$refs.texiao.fast_sub_bet(); }
                if (this.activeName == "正码") { return this.$refs.zma.fast_sub_bet(); }
                if (this.activeName == "正码1-6") { return this.$refs.zma1_6.fast_sub_bet(); }
                if (this.activeName == "正特") { return this.$refs.zte.fast_sub_bet(); }
                if (this.activeName == "连码") { return this.$refs.lma.fast_sub_bet(); }
                if (this.activeName == "一肖尾数") { return this.$refs.yxiao.fast_sub_bet(); }
                if (this.activeName == "自选不中") { return this.$refs.zxbz.fast_sub_bet(); }
                if (this.activeName == "连肖") { return this.$refs.lxiao.fast_sub_bet(); }
                if (this.activeName == "尾连") { return this.$refs.wlian.fast_sub_bet(); }
            },
            /**显示赔率 */
            set_lose() {
                if (this.activeName == "两面") { this.$refs.lm.get_lose(); }
                if (this.activeName == "特码") { this.$refs.tema.get_lose(); }
                if (this.activeName == "特码B") { this.$refs.temab.get_lose(); }
                if (this.activeName == "色波") { this.$refs.sebo.get_lose(); }
                if (this.activeName == "特肖") { this.$refs.texiao.get_lose(); }
                if (this.activeName == "正码") { this.$refs.zma.get_lose(); }
                if (this.activeName == "正码1-6") { this.$refs.zma1_6.get_lose(); }
                if (this.activeName == "正特") { this.$refs.zte.get_lose(); }
                if (this.activeName == "连码") { this.$refs.lma.get_lose(); }
                if (this.activeName == "一肖尾数") { this.$refs.yxiao.get_lose(); }
                if (this.activeName == "自选不中") { this.$refs.zxbz.get_lose(); }
                if (this.activeName == "连肖") { this.$refs.lxiao.get_lose(); }
                if (this.activeName == "尾连") { this.$refs.wlian.get_lose(); }
            },
            /**获取快捷下注金额 */
            get_fast() {
                return this.$parent.get_fast();
            },
            /**重填，重置 */
            reset() {
                if (this.activeName == "两面") { this.$refs.lm.reset(); }
                if (this.activeName == "特码") { this.$refs.tema.reset(); }
                if (this.activeName == "特码B") { this.$refs.temab.reset(); }
                if (this.activeName == "色波") { this.$refs.sebo.reset(); }
                if (this.activeName == "特肖") { this.$refs.texiao.reset(); }
                if (this.activeName == "正码") { this.$refs.zma.reset(); }
                if (this.activeName == "正码1-6") { this.$refs.zma1_6.reset(); }
                if (this.activeName == "正特") { this.$refs.zte.reset(); }
                if (this.activeName == "连码") { this.$refs.lma.reset(); }
                if (this.activeName == "一肖尾数") { this.$refs.yxiao.reset(); }
                if (this.activeName == "自选不中") { this.$refs.zxbz.reset(); }
                if (this.activeName == "连肖") { this.$refs.lxiao.reset(); }
                if (this.activeName == "尾连") { this.$refs.wlian.reset(); }
                if (this.fastsub.visible) {
                    this.reset_fast_sub();
                }
            },
            /**提交，下注 */
            sub_bet() {
                if (this.activeName == "两面") { return this.$refs.lm.sub_bet(); }
                if (this.activeName == "特码") { return this.$refs.tema.sub_bet(); }
                if (this.activeName == "特码B") { return this.$refs.temab.sub_bet(); }
                if (this.activeName == "色波") { return this.$refs.sebo.sub_bet(); }
                if (this.activeName == "特肖") { return this.$refs.texiao.sub_bet(); }
                if (this.activeName == "正码") { return this.$refs.zma.sub_bet(); }
                if (this.activeName == "正码1-6") { return this.$refs.zma1_6.sub_bet(); }
                if (this.activeName == "正特") { return this.$refs.zte.sub_bet(); }
                if (this.activeName == "连码") { return this.$refs.lma.sub_bet(); }
                if (this.activeName == "一肖尾数") { return this.$refs.yxiao.sub_bet(); }
                if (this.activeName == "自选不中") { return this.$refs.zxbz.sub_bet(); }
                if (this.activeName == "连肖") { return this.$refs.lxiao.sub_bet(); }
                if (this.activeName == "尾连") { return this.$refs.wlian.sub_bet(); }
            },
            /**重复上次下单 */
            repeat_last_bet(playing, list) {
                if (playing == "lmian") { this.$refs.lm.repeat_last_bet(list); }
                if (playing == "tema") { this.$refs.tema.repeat_last_bet(list); }
                if (playing == "temab") { this.$refs.temab.repeat_last_bet(list); }
                if (playing == "sebo") { this.$refs.sebo.repeat_last_bet(list); }
                if (playing == "texiao") { this.$refs.texiao.repeat_last_bet(list); }
                if (playing == "zma") { this.$refs.zma.repeat_last_bet(list); }
                if (playing == "zma1_6") { this.$refs.zma1_6.repeat_last_bet(list); }
                if (playing == "zte") { this.$refs.zte.repeat_last_bet(list); }
                if (playing == "lma") { this.$refs.lma.repeat_last_bet(list); }
                if (playing == "yxiao") { this.$refs.yxiao.repeat_last_bet(list); }
                if (playing == "zxbz") { this.$refs.zxbz.repeat_last_bet(list); }
                if (playing == "lxiao") { this.$refs.lxiao.repeat_last_bet(list); }
                if (playing == "wlian") { this.$refs.wlian.repeat_last_bet(list); }
            },
        },
    }
</script>
<style scoped>
    .sub-dialog-title {
        background-color: #409eff;
        z-index: 9999 !important;
    }
    .fastsub-dialog {
        width: 100%;
        height: 100%;
    }
</style>