<template>
    <div class="lxiao">
        <el-row type="flex">
            <el-col :span="24" v-for="item in lxiao" :key="item.index">
                <div class="bet-title">
                    <span>{{item.ballname}}</span>
                </div>
                <div class="lxiao_which_num">
                    <el-radio-group v-model="radio" fill size="small" text-color="#fff" @change="lxiao_change_num">
                        <el-radio label="lxiao">连肖二肖</el-radio>
                        <el-radio label="lxiao1">连肖三肖</el-radio>
                        <el-radio label="lxiao2">连肖四肖</el-radio>
                        <el-radio label="lxiao3">连肖五肖</el-radio>
                        <el-radio label="lxyz">六肖一中</el-radio>
                    </el-radio-group>
                </div>
                <el-row class="bet-items" :gutter="6">
                    <el-col :span="24" v-for="i in item.betclass" :key="i.index">
                        <div class="bet-items-info" :style="{backgroundColor:i.bg}" @click="click_item(i)">
                            <span class="bet-name">{{i.name}}</span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <span class="betclassname_lxiao" v-for="v in i.betclass_nums" :key="v.index">
                                <span v-if="v.color=='red'" class="lxiao_nums_red">{{v.num}}</span>
                                <span v-if="v.color=='blue'" class="lxiao_nums_blue">{{v.num}}</span>
                                <span v-if="v.color=='green'" class="lxiao_nums_green">{{v.num}}</span>
                            </span>
                            <span v-show="i.bg=='#ffef3a'" class="select-icon"><Select style="width: 24px; height: 24px;" /></span>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import { isArray } from 'util';
    import { get_lhc_sx } from "../../../plugin/sx";
    export default {
        data() {
            return {
                lxiao: [
                    {
                        ballname: "连肖",
                        betclass: [
                            { title: "连肖", bg: '#F2F3F8', name: "", isSelected: false, static_bonus: "", val: "", disabled: false, subname: "9_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue", isChoose: false, bgcolor: "#fff", betclass_nums: [{ num: 3, color: "blue" }, { num: 15, color: "blue" }, { num: 27, color: "green" }, { num: 39, color: "green" }] },
                            { title: "连肖", bg: '#F2F3F8', name: "", isSelected: false, static_bonus: "", val: "", disabled: false, subname: "9_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue", isChoose: false, bgcolor: "#fff", betclass_nums: [{ num: 2, color: "red" }, { num: 14, color: "blue" }, { num: 26, color: "blue" }, { num: 38, color: "green" }] },
                            { title: "连肖", bg: '#F2F3F8', name: "", isSelected: false, static_bonus: "", val: "", disabled: false, subname: "9_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue", isChoose: false, bgcolor: "#fff", betclass_nums: [{ num: 1, color: "red" }, { num: 13, color: "red" }, { num: 25, color: "blue" }, { num: 37, color: "blue" }, { num: 49, color: "green", hide: true }] },
                            { title: "连肖", bg: '#F2F3F8', name: "", isSelected: false, static_bonus: "", val: "", disabled: false, subname: "9_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red", isChoose: false, bgcolor: "#fff", betclass_nums: [{ num: 12, color: "red" }, { num: 24, color: "red" }, { num: 36, color: "blue" }, { num: 48, color: "blue" }] },
                            { title: "连肖", bg: '#F2F3F8', name: "", isSelected: false, static_bonus: "", val: "", disabled: false, subname: "9_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red", isChoose: false, bgcolor: "#fff", betclass_nums: [{ num: 11, color: "green" }, { num: 23, color: "red" }, { num: 35, color: "red" }, { num: 47, color: "blue" }] },
                            { title: "连肖", bg: '#F2F3F8', name: "", isSelected: false, static_bonus: "", val: "", disabled: false, subname: "9_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue", isChoose: false, bgcolor: "#fff", betclass_nums: [{ num: 10, color: "blue" }, { num: 22, color: "green" }, { num: 34, color: "red" }, { num: 46, color: "red" }] },
                            { title: "连肖", bg: '#F2F3F8', name: "", isSelected: false, static_bonus: "", val: "", disabled: false, subname: "9_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue", isChoose: false, bgcolor: "#fff", betclass_nums: [{ num: 9, color: "blue" }, { num: 21, color: "green" }, { num: 33, color: "green" }, { num: 45, color: "red" }] },
                            { title: "连肖", bg: '#F2F3F8', name: "", isSelected: false, static_bonus: "", val: "", disabled: false, subname: "9_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green", isChoose: false, bgcolor: "#fff", betclass_nums: [{ num: 8, color: "red" }, { num: 20, color: "blue" }, { num: 32, color: "green" }, { num: 44, color: "green" }] },
                            { title: "连肖", bg: '#F2F3F8', name: "", isSelected: false, static_bonus: "", val: "", disabled: false, subname: "9_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green", isChoose: false, bgcolor: "#fff", betclass_nums: [{ num: 7, color: "red" }, { num: 19, color: "red" }, { num: 31, color: "blue" }, { num: 43, color: "green" }] },
                            { title: "连肖", bg: '#F2F3F8', name: "", isSelected: false, static_bonus: "", val: "", disabled: false, subname: "9_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red", isChoose: false, bgcolor: "#fff", betclass_nums: [{ num: 6, color: "green" }, { num: 18, color: "red" }, { num: 30, color: "red" }, { num: 42, color: "blue" }] },
                            { title: "连肖", bg: '#F2F3F8', name: "", isSelected: false, static_bonus: "", val: "", disabled: false, subname: "9_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red", isChoose: false, bgcolor: "#fff", betclass_nums: [{ num: 5, color: "green" }, { num: 17, color: "green" }, { num: 29, color: "red" }, { num: 41, color: "blue" }] },
                            { title: "连肖", bg: '#F2F3F8', name: "", isSelected: false, static_bonus: "", val: "", disabled: false, subname: "9_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue", isChoose: false, bgcolor: "#fff", betclass_nums: [{ num: 4, color: "blue" }, { num: 16, color: "green" }, { num: 28, color: "green" }, { num: 40, color: "red" }] },
                        ]
                    }
                ],
                radio: 'lxiao',
                // 选择的个数，限制7个
                lxiao_count: 0,
                /**连肖包三下注金额 */
                lxiao_val: "",
                zhengxiao: "",
                zhengxiaopl: "",
            }
        },
        mounted() {
            this.lxiao = get_lhc_sx("初始化", this.lxiao);
        },
        methods: {
            lxiao_change_num(label) {
                this.$emit('regainLoss', label);
                this.reset();
                this.get_lose();
                console.log(label);
                if (label == 'lxyz') {
                    // this.lxiao[0].betclass[0].betclass_nums[4].hide = true;
                    this.lxiao[0].betclass[2].betclass_nums.length = 4;
                } else {
                    // this.lxiao[0].betclass[0].betclass_nums[4].hide = false
                    this.lxiao[0].betclass[2].betclass_nums[4] = { hide: false, num: 49, color: "green", }
                }
            },
            //选取投注项
            click_item(item) {
                if (item.isSelected) {
                    item.isSelected = false;
                    item.bg = '#F2F3F8';
                    this.lxiao_count -= 1;
                } else {
                    item.bg = '#ffef3a';
                    item.isSelected = true;
                    this.lxiao_count += 1;
                    let index = 0;
                    this.radio == "lxiao" ? index = 8 : "";
                    this.radio == "lxiao1" ? index = 8 : "";
                    this.radio == "lxiao2" ? index = 8 : "";
                    this.radio == "lxiao3" ? index = 8 : "";
                    this.radio == "lxyz" ? index = 6 : "";
                    if (this.lxiao_count > index) {
                        item.isSelected = false;
                        item.bg = '#F2F3F8';
                        this.lxiao_count -= 1;
                        this.$toast(`只能选择${index}个号码`);
                    }
                }
                return item
            },
            //快速下注
            fast_sub_bet() {
                return this.sub_bet();
            },
            /**显示赔率等数据 */
            get_lose() {
                let lose_data = this.$store.state.lose_data;
                for (let i = 0; i < this.lxiao[0].betclass.length; i++) {
                    this.lxiao[0].betclass[i].title = this.radio;
                    lose_data.forEach(item => {
                        if (item.name == this.lxiao[0].betclass[i].name) {
                            this.lxiao[0].betclass[i].static_bonus = item.rate;
                            this.lxiao[0].betclass[i].straightbet_high = item.other.straightbet_high;
                            this.lxiao[0].betclass[i].straightbet_low = item.other.straightbet_low;
                        }
                    });
                    if (this.lxiao[0].betclass[i].betclass_nums.length > 4) {
                        this.zhengxiao = this.lxiao[0].betclass[i].name;//保存当前正肖，以便下注时配置level2
                        this.zhengxiaopl = this.lxiao[0].betclass[i].static_bonus;//保存当前正肖的赔率
                    }
                }
            },
            /**检查是否满足快捷输入 */
            check_fast_val(val) {
                if (val) {
                    return val;
                }
                else {
                    val = this.$parent.get_fast();
                    return val;
                }
            },
            /**重填，重置 */
            reset() {
                this.lxiao_val = "";
                this.lxiao_count = 0;
                for (let i = 0; i < this.lxiao[0].betclass.length; i++) {
                    this.lxiao[0].betclass[i].val = false;
                    // this.lxiao[0].betclass[i].disabled=false;
                    this.lxiao[0].betclass[i].isSelected = false;
                    this.lxiao[0].betclass[i].bg = "#F2F3F8";
                    // console.log(this.lxiao[0].betclass[i].val)
                }
            },
            /**多选框点击 */
            checkbox_change(v) {
                v ? this.lxiao_count++ : this.lxiao_count--;
                let index = 0;
                this.radio == "lxiao" ? index = 2 : "";
                this.radio == "lxiao1" ? index = 3 : "";
                this.radio == "lxiao2" ? index = 4 : "";
                this.radio == "lxiao3" ? index = 5 : "";
                this.radio == "lxyz" ? index = 6 : "";
                if (this.lxiao_count >= index) {
                    for (let i = 0; i < this.lxiao[0].betclass.length; i++) {
                        if (this.lxiao[0].betclass[i].val == false) {
                            this.lxiao[0].betclass[i].disabled = true;
                        }
                    }
                } else {
                    for (let i = 0; i < this.lxiao[0].betclass.length; i++) {
                        if (this.lxiao[0].betclass[i].val == false) {
                            this.lxiao[0].betclass[i].disabled = false;
                        }
                    }
                }
            },
            /**提交，下注 */
            sub_bet() {
                let list = [];
                let index = 0;
                this.radio == "lxiao" ? index = 2 : "";
                this.radio == "lxiao1" ? index = 3 : "";
                this.radio == "lxiao2" ? index = 4 : "";
                this.radio == "lxiao3" ? index = 5 : "";
                this.radio == "lxyz" ? index = 6 : "";
                if (this.lxiao_count < index) {
                    return `至少选择${index}个号码`;
                } else {
                    if (this.lxiao_count > 0) {
                        for (let i = 0; i < this.lxiao.length; i++) {
                            for (let j = 0; j < this.lxiao[i].betclass.length; j++) {
                                if (this.lxiao[i].betclass[j].isSelected) {
                                    // this.lxiao[i].betclass[j].val=this.lxiao_val;
                                    list.push(this.lxiao[i].betclass[j]);
                                }
                            }
                        }

                        let choose_num = [];
                        let sx_num = [];
                        let sx_num_string = [];
                        // console.log(list)
                        for (let i = 0; i < list.length; i++) {
                            sx_num = [];
                            choose_num.push(list[i].name);
                            for (let j = 0; j < list[i].betclass_nums.length; j++) {
                                sx_num.push(list[i].betclass_nums[j].num);
                            }
                            sx_num_string.push(sx_num.toString() + "|");
                        }
                        //多选号码，重新组合成注单
                        let bet_obj = list[0]
                        // console.log(bet_obj)
                        list = []
                        let new_arr = this.combination_arr_elem(choose_num, index)
                        let sx_num_string_list = this.combination_arr_elem(sx_num_string, index);
                        for (let i = 0; i < new_arr.length; i++) {
                            list.push(
                                {
                                    title: new_arr[i].toString(),
                                    name: sx_num_string_list[i].join(''),
                                    isSelected: bet_obj.isSelected,
                                    static_bonus: bet_obj.static_bonus,
                                    val: bet_obj.val,
                                    subname: bet_obj.subname,
                                    straightbet_high: bet_obj.straightbet_high,
                                    straightbet_low: bet_obj.straightbet_low,
                                    bonus_high: bet_obj.bonus_high,
                                    sub_popover: bet_obj.sub_popover,
                                    color: bet_obj.color,
                                    isChoose: bet_obj.isChoose,
                                }
                            )
                            // this.suballcout += this.allinputval[i].val * 1;
                        }
                        //判断是正肖，还是平肖的投注,改变玩法level_two
                        // console.log(list,this.zhengxiao)
                        for (let i = 0; i < list.length; i++) {
                            if (list[i].title.includes(this.zhengxiao)) {
                                list[i].subname = this.radio == "lxyz" ? '14_0_0' : "9_" + (index - 2) + "_0";
                                list[i].static_bonus = this.zhengxiaopl;
                            }
                            else {
                                list[i].subname = this.radio == "lxyz" ? '14_0_1' : "9_" + (index - 2) + "_1";
                            }
                        }
                    }
                }
                //筛选最小的赔率
                for (let i = 0; i < list.length; i++) {
                    list[i].ball_list = list[i].title.split(',');
                    list[i].ball_loss_list = [];
                    for (let k = 0; k < list[i].ball_list.length; k++) {
                        for (let j = 0; j < this.lxiao[0].betclass.length; j++) {
                            if (this.lxiao[0].betclass[j].name == list[i].ball_list[k]) {
                                list[i].ball_loss_list.push({
                                    loss: this.lxiao[0].betclass[j].static_bonus,
                                    ball: this.lxiao[0].betclass[j].name,
                                });
                            }
                        }
                    }
                    // console.log(list[i].ball_loss_list);
                    let sitem = list[i].ball_loss_list.sort((a, b) => a.loss - b.loss)[0];
                    list[i].ball_min = sitem.ball;
                    list[i].static_bonus = sitem.loss;
                }
                // console.log(list)
                return list;
            },
            /**重复上次下单 */
            repeat_last_bet(list) {
                // console.log(list)
                // console.log(list[0].name.split(","))
                for (let i = 0; i < list.length; i++) {
                    if (!isArray(list[i].title)) {
                        list[i].title = list[i].title.split(",");
                    }
                    for (let j = 0; j < this.lxiao.length; j++) {
                        for (let k = 0; k < this.lxiao[j].betclass.length; k++) {
                            // console.log(this.lxiao[j].betclass[k].name)
                            if (list[i].title.find(n => n == this.lxiao[j].betclass[k].name)) {
                                this.lxiao[j].betclass[k].val = true;
                            }
                        }
                    }
                }
            },
            combination_arr_elem(nu, groupl, res) {
                let result = res ? res : [];
                let nuc = nu.slice(0);
                let item = nuc.shift();
                item = item.constructor === Array ? item : [item];
                (function func(item, nuc) {
                    let itemc;
                    let nucc = nuc.slice(0);
                    let margin = groupl - item.length
                    if (margin == 0) {
                        result.push(item);
                        return;
                    }
                    if (margin == 1) {
                        for (let j in nuc) {
                            itemc = item.slice(0);
                            itemc.push(nuc[j]);
                            result.push(itemc);
                        }
                    }
                    if (margin > 1) {
                        itemc = item.slice(0);
                        itemc.push(nucc.shift());
                        func(itemc, nucc);
                        if (item.length + nucc.length >= groupl) {
                            func(item, nucc);
                        }
                    }
                })(item, nuc);
                if (nuc.length >= groupl) {
                    return this.combination_arr_elem(nuc, groupl, result);
                } else {
                    return result;
                }
            },
        },
    }
</script>

<style scoped>
    .lxiao_which_num {
        text-align: center;
        padding: 10px;
    }
    .lxiao_nums_red,
    .lxiao_nums_blue,
    .lxiao_nums_green {
        float: right;
        width: 24px;
        height: 24px;
        line-height: 24px;
        border-radius: 50%;
        color: #fff;
        text-align: center;
        margin-top: 1px;
        margin-right: 3px;
        font-weight: bold;
    }
    .lxiao_nums_red {
        background-color: #f56c6c;
    }
    .lxiao_nums_blue {
        background-color: #409eff;
    }
    .lxiao_nums_green {
        background-color: #67c23a;
    }
    .lxiao-val {
        width: 120px !important;
        margin: 0 auto !important;
    }

    .lxiao .bet-items-one .bet-name {
        float: none;
    }
    .lxiao .bet-items-one .yxiao-input {
        float: none;
        width: 100px;
    }
    .yxiao .bet-items-one .bet-lose {
        text-align: center;
        min-width: 3rem;
    }
</style>