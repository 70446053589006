<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-07-02 16:01:03
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-09-12 15:25:45
 * @FilePath: \pc\src\views\notice.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="notice">
        <div class="ggBox">
            <el-row v-for="item in dialog.list" :key="item.id" class="item">
                <el-col :span="24">
                    <span class="titile">{{item.title}}</span>
                    <span class="content">:{{item.text}}</span>
                    <span>（{{item.create_time.split(' ')[0]}}）</span>
                </el-col>
            </el-row>
        </div>
        <div class="ggBtnBox">
            <input type="button" value="確 定" class="ggBtn" @click="agree" />
        </div>
    </div>
</template>

<script>
    import { set_playing } from '../plugin/set_playing'
    export default {
        components: {},
        data() {
            return {
                dialog: {
                    visible: false,
                    list: [],
                    gohome: false,
                },
                themeColor: '',
                themeColorhalf: '',
                themeColorLight: '',
            }
        },
        created() {
            this.themeColor = this.$store.state.userinfo.color;
            let strs = this.themeColor.split(',')
            let h = strs[0].match(/\d+/g)[0];
            let s = strs[1].split('%')[0];
            let l = strs[2].split('%')[0];
            if (l < 95) {
                l = 90;
            }
            if (s > 35) {
                s = 90;
            }
            this.themeColorLight = `hsl(${h},${s}%,${l}%)`;
            this.themeColorhalf = `hsl(${h},30%,75%)`;
            this.$emit('get_color', this.themeColor, this.themeColorLight, this.themeColorhalf);
            this.axios.post("/notice/notice_one", {}).then(result => {
                // console.log(result.data);
                if (result.data.status && result.data.msg === "请求成功") {
                    this.dialog.list = result.data.res;
                    this.dialog.visible = true;
                    if (this.dialog.list.length > 0) {
                    }
                }
            });
            //获取彩种
            this.axios.get("/main/game_mobile_list").then(result => {
                // console.log(result.data.res.game_list);
                if (result.data.status && result.data.msg === "请求成功！") {
                    let list = result.data.res.game_list;
                    list.sort((a, b) => a.order - b.order);
                    this.$store.state.game_list = list;
                    let game = list.find(game => game.status == 1 && game.open_status == 1)
                    if (game !== undefined) {
                        this.$store.state.checked_gid = game.id;
                        this.$store.state.checked_gname = game.gname;
                        this.$store.state.playing_list = set_playing(game.id);
                        this.$store.state.playing = this.$store.state.playing_list[0].play;
                        this.$store.state.activeName = this.$store.state.playing_list[0].text;
                        this.gohome = true;
                    }
                    // console.log(game);
                }
            });
        },
        methods: {
            exit() {
                this.$router.replace('/');
                this.axios.get(`/login/logout?user=${this.$store.state.userinfo.user_id}&token=${this.$store.state.userinfo.token}`).then(result => {
                    // console.log(result.data)
                    if (result.data.status && result.data.msg === "已退出登录！") {
                        sessionStorage.clear();
                        this.$store.state.isLogin = false;
                        this.$store.state.userinfo = "";
                        window.location.reload();
                    }
                    // console.log(this.$store.state.isLogin)
                });
            },
            agree() {
                if (this.gohome) {
                    this.$router.push('/home');
                }
            },
        },
    }
</script>

<style scoped>
    .ggBox {
        width: 86%;
        margin: 5% auto;
        background: #fff;
        padding: 3% 4%;
        border: 1px solid #cfd5d7;
        border-radius: 10px;
        font-size: 30px;
    }
    .ggBtnBox {
        width: 93%;
        margin: 0 auto 5%;
    }
    .ggBtn {
        width: 100%;
        height: 40px;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border: none;
        line-height: 40px;
        text-align: center;
        color: #fff;
        font-size: 24px;
        font-weight: 700;
        background: -moz-linear-gradient(to top, #23c0f7, #1dafe4);
        background: linear-gradient(to top, #23c0f7, #1dafe4);
        background: -webkit-linear-gradient(to top, #23c0f7, #1dafe4);
        -webkit-box-shadow: 0 2px 2px 0 #0893c6;
        -moz-box-shadow: 0 2px 2px 0 #0893c6;
        box-shadow: 0 2px 2px 0 #0893c6;
    }
    .item {
        border-bottom: 1px solid #ccc;
        padding: 10px 0;
    }
    .item:last-child {
        border: 0;
        padding-bottom: 0;
    }
    .titile{
        font-size: 14px;
        font-weight: bold;
    }
    .content{
        font-size: 14px;
    }
</style>