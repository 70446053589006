<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-09-02 21:22:33
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-09-15 20:58:45
 * @FilePath: \mobile_vue3\src\components\menuitems\betinfo.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="betinfo">
        <div class="myform" v-for="item in openOrder.list" :key="item.order_id">
            <el-row class="myform-item">
                <el-col class="myform-item-lable" :span="6">註單號</el-col>
                <el-col class="myform-item-value" :span="18">{{item.order_id}}</el-col>
            </el-row>
            <el-row class="myform-item">
                <el-col class="myform-item-lable" :span="6">時間</el-col>
                <el-col class="myform-item-value" :span="18">{{$filters.time(item.posttime)}}</el-col>
            </el-row>
            <el-row class="myform-item">
                <el-col class="myform-item-lable" :span="6">類型</el-col>
                <el-col class="myform-item-value" :span="18">
                    {{item.game_name}}#
                    <span style="color:green">{{item.qnum}}</span>期
                </el-col>
            </el-row>
            <el-row class="myform-item">
                <el-col class="myform-item-lable" :span="6">明細</el-col>
                <el-col class="myform-item-value" :span="18">
                    {{item.game_level}}@
                    <span style="color:red">{{item.list_bonus*1}}</span>
                </el-col>
            </el-row>
            <el-row class="myform-item">
                <el-col class="myform-item-lable" :span="6">金額/退水</el-col>
                <el-col class="myform-item-value" :span="18">金額:{{item.balance}}/退水:{{item.tuishui}}</el-col>
            </el-row>
        </div>
        <div style="text-align:center">
            <el-pagination hide-on-single-page @current-change="getOpenOrder" small :current-page="openOrder.currentPage" :page-size="20" :pager-count="5" :total="openOrder.total.count*1" layout=" total,prev, pager, next, jumper," />
        </div>
        <div style="text-align:center;margin-top:20px" v-if="openOrder.list.length==0">暂无数据</div>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                openOrder: {
                    currentPage: 1, //分页默认第一页
                    list: [], //记录数据
                    total: {
                        count: 0,
                        currentPage: 1,
                    } //分页信息
                },
            }
        },
        created() {
            if (this.$route.params.data != undefined) {
                let data = JSON.parse(this.$route.params.data);
                if (data.length == 0) {
                    this.openOrder.list = [];
                    this.openOrder.total = {count:0};
                    this.openOrder.total.page_count = 0;
                    return;
                }
                this.openOrder.list = data.list;
                this.openOrder.total = data.total;
            } else {
                this.getOpenOrder(1);
            }
        },
        methods: {
            getOpenOrder(val = 1) {
                this.axios.get("/order/open_order?page=" + val).then(result => {
                    // console.log(result.data)
                    if (result.data.status && result.data.msg === "请求成功！") {
                        if (result.data.res.length == 0) {
                            this.openOrder.list = [];
                            this.openOrder.total = {count:0};
                            this.openOrder.total.page_count = 0;
                            return;
                        }
                        this.openOrder.list = result.data.res.list;
                        // console.log(this.openOrder.list)
                        this.openOrder.total = result.data.res.total;
                    }
                })
            },
        },
    }
</script>

<style scoped>
</style>