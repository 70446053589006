<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-08-30 00:22:59
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-09-09 20:29:21
 * @FilePath: \mobile_vue3\src\components\bet\betPage.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="bet-page">
        <div class="game-info-bet-list">
            <cqssc v-show="gid == 1|| gid == 7|| gid == 18|| gid == 38 || gid == 39|| gid == 44||gid == 45||gid == 46" :activeName="activeName" ref="cqssc"></cqssc>
            <bjpk10 v-show="gid == 3|| gid == 19|| gid == 24|| gid == 28|| gid == 37||gid == 40|| gid == 43||gid == 47" :activeName="activeName" ref="bjpk10"></bjpk10>
            <can28 v-show="gid==22||gid==23" :activeName="activeName" ref="can28"></can28>
            <jslhc v-show="gid==34||gid==20||gid==48" :activeName="activeName" ref="jslhc" @regainLoss="regainLoss" @fastsub="fastsub" @rest_gameplaying="rest_gameplaying"></jslhc>
            <k3 v-show="gid==6||gid==29||gid==30||gid==31||gid==32||gid==36" :activeName="activeName" ref="k3"></k3>
            <fc3D v-show="gid==4" :activeName="activeName" ref="fc3D"></fc3D>
            <pl35 v-show="gid==10" :activeName="activeName" ref="pl35"></pl35>
        </div>
        <!-- 大小 -->
        <div v-if="!lmcl.nodata">
            <div class="bet-title light-bg-lmcl-title">
                <span>两面长龙</span>
            </div>
            <el-row>
                <el-col class="lmcl-item" :span="12" v-for="item in lmcl.list1" :key="item.index">
                    <span class="item-tit">{{item.title}}-</span>
                    <span class="item-val">{{item.big_small}}:</span>
                    <span class="item-sum theme-text">{{item.b_s_qnum}}期</span>
                </el-col>
                <!-- 单双 -->
                <el-col class="lmcl-item" :span="12" v-for="item in lmcl.list2" :key="item.index">
                    <span class="item-tit">{{item.title}}-</span>
                    <span class="item-val">{{item.odd_even}}</span>
                    <span class="item-sum theme-text">{{item.b_s_qnum}}期</span>
                </el-col>
            </el-row>
        </div>
        <historyChart ref="historyChart" />
    </div>
</template>

<script>
    import cqssc from "./cqssc/cqssc.vue";
    import bjpk10 from "./bjpk10/bjpk10.vue";
    import can28 from "./can28/can28.vue";
    import jslhc from "./jslhc/jslhc.vue";
    import k3 from "./k3/k3.vue";
    import fc3D from "./fc3D/fc3D.vue";
    import pl35 from "./pl35/pl35.vue";
    import historyChart from "./historyChart.vue";
    export default {
        props: ["activeName"],
        components: {
            cqssc,
            bjpk10,
            can28,
            jslhc,
            k3,
            fc3D,
            pl35,
            historyChart,
        },
        data() {
            return {
                gid: "",
                /**两面长龙 */
                lmcl: {
                    list1: [],
                    list2: [],
                    nodata: false,
                },
            }
        },
        mounted() {
            this.gid = this.$store.state.checked_gid;
            this.get_lmcl();
        },
        methods: {
            rest_gameplaying(e) {
                this.$emit('rest_gameplaying', e);
            },
            //六合彩快速下注
            fastsub(list) {
                // this.$refs['subBet'].fastsub(list);
                this.$emit('fastsub', list);
            },
            //六合彩正特，连码，自选不中，根据小玩法重新获取赔率
            regainLoss(playStr) {
                this.$emit('regainLoss', playStr)
            },
            //停止下注
            stop_bet(state, text) {
                this.reset();
            },
            //快速下注
            fast_sub_bet() {
                // console.log(1);
                if (this.gid == 1 || this.gid == 7 || this.gid == 18 || this.gid == 38 || this.gid == 39 || this.gid == 44 || this.gid == 45 || this.gid == 46) {
                    return this.$refs.cqssc.fast_sub_bet();
                }
                if (this.gid == 3 || this.gid == 19 || this.gid == 24 || this.gid == 28 || this.gid == 37 || this.gid == 40 || this.gid == 43 || this.gid == 47) {
                    return this.$refs.bjpk10.fast_sub_bet();
                }
                if (this.gid == 22 || this.gid == 23) {
                    return this.$refs.can28.fast_sub_bet();
                }
                if (this.gid == 34 || this.gid == 20 || this.gid == 48) {
                    return this.$refs.jslhc.fast_sub_bet();
                }
                if (this.gid == 6 || this.gid == 29 || this.gid == 30 || this.gid == 31 || this.gid == 32 || this.gid == 36) {
                    return this.$refs.k3.fast_sub_bet();
                }
                if (this.gid == 4) {
                    return this.$refs.fc3D.fast_sub_bet();
                }
                if (this.gid == 10) {
                    return this.$refs.pl35.fast_sub_bet();
                }
            },
            /**切换彩种 */
            choose_lottery(gid) {
                // this.gid = gid
                this.get_lmcl(gid);
                this.set_lose();
                this.$refs['historyChart'].set_chart(gid);
                this.reset();
            },
            /**显示赔率 */
            set_lose() {
                let gid = this.$store.state.checked_gid;
                // 重庆时时彩
                gid == 1 || gid == 7 || gid == 18 || gid == 38 || gid == 39 || gid == 44 || gid == 45 || gid == 46 ? this.$refs.cqssc.set_lose() : "";
                // 北京PK10
                gid == 3 || gid == 19 || gid == 24 || gid == 28 || gid == 37 || gid == 40 || gid == 43 || gid == 47 ? this.$refs.bjpk10.set_lose() : "";
                //加拿大28
                gid == 22 || gid == 23 ? this.$refs.can28.set_lose() : "";
                //极速六合彩
                gid == 34 || gid == 20 || gid == 48 ? this.$refs.jslhc.set_lose() : "";
                //快3
                gid == 6 || gid == 29 || gid == 30 || gid == 31 || gid == 32 || gid == 36 ? this.$refs.k3.set_lose() : "";
                gid == 4 ? this.$refs.fc3D.set_lose() : "";
                gid == 10 ? this.$refs.pl35.set_lose() : "";
                this.gid = gid;
                // let sid = setInterval(() => {
                //     if (this.$store.state.lose_data != null) {
                //         window.clearInterval(sid);
                //     }
                // }, 500);
            },
            /**获取快捷下注金额 */
            get_fast() {
                // console.log(this.$refs.subBet.get_fast())
                return this.$parent.subBet.get_fast();
            },
            /**重填，重置 */
            reset() {
                let gid = this.$store.state.checked_gid;
                gid == 1 || gid == 7 || gid == 18 || gid == 38 || gid == 39 || gid == 44 || gid == 45 || gid == 46 ? this.$refs.cqssc.reset() : "";
                gid == 3 || gid == 19 || gid == 24 || gid == 28 || gid == 37 || gid == 40 || gid == 43 || gid == 47 ? this.$refs.bjpk10.reset() : "";
                gid == 22 || gid == 23 ? this.$refs.can28.reset() : "";
                gid == 34 || gid == 20 || gid == 48 ? this.$refs.jslhc.reset() : "";
                gid == 6 || gid == 29 || gid == 30 || gid == 31 || gid == 32 || gid == 36 ? this.$refs.k3.reset() : "";
                gid == 4 ? this.$refs.fc3D.reset() : "";
                gid == 10 ? this.$refs.pl35.reset() : "";
                // this.$nextTick(() => {
                    // })
            },
            /**提交，下注 */
            sub_bet() {
                if (this.gid == 1 || this.gid == 7 || this.gid == 18 || this.gid == 38 || this.gid == 39 || this.gid == 44 || this.gid == 45 || this.gid == 46) {
                    return this.$refs.cqssc.sub_bet();
                }
                if (this.gid == 3 || this.gid == 19 || this.gid == 24 || this.gid == 28 || this.gid == 37 || this.gid == 40 || this.gid == 43 || this.gid == 47) {
                    return this.$refs.bjpk10.sub_bet();
                }
                if (this.gid == 22 || this.gid == 23) {
                    return this.$refs.can28.sub_bet();
                }
                if (this.gid == 34 || this.gid == 20 || this.gid == 48) {
                    return this.$refs.jslhc.sub_bet();
                }
                if (this.gid == 6 || this.gid == 29 || this.gid == 30 || this.gid == 31 || this.gid == 32 || this.gid == 36) {
                    return this.$refs.k3.sub_bet();
                }
                if (this.gid == 4) {
                    return this.$refs.fc3D.sub_bet();
                }
                if (this.gid == 10) {
                    return this.$refs.pl35.sub_bet();
                }
            },
            /**下注成功后刷新用余额 */
            get_user_balance() {
                this.$emit("get_user_balance", "")
            },
            /**重复上次下单 */
            repeat_last_bet(gid, playing, list) {
                gid == 1 || gid == 7 || gid == 18 || gid == 38 || gid == 39 || gid == 44 || gid == 45 || this.gid == 46 ? this.$refs.cqssc.repeat_last_bet(playing, list) : "";
                gid == 3 || gid == 19 || gid == 24 || gid == 28 || gid == 37 || gid == 40 || gid == 43 || this.gid == 47 ? this.$refs.bjpk10.repeat_last_bet(playing, list) : "";
                gid == 22 || gid == 23 ? this.$refs.can28.repeat_last_bet(playing, list) : "";
                gid == 34 || gid == 20 || gid == 48 ? this.$refs.jslhc.repeat_last_bet(playing, list) : "";
                gid == 6 || gid == 29 || gid == 30 || gid == 31 || gid == 32 || gid == 36 ? this.$refs.k3.repeat_last_bet(playing, list) : "";
                gid == 4 ? this.$refs.fc3D.repeat_last_bet(playing, list) : "";
                gid == 10 ? this.$refs.pl35.repeat_last_bet(playing, list) : "";
            },
            get_lmcl() {
                this.axios.get("/order/index_right_lm?gid=" + this.$store.state.checked_gid).then(result => {
                    // console.log(result.data);
                    this.lmcl.list1 = [];
                    this.lmcl.list2 = [];
                    if (result.data.status == 1 && result.data.msg === "获取长龙成功") {
                        this.lmcl.nodata = false;
                        this.lmcl.list1 = result.data.res[0];
                        this.lmcl.list2 = result.data.res[1];
                    } else {
                        this.lmcl.nodata = true;
                    }
                })
            },
        },
        destroyed() {
            this.$store.state.lose_data.static_bonus = [];
        }
    }
</script>

<style>
    .bet-page {
        overflow: hidden;
        background-color: #fff;
        margin-bottom: 36px;
    }
    .bet-page .bet-title {
        background-image: linear-gradient(180deg, #fdfdfd 25%, #dcdfe6 100%);
        color: #fff;
        height: 26px;
        line-height: 26px;
        text-align: center;
        color: #333;
        font-weight: bold;
        border-top: 1px solid #e4e7ed;
    }
    .bet-page .bet-title span {
        padding-left: 0px;
    }
    .bet-page .bet-items {
        padding: 3px 0;
        line-height: 0px;
    }
    .bet-items-info {
        height: 26px;
        border: 1px solid #e2e2e2;
        border-radius: 15px;
        font-weight: bold;
        font-size: 12px;
        margin-bottom: 3px;
        white-space: nowrap;
        position: relative;
    }
    .bet-items .select-icon {
        position: absolute;
        right: 0px;
        top: 9px;
        color: green;
        font-weight: bold;
    }
    .bet-page .bet-items .bet-name {
        float: left;
        line-height: 26px;
        padding-left: 5px;
        color: #333;
    }
    .bet-page .bet-items .bet-lose {
        float: right;
        line-height: 26px;
        color: #f56c6c;
        font-weight: bold;
        padding-right: 5px;
    }
    .bet-page .bet-items .el-input--mini {
        width: 40%;
        float: right;
        margin-right: 6px;
    }
    /* 只有1列的 */
    .bet-page .bet-items-one {
        padding: 3px 0;
        height: 26px;
    }
    .bet-page .bet-items-one .bet-name {
        float: left;
        line-height: 26px;
        padding-left: 5px;
    }
    .bet-page .bet-items-one .bet-lose {
        display: inline-block;

        line-height: 26px;
    }
    .bet-page .bet-items-one .bet-lose {
        color: #f56c6c;
        font-weight: bold;
    }
    .bet-page .bet-items-one .el-input--mini {
        width: 49%;
        float: right;
        margin-right: 6px;
    }
    .subBet .sub-dialog .el-dialog__body {
        padding: 0;
    }
    .lmcl-item {
        text-align: center;
        font-size: 14px;
        line-height: 24px;
        border-bottom: 1px solid #ddd;
        border-right: 1px solid #ddd;
    }
    .item-sum {
        font-weight: bold;
    }
</style>
