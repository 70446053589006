<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-07-08 19:26:36
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-09-06 21:17:37
 * @FilePath: \pc\src\components\menuitems\userinfo.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="credit-info">
        <div class="myform">
            <el-row class="myform-item">
                <el-col class="myform-item-lable" :span="12">会员账户：</el-col>
                <el-col class="myform-item-value" :span="12">{{user_data.username}}</el-col>
            </el-row>
            <el-row class="myform-item">
                <el-col class="myform-item-lable" :span="12">盘口：</el-col>
                <el-col class="myform-item-value" :span="12">{{user_data.level_id}}</el-col>
            </el-row>
            <el-row class="myform-item">
                <el-col class="myform-item-lable" :span="12">彩票额度：</el-col>
                <el-col class="myform-item-value" :span="12">{{user_data.balance}}</el-col>
            </el-row>
        </div>
        <div class="credit-table-data">
            <el-table :data="credit_data" highlight-current-row size="small" border header-cell-class-name="table-header-cell" header-row-class-name="table-header-row">
                <el-table-column property="type" label="交易类型" align="center"></el-table-column>
                <el-table-column property="water_break" :label="user_data.level_id" align="center"></el-table-column>
                <el-table-column property="straightbet_low" label="单注最低" align="center" width="60"></el-table-column>
                <el-table-column property="straightbet_high" label="单注限额" align="center"></el-table-column>
                <el-table-column property="single_high" label="单期限额" align="center"></el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                /**彩种列表 */
                lottery_list: [],
                list_default: '',
                /**信用资料 */
                credit_data: [],
                /**用户资料 */
                user_data: {},
                pk_label: '',
            }
        },
        created() {
            this.get_lottery_list();
            if (this.$route.params.data!=undefined) {
                this.get_info(JSON.parse(this.$route.params.data));
            }
        },
        methods: {
            /**获取彩种列表 */
            get_lottery_list() {
                this.axios.post("/main/game_list").then(result => {
                    // console.log(result.data.res.game_list);
                    if (result.data.status && result.data.msg === "请求成功！") {
                        this.lottery_list = result.data.res.game_list;
                        this.list_default = this.lottery_list[0].id;
                        if (this.credit_data.length==0) {
                            this.get_credit_data(this.list_default);
                        }
                    }
                })
            },
            get_credit_data(e) {
                // console.lopostg(e);
                this.axios.post("/user/credit_data", { gid: e }).then((result) => {
                    if (result.data.status && result.data.msg === "请求成功") {
                        // console.log(result.data.res)
                        this.credit_data = result.data.res.list;
                        this.user_data = result.data.res.user_data[0];
                        // if (e == 20 || e == 34 || e == 4 || e == 10) {
                        //     this.pk_label = this.user_data.level_id;
                        // } else {
                        //     this.pk_label = this.user_data.kuaicai_level_id;
                        // }
                    }
                })
            },
            get_info(data) {
                this.credit_data = data.list;
                this.user_data = data.user_data[0];
            }
        },
    }
</script>

<style scoped>
    .bottom-text {
        float: right;
        margin-top: 6px;
    }

    .right-tit {
        float: right;
    }

    .right-tit .tit-cn {
        margin-right: 10px;
        font-size: 36px;
        font-weight: bold;
        text-align: right;
    }

    .right-tit .tit-cn span {
        color: #409eff;
    }

    .right-tit .tit-en {
        margin-right: 10px;
        font-size: 26px;
        color: #ccc;
    }

    .credit-info .credit-user-info {
        margin: 10px;
    }

    .credit-info .credit-user-info img {
        height: 120px;
        vertical-align: text-bottom;
    }

    .credit-info .credit-user-info div {
        display: inline-block;
    }

    .credit-info .credit-user-info span {
        display: inline-block;
        margin-right: 15px;
        vertical-align: text-bottom;
    }

    .credit-info .credit-user-info .user-balance {
        width: auto;
        font-weight: bold;
        color: #ff7900;
        font-size: 16px;
    }
</style>
