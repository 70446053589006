<!--
 * @Author: your name
 * @Date: 2021-04-26 15:04:00
 * @LastEditTime: 2022-09-04 01:34:50
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: In User Settings Edit
 * @FilePath: \luck\src\components\betPage\k3\sthtx.vue
-->
<template>
    <div class="sthtx">
        <el-row style="width:100vw">
            <el-col :span="24">
                <div class="bet-title">
                    <span>{{sthtx[0].ballname}}</span>
                </div>
                <el-row class="bet-items" :gutter="6">
                    <el-col :span="8" v-for="i in sthtx[0].betclass" :key="i.index" offset="8">
                        <div class="bet-items-info" :style="{backgroundColor:i.bg}" @click="click_item(i)">
                            <span class="bet-name">{{i.name}}</span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <span v-show="i.bg=='#ffef3a'" class="select-icon"><Select style="width: 24px; height: 24px;" /></span>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                sthtx: [
                    {
                        ballname: "三同号通选",
                        betclass: [
                            { title: "三同号通选", name: "三同号通选", isSelected: false, static_bonus: "", val: '', disabled: false, subname: "1_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, bg: '#F2F3F8', },
                        ]
                    },
                ],
            }
        },
        methods: {
            //快速下注
            fast_sub_bet() {
                return this.sub_bet();
            },
            //选取投注项
            click_item(item) {
                if (item.isSelected) {
                    item.isSelected = false;
                    item.bg = '#F2F3F8';
                } else {
                    item.bg = '#ffef3a';
                    item.isSelected = true;
                }
                return item
            },
            /**显示赔率等数据 */
            get_lose() {
                let bonus_high = this.$store.state.lottery.lose_data.bonus_high;
                let others = this.$store.state.lottery.lose_data.others;
                let static_bonus = this.$store.state.lottery.lose_data.static_bonus;
                for (let i = 0; i < this.sthtx.length; i++) {
                    for (let j = 0; j < this.sthtx[i].betclass.length; j++) {
                        this.sthtx[i].betclass[j].static_bonus = static_bonus[0];
                        this.sthtx[i].betclass[j].straightbet_high = others[0].straightbet_high;
                        this.sthtx[i].betclass[j].straightbet_low = others[0].straightbet_low;
                    }
                }
            },
            /**检查是否满足快捷输入 */
            check_fast_val(val) {
                if (val) {
                    return val;
                }
                else {
                    val = this.$parent.get_fast();
                    return val;
                }
            },
            /**重填，重置 */
            reset() {
                for (let i = 0; i < this.sthtx.length; i++) {
                    for (let j = 0; j < this.sthtx[i].betclass.length; j++) {
                        this.sthtx[i].betclass[j].val = '';
                        this.sthtx[i].betclass[j].isSelected = false;
                        this.sthtx[i].betclass[j].bg = "#F2F3F8";
                    }
                }
            },
            /**提交，下注 */
            sub_bet() {
                let list = [];
                for (let i = 0; i < this.sthtx[0].betclass.length; i++) {
                    if (this.sthtx[0].betclass[i].isSelected) {
                        this.sthtx[0].betclass[i].bet_count = 1;
                        list.push(this.sthtx[0].betclass[i]);
                    }
                }
                return list;
            },
            /**重复上次下单 */
            repeat_last_bet(list) {
                // console.log(list)
                for (let i = 0; i < list.length; i++) {
                    for (let j = 0; j < this.sthtx.length; j++) {
                        for (let k = 0; k < this.sthtx[j].betclass.length; k++) {
                            if (this.sthtx[j].betclass[k].title == list[i].title && this.sthtx[j].betclass[k].name == list[i].name) {
                                this.sthtx[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                }
            },
        },
    }
</script>