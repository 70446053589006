<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-08-14 16:48:18
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-09-04 00:41:11
 * @FilePath: \pc\src\components\menuitems\duijiangguize.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="duijiangguize">
        <div v-html="data"></div>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                data: '',
            }
        },
        created() {
            if (this.$route.params.data != undefined) {
                this.getdata(JSON.parse(this.$route.params.data));
            } else {
                this.axios.post("/game/rules", { user_id: this.$store.state.userinfo.user_id }).then((result) => {
                    if (result.data.status == 1) {
                        this.getdata(result.data.res)
                    }
                })
            }
        },
        methods: {
            getdata(data) {
                this.data = data;
            }
        },
    }
</script>

<style scoped>
    .duijiangguize {
        width: 90%;
        word-break: break-all;
        margin: 0 auto;
    }
</style>