<template>
    <div class="zxbz">
        <el-row type="flex">
            <el-col :span="24" v-for="item in zxbz" :key="item.index">
                <div class="bet-title">
                    <span>{{item.ballname}}</span>
                </div>
                <div class="zxbz_which_num">
                    <el-radio-group v-model="radio" fill size="small" text-color="#fff" @change="zxbz_change_num">
                        <div>
                            <el-radio label="zxbz">五不中</el-radio>
                            <el-radio label="zxbz6">六不中</el-radio>
                            <el-radio label="zxbz7">七不中</el-radio>
                            <el-radio label="zxbz8">八不中</el-radio>
                        </div>
                        <div style="margin:0.6rem 0;">
                            <el-radio label="zxbz9">九不中</el-radio>
                            <el-radio label="zxbz10">十不中</el-radio>
                            <el-radio label="zxbz11">十一不中</el-radio>
                            <el-radio label="zxbz12">十二不中</el-radio>
                        </div>
                    </el-radio-group>
                </div>
                <el-row class="bet-items" :gutter="6">
                    <el-col :span="8" v-for="i in item.betclass" :key="i.index">
                        <div class="bet-items-info" :style="{backgroundColor:i.bg}" @click="click_item(i)">
                            <span v-if="i.color=='red'" class="lhc_zxbz_red">{{i.name}}</span>
                            <span v-if="i.color=='green'" class="lhc_zxbz_green">{{i.name}}</span>
                            <span v-if="i.color=='blue'" class="lhc_zxbz_blue">{{i.name}}</span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <span v-show="i.bg=='#ffef3a'" class="select-icon"><Select style="width: 24px; height: 24px;" /></span>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import { isArray } from 'util';
    export default {
        data() {
            return {
                zxbz: [
                    {
                        ballname: "自选不中",
                        betclass: [
                            { title: "自选不中", bg: '#F2F3F8', name: "1", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "自选不中", bg: '#F2F3F8', name: "2", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "自选不中", bg: '#F2F3F8', name: "3", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "自选不中", bg: '#F2F3F8', name: "4", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "自选不中", bg: '#F2F3F8', name: "5", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "自选不中", bg: '#F2F3F8', name: "6", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "自选不中", bg: '#F2F3F8', name: "7", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "自选不中", bg: '#F2F3F8', name: "8", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "自选不中", bg: '#F2F3F8', name: "9", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "自选不中", bg: '#F2F3F8', name: "10", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "自选不中", bg: '#F2F3F8', name: "11", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "自选不中", bg: '#F2F3F8', name: "12", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "自选不中", bg: '#F2F3F8', name: "13", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "自选不中", bg: '#F2F3F8', name: "14", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "自选不中", bg: '#F2F3F8', name: "15", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "自选不中", bg: '#F2F3F8', name: "16", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "自选不中", bg: '#F2F3F8', name: "17", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "自选不中", bg: '#F2F3F8', name: "18", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "自选不中", bg: '#F2F3F8', name: "19", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "自选不中", bg: '#F2F3F8', name: "20", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "自选不中", bg: '#F2F3F8', name: "21", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "自选不中", bg: '#F2F3F8', name: "22", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "自选不中", bg: '#F2F3F8', name: "23", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "自选不中", bg: '#F2F3F8', name: "24", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "自选不中", bg: '#F2F3F8', name: "25", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "自选不中", bg: '#F2F3F8', name: "26", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "自选不中", bg: '#F2F3F8', name: "27", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "自选不中", bg: '#F2F3F8', name: "28", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "自选不中", bg: '#F2F3F8', name: "29", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "自选不中", bg: '#F2F3F8', name: "30", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "自选不中", bg: '#F2F3F8', name: "31", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "自选不中", bg: '#F2F3F8', name: "32", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "自选不中", bg: '#F2F3F8', name: "33", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "自选不中", bg: '#F2F3F8', name: "34", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "自选不中", bg: '#F2F3F8', name: "35", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "自选不中", bg: '#F2F3F8', name: "36", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "自选不中", bg: '#F2F3F8', name: "37", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "自选不中", bg: '#F2F3F8', name: "38", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "自选不中", bg: '#F2F3F8', name: "39", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "自选不中", bg: '#F2F3F8', name: "40", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "自选不中", bg: '#F2F3F8', name: "41", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "自选不中", bg: '#F2F3F8', name: "42", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "自选不中", bg: '#F2F3F8', name: "43", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "自选不中", bg: '#F2F3F8', name: "44", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "自选不中", bg: '#F2F3F8', name: "45", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "自选不中", bg: '#F2F3F8', name: "46", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "自选不中", bg: '#F2F3F8', name: "47", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "自选不中", bg: '#F2F3F8', name: "48", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "自选不中", bg: '#F2F3F8', name: "49", isSelected: false, static_bonus: "", val: false, disabled: false, subname: "8_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                        ]
                    }
                ],
                radio: 'zxbz',
                // 选择的个数
                zxbz_count: 0,
                /**自选不中包三下注金额 */
                zxbz_val: "",
            }
        },
        created() {
            // this.get_lose();
        },
        methods: {
            zxbz_change_num(label) {
                // console.log(label)
                this.$emit('regainLoss', label);
                this.reset();
                this.get_lose();
            },
            //选取投注项
            click_item(item) {
                if (item.isSelected) {
                    item.isSelected = false;
                    item.bg = '#F2F3F8';
                    this.zxbz_count -= 1;
                } else {
                    item.bg = '#ffef3a';
                    item.isSelected = true;
                    this.zxbz_count += 1;
                    let choose_count = 0;
                    this.radio == "zxbz" ? choose_count = 5 : "";
                    this.radio == "zxbz6" ? choose_count = 6 : "";
                    this.radio == "zxbz7" ? choose_count = 7 : "";
                    this.radio == "zxbz8" ? choose_count = 8 : "";
                    this.radio == "zxbz9" ? choose_count = 9 : "";
                    this.radio == "zxbz10" ? choose_count = 10 : "";
                    this.radio == "zxbz11" ? choose_count = 11 : "";
                    this.radio == "zxbz12" ? choose_count = 12 : "";
                    if (this.zxbz_count > choose_count) {
                        item.isSelected = false;
                        item.bg = '#F2F3F8';
                        this.zxbz_count -= 1;
                        this.$toast(`只能选择${choose_count}个号码`);
                    }
                }
                return item
            },
            //快速下注
            fast_sub_bet() {
                return this.sub_bet();
            },
            /**显示赔率等数据 */
            get_lose() {
                let lose_data = this.$store.state.lose_data;
                for (let j = 0; j < this.zxbz[0].betclass.length; j++) {
                    if (this.radio == "zxbz") {
                        this.zxbz[0].betclass[j].title = "五不中";
                        this.zxbz[0].betclass[j].subname = "8_0_0";
                        lose_data.forEach(item => {
                            if (item.title == this.zxbz[0].betclass[j].title && item.name == this.zxbz[0].betclass[j].name) {
                                this.zxbz[0].betclass[j].static_bonus = item.rate;
                                this.zxbz[0].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.zxbz[0].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                    if (this.radio == "zxbz6") {
                        this.zxbz[0].betclass[j].title = "六不中";
                        this.zxbz[0].betclass[j].subname = "8_0_1";
                        lose_data.forEach(item => {
                            if (item.title == this.zxbz[0].betclass[j].title && item.name == this.zxbz[0].betclass[j].name) {
                                this.zxbz[0].betclass[j].static_bonus = item.rate;
                                this.zxbz[0].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.zxbz[0].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                    if (this.radio == "zxbz7") {
                        this.zxbz[0].betclass[j].title = "七不中";
                        this.zxbz[0].betclass[j].subname = "8_0_2";
                        lose_data.forEach(item => {
                            if (item.title == this.zxbz[0].betclass[j].title && item.name == this.zxbz[0].betclass[j].name) {
                                this.zxbz[0].betclass[j].static_bonus = item.rate;
                                this.zxbz[0].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.zxbz[0].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                    if (this.radio == "zxbz8") {
                        this.zxbz[0].betclass[j].title = "八不中";
                        this.zxbz[0].betclass[j].subname = "8_0_3";
                        lose_data.forEach(item => {
                            if (item.title == this.zxbz[0].betclass[j].title && item.name == this.zxbz[0].betclass[j].name) {
                                this.zxbz[0].betclass[j].static_bonus = item.rate;
                                this.zxbz[0].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.zxbz[0].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                    if (this.radio == "zxbz9") {
                        this.zxbz[0].betclass[j].title = "九不中";
                        this.zxbz[0].betclass[j].subname = "8_0_4";
                        lose_data.forEach(item => {
                            if (item.title == this.zxbz[0].betclass[j].title && item.name == this.zxbz[0].betclass[j].name) {
                                this.zxbz[0].betclass[j].static_bonus = item.rate;
                                this.zxbz[0].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.zxbz[0].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                    if (this.radio == "zxbz10") {
                        this.zxbz[0].betclass[j].title = "十不中";
                        this.zxbz[0].betclass[j].subname = "8_0_5";
                        lose_data.forEach(item => {
                            if (item.title == this.zxbz[0].betclass[j].title && item.name == this.zxbz[0].betclass[j].name) {
                                this.zxbz[0].betclass[j].static_bonus = item.rate;
                                this.zxbz[0].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.zxbz[0].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                    if (this.radio == "zxbz11") {
                        this.zxbz[0].betclass[j].title = "十一不中";
                        this.zxbz[0].betclass[j].subname = "8_0_6";
                        lose_data.forEach(item => {
                            if (item.title == this.zxbz[0].betclass[j].title && item.name == this.zxbz[0].betclass[j].name) {
                                this.zxbz[0].betclass[j].static_bonus = item.rate;
                                this.zxbz[0].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.zxbz[0].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                    if (this.radio == "zxbz12") {
                        this.zxbz[0].betclass[j].title = "十二不中";
                        this.zxbz[0].betclass[j].subname = "8_0_7";
                        lose_data.forEach(item => {
                            if (item.title == this.zxbz[0].betclass[j].title && item.name == this.zxbz[0].betclass[j].name) {
                                this.zxbz[0].betclass[j].static_bonus = item.rate;
                                this.zxbz[0].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.zxbz[0].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                }
            },
            /**检查是否满足快捷输入 */
            check_fast_val(val) {
                if (val) {
                    return val;
                }
                else {
                    val = this.$parent.get_fast();
                    return val;
                }
            },
            /**重填，重置 */
            reset() {
                this.zxbz_val = "";
                this.zxbz_count = 0;
                for (let i = 0; i < this.zxbz[0].betclass.length; i++) {
                    this.zxbz[0].betclass[i].val = false;
                    this.zxbz[0].betclass[i].isSelected = false;
                    this.zxbz[0].betclass[i].bg = "#F2F3F8";
                }
            },
            /**多选框点击 */
            checkbox_change(v) {
                v ? this.zxbz_count++ : this.zxbz_count--;
                let choose_count = 0;
                this.radio == "zxbz" ? choose_count = 5 : "";
                this.radio == "zxbz6" ? choose_count = 6 : "";
                this.radio == "zxbz7" ? choose_count = 7 : "";
                this.radio == "zxbz8" ? choose_count = 8 : "";
                this.radio == "zxbz9" ? choose_count = 9 : "";
                this.radio == "zxbz10" ? choose_count = 10 : "";
                this.radio == "zxbz11" ? choose_count = 11 : "";
                this.radio == "zxbz12" ? choose_count = 12 : "";
                if (this.zxbz_count >= choose_count) {
                    for (let i = 0; i < this.zxbz[0].betclass.length; i++) {
                        if (this.zxbz[0].betclass[i].val == false) {
                            this.zxbz[0].betclass[i].disabled = true;
                        }
                    }
                } else {
                    for (let i = 0; i < this.zxbz[0].betclass.length; i++) {
                        if (this.zxbz[0].betclass[i].val == false) {
                            this.zxbz[0].betclass[i].disabled = false;
                        }
                    }
                }
            },
            /**提交，下注 */
            sub_bet() {
                let list = [];
                let choose_count = 0;
                this.radio == "zxbz" ? choose_count = 5 : "";
                this.radio == "zxbz6" ? choose_count = 6 : "";
                this.radio == "zxbz7" ? choose_count = 7 : "";
                this.radio == "zxbz8" ? choose_count = 8 : "";
                this.radio == "zxbz9" ? choose_count = 9 : "";
                this.radio == "zxbz10" ? choose_count = 10 : "";
                this.radio == "zxbz11" ? choose_count = 11 : "";
                this.radio == "zxbz12" ? choose_count = 12 : "";
                if (this.zxbz_count < choose_count) {
                    return `至少选择${choose_count}个号码`;
                } else {
                    if (this.zxbz_count > 0) {
                        for (let i = 0; i < this.zxbz.length; i++) {
                            for (let j = 0; j < this.zxbz[i].betclass.length; j++) {
                                if (this.zxbz[i].betclass[j].isSelected) {
                                    // this.zxbz[i].betclass[j].val=this.zxbz_val;
                                    list.push(this.zxbz[i].betclass[j]);
                                }
                            }
                        }
                        let choose_num = []
                        for (let i = 0; i < list.length; i++) {
                            choose_num.push(list[i].name)
                        }
                        //多选号码，重新组合成注单
                        let bet_obj = list[0]
                        // console.log(bet_obj)
                        list = []
                        let new_arr = this.combination_arr_elem(choose_num, choose_count)
                        for (let i = 0; i < new_arr.length; i++) {
                            list.push(
                                {
                                    title: bet_obj.title, name: new_arr[i].toString(), isSelected: bet_obj.isSelected,
                                    static_bonus: bet_obj.static_bonus, val: bet_obj.val, subname: bet_obj.subname,
                                    straightbet_high: bet_obj.straightbet_high, straightbet_low: bet_obj.straightbet_low, bonus_high: bet_obj.bonus_high,
                                    sub_popover: bet_obj.sub_popover,
                                    color: bet_obj.color, isChoose: bet_obj.isChoose,
                                }
                            )
                            // this.suballcout += this.allinputval[i].val * 1;
                        }
                    }
                }
                //筛选最小的赔率
                for (let i = 0; i < list.length; i++) {
                    list[i].ball_list = list[i].name.split(',');
                    list[i].ball_loss_list = [];
                    for (let k = 0; k < list[i].ball_list.length; k++) {
                        for (let j = 0; j < this.zxbz[0].betclass.length; j++) {
                            if (this.zxbz[0].betclass[j].name == list[i].ball_list[k]) {
                                list[i].ball_loss_list.push({
                                    loss: this.zxbz[0].betclass[j].static_bonus,
                                    ball: this.zxbz[0].betclass[j].name,
                                });
                            }
                        }
                    }
                    let sitem = list[i].ball_loss_list.sort((a, b) => a.loss - b.loss)[0];
                    list[i].ball_min = sitem.ball;
                    list[i].static_bonus = sitem.loss;
                }
                // console.log(list)
                return list;
            },
            /**重复上次下单 */
            repeat_last_bet(list) {
                // console.log(list)
                // console.log(list[0].name.split(","))
                for (let i = 0; i < list.length; i++) {
                    if (!isArray(list[i].name)) {
                        list[i].name = list[i].name.split(",");
                    }
                    for (let j = 0; j < this.zxbz.length; j++) {
                        for (let k = 0; k < this.zxbz[j].betclass.length; k++) {
                            if (this.zxbz[j].betclass[k].title == list[i].title && list[i].name.find(n => n == this.zxbz[j].betclass[k].name)) {
                                this.zxbz[j].betclass[k].val = true;
                            }
                        }
                    }
                }
            },
            combination_arr_elem(nu, groupl, res) {
                let result = res ? res : [];
                let nuc = nu.slice(0);
                let item = nuc.shift();
                item = item.constructor === Array ? item : [item];
                (function func(item, nuc) {
                    let itemc;
                    let nucc = nuc.slice(0);
                    let margin = groupl - item.length
                    if (margin == 0) {
                        result.push(item);
                        return;
                    }
                    if (margin == 1) {
                        for (let j in nuc) {
                            itemc = item.slice(0);
                            itemc.push(nuc[j]);
                            result.push(itemc);
                        }
                    }
                    if (margin > 1) {
                        itemc = item.slice(0);
                        itemc.push(nucc.shift());
                        func(itemc, nucc);
                        if (item.length + nucc.length >= groupl) {
                            func(item, nucc);
                        }
                    }
                })(item, nuc);
                if (nuc.length >= groupl) {
                    return this.combination_arr_elem(nuc, groupl, result);
                } else {
                    return result;
                }
            },
        },
    }
</script>

<style scoped>
    .zxbz_which_num {
        text-align: center;
        padding: 10px 10px;
        background-color: #fff;
    }
    .lhc_zxbz_red,
    .lhc_zxbz_blue,
    .lhc_zxbz_green {
        display: inline-block;
        width: 24px;
        height: 24px;
        line-height: 24px;
        border-radius: 50%;
        color: #fff;
        text-align: center;
        margin-left: 5px;
        margin-top: 1px;
        font-weight: bold;
    }
    .lhc_zxbz_red {
        background-color: #f56c6c;
    }
    .lhc_zxbz_blue {
        background-color: #0089ff;
    }
    .lhc_zxbz_green {
        background-color: #67c23a;
    }
    .zxbz-val {
        width: 120px !important;
        margin: 0 auto !important;
    }
</style>