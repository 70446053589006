<!--
 * @Author: your name
 * @Date: 2021-04-26 15:04:00
 * @LastEditTime: 2022-09-04 01:32:09
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: In User Settings Edit
 * @FilePath: \luck\src\components\betPage\k3\ethdx.vue
-->
<template>
    <div class="ethdx">
        <el-row type="flex">
            <el-col :span="24">
                <div class="bet-title">
                    <span>{{ethdx[0].ballname}}</span>
                </div>
                <el-row class="bet-items" :gutter="6">
                    <el-col :span="8" v-for="i in ethdx[0].betclass" :key="i.index">
                        <div class="bet-items-info" :style="{backgroundColor:i.bg}" @click="click_item(i)">
                            <span class="bet-name">{{i.name}}</span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <span v-show="i.bg=='#ffef3a'" class="select-icon"><Select style="width: 24px; height: 24px;" /></span>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                ethdx: [
                    {
                        ballname: "二同号单选",
                        betclass: [
                            { title: "二同号单选", name: "112", isSelected: false, static_bonus: "", val: '', disabled: false, subname: "6_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, bg: '#F2F3F8', },
                            { title: "二同号单选", name: "113", isSelected: false, static_bonus: "", val: '', disabled: false, subname: "6_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, bg: '#F2F3F8', },
                            { title: "二同号单选", name: "114", isSelected: false, static_bonus: "", val: '', disabled: false, subname: "6_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, bg: '#F2F3F8', },
                            { title: "二同号单选", name: "115", isSelected: false, static_bonus: "", val: '', disabled: false, subname: "6_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, bg: '#F2F3F8', },
                            { title: "二同号单选", name: "116", isSelected: false, static_bonus: "", val: '', disabled: false, subname: "6_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, bg: '#F2F3F8', },
                            { title: "二同号单选", name: "122", isSelected: false, static_bonus: "", val: '', disabled: false, subname: "6_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, bg: '#F2F3F8', },
                            { title: "二同号单选", name: "133", isSelected: false, static_bonus: "", val: '', disabled: false, subname: "6_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, bg: '#F2F3F8', },
                            { title: "二同号单选", name: "144", isSelected: false, static_bonus: "", val: '', disabled: false, subname: "6_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, bg: '#F2F3F8', },
                            { title: "二同号单选", name: "155", isSelected: false, static_bonus: "", val: '', disabled: false, subname: "6_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, bg: '#F2F3F8', },
                            { title: "二同号单选", name: "166", isSelected: false, static_bonus: "", val: '', disabled: false, subname: "6_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, bg: '#F2F3F8', },
                            { title: "二同号单选", name: "223", isSelected: false, static_bonus: "", val: '', disabled: false, subname: "6_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, bg: '#F2F3F8', },
                            { title: "二同号单选", name: "224", isSelected: false, static_bonus: "", val: '', disabled: false, subname: "6_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, bg: '#F2F3F8', },
                            { title: "二同号单选", name: "225", isSelected: false, static_bonus: "", val: '', disabled: false, subname: "6_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, bg: '#F2F3F8', },
                            { title: "二同号单选", name: "226", isSelected: false, static_bonus: "", val: '', disabled: false, subname: "6_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, bg: '#F2F3F8', },
                            { title: "二同号单选", name: "233", isSelected: false, static_bonus: "", val: '', disabled: false, subname: "6_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, bg: '#F2F3F8', },
                            { title: "二同号单选", name: "244", isSelected: false, static_bonus: "", val: '', disabled: false, subname: "6_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, bg: '#F2F3F8', },
                            { title: "二同号单选", name: "255", isSelected: false, static_bonus: "", val: '', disabled: false, subname: "6_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, bg: '#F2F3F8', },
                            { title: "二同号单选", name: "266", isSelected: false, static_bonus: "", val: '', disabled: false, subname: "6_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, bg: '#F2F3F8', },
                            { title: "二同号单选", name: "334", isSelected: false, static_bonus: "", val: '', disabled: false, subname: "6_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, bg: '#F2F3F8', },
                            { title: "二同号单选", name: "335", isSelected: false, static_bonus: "", val: '', disabled: false, subname: "6_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, bg: '#F2F3F8', },
                            { title: "二同号单选", name: "336", isSelected: false, static_bonus: "", val: '', disabled: false, subname: "6_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, bg: '#F2F3F8', },
                            { title: "二同号单选", name: "344", isSelected: false, static_bonus: "", val: '', disabled: false, subname: "6_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, bg: '#F2F3F8', },
                            { title: "二同号单选", name: "355", isSelected: false, static_bonus: "", val: '', disabled: false, subname: "6_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, bg: '#F2F3F8', },
                            { title: "二同号单选", name: "366", isSelected: false, static_bonus: "", val: '', disabled: false, subname: "6_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, bg: '#F2F3F8', },
                            { title: "二同号单选", name: "445", isSelected: false, static_bonus: "", val: '', disabled: false, subname: "6_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, bg: '#F2F3F8', },
                            { title: "二同号单选", name: "446", isSelected: false, static_bonus: "", val: '', disabled: false, subname: "6_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, bg: '#F2F3F8', },
                            { title: "二同号单选", name: "455", isSelected: false, static_bonus: "", val: '', disabled: false, subname: "6_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, bg: '#F2F3F8', },
                            { title: "二同号单选", name: "466", isSelected: false, static_bonus: "", val: '', disabled: false, subname: "6_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, bg: '#F2F3F8', },
                            { title: "二同号单选", name: "556", isSelected: false, static_bonus: "", val: '', disabled: false, subname: "6_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, bg: '#F2F3F8', },
                            { title: "二同号单选", name: "566", isSelected: false, static_bonus: "", val: '', disabled: false, subname: "6_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, bg: '#F2F3F8', },
                        ]
                    },
                ],
            }
        },
        methods: {
            //快速下注
            fast_sub_bet() {
                return this.sub_bet();
            },
            //选取投注项
            click_item(item) {
                if (item.isSelected) {
                    item.isSelected = false;
                    item.bg = '#F2F3F8';
                } else {
                    item.bg = '#ffef3a';
                    item.isSelected = true;
                }
                return item
            },
            /**显示赔率等数据 */
            get_lose() {
                let lose_data = this.$store.state.lose_data;
                lose_data.forEach(item => {
                    item.subname = `${item.level_two}_${item.level_three}_${item.level_four}`
                })
                for (let i = 0; i < this.ethdx.length; i++) {
                    for (let j = 0; j < this.ethdx[i].betclass.length; j++) {
                        lose_data.forEach(item => {
                            if (item.subname == this.ethdx[i].betclass[j].subname) {
                                this.ethdx[i].betclass[j].static_bonus = item.rate;
                                this.ethdx[i].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.ethdx[i].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                }
            },
            /**检查是否满足快捷输入 */
            check_fast_val(val) {
                if (val) {
                    return val;
                }
                else {
                    val = this.$parent.get_fast();
                    return val;
                }
            },
            /**重填，重置 */
            reset() {
                for (let i = 0; i < this.ethdx.length; i++) {
                    for (let j = 0; j < this.ethdx[i].betclass.length; j++) {
                        this.ethdx[i].betclass[j].val = '';
                        this.ethdx[i].betclass[j].isSelected = false;
                        this.ethdx[i].betclass[j].bg = "#F2F3F8";
                    }
                }
            },
            /**提交，下注 */
            sub_bet() {
                let list = [];
                for (let i = 0; i < this.ethdx[0].betclass.length; i++) {
                    if (this.ethdx[0].betclass[i].isSelected) {
                        this.ethdx[0].betclass[i].bet_count = 1;
                        list.push(this.ethdx[0].betclass[i]);
                    }
                }
                return list;
            },
            /**重复上次下单 */
            repeat_last_bet(list) {
                // console.log(list)
                for (let i = 0; i < list.length; i++) {
                    for (let j = 0; j < this.ethdx.length; j++) {
                        for (let k = 0; k < this.ethdx[j].betclass.length; k++) {
                            if (this.ethdx[j].betclass[k].title == list[i].title && this.ethdx[j].betclass[k].name == list[i].name) {
                                this.ethdx[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                }
            },
        },
    }
</script>