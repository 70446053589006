<template>
    <div class="zte">
        <el-row>
            <el-col :span="24" v-for="item in zte" :key="item.index">
                <div class="bet-title">
                    <span>{{item.ballname}}</span>
                </div>
                <div class="zhengte_which_num">
                    <el-radio-group v-model="radio" text-color="#fff" @change="zhengte_change_num">
                        <div>
                            <el-radio label="zte">正特码一</el-radio>
                            <el-radio label="zte2">正特码二</el-radio>
                            <el-radio label="zte3">正特码三</el-radio>
                        </div>
                        <div style="margin-top:0.6rem">
                            <el-radio label="zte4">正特码四</el-radio>
                            <el-radio label="zte5">正特码五</el-radio>
                            <el-radio label="zte6">正特码六</el-radio>
                        </div>
                    </el-radio-group>
                </div>
                <el-row class="bet-items" :gutter="6">
                    <el-col :span="8" v-for="i in item.betclass" :key="i.index">
                        <div class="bet-items-info" :style="{backgroundColor:i.bg}" @click="click_item(i)">
                            <span v-if="i.color=='red'" class="lhc_zte_red">{{i.name}}</span>
                            <span v-if="i.color=='green'" class="lhc_zte_green">{{i.name}}</span>
                            <span v-if="i.color=='blue'" class="lhc_zte_blue">{{i.name}}</span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <span v-show="i.bg=='#ffef3a'" class="select-icon"><Select style="width: 24px; height: 24px;" /></span>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                zte: [
                    {
                        ballname: "正特",
                        betclass: [
                            { title: "正特", bg: '#F2F3F8', name: "1", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "正特", bg: '#F2F3F8', name: "2", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "正特", bg: '#F2F3F8', name: "3", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "正特", bg: '#F2F3F8', name: "4", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "正特", bg: '#F2F3F8', name: "5", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "正特", bg: '#F2F3F8', name: "6", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "正特", bg: '#F2F3F8', name: "7", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "正特", bg: '#F2F3F8', name: "8", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "正特", bg: '#F2F3F8', name: "9", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "正特", bg: '#F2F3F8', name: "10", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "正特", bg: '#F2F3F8', name: "11", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "正特", bg: '#F2F3F8', name: "12", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "正特", bg: '#F2F3F8', name: "13", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "正特", bg: '#F2F3F8', name: "14", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "正特", bg: '#F2F3F8', name: "15", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "正特", bg: '#F2F3F8', name: "16", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "正特", bg: '#F2F3F8', name: "17", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "正特", bg: '#F2F3F8', name: "18", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "正特", bg: '#F2F3F8', name: "19", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "正特", bg: '#F2F3F8', name: "20", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "正特", bg: '#F2F3F8', name: "21", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "正特", bg: '#F2F3F8', name: "22", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "正特", bg: '#F2F3F8', name: "23", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "正特", bg: '#F2F3F8', name: "24", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "正特", bg: '#F2F3F8', name: "25", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "正特", bg: '#F2F3F8', name: "26", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "正特", bg: '#F2F3F8', name: "27", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "正特", bg: '#F2F3F8', name: "28", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "正特", bg: '#F2F3F8', name: "29", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "正特", bg: '#F2F3F8', name: "30", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "正特", bg: '#F2F3F8', name: "31", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "正特", bg: '#F2F3F8', name: "32", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "正特", bg: '#F2F3F8', name: "33", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "正特", bg: '#F2F3F8', name: "34", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "正特", bg: '#F2F3F8', name: "35", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "正特", bg: '#F2F3F8', name: "36", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "正特", bg: '#F2F3F8', name: "37", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "正特", bg: '#F2F3F8', name: "38", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "正特", bg: '#F2F3F8', name: "39", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "正特", bg: '#F2F3F8', name: "40", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "正特", bg: '#F2F3F8', name: "41", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "正特", bg: '#F2F3F8', name: "42", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "正特", bg: '#F2F3F8', name: "43", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "正特", bg: '#F2F3F8', name: "44", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "正特", bg: '#F2F3F8', name: "45", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "正特", bg: '#F2F3F8', name: "46", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "正特", bg: '#F2F3F8', name: "47", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "正特", bg: '#F2F3F8', name: "48", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "正特", bg: '#F2F3F8', name: "49", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                        ]
                    }
                ],
                radio: 'zte',
            }
        },
        methods: {
            zhengte_change_num(label) {
                // console.log(label)
                this.$emit('regainLoss', label)
                this.reset();
                this.get_lose();
            },
            //选取投注项
            click_item(item) {
                if (item.isSelected) {
                    item.isSelected = false;
                    item.bg = '#F2F3F8';
                } else {
                    item.bg = '#ffef3a';
                    item.isSelected = true;
                }
                return item
            },
            //快速下注
            fast_sub_bet() {
                let list = [];
                //1-5
                for (let i = 0; i < this.zte.length; i++) {
                    for (let j = 0; j < this.zte[i].betclass.length; j++) {
                        if (this.zte[i].betclass[j].isSelected) {
                            list.push(this.zte[i].betclass[j]);
                        }
                    }
                }
                // console.log(list)
                return list;
            },
            /**显示赔率等数据 */
            get_lose() {
                let lose_data = this.$store.state.lose_data;
                //正特
                for (let j = 0; j < this.zte[0].betclass.length; j++) {
                    if (this.radio == "zte") {
                        this.zte[0].betclass[j].title = "正特码一";
                        this.zte[0].betclass[j].subname = "4_0_0";
                        lose_data.forEach(item => {
                            if (item.title == this.zte[0].betclass[j].title && item.name == this.zte[0].betclass[j].name) {
                                this.zte[0].betclass[j].static_bonus = item.rate;
                                this.zte[0].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.zte[0].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                    if (this.radio == "zte2") {
                        this.zte[0].betclass[j].title = "正特码二";
                        this.zte[0].betclass[j].subname = "4_0_1";
                        lose_data.forEach(item => {
                            if (item.title == this.zte[0].betclass[j].title && item.name == this.zte[0].betclass[j].name) {
                                this.zte[0].betclass[j].static_bonus = item.rate;
                                this.zte[0].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.zte[0].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                    if (this.radio == "zte3") {
                        this.zte[0].betclass[j].title = "正特码三";
                        this.zte[0].betclass[j].subname = "4_0_2";
                        lose_data.forEach(item => {
                            if (item.title == this.zte[0].betclass[j].title && item.name == this.zte[0].betclass[j].name) {
                                this.zte[0].betclass[j].static_bonus = item.rate;
                                this.zte[0].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.zte[0].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                    if (this.radio == "zte4") {
                        this.zte[0].betclass[j].title = "正特码四";
                        this.zte[0].betclass[j].subname = "4_0_3";
                        lose_data.forEach(item => {
                            if (item.title == this.zte[0].betclass[j].title && item.name == this.zte[0].betclass[j].name) {
                                this.zte[0].betclass[j].static_bonus = item.rate;
                                this.zte[0].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.zte[0].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                    if (this.radio == "zte5") {
                        this.zte[0].betclass[j].title = "正特码五";
                        this.zte[0].betclass[j].subname = "4_0_4";
                        lose_data.forEach(item => {
                            if (item.title == this.zte[0].betclass[j].title && item.name == this.zte[0].betclass[j].name) {
                                this.zte[0].betclass[j].static_bonus = item.rate;
                                this.zte[0].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.zte[0].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                    if (this.radio == "zte6") {
                        this.zte[0].betclass[j].title = "正特码六";
                        this.zte[0].betclass[j].subname = "4_0_5";
                        lose_data.forEach(item => {
                            if (item.title == this.zte[0].betclass[j].title && item.name == this.zte[0].betclass[j].name) {
                                this.zte[0].betclass[j].static_bonus = item.rate;
                                this.zte[0].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.zte[0].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                }
                // console.log(this.zte)
            },
            /**检查是否满足快捷输入 */
            check_fast_val(val) {
                if (val) {
                    return val;
                }
                else {
                    val = this.$parent.get_fast();
                    return val;
                }
            },
            /**重填，重置 */
            reset() {
                //正特
                for (let i = 0; i < this.zte.length; i++) {
                    for (let j = 0; j < this.zte[i].betclass.length; j++) {
                        this.zte[i].betclass[j].val = "";
                        this.zte[i].betclass[j].isSelected = false;
                        this.zte[i].betclass[j].bg = "#F2F3F8";
                    }
                }
            },
            /**提交，下注 */
            sub_bet() {
                let list = [];
                //正特
                for (let i = 0; i < this.zte.length; i++) {
                    for (let j = 0; j < this.zte[i].betclass.length; j++) {
                        if (this.zte[i].betclass[j].isSelected) {
                            list.push(this.zte[i].betclass[j]);
                        }
                    }
                }
                // console.log(list)
                return list;
            },
            /**重复上次下单 */
            repeat_last_bet(list) {
                // console.log(list)
                for (let i = 0; i < list.length; i++) {
                    //正特
                    for (let j = 0; j < this.zte.length; j++) {
                        for (let k = 0; k < this.zte[j].betclass.length; k++) {
                            if (this.zte[j].betclass[k].title == list[i].title && this.zte[j].betclass[k].name == list[i].name) {
                                this.zte[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                }
            },
        },
    }
</script>

<style scoped>
    .zhengte_which_num {
        margin: 0 20px;
        text-align: center;
    }
    .lhc_zte_red,
    .lhc_zte_blue,
    .lhc_zte_green {
        float: left;
        width: 24px;
        height: 24px;
        line-height: 24px;
        border-radius: 50%;
        color: #fff;
        text-align: center;
        margin-left: 3px;
        margin-top: 1px;
        font-weight: bold;
    }
    .lhc_zte_red {
        background-color: #f56c6c;
    }
    .lhc_zte_blue {
        background-color: #0089ff;
    }
    .lhc_zte_green {
        background-color: #67c23a;
    }
</style>