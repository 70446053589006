<template>
    <div class="temab">
        <el-row>
            <el-col :span="24" v-for="item in temab" :key="item.index">
                <div class="bet-title">
                    <span>{{item.ballname}}</span>
                </div>
                <el-row class="bet-items" :gutter="6">
                    <el-col :span="8" v-for="i in item.betclass" :key="i.index">
                        <div class="bet-items-info" :style="{backgroundColor:i.bg}" @click="click_item(i)">
                            <span v-if="i.color=='red'" class="lhc_temab_red">{{i.name}}</span>
                            <span v-if="i.color=='green'" class="lhc_temab_green">{{i.name}}</span>
                            <span v-if="i.color=='blue'" class="lhc_temab_blue">{{i.name}}</span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <span v-show="i.bg=='#ffef3a'" class="select-icon"><Select style="width: 24px; height: 24px;" /></span>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <el-row type="flex">
            <el-col :span="24" v-for="item in dxdssb" :key="item.index">
                <div class="bet-title">
                    <span>{{item.ballname}}</span>
                </div>
                <el-row class="bet-items" :gutter="6">
                    <el-col :span="8" v-for="i in item.betclass" :key="i.index">
                        <div class="bet-items-info" :style="{backgroundColor:i.bg}" @click="click_item(i)">
                            <span class="bet-name" :style="{color:i.color}">{{i.name}}</span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <span v-show="i.bg=='#ffef3a'" class="select-icon"><Select style="width: 24px; height: 24px;" /></span>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                temab: [
                    {
                        ballname: "特码B",
                        betclass: [
                            { title: "特码B", bg: '#F2F3F8', name: "1", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "特码B", bg: '#F2F3F8', name: "2", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "特码B", bg: '#F2F3F8', name: "3", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "特码B", bg: '#F2F3F8', name: "4", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "特码B", bg: '#F2F3F8', name: "5", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "特码B", bg: '#F2F3F8', name: "6", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "特码B", bg: '#F2F3F8', name: "7", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "特码B", bg: '#F2F3F8', name: "8", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "特码B", bg: '#F2F3F8', name: "9", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "特码B", bg: '#F2F3F8', name: "10", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "特码B", bg: '#F2F3F8', name: "11", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "特码B", bg: '#F2F3F8', name: "12", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "特码B", bg: '#F2F3F8', name: "13", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "特码B", bg: '#F2F3F8', name: "14", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "特码B", bg: '#F2F3F8', name: "15", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "特码B", bg: '#F2F3F8', name: "16", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "特码B", bg: '#F2F3F8', name: "17", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "特码B", bg: '#F2F3F8', name: "18", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "特码B", bg: '#F2F3F8', name: "19", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "特码B", bg: '#F2F3F8', name: "20", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "特码B", bg: '#F2F3F8', name: "21", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "特码B", bg: '#F2F3F8', name: "22", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "特码B", bg: '#F2F3F8', name: "23", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "特码B", bg: '#F2F3F8', name: "24", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "特码B", bg: '#F2F3F8', name: "25", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "特码B", bg: '#F2F3F8', name: "26", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "特码B", bg: '#F2F3F8', name: "27", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "特码B", bg: '#F2F3F8', name: "28", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "特码B", bg: '#F2F3F8', name: "29", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "特码B", bg: '#F2F3F8', name: "30", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "特码B", bg: '#F2F3F8', name: "31", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "特码B", bg: '#F2F3F8', name: "32", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "特码B", bg: '#F2F3F8', name: "33", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "特码B", bg: '#F2F3F8', name: "34", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "特码B", bg: '#F2F3F8', name: "35", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "特码B", bg: '#F2F3F8', name: "36", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "特码B", bg: '#F2F3F8', name: "37", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "特码B", bg: '#F2F3F8', name: "38", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "特码B", bg: '#F2F3F8', name: "39", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "特码B", bg: '#F2F3F8', name: "40", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "特码B", bg: '#F2F3F8', name: "41", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "特码B", bg: '#F2F3F8', name: "42", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "特码B", bg: '#F2F3F8', name: "43", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "特码B", bg: '#F2F3F8', name: "44", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "特码B", bg: '#F2F3F8', name: "45", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "特码B", bg: '#F2F3F8', name: "46", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "特码B", bg: '#F2F3F8', name: "47", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "特码B", bg: '#F2F3F8', name: "48", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "特码B", bg: '#F2F3F8', name: "49", isSelected: false, static_bonus: "", val: "", subname: "13_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                        ]
                    }
                ],
                dxdssb: [
                    {
                        ballname: '大小单双、色波',
                        betclass: [
                            { title: "两面", bg: '#F2F3F8', name: "特大", isSelected: false, static_bonus: "", val: "", subname: "10_0_10", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "" },
                            { title: "两面", bg: '#F2F3F8', name: "特小", isSelected: false, static_bonus: "", val: "", subname: "10_0_11", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "" },
                            { title: "两面", bg: '#F2F3F8', name: "特单", isSelected: false, static_bonus: "", val: "", subname: "10_0_12", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "" },
                            { title: "两面", bg: '#F2F3F8', name: "特双", isSelected: false, static_bonus: "", val: "", subname: "10_0_13", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "" },
                            { title: "两面", bg: '#F2F3F8', name: "合单", isSelected: false, static_bonus: "", val: "", subname: "10_0_16", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "" },
                            { title: "两面", bg: '#F2F3F8', name: "合双", isSelected: false, static_bonus: "", val: "", subname: "10_0_17", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "" },
                            { title: "两面", bg: '#F2F3F8', name: "尾大", isSelected: false, static_bonus: "", val: "", subname: "10_0_24", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "" },
                            { title: "两面", bg: '#F2F3F8', name: "尾小", isSelected: false, static_bonus: "", val: "", subname: "10_0_25", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "" },
                            { title: "红波", bg: '#F2F3F8', name: "红波", isSelected: false, static_bonus: "", val: "", subname: "1_0_12", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#f56c6c" },
                            { title: "蓝波", bg: '#F2F3F8', name: "蓝波", isSelected: false, static_bonus: "", val: "", subname: "1_0_13", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#409eff" },
                            { title: "绿波", bg: '#F2F3F8', name: "绿波", isSelected: false, static_bonus: "", val: "", subname: "1_0_14", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#67c23a" },
                        ]
                    }
                ]
            }
        },
        methods: {
            //选取投注项
            click_item(item) {
                if (item.isSelected) {
                    item.isSelected = false;
                    item.bg = '#F2F3F8';
                } else {
                    item.bg = '#ffef3a';
                    item.isSelected = true;
                }
                return item
            },
            //快速下注
            fast_sub_bet() {
                let list = [];
                //特码
                for (let i = 0; i < this.temab.length; i++) {
                    for (let j = 0; j < this.temab[i].betclass.length; j++) {
                        if (this.temab[i].betclass[j].isSelected) {
                            list.push(this.temab[i].betclass[j]);
                        }
                    }
                }
                for (let i = 0; i < this.dxdssb.length; i++) {
                    for (let j = 0; j < this.dxdssb[i].betclass.length; j++) {
                        if (this.dxdssb[i].betclass[j].isSelected) {
                            list.push(this.dxdssb[i].betclass[j]);
                        }
                    }
                }
                // console.log(list)
                return list;
            },
            /**显示赔率等数据 */
             get_lose() {
                let lose_data = this.$store.state.lose_data;
                //特码
                for (let j = 0; j < this.temab[0].betclass.length; j++) {
                    lose_data.forEach(item => {
                        if (item.title == this.temab[0].betclass[j].title && item.name == this.temab[0].betclass[j].name) {
                            this.temab[0].betclass[j].static_bonus = item.rate;
                            this.temab[0].betclass[j].straightbet_high = item.other.straightbet_high;
                            this.temab[0].betclass[j].straightbet_low = item.other.straightbet_low;
                        }
                    });
                }
                //大小单双、色波
                for (let j = 0; j < this.dxdssb[0].betclass.length; j++) {
                    lose_data.forEach(item => {
                        if (item.title == this.dxdssb[0].betclass[j].title && item.name == this.dxdssb[0].betclass[j].name) {
                            this.dxdssb[0].betclass[j].static_bonus = item.rate;
                            this.dxdssb[0].betclass[j].straightbet_high = item.other.straightbet_high;
                            this.dxdssb[0].betclass[j].straightbet_low = item.other.straightbet_low;
                        }
                    });
                }
            },
            /**检查是否满足快捷输入 */
            check_fast_val(val) {
                if (val) {
                    return val;
                }
                else {
                    val = this.$parent.get_fast();
                    return val;
                }
            },
            /**重填，重置 */
            reset() {
                //特码
                for (let i = 0; i < this.temab.length; i++) {
                    for (let j = 0; j < this.temab[i].betclass.length; j++) {
                        this.temab[i].betclass[j].val = "";
                        this.temab[i].betclass[j].isSelected = false;
                        this.temab[i].betclass[j].bg = "#F2F3F8";
                    }
                }
                for (let i = 0; i < this.dxdssb.length; i++) {
                    for (let j = 0; j < this.dxdssb[i].betclass.length; j++) {
                        this.dxdssb[i].betclass[j].val = "";
                        this.dxdssb[i].betclass[j].isSelected = false;
                        this.dxdssb[i].betclass[j].bg = "#F2F3F8";
                    }
                }
            },
            /**提交，下注 */
            sub_bet() {
                let list = [];
                //特码
                for (let i = 0; i < this.temab.length; i++) {
                    for (let j = 0; j < this.temab[i].betclass.length; j++) {
                        if (this.temab[i].betclass[j].isSelected) {
                            list.push(this.temab[i].betclass[j]);
                        }
                    }
                }
                for (let i = 0; i < this.dxdssb.length; i++) {
                    for (let j = 0; j < this.dxdssb[i].betclass.length; j++) {
                        if (this.dxdssb[i].betclass[j].isSelected) {
                            list.push(this.dxdssb[i].betclass[j]);
                        }
                    }
                }
                // console.log(list)
                return list;
            },
            /**重复上次下单 */
            repeat_last_bet(list) {
                // console.log(list)
                for (let i = 0; i < list.length; i++) {
                    //特码
                    for (let j = 0; j < this.temab.length; j++) {
                        for (let k = 0; k < this.temab[j].betclass.length; k++) {
                            if (this.temab[j].betclass[k].title == list[i].title && this.temab[j].betclass[k].name == list[i].name) {
                                this.temab[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                }
                for (let j = 0; j < this.dxdssb.length; j++) {
                    for (let k = 0; k < this.dxdssb[j].betclass.length; k++) {
                        if (this.dxdssb[j].betclass[k].title == list[i].title && this.dxdssb[j].betclass[k].name == list[i].name) {
                            this.dxdssb[j].betclass[k].val = list[i].val;
                        }
                    }
                }
            },
        },
    }
</script>

<style scoped>
    .lhc_temab_red,
    .lhc_temab_blue,
    .lhc_temab_green {
        float: left;
        width: 22px;
        height: 22px;
        line-height: 22px;
        border-radius: 50%;
        color: #fff;
        text-align: center;
        margin-left: 3px;
        margin-top: 2px;
        font-weight: bold;
    }
    .lhc_temab_red {
        background-color: #f56c6c;
    }
    .lhc_temab_blue {
        background-color: #0089ff;
    }
    .lhc_temab_green {
        background-color: #67c23a;
    }
</style>