<template>
    <div class="sebo">
        <el-row type="flex">
            <el-col :span="24" v-for="item in sebo" :key="item.index">
                <div class="bet-title">
                    <span>{{item.ballname}}</span>
                </div>
                <el-row class="bet-items">
                    <el-col :span="24" v-for="i in item.betclass" :key="i.index">
                        <div class="bet-items-info" :style="{backgroundColor:i.bg}" @click="click_item(i)">
                            <span class="bet-name" :style="{color:i.color}">{{i.name}}</span>
                            <span class="bet-lose lhc-sb-static_bonus">{{i.static_bonus}}</span>
                            <span class="sebo_nums" v-for="v in i.betclass_nums" :key="v.index">
                                <span v-if="i.name.substring(0,1)=='红'" class="sebo_nums_red">{{v}}</span>
                                <span v-if="i.name.substring(0,1)=='蓝'" class="sebo_nums_blue">{{v}}</span>
                                <span v-if="i.name.substring(0,1)=='绿'" class="sebo_nums_green">{{v}}</span>
                            </span>
                            <span v-show="i.bg=='#ffef3a'" class="select-icon"><Select style="width: 24px; height: 24px;" /></span>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                sebo: [
                    {
                        ballname: "色波",
                        betclass: [
                            { title: "色波", bg: '#F2F3F8', name: "红单", color: '#f56c6c', isSelected: false, static_bonus: "", val: "", subname: "1_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, betclass_nums: [1, 7, 13, 19, 23, 29, 35, 45] },
                            { title: "色波", bg: '#F2F3F8', name: "红双", color: '#f56c6c', isSelected: false, static_bonus: "", val: "", subname: "1_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, betclass_nums: [2, 8, 12, 18, 24, 30, 34, 40, 46] },
                            { title: "色波", bg: '#F2F3F8', name: "红大", color: '#f56c6c', isSelected: false, static_bonus: "", val: "", subname: "1_0_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, betclass_nums: [29, 30, 34, 35, 40, 45, 46] },
                            { title: "色波", bg: '#F2F3F8', name: "红小", color: '#f56c6c', isSelected: false, static_bonus: "", val: "", subname: "1_0_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, betclass_nums: [1, 2, 7, 8, 12, 13, 18, 19, 23, 24] },
                            { title: "色波", bg: '#F2F3F8', name: "蓝单", color: '#409eff', isSelected: false, static_bonus: "", val: "", subname: "1_0_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, betclass_nums: [3, 9, 15, 25, 31, 37, 41, 47] },
                            { title: "色波", bg: '#F2F3F8', name: "蓝双", color: '#409eff', isSelected: false, static_bonus: "", val: "", subname: "1_0_5", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, betclass_nums: [4, 10, 14, 20, 26, 36, 42, 48] },
                            { title: "色波", bg: '#F2F3F8', name: "蓝大", color: '#409eff', isSelected: false, static_bonus: "", val: "", subname: "1_0_6", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, betclass_nums: [25, 26, 31, 36, 37, 41, 42, 47, 48] },
                            { title: "色波", bg: '#F2F3F8', name: "蓝小", color: '#409eff', isSelected: false, static_bonus: "", val: "", subname: "1_0_7", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, betclass_nums: [3, 4, 9, 10, 14, 15, 20] },
                            { title: "色波", bg: '#F2F3F8', name: "绿单", color: '#67c23a', isSelected: false, static_bonus: "", val: "", subname: "1_0_8", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, betclass_nums: [5, 11, 17, 21, 27, 33, 39, 43] },
                            { title: "色波", bg: '#F2F3F8', name: "绿双", color: '#67c23a', isSelected: false, static_bonus: "", val: "", subname: "1_0_9", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, betclass_nums: [6, 16, 22, 28, 32, 38, 44] },
                            { title: "色波", bg: '#F2F3F8', name: "绿大", color: '#67c23a', isSelected: false, static_bonus: "", val: "", subname: "1_0_10", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, betclass_nums: [27, 28, 32, 33, 38, 39, 43, 44] },
                            { title: "色波", bg: '#F2F3F8', name: "绿小", color: '#67c23a', isSelected: false, static_bonus: "", val: "", subname: "1_0_11", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, betclass_nums: [5, 6, 11, 16, 17, 21, 22] },
                        ]
                    }
                ]
            }
        },
        methods: {
            //选取投注项
            click_item(item) {
                if (item.isSelected) {
                    item.isSelected = false;
                    item.bg = '#F2F3F8';
                } else {
                    item.bg = '#ffef3a';
                    item.isSelected = true;
                }
                return item
            },
            //快速下注
            fast_sub_bet() {
                let list = [];
                //色波
                for (let i = 0; i < this.sebo.length; i++) {
                    for (let j = 0; j < this.sebo[i].betclass.length; j++) {
                        if (this.sebo[i].betclass[j].isSelected) {
                            list.push(this.sebo[i].betclass[j]);
                        }
                    }
                }
                // console.log(list)
                return list;
            },
            /**显示赔率等数据 */
            get_lose() {
                let lose_data = this.$store.state.lose_data;
                //色波
                for (let j = 0; j < this.sebo[0].betclass.length; j++) {
                    lose_data.forEach(item => {
                        if (item.title == this.sebo[0].betclass[j].title && item.name == this.sebo[0].betclass[j].name) {
                            this.sebo[0].betclass[j].static_bonus = item.rate;
                            this.sebo[0].betclass[j].straightbet_high = item.other.straightbet_high;
                            this.sebo[0].betclass[j].straightbet_low = item.other.straightbet_low;
                        }
                    });
                }
            },
            /**检查是否满足快捷输入 */
            check_fast_val(val) {
                if (val) {
                    return val;
                }
                else {
                    val = this.$parent.get_fast();
                    return val;
                }
            },
            /**重填，重置 */
            reset() {
                //色波
                for (let i = 0; i < this.sebo.length; i++) {
                    for (let j = 0; j < this.sebo[i].betclass.length; j++) {
                        this.sebo[i].betclass[j].val = "";
                        this.sebo[i].betclass[j].isSelected = false;
                        this.sebo[i].betclass[j].bg = "#F2F3F8";
                    }
                }
            },
            /**提交，下注 */
            sub_bet() {
                let list = [];
                //色波
                for (let i = 0; i < this.sebo.length; i++) {
                    for (let j = 0; j < this.sebo[i].betclass.length; j++) {
                        if (this.sebo[i].betclass[j].isSelected) {
                            list.push(this.sebo[i].betclass[j]);
                        }
                    }
                }
                // console.log(list)
                return list;
            },
            /**重复上次下单 */
            repeat_last_bet(list) {
                // console.log(list)
                for (let i = 0; i < list.length; i++) {
                    //色波
                    for (let j = 0; j < this.sebo.length; j++) {
                        for (let k = 0; k < this.sebo[j].betclass.length; k++) {
                            if (this.sebo[j].betclass[k].title == list[i].title && this.sebo[j].betclass[k].name == list[i].name) {
                                this.sebo[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                }
            },
        },
    }
</script>

<style scoped>
    .sebo_nums_red,
    .sebo_nums_blue,
    .sebo_nums_green {
        float: right;
        width: 24px;
        height: 24px;
        line-height: 24px;
        border-radius: 50%;
        color: #fff;
        text-align: center;
        margin-top: 0;
        margin-right: 1px;
        font-size: 14px;
    }
    .sebo_nums_red {
        background-color: #f56c6c;
    }
    .sebo_nums_blue {
        background-color: #409eff;
    }
    .sebo_nums_green {
        background-color: #67c23a;
    }
    .sebo .bet-items-one {
        text-align: left;
    }

    .sebo .lhc-sb-static_bonus {
        float: left;
        margin: 0 3px;
    }
</style>
