<template>
    <div class="bjpk10-d1_d10">
        <!-- 1-10名 -->
        <el-row>
            <el-col :span="24" v-for="item in balls" :key="item.index">
                <div class="bet-title">
                    <span>{{item.ballname}}</span>
                </div>
                <el-row class="bet-items" :gutter="6">
                    <el-col :span="8" v-for="i in item.betclass" :key="i.index">
                        <div class="bet-items-info" :style="{backgroundColor:i.bg}" @click="click_item(i)">
                            <span class="bet-name pk10-nums" :style="{'padding-left':!isNaN(i.name)?'1rem':'0.3rem'}">
                                <span v-if="i.name==1" class="pk10_num1">1</span>
                                <span v-else-if="i.name==2" class="pk10_num2">2</span>
                                <span v-else-if="i.name==3" class="pk10_num3">3</span>
                                <span v-else-if="i.name==4" class="pk10_num4">4</span>
                                <span v-else-if="i.name==5" class="pk10_num5">5</span>
                                <span v-else-if="i.name==6" class="pk10_num6">6</span>
                                <span v-else-if="i.name==7" class="pk10_num7">7</span>
                                <span v-else-if="i.name==8" class="pk10_num8">8</span>
                                <span v-else-if="i.name==9" class="pk10_num9">9</span>
                                <span v-else-if="i.name==10" class="pk10_num10">10</span>
                                <span v-else style="color: #333;font-size:12px;text-shadow:none">{{i.name}}</span>
                            </span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <span v-show="i.bg=='#ffef3a'" class="select-icon"><Select style="width: 24px; height: 24px;" /></span>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <!-- 冠亚和 -->
        <el-row>
            <el-col :span="24" v-for="item in gy_count" :key="item.index">
                <div class="bet-title">
                    <span>{{item.ballname}}</span>
                </div>
                <el-row class="bet-items" :gutter="6">
                    <el-col :span="8" v-for="i in item.betclass" :key="i.index">
                        <div class="bet-items-info" :style="{backgroundColor:i.bg}" @click="click_item(i)">
                            <span class="bet-name" style="font-weight:bold">{{i.name}}</span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <span v-show="i.bg=='#ffef3a'" class="select-icon"><Select style="width: 24px; height: 24px;" /></span>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>

        <!-- 冠亚和大小单双 -->
        <el-row>
            <el-col :span="24" v-for="item in gy_count_size" :key="item.index">
                <div class="bet-title">
                    <span>{{item.ballname}}</span>
                </div>
                <el-row class="bet-items" :gutter="6">
                    <el-col :span="8" v-for="i in item.betclass" :key="i.index">
                        <div class="bet-items-info" :style="{backgroundColor:i.bg}" @click="click_item(i)">
                            <span class="bet-name">{{i.name}}</span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <span v-show="i.bg=='#ffef3a'" class="select-icon"><Select style="width: 24px; height: 24px;" /></span>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                // 1-10名
                balls: [
                    {
                        ballname: "冠军",
                        betclass: [
                            { title: "冠军", bg: '#F2F3F8', name: "01", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠军", bg: '#F2F3F8', name: "02", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠军", bg: '#F2F3F8', name: "03", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠军", bg: '#F2F3F8', name: "04", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠军", bg: '#F2F3F8', name: "05", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠军", bg: '#F2F3F8', name: "06", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠军", bg: '#F2F3F8', name: "07", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠军", bg: '#F2F3F8', name: "08", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠军", bg: '#F2F3F8', name: "09", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠军", bg: '#F2F3F8', name: "10", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠军", bg: '#F2F3F8', name: "大", isSelected: false, static_bonus: "", val: "", subname: "4_0_7", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠军", bg: '#F2F3F8', name: "小", isSelected: false, static_bonus: "", val: "", subname: "4_0_8", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠军", bg: '#F2F3F8', name: "单", isSelected: false, static_bonus: "", val: "", subname: "4_0_9", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠军", bg: '#F2F3F8', name: "双", isSelected: false, static_bonus: "", val: "", subname: "4_0_10", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠军", bg: '#F2F3F8', name: "龙", isSelected: false, static_bonus: "", val: "", subname: "4_0_11", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠军", bg: '#F2F3F8', name: "虎", isSelected: false, static_bonus: "", val: "", subname: "4_0_12", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    },
                    {
                        ballname: "亚军",
                        betclass: [
                            { title: "亚军", bg: '#F2F3F8', name: "01", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "亚军", bg: '#F2F3F8', name: "02", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "亚军", bg: '#F2F3F8', name: "03", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "亚军", bg: '#F2F3F8', name: "04", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "亚军", bg: '#F2F3F8', name: "05", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "亚军", bg: '#F2F3F8', name: "06", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "亚军", bg: '#F2F3F8', name: "07", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "亚军", bg: '#F2F3F8', name: "08", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "亚军", bg: '#F2F3F8', name: "09", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "亚军", bg: '#F2F3F8', name: "10", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "亚军", bg: '#F2F3F8', name: "大", isSelected: false, static_bonus: "", val: "", subname: "4_0_13", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "亚军", bg: '#F2F3F8', name: "小", isSelected: false, static_bonus: "", val: "", subname: "4_0_14", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "亚军", bg: '#F2F3F8', name: "单", isSelected: false, static_bonus: "", val: "", subname: "4_0_15", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "亚军", bg: '#F2F3F8', name: "双", isSelected: false, static_bonus: "", val: "", subname: "4_0_16", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "亚军", bg: '#F2F3F8', name: "龙", isSelected: false, static_bonus: "", val: "", subname: "4_0_17", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "亚军", bg: '#F2F3F8', name: "虎", isSelected: false, static_bonus: "", val: "", subname: "4_0_18", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    },
                    {
                        ballname: "第三名",
                        betclass: [
                            { title: "第三名", bg: '#F2F3F8', name: "01", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三名", bg: '#F2F3F8', name: "02", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三名", bg: '#F2F3F8', name: "03", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三名", bg: '#F2F3F8', name: "04", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三名", bg: '#F2F3F8', name: "05", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三名", bg: '#F2F3F8', name: "06", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三名", bg: '#F2F3F8', name: "07", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三名", bg: '#F2F3F8', name: "08", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三名", bg: '#F2F3F8', name: "09", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三名", bg: '#F2F3F8', name: "10", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三名", bg: '#F2F3F8', name: "大", isSelected: false, static_bonus: "", val: "", subname: "4_0_19", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三名", bg: '#F2F3F8', name: "小", isSelected: false, static_bonus: "", val: "", subname: "4_0_20", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三名", bg: '#F2F3F8', name: "单", isSelected: false, static_bonus: "", val: "", subname: "4_0_21", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三名", bg: '#F2F3F8', name: "双", isSelected: false, static_bonus: "", val: "", subname: "4_0_22", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三名", bg: '#F2F3F8', name: "龙", isSelected: false, static_bonus: "", val: "", subname: "4_0_23", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三名", bg: '#F2F3F8', name: "虎", isSelected: false, static_bonus: "", val: "", subname: "4_0_24", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    },
                    {
                        ballname: "第四名",
                        betclass: [
                            { title: "第四名", bg: '#F2F3F8', name: "01", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第四名", bg: '#F2F3F8', name: "02", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第四名", bg: '#F2F3F8', name: "03", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第四名", bg: '#F2F3F8', name: "04", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第四名", bg: '#F2F3F8', name: "05", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第四名", bg: '#F2F3F8', name: "06", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第四名", bg: '#F2F3F8', name: "07", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第四名", bg: '#F2F3F8', name: "08", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第四名", bg: '#F2F3F8', name: "09", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第四名", bg: '#F2F3F8', name: "10", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第四名", bg: '#F2F3F8', name: "大", isSelected: false, static_bonus: "", val: "", subname: "4_0_25", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第四名", bg: '#F2F3F8', name: "小", isSelected: false, static_bonus: "", val: "", subname: "4_0_26", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第四名", bg: '#F2F3F8', name: "单", isSelected: false, static_bonus: "", val: "", subname: "4_0_27", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第四名", bg: '#F2F3F8', name: "双", isSelected: false, static_bonus: "", val: "", subname: "4_0_28", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第四名", bg: '#F2F3F8', name: "龙", isSelected: false, static_bonus: "", val: "", subname: "4_0_29", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第四名", bg: '#F2F3F8', name: "虎", isSelected: false, static_bonus: "", val: "", subname: "4_0_30", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    },
                    {
                        ballname: "第五名",
                        betclass: [
                            { title: "第五名", bg: '#F2F3F8', name: "01", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第五名", bg: '#F2F3F8', name: "02", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第五名", bg: '#F2F3F8', name: "03", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第五名", bg: '#F2F3F8', name: "04", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第五名", bg: '#F2F3F8', name: "05", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第五名", bg: '#F2F3F8', name: "06", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第五名", bg: '#F2F3F8', name: "07", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第五名", bg: '#F2F3F8', name: "08", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第五名", bg: '#F2F3F8', name: "09", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第五名", bg: '#F2F3F8', name: "10", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第五名", bg: '#F2F3F8', name: "大", isSelected: false, static_bonus: "", val: "", subname: "4_0_31", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第五名", bg: '#F2F3F8', name: "小", isSelected: false, static_bonus: "", val: "", subname: "4_0_32", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第五名", bg: '#F2F3F8', name: "单", isSelected: false, static_bonus: "", val: "", subname: "4_0_33", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第五名", bg: '#F2F3F8', name: "双", isSelected: false, static_bonus: "", val: "", subname: "4_0_34", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第五名", bg: '#F2F3F8', name: "龙", isSelected: false, static_bonus: "", val: "", subname: "4_0_35", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第五名", bg: '#F2F3F8', name: "虎", isSelected: false, static_bonus: "", val: "", subname: "4_0_36", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    },
                    {
                        ballname: "第六名",
                        betclass: [
                            { title: "第六名", bg: '#F2F3F8', name: "01", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第六名", bg: '#F2F3F8', name: "02", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第六名", bg: '#F2F3F8', name: "03", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第六名", bg: '#F2F3F8', name: "04", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第六名", bg: '#F2F3F8', name: "05", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第六名", bg: '#F2F3F8', name: "06", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第六名", bg: '#F2F3F8', name: "07", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第六名", bg: '#F2F3F8', name: "08", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第六名", bg: '#F2F3F8', name: "09", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第六名", bg: '#F2F3F8', name: "10", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第六名", bg: '#F2F3F8', name: "大", isSelected: false, static_bonus: "", val: "", subname: "4_0_37", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第六名", bg: '#F2F3F8', name: "小", isSelected: false, static_bonus: "", val: "", subname: "4_0_38", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第六名", bg: '#F2F3F8', name: "单", isSelected: false, static_bonus: "", val: "", subname: "4_0_39", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第六名", bg: '#F2F3F8', name: "双", isSelected: false, static_bonus: "", val: "", subname: "4_0_40", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    },
                    {
                        ballname: "第七名",
                        betclass: [
                            { title: "第七名", bg: '#F2F3F8', name: "01", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第七名", bg: '#F2F3F8', name: "02", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第七名", bg: '#F2F3F8', name: "03", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第七名", bg: '#F2F3F8', name: "04", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第七名", bg: '#F2F3F8', name: "05", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第七名", bg: '#F2F3F8', name: "06", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第七名", bg: '#F2F3F8', name: "07", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第七名", bg: '#F2F3F8', name: "08", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第七名", bg: '#F2F3F8', name: "09", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第七名", bg: '#F2F3F8', name: "10", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第七名", bg: '#F2F3F8', name: "大", isSelected: false, static_bonus: "", val: "", subname: "4_0_41", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第七名", bg: '#F2F3F8', name: "小", isSelected: false, static_bonus: "", val: "", subname: "4_0_42", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第七名", bg: '#F2F3F8', name: "单", isSelected: false, static_bonus: "", val: "", subname: "4_0_43", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第七名", bg: '#F2F3F8', name: "双", isSelected: false, static_bonus: "", val: "", subname: "4_0_44", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    },
                    {
                        ballname: "第八名",
                        betclass: [
                            { title: "第八名", bg: '#F2F3F8', name: "01", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第八名", bg: '#F2F3F8', name: "02", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第八名", bg: '#F2F3F8', name: "03", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第八名", bg: '#F2F3F8', name: "04", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第八名", bg: '#F2F3F8', name: "05", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第八名", bg: '#F2F3F8', name: "06", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第八名", bg: '#F2F3F8', name: "07", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第八名", bg: '#F2F3F8', name: "08", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第八名", bg: '#F2F3F8', name: "09", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第八名", bg: '#F2F3F8', name: "10", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第八名", bg: '#F2F3F8', name: "大", isSelected: false, static_bonus: "", val: "", subname: "4_0_45", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第八名", bg: '#F2F3F8', name: "小", isSelected: false, static_bonus: "", val: "", subname: "4_0_46", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第八名", bg: '#F2F3F8', name: "单", isSelected: false, static_bonus: "", val: "", subname: "4_0_47", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第八名", bg: '#F2F3F8', name: "双", isSelected: false, static_bonus: "", val: "", subname: "4_0_48", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    },
                    {
                        ballname: "第九名",
                        betclass: [
                            { title: "第九名", bg: '#F2F3F8', name: "01", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第九名", bg: '#F2F3F8', name: "02", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第九名", bg: '#F2F3F8', name: "03", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第九名", bg: '#F2F3F8', name: "04", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第九名", bg: '#F2F3F8', name: "05", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第九名", bg: '#F2F3F8', name: "06", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第九名", bg: '#F2F3F8', name: "07", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第九名", bg: '#F2F3F8', name: "08", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第九名", bg: '#F2F3F8', name: "09", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第九名", bg: '#F2F3F8', name: "10", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第九名", bg: '#F2F3F8', name: "大", isSelected: false, static_bonus: "", val: "", subname: "4_0_49", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第九名", bg: '#F2F3F8', name: "小", isSelected: false, static_bonus: "", val: "", subname: "4_0_50", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第九名", bg: '#F2F3F8', name: "单", isSelected: false, static_bonus: "", val: "", subname: "4_0_51", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第九名", bg: '#F2F3F8', name: "双", isSelected: false, static_bonus: "", val: "", subname: "4_0_52", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    },
                    {
                        ballname: "第十名",
                        betclass: [
                            { title: "第十名", bg: '#F2F3F8', name: "01", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第十名", bg: '#F2F3F8', name: "02", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第十名", bg: '#F2F3F8', name: "03", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第十名", bg: '#F2F3F8', name: "04", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第十名", bg: '#F2F3F8', name: "05", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第十名", bg: '#F2F3F8', name: "06", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第十名", bg: '#F2F3F8', name: "07", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第十名", bg: '#F2F3F8', name: "08", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第十名", bg: '#F2F3F8', name: "09", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第十名", bg: '#F2F3F8', name: "10", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第十名", bg: '#F2F3F8', name: "大", isSelected: false, static_bonus: "", val: "", subname: "4_0_53", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第十名", bg: '#F2F3F8', name: "小", isSelected: false, static_bonus: "", val: "", subname: "4_0_54", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第十名", bg: '#F2F3F8', name: "单", isSelected: false, static_bonus: "", val: "", subname: "4_0_55", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第十名", bg: '#F2F3F8', name: "双", isSelected: false, static_bonus: "", val: "", subname: "4_0_56", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    },
                ],
                /**冠亚总和值*/
                gy_count: [
                    {
                        ballname: "冠亚和值",
                        betclass: [
                            { title: "冠亚和值", bg: '#F2F3F8', name: "3", isSelected: false, static_bonus: "", val: "", subname: "4_3_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠亚和值", bg: '#F2F3F8', name: "4", isSelected: false, static_bonus: "", val: "", subname: "4_3_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠亚和值", bg: '#F2F3F8', name: "5", isSelected: false, static_bonus: "", val: "", subname: "4_3_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠亚和值", bg: '#F2F3F8', name: "6", isSelected: false, static_bonus: "", val: "", subname: "4_3_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠亚和值", bg: '#F2F3F8', name: "7", isSelected: false, static_bonus: "", val: "", subname: "4_3_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠亚和值", bg: '#F2F3F8', name: "8", isSelected: false, static_bonus: "", val: "", subname: "4_3_5", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠亚和值", bg: '#F2F3F8', name: "9", isSelected: false, static_bonus: "", val: "", subname: "4_3_6", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠亚和值", bg: '#F2F3F8', name: "10", isSelected: false, static_bonus: "", val: "", subname: "4_3_7", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠亚和值", bg: '#F2F3F8', name: "11", isSelected: false, static_bonus: "", val: "", subname: "4_3_8", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠亚和值", bg: '#F2F3F8', name: "12", isSelected: false, static_bonus: "", val: "", subname: "4_3_9", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠亚和值", bg: '#F2F3F8', name: "13", isSelected: false, static_bonus: "", val: "", subname: "4_3_10", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠亚和值", bg: '#F2F3F8', name: "14", isSelected: false, static_bonus: "", val: "", subname: "4_3_11", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠亚和值", bg: '#F2F3F8', name: "15", isSelected: false, static_bonus: "", val: "", subname: "4_3_12", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠亚和值", bg: '#F2F3F8', name: "16", isSelected: false, static_bonus: "", val: "", subname: "4_3_13", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠亚和值", bg: '#F2F3F8', name: "17", isSelected: false, static_bonus: "", val: "", subname: "4_3_14", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠亚和值", bg: '#F2F3F8', name: "18", isSelected: false, static_bonus: "", val: "", subname: "4_3_15", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠亚和值", bg: '#F2F3F8', name: "19", isSelected: false, static_bonus: "", val: "", subname: "4_3_16", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]

                    }
                ],
                /**冠亚总和值大小，单双*/
                gy_count_size: [
                    {
                        ballname: "冠亚和值",
                        betclass: [
                            { title: "冠亚和值", bg: '#F2F3F8', name: "大", isSelected: false, static_bonus: "", val: "", subname: "4_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠亚和值", bg: '#F2F3F8', name: "小", isSelected: false, static_bonus: "", val: "", subname: "4_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠亚和值", bg: '#F2F3F8', name: "单", isSelected: false, static_bonus: "", val: "", subname: "4_0_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠亚和值", bg: '#F2F3F8', name: "双", isSelected: false, static_bonus: "", val: "", subname: "4_0_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false }
                        ]
                    }
                ],
            }
        },
        methods: {
            //选取投注项
            click_item(item) {
                if (item.isSelected) {
                    item.isSelected = false;
                    item.bg = '#F2F3F8';
                } else {
                    item.bg = '#ffef3a';
                    item.isSelected = true;
                }
                return item
            },
            //快速下注
            fast_sub_bet() {
                let list = [];
                //1-10
                for (let i = 0; i < this.balls.length; i++) {
                    for (let j = 0; j < this.balls[i].betclass.length; j++) {
                        if (this.balls[i].betclass[j].isSelected) {
                            list.push(this.balls[i].betclass[j]);
                        }
                    }
                }
                //冠亚总和值
                for (let i = 0; i < this.gy_count.length; i++) {
                    for (let j = 0; j < this.gy_count[i].betclass.length; j++) {
                        if (this.gy_count[i].betclass[j].isSelected) {
                            list.push(this.gy_count[i].betclass[j]);
                        }
                    }
                }
                //冠亚总和值大小，单双
                for (let i = 0; i < this.gy_count_size.length; i++) {
                    for (let j = 0; j < this.gy_count_size[i].betclass.length; j++) {
                        if (this.gy_count_size[i].betclass[j].isSelected) {
                            list.push(this.gy_count_size[i].betclass[j]);
                        }
                    }
                }
                // console.log(list)
                return list;
            },
            /**显示赔率等数据 */
            get_lose() {
                let lose_data = this.$store.state.lose_data;
                for (let i = 0; i < this.balls.length; i++) {
                    for (let j = 0; j < this.balls[i].betclass.length; j++) {
                        lose_data.forEach(item => {
                            if (item.title == this.balls[i].betclass[j].title && item.name == this.balls[i].betclass[j].name) {
                                this.balls[i].betclass[j].static_bonus = item.rate;
                                this.balls[i].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.balls[i].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                }
                //冠亚总和值
                for (let i = 0; i < this.gy_count.length; i++) {
                    for (let j = 0; j < this.gy_count[i].betclass.length; j++) {
                        lose_data.forEach(item => {
                            if (item.title == this.gy_count[i].betclass[j].title && item.name == this.gy_count[i].betclass[j].name) {
                                this.gy_count[i].betclass[j].static_bonus = item.rate;
                                this.gy_count[i].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.gy_count[i].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                }
                //冠亚总和值大小，单双
                for (let i = 0; i < this.gy_count_size.length; i++) {
                    for (let j = 0; j < this.gy_count_size[i].betclass.length; j++) {
                        lose_data.forEach(item => {
                            if (item.title == this.gy_count_size[i].betclass[j].title && item.name == this.gy_count_size[i].betclass[j].name) {
                                this.gy_count_size[i].betclass[j].static_bonus = item.rate;
                                this.gy_count_size[i].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.gy_count_size[i].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                }
            },
            /**检查是否满足快捷输入 */
            check_fast_val(val) {
                if (val) {
                    return val;
                }
                else {
                    val = this.$parent.get_fast();
                    return val;
                }
            },
            /**重填，重置 */
            reset() {
                //1-10
                for (let i = 0; i < this.balls.length; i++) {
                    for (let j = 0; j < this.balls[i].betclass.length; j++) {
                        this.balls[i].betclass[j].val = "";
                        this.balls[i].betclass[j].isSelected = false;
                        this.balls[i].betclass[j].bg = "#F2F3F8";
                    }
                }
                //冠亚总和值
                for (let i = 0; i < this.gy_count.length; i++) {
                    for (let j = 0; j < this.gy_count[i].betclass.length; j++) {
                        this.gy_count[i].betclass[j].val = "";
                        this.gy_count[i].betclass[j].isSelected = false;
                        this.gy_count[i].betclass[j].bg = "#F2F3F8";
                    }
                }
                //冠亚总和值大小，单双
                for (let i = 0; i < this.gy_count_size.length; i++) {
                    for (let j = 0; j < this.gy_count_size[i].betclass.length; j++) {
                        this.gy_count_size[i].betclass[j].val = "";
                        this.gy_count_size[i].betclass[j].isSelected = false;
                        this.gy_count_size[i].betclass[j].bg = "#F2F3F8";
                    }
                }
            },
            /**提交，下注 */
            sub_bet() {
                let list = [];
                //1-10
                for (let i = 0; i < this.balls.length; i++) {
                    for (let j = 0; j < this.balls[i].betclass.length; j++) {
                        if (this.balls[i].betclass[j].isSelected) {
                            list.push(this.balls[i].betclass[j]);
                        }
                    }
                }
                //冠亚总和值
                for (let i = 0; i < this.gy_count.length; i++) {
                    for (let j = 0; j < this.gy_count[i].betclass.length; j++) {
                        if (this.gy_count[i].betclass[j].isSelected) {
                            list.push(this.gy_count[i].betclass[j]);
                        }
                    }
                }
                //冠亚总和值大小，单双
                for (let i = 0; i < this.gy_count_size.length; i++) {
                    for (let j = 0; j < this.gy_count_size[i].betclass.length; j++) {
                        if (this.gy_count_size[i].betclass[j].isSelected) {
                            list.push(this.gy_count_size[i].betclass[j]);
                        }
                    }
                }
                // console.log(list)
                return list;
            },
            /**重复上次下单 */
            repeat_last_bet(list) {
                // console.log(list)
                for (let i = 0; i < list.length; i++) {
                    //1-5
                    for (let j = 0; j < this.balls.length; j++) {
                        for (let k = 0; k < this.balls[j].betclass.length; k++) {
                            if (this.balls[j].betclass[k].title == list[i].title && this.balls[j].betclass[k].name == list[i].name) {
                                this.balls[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                }
                //冠亚总和值
                for (let j = 0; j < this.gy_count.length; j++) {
                    for (let k = 0; k < this.gy_count[j].betclass.length; k++) {
                        if (this.gy_count[j].betclass[k].title == list[i].title && this.gy_count[j].betclass[k].name == list[i].name) {
                            this.gy_count[j].betclass[k].val = list[i].val;
                        }
                    }
                }
                //冠亚总和值大小，单双
                for (let j = 0; j < this.gy_count_size.length; j++) {
                    for (let k = 0; k < this.gy_count_size[j].betclass.length; k++) {
                        if (this.gy_count_size[j].betclass[k].title == list[i].title && this.gy_count_size[j].betclass[k].name == list[i].name) {
                            this.gy_count_size[j].betclass[k].val = list[i].val;
                        }
                    }
                }
            },
        },
    }
</script>

<style scoped>
</style>

