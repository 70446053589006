<!--
 * @Author: your name
 * @Date: 2021-04-26 15:04:00
 * @LastEditTime: 2022-09-04 01:32:02
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: In User Settings Edit
 * @FilePath: \luck\src\components\betPage\k3\ebth.vue
-->
<template>
    <div class="ebth">
        <el-row style="width:100vw">
            <el-col :span="24" v-for="item in ebth" :key="item.index">
                <div class="bet-title">
                    <span>{{item.ballname}}</span>
                </div>
                <el-row class="bet-items" :gutter="6">
                    <el-col :span="8" v-for="i in item.betclass" :key="i.index">
                        <div class="bet-items-info" :style="{backgroundColor:i.bg}" @click="click_item(i)">
                            <span class="bet-name">{{i.name}}</span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <span v-show="i.bg=='#ffef3a'" class="select-icon"><Select style="width: 24px; height: 24px;" /></span>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                ebth: [
                    {
                        ballname: "二不同号",
                        betclass: [
                            { title: "二不同号", name: "12", isSelected: false, static_bonus: "", val: '', disabled: false, subname: "7_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, bg: '#F2F3F8', },
                            { title: "二不同号", name: "13", isSelected: false, static_bonus: "", val: '', disabled: false, subname: "7_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, bg: '#F2F3F8', },
                            { title: "二不同号", name: "14", isSelected: false, static_bonus: "", val: '', disabled: false, subname: "7_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, bg: '#F2F3F8', },
                            { title: "二不同号", name: "15", isSelected: false, static_bonus: "", val: '', disabled: false, subname: "7_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, bg: '#F2F3F8', },
                            { title: "二不同号", name: "16", isSelected: false, static_bonus: "", val: '', disabled: false, subname: "7_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, bg: '#F2F3F8', },
                            { title: "二不同号", name: "23", isSelected: false, static_bonus: "", val: '', disabled: false, subname: "7_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, bg: '#F2F3F8', },
                            { title: "二不同号", name: "24", isSelected: false, static_bonus: "", val: '', disabled: false, subname: "7_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, bg: '#F2F3F8', },
                            { title: "二不同号", name: "25", isSelected: false, static_bonus: "", val: '', disabled: false, subname: "7_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, bg: '#F2F3F8', },
                            { title: "二不同号", name: "26", isSelected: false, static_bonus: "", val: '', disabled: false, subname: "7_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, bg: '#F2F3F8', },
                            { title: "二不同号", name: "34", isSelected: false, static_bonus: "", val: '', disabled: false, subname: "7_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, bg: '#F2F3F8', },
                            { title: "二不同号", name: "35", isSelected: false, static_bonus: "", val: '', disabled: false, subname: "7_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, bg: '#F2F3F8', },
                            { title: "二不同号", name: "36", isSelected: false, static_bonus: "", val: '', disabled: false, subname: "7_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, bg: '#F2F3F8', },
                            { title: "二不同号", name: "45", isSelected: false, static_bonus: "", val: '', disabled: false, subname: "7_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, bg: '#F2F3F8', },
                            { title: "二不同号", name: "46", isSelected: false, static_bonus: "", val: '', disabled: false, subname: "7_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, bg: '#F2F3F8', },
                            { title: "二不同号", name: "56", isSelected: false, static_bonus: "", val: '', disabled: false, subname: "7_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, bg: '#F2F3F8', },
                        ]
                    }
                ],
            }
        },
        methods: {
            //快速下注
            fast_sub_bet() {
                return this.sub_bet();
            },
            //选取投注项
            click_item(item) {
                if (item.isSelected) {
                    item.isSelected = false;
                    item.bg = '#F2F3F8';
                } else {
                    item.bg = '#ffef3a';
                    item.isSelected = true;
                }
                return item
            },
            /**显示赔率等数据 */
            get_lose() {
                let lose_data = this.$store.state.lose_data;
                lose_data.forEach(item => {
                    item.subname = `${item.level_two}_${item.level_three}_${item.level_four}`
                })
                for (let i = 0; i < this.ebth.length; i++) {
                    for (let j = 0; j < this.ebth[i].betclass.length; j++) {
                        lose_data.forEach(item => {
                            if (item.subname == this.ebth[i].betclass[j].subname) {
                                this.ebth[i].betclass[j].static_bonus = item.rate;
                                this.ebth[i].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.ebth[i].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                }
            },
            /**检查是否满足快捷输入 */
            check_fast_val(val) {
                if (val) {
                    return val;
                }
                else {
                    val = this.$parent.get_fast();
                    return val;
                }
            },
            /**重填，重置 */
            reset() {
                for (let i = 0; i < this.ebth[0].betclass.length; i++) {
                    this.ebth[0].betclass[i].val = '';
                    this.ebth[0].betclass[i].isSelected = false;
                    this.ebth[0].betclass[i].bg = "#F2F3F8";
                }
            },
            /**提交，下注 */
            sub_bet() {
                let list = [];
                for (let i = 0; i < this.ebth.length; i++) {
                    for (let j = 0; j < this.ebth[i].betclass.length; j++) {
                        if (this.ebth[i].betclass[j].isSelected) {
                            this.ebth[i].betclass[j].bet_count = 1;
                            list.push(this.ebth[i].betclass[j]);
                        }
                    }
                }
                return list;
            },
            /**重复上次下单 */
            repeat_last_bet(list) {
                // console.log(list)
                for (let i = 0; i < list.length; i++) {
                    for (let j = 0; j < this.ebth.length; j++) {
                        for (let k = 0; k < this.ebth[j].betclass.length; k++) {
                            if (this.ebth[j].betclass[k].title == list[i].title && this.ebth[j].betclass[k].name == list[i].name) {
                                this.ebth[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                }
            },
        },
    }
</script>