<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-08-19 16:25:04
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-09-16 23:50:28
 * @FilePath: \mobile_vue3\src\components\menuitems\gameLobby.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AEe
-->
<template>
    <div class="gameLobby">
        <div class="ui-panel-wrapper">
            <div class="pm ui-content" style="padding-bottom: 0px;">
                <div class="topBox theme-bg-half">
                    <div class="box">
                        <a @click="get_info">
                            <div class="ico1">
                                <img src="../../assets/ico4.png" />
                            </div>
                            <div class="title">信用資料</div>
                        </a>
                    </div>
                    <div class="box">
                        <a @click="go_betinfo">
                            <div class="ico2">
                                <img src="../../assets/ico5.png" />
                            </div>
                            <div class="title">下注明細</div>
                        </a>
                    </div>
                    <div class="box">
                        <a @click="get_report">
                            <div class="ico3">
                                <img src="../../assets/ico6.png" />
                            </div>
                            <div class="title">結算報表</div>
                        </a>
                    </div>
                </div>
                <div class="gamelist">
                    <ul>
                        <li v-for="game in game_list" :key="game.id" @click="change_game(game.id,game.gname)">
                            <div class="pic">
                                <img :src="game.img" width="74" height="74" />
                            </div>
                            <div class="box">
                                <div class="title">{{game.gname}}</div>
                                <p data-game-id="38">
                                    <span v-if="game.qnum_time>0&&game.id!=34&&game.id!=4&&game.id!=10">
                                        <span v-if="game.qnum_time%60!==0">{{game.qnum_time}}秒一期</span>
                                        <span v-else>{{game.qnum_time/60}}分钟一期</span>
                                    </span>
                                    <span v-else-if="game.id==34||game.id==4||game.id==10">一天一期</span>
                                    <span v-else>
                                        <span v-if="game.qnum_time%60!==0">{{game.qnum_time}}秒一期</span>
                                        <span>{{game.qnum_time/60}}分钟一期</span>
                                    </span>
                                    <br />
                                    <span>已开{{game.is_open}}期，还剩{{game.not_open}}期</span>
                                    <br />
                                    每天07:00-次日06:00
                                </p>
                            </div>
                            <div class="arr"></div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                game_list: [],
            }
        },
        created() {
            let list = this.$store.state.game_list;
            this.game_list = list;
            for (let i = 0; i < this.game_list.length; i++) {
                this.game_list[i].img = require(`../../assets/${this.game_list[i].gname}.png`);
            }
            
        },
        methods: {
            change_game(gid = '', gname = '') {
                this.$emit('change_game', gid, gname);
            },
            get_info() {
                this.$emit('get_info')
            },
            go_betinfo() {
                this.$emit('go_betinfo')
            },
            get_report() {
                this.$emit('get_report')
            },
        },
    }
</script>

<style scoped>
    .ui-panel-wrapper {
        background-color: #eceff0;
        color: #333;
    }
    .pm {
        padding: 0;
        margin: 0;
        position: relative;
    }

    .ui-content {
        border-width: 0;
        overflow: visible;
        overflow-x: hidden;
        padding: 0;
    }
    .topBox {
        width: 100%;
        float: left;
        height: 112px;
        padding: 18px 0 2px;
        margin-bottom: 8px;
    }
    .topBox .box {
        width: 33.3%;
        float: left;
        text-align: center;
    }
    .ico1,
    .ico2,
    .ico3 {
        width: 80px;
        height: 80px;
        margin: 0 auto;
    }

    img {
        border: none;
        padding: 0;
        margin: 0;
        height: 80px;
    }
    .topBox .box .title {
        width: 100%;
        height: 24px;
        line-height: 24px;
        text-align: center;
        margin: 0 auto;
        color: #fff;
        font-weight: bold;
        text-shadow: 1px 1px #666;
        font-size: 16px;
    }
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: inline;
    }
    li {
        width: 100%;
        float: left;
        margin-bottom: 5px;
        color: #333;
        background: #fff;
        border-top: 1px solid #dcdcdc;
        border-bottom: 1px solid #dcdcdc;
    }
    .pic {
        width: 74px;
        height: 74px;
        margin: 5px 10px 5px 5px;
        float: left;
        -moz-border-radius: 74px;
        -webkit-border-radius: 74px;
        border-radius: 74px;
    }
    li .box {
        width: 64%;
        float: left;
        margin-top: 10px;
    }
    li .box .title {
        width: 100%;
        font-size: 18px;
        font-weight: 700;
    }
    li .box p {
        font-size: 12px;
        padding: 0;
        margin: 0;
        line-height: 14px;
        color: #888;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    li .arr {
        width: 13px;
        height: 23px;
        float: right;
        margin-right: 10px;
        background: url("../../assets/arr1.png") no-repeat;
        margin-top: 30px;
    }
</style>