<template>
    <div class="yxiao">
        <el-row :gutter="1">
            <el-col :span="24" v-for="item in yxiao" :key="item.index">
                <div class="bet-title">
                    <span>{{item.ballname}}</span>
                </div>
                <el-row class="bet-items" :gutter="6">
                    <el-col :span="24" v-for="i in item.betclass" :key="i.index">
                        <div class="bet-items-info" :style="{backgroundColor:i.bg}" @click="click_item(i)">
                            <span class="bet-name">{{i.name}}</span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <span class="betclassname_yxiao" v-for="v in i.betclass_nums" :key="v.index">
                                <span v-if="v.color=='red'" class="yxiao_nums_red">{{v.num}}</span>
                                <span v-if="v.color=='blue'" class="yxiao_nums_blue">{{v.num}}</span>
                                <span v-if="v.color=='green'" class="yxiao_nums_green">{{v.num}}</span>
                            </span>
                            <span v-show="i.bg=='#ffef3a'" class="select-icon"><Select style="width: 24px; height: 24px;" /></span>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="24" v-for="item in yxiaobz" :key="item.index">
                <div class="bet-title">
                    <span>{{item.ballname}}</span>
                </div>
                <el-row class="bet-items" :gutter="6">
                    <el-col :span="24" v-for="i in item.betclass" :key="i.index">
                        <div class="bet-items-info" :style="{backgroundColor:i.bg}" @click="click_item(i)">
                            <span class="bet-name">{{i.name}}</span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <span class="betclassname_yxiao" v-for="v in i.betclass_nums" :key="v.index">
                                <span v-if="v.color=='red'" class="yxiao_nums_red">{{v.num}}</span>
                                <span v-if="v.color=='blue'" class="yxiao_nums_blue">{{v.num}}</span>
                                <span v-if="v.color=='green'" class="yxiao_nums_green">{{v.num}}</span>
                            </span>
                            <span v-show="i.bg=='#ffef3a'" class="select-icon"><Select style="width: 24px; height: 24px;" /></span>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="24" v-for="item in weishu" :key="item.index">
                <div class="bet-title">
                    <span>{{item.ballname}}</span>
                </div>
                <el-row class="bet-items" :gutter="6">
                    <el-col :span="24" v-for="i in item.betclass" :key="i.index">
                        <div class="bet-items-info" :style="{backgroundColor:i.bg}" @click="click_item(i)">
                            <span class="bet-name">{{i.name}}</span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <span class="betclassname_yxiao" v-for="v in i.betclass_nums" :key="v.index">
                                <span v-if="v.color=='red'" class="yxiao_nums_red">{{v.num}}</span>
                                <span v-if="v.color=='blue'" class="yxiao_nums_blue">{{v.num}}</span>
                                <span v-if="v.color=='green'" class="yxiao_nums_green">{{v.num}}</span>
                            </span>
                            <span v-show="i.bg=='#ffef3a'" class="select-icon"><Select style="width: 24px; height: 24px;" /></span>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import { get_lhc_sx } from "../../../plugin/sx";
    export default {
        data() {
            return {
                yxiao: [
                    {
                        ballname: "一肖",
                        betclass: [
                            { title: "一肖", bg: '#F2F3F8', name: "", isSelected: false, static_bonus: "", val: "", subname: "6_0_10", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, betclass_nums: [{ num: 3, color: "blue" }, { num: 15, color: "blue" }, { num: 27, color: "green" }, { num: 39, color: "green" }] },
                            { title: "一肖", bg: '#F2F3F8', name: "", isSelected: false, static_bonus: "", val: "", subname: "6_0_11", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, betclass_nums: [{ num: 2, color: "red" }, { num: 14, color: "blue" }, { num: 26, color: "blue" }, { num: 38, color: "green" }] },
                            { title: "一肖", bg: '#F2F3F8', name: "", isSelected: false, static_bonus: "", val: "", subname: "6_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, betclass_nums: [{ num: 1, color: "red" }, { num: 13, color: "red" }, { num: 25, color: "blue" }, { num: 37, color: "blue" }, { num: 49, color: "green" }] },
                            { title: "一肖", bg: '#F2F3F8', name: "", isSelected: false, static_bonus: "", val: "", subname: "6_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, betclass_nums: [{ num: 12, color: "red" }, { num: 24, color: "red" }, { num: 36, color: "blue" }, { num: 48, color: "blue" }] },
                            { title: "一肖", bg: '#F2F3F8', name: "", isSelected: false, static_bonus: "", val: "", subname: "6_0_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, betclass_nums: [{ num: 11, color: "green" }, { num: 23, color: "red" }, { num: 35, color: "red" }, { num: 47, color: "blue" }] },
                            { title: "一肖", bg: '#F2F3F8', name: "", isSelected: false, static_bonus: "", val: "", subname: "6_0_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, betclass_nums: [{ num: 10, color: "blue" }, { num: 22, color: "green" }, { num: 34, color: "red" }, { num: 46, color: "red" }] },
                            { title: "一肖", bg: '#F2F3F8', name: "", isSelected: false, static_bonus: "", val: "", subname: "6_0_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, betclass_nums: [{ num: 9, color: "blue" }, { num: 21, color: "green" }, { num: 33, color: "green" }, { num: 45, color: "red" }] },
                            { title: "一肖", bg: '#F2F3F8', name: "", isSelected: false, static_bonus: "", val: "", subname: "6_0_5", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, betclass_nums: [{ num: 8, color: "red" }, { num: 20, color: "blue" }, { num: 32, color: "green" }, { num: 44, color: "green" }] },
                            { title: "一肖", bg: '#F2F3F8', name: "", isSelected: false, static_bonus: "", val: "", subname: "6_0_6", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, betclass_nums: [{ num: 7, color: "red" }, { num: 19, color: "red" }, { num: 31, color: "blue" }, { num: 43, color: "green" }] },
                            { title: "一肖", bg: '#F2F3F8', name: "", isSelected: false, static_bonus: "", val: "", subname: "6_0_7", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, betclass_nums: [{ num: 6, color: "green" }, { num: 18, color: "red" }, { num: 30, color: "red" }, { num: 42, color: "blue" }] },
                            { title: "一肖", bg: '#F2F3F8', name: "", isSelected: false, static_bonus: "", val: "", subname: "6_0_8", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, betclass_nums: [{ num: 5, color: "green" }, { num: 17, color: "green" }, { num: 29, color: "red" }, { num: 41, color: "blue" }] },
                            { title: "一肖", bg: '#F2F3F8', name: "", isSelected: false, static_bonus: "", val: "", subname: "6_0_9", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, betclass_nums: [{ num: 4, color: "blue" }, { num: 16, color: "green" }, { num: 28, color: "green" }, { num: 40, color: "red" }] },
                        ]
                    }
                ],
                yxiaobz: [
                    {
                        ballname: "一肖不中",
                        betclass: [
                            { title: "一肖不中", bg: '#F2F3F8', name: "", isSelected: false, static_bonus: "", val: "", subname: "15_0_10", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, betclass_nums: [{ num: 3, color: "blue" }, { num: 15, color: "blue" }, { num: 27, color: "green" }, { num: 39, color: "green" }] },
                            { title: "一肖不中", bg: '#F2F3F8', name: "", isSelected: false, static_bonus: "", val: "", subname: "15_0_11", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, betclass_nums: [{ num: 2, color: "red" }, { num: 14, color: "blue" }, { num: 26, color: "blue" }, { num: 38, color: "green" }] },
                            { title: "一肖不中", bg: '#F2F3F8', name: "", isSelected: false, static_bonus: "", val: "", subname: "15_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, betclass_nums: [{ num: 1, color: "red" }, { num: 13, color: "red" }, { num: 25, color: "blue" }, { num: 37, color: "blue" }, { num: 49, color: "green" }] },
                            { title: "一肖不中", bg: '#F2F3F8', name: "", isSelected: false, static_bonus: "", val: "", subname: "15_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, betclass_nums: [{ num: 12, color: "red" }, { num: 24, color: "red" }, { num: 36, color: "blue" }, { num: 48, color: "blue" }] },
                            { title: "一肖不中", bg: '#F2F3F8', name: "", isSelected: false, static_bonus: "", val: "", subname: "15_0_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, betclass_nums: [{ num: 11, color: "green" }, { num: 23, color: "red" }, { num: 35, color: "red" }, { num: 47, color: "blue" }] },
                            { title: "一肖不中", bg: '#F2F3F8', name: "", isSelected: false, static_bonus: "", val: "", subname: "15_0_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, betclass_nums: [{ num: 10, color: "blue" }, { num: 22, color: "green" }, { num: 34, color: "red" }, { num: 46, color: "red" }] },
                            { title: "一肖不中", bg: '#F2F3F8', name: "", isSelected: false, static_bonus: "", val: "", subname: "15_0_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, betclass_nums: [{ num: 9, color: "blue" }, { num: 21, color: "green" }, { num: 33, color: "green" }, { num: 45, color: "red" }] },
                            { title: "一肖不中", bg: '#F2F3F8', name: "", isSelected: false, static_bonus: "", val: "", subname: "15_0_5", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, betclass_nums: [{ num: 8, color: "red" }, { num: 20, color: "blue" }, { num: 32, color: "green" }, { num: 44, color: "green" }] },
                            { title: "一肖不中", bg: '#F2F3F8', name: "", isSelected: false, static_bonus: "", val: "", subname: "15_0_6", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, betclass_nums: [{ num: 7, color: "red" }, { num: 19, color: "red" }, { num: 31, color: "blue" }, { num: 43, color: "green" }] },
                            { title: "一肖不中", bg: '#F2F3F8', name: "", isSelected: false, static_bonus: "", val: "", subname: "15_0_7", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, betclass_nums: [{ num: 6, color: "green" }, { num: 18, color: "red" }, { num: 30, color: "red" }, { num: 42, color: "blue" }] },
                            { title: "一肖不中", bg: '#F2F3F8', name: "", isSelected: false, static_bonus: "", val: "", subname: "15_0_8", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, betclass_nums: [{ num: 5, color: "green" }, { num: 17, color: "green" }, { num: 29, color: "red" }, { num: 41, color: "blue" }] },
                            { title: "一肖不中", bg: '#F2F3F8', name: "", isSelected: false, static_bonus: "", val: "", subname: "15_0_9", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, betclass_nums: [{ num: 4, color: "blue" }, { num: 16, color: "green" }, { num: 28, color: "green" }, { num: 40, color: "red" }] },
                        ]
                    }
                ],
                weishu: [
                    {
                        ballname: '尾数',
                        betclass: [
                            { title: "尾数", bg: '#F2F3F8', name: "0尾", isSelected: false, visible: false, static_bonus: "", val: "", subname: "11_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, betclass_nums: [{ num: 0 }] },
                            { title: "尾数", bg: '#F2F3F8', name: "1尾", isSelected: false, visible: false, static_bonus: "", val: "", subname: "11_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, betclass_nums: [{ num: 1 }] },
                            { title: "尾数", bg: '#F2F3F8', name: "2尾", isSelected: false, visible: false, static_bonus: "", val: "", subname: "11_0_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, betclass_nums: [{ num: 2 }] },
                            { title: "尾数", bg: '#F2F3F8', name: "3尾", isSelected: false, visible: false, static_bonus: "", val: "", subname: "11_0_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, betclass_nums: [{ num: 3 }] },
                            { title: "尾数", bg: '#F2F3F8', name: "4尾", isSelected: false, visible: false, static_bonus: "", val: "", subname: "11_0_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, betclass_nums: [{ num: 4 }] },
                            { title: "尾数", bg: '#F2F3F8', name: "5尾", isSelected: false, visible: false, static_bonus: "", val: "", subname: "11_0_5", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, betclass_nums: [{ num: 5 }] },
                            { title: "尾数", bg: '#F2F3F8', name: "6尾", isSelected: false, visible: false, static_bonus: "", val: "", subname: "11_0_6", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, betclass_nums: [{ num: 6 }] },
                            { title: "尾数", bg: '#F2F3F8', name: "7尾", isSelected: false, visible: false, static_bonus: "", val: "", subname: "11_0_7", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, betclass_nums: [{ num: 7 }] },
                            { title: "尾数", bg: '#F2F3F8', name: "8尾", isSelected: false, visible: false, static_bonus: "", val: "", subname: "11_0_8", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, betclass_nums: [{ num: 8 }] },
                            { title: "尾数", bg: '#F2F3F8', name: "9尾", isSelected: false, visible: false, static_bonus: "", val: "", subname: "11_0_9", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, betclass_nums: [{ num: 9 }] },
                        ]
                    }
                ]
            }
        },
        mounted() {
            this.yxiao = get_lhc_sx("初始化", this.yxiao);
            this.yxiaobz = get_lhc_sx("初始化", this.yxiaobz);
        },
        methods: {
            //选取投注项
            click_item(item) {
                if (item.isSelected) {
                    item.isSelected = false;
                    item.bg = '#F2F3F8';
                } else {
                    item.bg = '#ffef3a';
                    item.isSelected = true;
                }
                return item
            },
            //快速下注
            fast_sub_bet() {
                let list = [];
                for (let i = 0; i < this.yxiao.length; i++) {
                    for (let j = 0; j < this.yxiao[i].betclass.length; j++) {
                        if (this.yxiao[i].betclass[j].isSelected) {
                            list.push(this.yxiao[i].betclass[j]);
                        }
                    }
                }
                for (let i = 0; i < this.yxiaobz.length; i++) {
                    for (let j = 0; j < this.yxiaobz[i].betclass.length; j++) {
                        if (this.yxiaobz[i].betclass[j].isSelected) {
                            list.push(this.yxiaobz[i].betclass[j]);
                        }
                    }
                }
                for (let i = 0; i < this.weishu.length; i++) {
                    for (let j = 0; j < this.weishu[i].betclass.length; j++) {
                        if (this.weishu[i].betclass[j].isSelected) {
                            list.push(this.weishu[i].betclass[j]);
                        }
                    }
                }
                // console.log(list)
                return list;
            },
            /**显示赔率等数据 */
            get_lose() {
                let lose_data = this.$store.state.lose_data;
                lose_data.forEach(item => {
                    item.subname = `${item.level_two}_${item.level_three}_${item.level_four}`
                })
                //一肖
                for (let i = 0; i < this.yxiao[0].betclass.length; i++) {
                    lose_data.forEach(item => {
                        if (item.subname == this.yxiao[0].betclass[i].subname) {
                            this.yxiao[0].betclass[i].static_bonus = item.rate;
                            this.yxiao[0].betclass[i].straightbet_high = item.other.straightbet_high;
                            this.yxiao[0].betclass[i].straightbet_low = item.other.straightbet_low;
                        }
                    });
                }
                //一肖不中
                for (let i = 0; i < this.yxiaobz[0].betclass.length; i++) {
                    lose_data.forEach(item => {
                        if (item.subname == this.yxiaobz[0].betclass[i].subname) {
                            this.yxiaobz[0].betclass[i].static_bonus = item.rate;
                            this.yxiaobz[0].betclass[i].straightbet_high = item.other.straightbet_high;
                            this.yxiaobz[0].betclass[i].straightbet_low = item.other.straightbet_low;
                        }
                    });
                }
                //尾数
                for (let i = 0; i< this.weishu[0].betclass.length; i++) {
                    lose_data.forEach(item => {
                        if (item.subname == this.weishu[0].betclass[i].subname) {
                            this.weishu[0].betclass[i].static_bonus = item.rate;
                            this.weishu[0].betclass[i].straightbet_high = item.other.straightbet_high;
                            this.weishu[0].betclass[i].straightbet_low = item.other.straightbet_low;
                        }
                    });
                }
            },
            /**检查是否满足快捷输入 */
            check_fast_val(val) {
                if (val) {
                    return val;
                }
                else {
                    val = this.$parent.get_fast();
                    return val;
                }
            },
            /**重填，重置 */
            reset() {
                //一肖
                for (let i = 0; i < this.yxiao.length; i++) {
                    for (let j = 0; j < this.yxiao[i].betclass.length; j++) {
                        this.yxiao[i].betclass[j].val = "";
                        this.yxiao[i].betclass[j].isSelected = false;
                        this.yxiao[i].betclass[j].bg = "#F2F3F8";
                    }
                }
                for (let i = 0; i < this.yxiaobz.length; i++) {
                    for (let j = 0; j < this.yxiaobz[i].betclass.length; j++) {
                        this.yxiaobz[i].betclass[j].val = "";
                        this.yxiaobz[i].betclass[j].isSelected = false;
                        this.yxiaobz[i].betclass[j].bg = "#F2F3F8";
                    }
                }
                for (let i = 0; i < this.weishu.length; i++) {
                    for (let j = 0; j < this.weishu[i].betclass.length; j++) {
                        this.weishu[i].betclass[j].val = "";
                        this.weishu[i].betclass[j].isSelected = false;
                        this.weishu[i].betclass[j].bg = "#F2F3F8";
                    }
                }
            },
            /**提交，下注 */
            sub_bet() {
                let list = [];
                //一肖
                for (let i = 0; i < this.yxiao.length; i++) {
                    for (let j = 0; j < this.yxiao[i].betclass.length; j++) {
                        if (this.yxiao[i].betclass[j].isSelected) {
                            list.push(this.yxiao[i].betclass[j]);
                        }
                    }
                }
                for (let i = 0; i < this.yxiaobz.length; i++) {
                    for (let j = 0; j < this.yxiaobz[i].betclass.length; j++) {
                        if (this.yxiaobz[i].betclass[j].isSelected) {
                            list.push(this.yxiaobz[i].betclass[j]);
                        }
                    }
                }
                for (let i = 0; i < this.weishu.length; i++) {
                    for (let j = 0; j < this.weishu[i].betclass.length; j++) {
                        if (this.weishu[i].betclass[j].isSelected) {
                            list.push(this.weishu[i].betclass[j]);
                        }
                    }
                }
                // console.log(list)
                return list;
            },
            /**重复上次下单 */
            repeat_last_bet(list) {
                // console.log(list)
                for (let i = 0; i < list.length; i++) {
                    //一肖
                    for (let j = 0; j < this.yxiao.length; j++) {
                        for (let k = 0; k < this.yxiao[j].betclass.length; k++) {
                            if (this.yxiao[j].betclass[k].title == list[i].title && this.yxiao[j].betclass[k].name == list[i].name) {
                                this.yxiao[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                    for (let j = 0; j < this.yxiaobz.length; j++) {
                        for (let k = 0; k < this.yxiaobz[j].betclass.length; k++) {
                            if (this.yxiaobz[j].betclass[k].title == list[i].title && this.yxiaobz[j].betclass[k].name == list[i].name) {
                                this.yxiaobz[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                    for (let j = 0; j < this.weishu.length; j++) {
                        for (let k = 0; k < this.weishu[j].betclass.length; k++) {
                            if (this.weishu[j].betclass[k].title == list[i].title && this.weishu[j].betclass[k].name == list[i].name) {
                                this.weishu[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                }
            },
        },
    }
</script>

<style scoped>
    .yxiao_nums_red,
    .yxiao_nums_blue,
    .yxiao_nums_green {
        float: right;
        width: 24px;
        height: 24px;
        line-height: 24px;
        border-radius: 50%;
        color: #fff;
        text-align: center;
        margin-right: 2px;
        font-weight: bold;
        margin-top: 1px;
    }
    .yxiao_nums_red {
        background-color: #f56c6c;
    }
    .yxiao_nums_blue {
        background-color: #409eff;
    }
    .yxiao_nums_green {
        background-color: #67c23a;
    }
    .yxiao .bet-items-one {
        text-align: left;
    }
    .yxiao .bet-items-one .yxiao-input {
        float: none;
        width: 100px;
    }
    .yxiao .bet-items-one .bet-lose {
        text-align: center;
        margin: 0 5px;
    }
</style>
