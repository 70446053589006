<!--
 * @Author: your name
 * @Date: 2021-04-05 14:58:39
 * @LastEditTime: 2022-08-30 00:26:25
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: In User Settings Edit
 * @FilePath: \luck\src\components\betPage\jslhc\fastsubcp.vue
-->
<template>
    <div class="fastsubcp">
        <el-row class="nums">
            <el-checkbox-group v-model="checkedNums" fill="#fbd192">
                <el-col :span="2" v-for="num in nums" :key="num.name">
                    <el-checkbox-button :label="num.name">
                        <span :style="{'color':num.color,'font-weight':'bold'}">{{num.name}}</span>
                    </el-checkbox-button>
                </el-col>
            </el-checkbox-group>
        </el-row>
        <el-row class="style-nums">
            <el-checkbox-group v-model="checkedStyleNums" fill="#409eff" size="mini" @change="change_checkedStyleNums">
                <el-col :span="3" v-for="num in styleNums" :key="num.name">
                    <el-checkbox-button :label="num.name">
                        <span :style="{'color':num.color,'font-weight':'bold'}">{{num.name}}</span>
                    </el-checkbox-button>
                </el-col>
            </el-checkbox-group>
            <el-col :span="24">
                <el-button style="margin-top:3px" type="info" size="mini" plain @click="checkedNums=checkedStyleNums=[]">取消</el-button>
                <el-button style="margin-top:3px" type="info" size="mini" plain @click="reset_subList">清除列表</el-button>
            </el-col>
        </el-row>
        <el-row style="margin-top:10px">
            <el-col class="subinfo" :span="24">
                <el-col class="item" :span="8" style="color:#409eff">下注金额</el-col>
                <el-col class="item" :span="16">
                    <el-input style="width:80%" v-model="subinfo.money" size="mini" v-enter-number clearable>
                        <el-button slot="append" @click="add_sublist">添加</el-button>
                    </el-input>
                </el-col>
                <el-col class="item" :span="4">总注额</el-col>
                <el-col class="item" :span="4"><span style="color:#F56C6C;">{{subinfo.countMoney}}</span><span v-show="subinfo.countMoney>0"> / {{subList.length}} 注</span></el-col>
                <el-col class="item" :span="4">单注最低</el-col>
                <el-col class="item" :span="4">{{subinfo.straightbet_low}}</el-col>
                <el-col class="item" :span="4">单注最高</el-col>
                <el-col class="item" :span="4">{{subinfo.straightbet_high}}</el-col>
                <!-- <el-col class="item" :span="8">单期限额</el-col>
                <el-col class="item" :span="16">{{subinfo.single_high}}</el-col> -->
            </el-col>
            <el-col :span="24" :offset="0">
                <el-table :data="subList" stripe border size="mini" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" height="250">
                    <el-table-column property="name" label="下注明细" align="center">
                        <template slot-scope="scope">
                            <span>{{scope.row.title}}</span>
                            <span style="color:red;font-weight:bold;font-size:14px"> {{scope.row.name}}</span> @
                            <span style="color:blue">{{scope.row.static_bonus}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column property="val" label="注额" width="" align="center"></el-table-column>
                    <el-table-column property="" label="操作" align="center" width="">
                        <template slot-scope="scope">
                            <el-button type="danger" icon="el-icon-close" size="mini" circle plain @click="del_sublist_item(scope.row)"></el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>
    </div>
</template>
<script>
    import { get_lhc_sx } from "../../../plugin/sx";
    export default {
        props: ['nums'],
        data() {
            return {
                checkedNums: [],
                checkedStyleNums: [],
                subList: [],
                subinfo: {
                    money: '',
                    countMoney: '',
                    straightbet_low: '',
                    straightbet_high: '',
                    single_high: '',
                },
                styleNums: [
                    { title: "红波", name: "红波", color: "red", nums: [1, 2, 7, 8, 12, 13, 18, 19, 23, 24, 29, 30, 34, 35, 40, 45, 46] },
                    { title: "蓝波", name: "蓝波", color: "blue", nums: [3, 4, 9, 10, 14, 15, 20, 25, 26, 31, 36, 37, 41, 42, 47, 48] },
                    { title: "绿波", name: "绿波", color: "green", nums: [5, 6, 11, 16, 17, 21, 22, 27, 28, 32, 33, 38, 39, 43, 44, 49] },
                    { title: "色波", name: "红单", color: 'red', nums: [1, 7, 13, 19, 23, 29, 35, 45] },
                    { title: "色波", name: "红双", color: 'red', nums: [2, 8, 12, 18, 24, 30, 34, 40, 46] },
                    { title: "色波", name: "红大", color: 'red', nums: [29, 30, 34, 35, 40, 45, 46] },
                    { title: "色波", name: "红小", color: 'red', nums: [1, 2, 7, 8, 12, 13, 18, 19, 23, 24] },
                    { title: "色波", name: "蓝单", color: 'blue', nums: [3, 9, 15, 25, 31, 37, 41, 47] },
                    { title: "色波", name: "蓝双", color: 'blue', nums: [4, 10, 14, 20, 26, 36, 42, 48] },
                    { title: "色波", name: "蓝大", color: 'blue', nums: [25, 26, 31, 36, 37, 41, 42, 47, 48] },
                    { title: "色波", name: "蓝小", color: 'blue', nums: [3, 4, 9, 10, 14, 15, 20] },
                    { title: "色波", name: "绿单", color: 'green', nums: [5, 11, 17, 21, 27, 33, 39, 43, 49] },
                    { title: "色波", name: "绿双", color: 'green', nums: [6, 16, 22, 28, 32, 38, 44] },
                    { title: "色波", name: "绿大", color: 'green', nums: [27, 28, 32, 33, 38, 39, 43, 44, 49] },
                    { title: "色波", name: "绿小", color: 'green', nums: [5, 6, 11, 16, 17, 21, 22] },
                    { title: "生肖", name: "", nums: [2, 14, 26, 38] },
                    { title: "生肖", name: "", nums: [1, 13, 25, 37, 49] },
                    { title: "生肖", name: "", nums: [12, 24, 36, 48] },
                    { title: "生肖", name: "", nums: [11, 23, 35, 47] },
                    { title: "生肖", name: "", nums: [10, 22, 34, 46] },
                    { title: "生肖", name: "", nums: [9, 21, 33, 45] },
                    { title: "生肖", name: "", nums: [8, 20, 32, 44] },
                    { title: "生肖", name: "", nums: [7, 19, 31, 43] },
                    { title: "生肖", name: "", nums: [6, 18, 30, 42] },
                    { title: "生肖", name: "", nums: [5, 17, 29, 41] },
                    { title: "生肖", name: "", nums: [4, 16, 28, 40] },
                    { title: "生肖", name: "", nums: [3, 15, 27, 39] },
                    { title: '家禽野兽', name: '家禽', nums: [] },
                    { title: '家禽野兽', name: '野兽', nums: [] },
                    { title: '大小单双', name: '大', nums: [25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48] },
                    { title: '大小单双', name: '小', nums: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24] },
                    { title: '大小单双', name: '单', nums: [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31, 33, 35, 37, 39, 41, 43, 45, 47] },
                    { title: '大小单双', name: '双', nums: [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48] },
                    { title: '大小单双', name: '和单', nums: [1, 3, 5, 7, 9, 10, 12, 14, 16, 18, 21, 23, 25, 27, 29, 30, 32, 34, 36, 38, 41, 43, 45, 47] },
                    { title: '大小单双', name: '和双', nums: [2, 4, 6, 8, 11, 13, 15, 17, 19, 20, 22, 24, 26, 28, 31, 33, 35, 37, 39, 40, 42, 44, 46, 48] },
                    { title: '大小单双', name: '尾大', nums: [5, 6, 7, 8, 9, 15, 16, 17, 18, 19, 25, 26, 27, 28, 29, 35, 36, 37, 38, 39, 45, 46, 47, 48] },
                    { title: '大小单双', name: '尾小', nums: [1, 2, 3, 4, 10, 11, 12, 13, 14, 20, 21, 22, 23, 24, 30, 31, 32, 33, 34, 40, 41, 42, 43, 44] },
                ],
            }
        },
        created() {
            this.subinfo.straightbet_low = this.nums.sort((a, b) => b.straightbet_low - a.straightbet_low)[0].straightbet_low;
            this.subinfo.straightbet_high = this.nums.sort((a, b) => a.straightbet_high - b.straightbet_high)[0].straightbet_high;
            this.subinfo.single_high = this.nums.sort((a, b) => a.single_high - b.single_high)[0].single_high;
            // console.log(this.subinfo.single_high);
            get_lhc_sx("快速下注初始化", this.styleNums);
            //初始家禽野兽的nums
            let jq = [];
            let ys = [];
            this.styleNums.forEach(item => {
                if (item.title == '生肖') {
                    if (item.name == '牛' || item.name == '马' || item.name == '羊' || item.name == '鸡' || item.name == '狗' || item.name == '猪') {
                        item.nums.forEach(num => {
                            jq.push(num);
                        })
                    } else {
                        item.nums.forEach(num => {
                            ys.push(num);
                        })
                    }
                }
            })
            this.styleNums.find(item => item.name == '家禽').nums = jq;
            this.styleNums.find(item => item.name == '野兽').nums = ys;
            // console.log(this.nums);
        },
        methods: {
            change_checkedStyleNums(checkedStyleNums) {
                let list = [];
                checkedStyleNums.forEach(name => {
                    this.styleNums.forEach(item => {
                        if (name == item.name) {
                            list = Array.from(new Set([...list, ...item.nums]))
                        }
                    });
                })
                for (let i = 0; i < list.length; i++) {
                    list[i] = list[i].toString();
                }
                this.checkedNums = list;
            },
            add_sublist() {
                if (this.subinfo.money < this.subinfo.straightbet_low) {
                    return this.$toast.fail("下注金额不能小于最小投注金额：" + this.subinfo.straightbet_low);
                }
                if (this.subinfo.money > this.subinfo.straightbet_high) {
                    return this.$toast.fail("下注金额不能大于最大投注金额：" + this.subinfo.straightbet_high);
                }
                this.checkedNums.forEach(cnum => {
                    this.nums.forEach(num => {
                        if (cnum == num.name && this.subList.find(item => item.name == cnum) == undefined) {
                            num.val = this.subinfo.money;
                            this.subList.push(num);
                        }
                    })
                })
                // console.log(this.subList);
                if (this.subList.length < 1) {
                    return this.$toast.fail("您还未选择投注号码");
                }
                this.get_countMoney();
            },
            del_sublist_item(row) {
                this.subList.splice(this.subList.findIndex(item => item.name == row.name), 1);
                this.get_countMoney();
            },
            get_countMoney() {
                this.subinfo.countMoney = 0;
                this.subList.forEach(item => {
                    this.subinfo.countMoney += item.val * 1;
                })
            },
            reset_subList() {
                this.subList = [];
                this.subinfo.countMoney = '';
            },
            reset_fast_sub() {
                this.subList = [];
                this.checkedNums = [];
                this.checkedStyleNums = [];
                this.subinfo.money = '';
                this.subinfo.countMoney = '';
                this.nums.forEach(num => {
                    num.val = '';
                });
            }
        },
    }
</script>

<style scoped>
    .fastsubcp {
        overflow-y: hidden;
    }
    .fastsubcp > .nums >>> .el-checkbox-button__inner {
        width: 30px;
        /* height: 30px; */
        padding: 10px 5px;
    }
    .style-nums {
        margin-top: 20px;
    }
    .style-nums >>> .el-checkbox-button__inner {
        width: 48px;
        background-color: #ecf5ff;
        padding: 8px 3px;
    }
    .subinfo {
        border-top: 1px solid #dcdfe6;
        border-left: 1px solid #dcdfe6;
    }
    .subinfo > .item {
        height: 30px;
        line-height: 30px;
        border-right: 1px solid #dcdfe6;
        border-bottom: 1px solid #dcdfe6;
        font-size: 12px;
        text-align: center;
    }
</style>
