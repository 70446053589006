<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-07-15 15:38:10
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-09-15 23:39:36
 * @FilePath: \pc\src\components\menuitems\report
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="report">
        <div v-show="!day_data.show&&!game_data.show">
            <el-table size="small" :data="lastweek" border header-cell-class-name="table-header-cell" header-row-class-name="table-header-row">
                <el-table-column property="time" label="交易日期" align="center" width="72">
                    <template #default="scope">
                        <el-link @click="get_day_data(scope.row)">{{scope.row.timetext}}</el-link>
                    </template>
                </el-table-column>
                <el-table-column property="count" label="注单数" align="center" width="45"></el-table-column>
                <el-table-column property="balance" label="有效金额" align="center" width="60"></el-table-column>
                <el-table-column property="bonus" label="结果" align="center" width="50"></el-table-column>
                <el-table-column property="tuishui" label="退水" align="center" width="70"></el-table-column>
                <el-table-column property="res" label="退水后结果" align="center"></el-table-column>
            </el-table>
            <br />
            <el-table size="small" :data="now_week" border header-cell-class-name="table-header-cell" header-row-class-name="table-header-row">
                <el-table-column property="time" label="交易日期" align="center" width="72">
                    <template #default="scope">
                        <el-link @click="get_day_data(scope.row)">{{scope.row.timetext}}</el-link>
                    </template>
                </el-table-column>
                <el-table-column property="count" label="注单数" align="center" width="45"></el-table-column>
                <el-table-column property="balance" label="有效金额" align="center" width="60"></el-table-column>
                <el-table-column property="bonus" label="结果" align="center" width="50"></el-table-column>
                <el-table-column property="tuishui" label="退水" align="center" width="70"></el-table-column>
                <el-table-column property="res" label="退水后结果" align="center"></el-table-column>
            </el-table>
        </div>
        <!-- 当天 -->
        <div v-show="day_data.show">
            <div class="mx-title">
                <span>明細日期：{{day_data.title}}</span>
                <el-button type="primary" size="small" class="backbtn" @click="day_data.show=false">返回</el-button>
            </div>
            <el-table size="small" :data="day_data.list" border header-cell-class-name="table-header-cell" header-row-class-name="table-header-row">
                <el-table-column property="game" label="彩种类型" align="center" width="70">
                    <template #default="scope">
                        <el-link @click="get_game_data(scope.row)">{{scope.row.game}}</el-link>
                    </template>
                </el-table-column>
                <el-table-column property="count" label="笔数" align="center" width="40"></el-table-column>
                <el-table-column property="balance" label="有效总额" align="center" width="60"></el-table-column>
                <el-table-column property="bonus" label="结果" align="center" width="50"></el-table-column>
                <el-table-column property="tuishui" label="退水" align="center" width="70"></el-table-column>
                <el-table-column property="rebate_res" label="退水后结果" align="center">
                    <template #default="scope">{{Number(scope.row.rebate_res).toFixed(2)}}</template>
                </el-table-column>
            </el-table>
            <!-- <div class="myform" v-for="item in day_data.list" :key="item.game_id">
                <el-row class="myform-item">
                    <el-col class="myform-item-lable" :span="6">彩种类型</el-col>
                    <el-col class="myform-item-value" :span="18">
                        <el-link :style="{'font-weight': 'bold',color:item.game=='合计'?'red':'#409eff'}" @click="get_game_data(item)">{{item.game}}</el-link>
                    </el-col>
                </el-row>
                <el-row class="myform-item">
                    <el-col class="myform-item-lable" :span="6">注单笔数</el-col>
                    <el-col class="myform-item-value" :span="18">{{item.count}}</el-col>
                </el-row>
                <el-row class="myform-item">
                    <el-col class="myform-item-lable" :span="6">有效总额</el-col>
                    <el-col class="myform-item-value" :span="18">{{item.balance}}</el-col>
                </el-row>
                <el-row class="myform-item">
                    <el-col class="myform-item-lable" :span="6">结果</el-col>
                    <el-col class="myform-item-value" :span="18">{{item.bonus}}</el-col>
                </el-row>
                <el-row class="myform-item">
                    <el-col class="myform-item-lable" :span="6">退水</el-col>
                    <el-col class="myform-item-value" :span="18">{{item.tuishui}}</el-col>
                </el-row>
                <el-row class="myform-item">
                    <el-col class="myform-item-lable" :span="6">退水后结果</el-col>
                    <el-col class="myform-item-value" :span="18">{{Number(item.rebate_res).toFixed(2)}}</el-col>
                </el-row>
            </div> -->
        </div>
        <!-- 彩种 -->
        <div v-show="game_data.show">
            <div class="mx-title">
                <span>註單明細：（{{game_data.title}}</span>
                <el-button type="primary" size="small" class="backbtn" @click="back_day_data">返回</el-button>
            </div>
            <!-- <el-table size="small" :data="game_data.list" border header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" show-summary :summary-method="getSummaries">
                <el-table-column property="game" label="主单号/时间" align="center">
                    <template #default="scope">
                        <div>{{scope.row.order_id}}#</div>
                        <div>{{scope.row.posttime}}</div>
                    </template>
                </el-table-column>
                <el-table-column property="count" label="下注类型" align="center">
                    <template #default="scope">
                        <div>{{scope.row.game}}</div>
                        <div>{{scope.row.qnum}}期</div>
                    </template>
                </el-table-column>
                <el-table-column property="balance" label="注单明细" align="center">
                    <template #default="scope">
                        <span>{{scope.row.bet_name}}@</span>
                        <span style="color:#F56C6C">{{scope.row.list_bonus}}</span>
                    </template>
                </el-table-column>
                <el-table-column property="balance" label="下注金额" align="center"></el-table-column>
                <el-table-column property="bonus" label="结果" align="center"></el-table-column>
                <el-table-column property="tuishui" label="退水" align="center"></el-table-column>
                <el-table-column property="rebate_res" label="退水后结果" align="center"></el-table-column>
            </el-table> -->
            <div class="myform" v-for="item in game_data.list" :key="item.order_id">
                <el-row class="myform-item">
                    <el-col class="myform-item-lable" :span="6">主单号/时间</el-col>
                    <el-col class="myform-item-value" :span="18">
                        {{item.order_id}}#{{item.posttime}}
                    </el-col>
                </el-row>
                <el-row class="myform-item">
                    <el-col class="myform-item-lable" :span="6">下注类型</el-col>
                    <el-col class="myform-item-value" :span="18">{{item.game}}#{{item.qnum}}期</el-col>
                </el-row>
                <el-row class="myform-item">
                    <el-col class="myform-item-lable" :span="6">注单明细</el-col>
                    <el-col class="myform-item-value" :span="18">{{item.bet_name}}@<span style="color:#F56C6C">{{item.list_bonus}}</span></el-col>
                </el-row>
                <el-row class="myform-item">
                    <el-col class="myform-item-lable" :span="6">下注金额</el-col>
                    <el-col class="myform-item-value" :span="18">{{item.balance}}</el-col>
                </el-row>
                <el-row class="myform-item">
                    <el-col class="myform-item-lable" :span="6">结果</el-col>
                    <el-col class="myform-item-value" :span="18">{{item.bonus}}</el-col>
                </el-row>
                <el-row class="myform-item">
                    <el-col class="myform-item-lable" :span="6">退水</el-col>
                    <el-col class="myform-item-value" :span="18">{{item.tuishui}}</el-col>
                </el-row>
                <el-row class="myform-item">
                    <el-col class="myform-item-lable" :span="6">退水后结果</el-col>
                    <el-col class="myform-item-value" :span="18">{{item.rebate_res}}</el-col>
                </el-row>
            </div>
            <div style="margin:0 auto">
                <el-pagination small hide-on-single-page @current-change="game_data_changepage" :page-size="20" :current-page="game_data.page" :pager-count="5" :total="game_data.res.page_num*1" layout="total,prev,pager,next,jumper" prev-text="上一页" next-text="下一页"></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                lastweek: [],
                now_week: [],
                day_data: {
                    show: false,
                    title: '',
                    list: [],
                    row: '',
                },
                game_data: {
                    show: false,
                    title: '',
                    list: [],
                    res: '',
                    page: 1,
                    row: '',
                },
            }
        },
        created() {

            if (this.$route.params.data != undefined) {
                this.getdata(JSON.parse(this.$route.params.data));
            } else {
                this.axios.post("/order/close_order", { user_id: this.$store.state.userinfo.user_id }).then((result) => {
                    if (result.data.status == 1 && result.data.msg === "请求成功") {
                        this.getdata(result.data.res);
                    }
                })
            }
        },
        methods: {
            back_day_data() {
                this.game_data.show = false;
                this.day_data.show = true;
            },
            getSummaries(param) {
                const { columns, data } = param
                const sums = []
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = ''
                        return
                    }
                    if (index === 1) {
                        sums[index] = '合计'
                        return
                    }
                    if (index === 2) {
                        sums[index] = this.game_data.res.total_count + '笔';
                        return
                    }
                    if (index === 3) {
                        sums[index] = this.game_data.res.total_balance;
                        return
                    }
                    if (index === 4) {
                        sums[index] = this.game_data.res.total_bonus;
                        return
                    }
                    if (index === 5) {
                        sums[index] = this.game_data.res.total_a_rebate;
                        return
                    }
                    if (index === 6) {
                        sums[index] = this.game_data.res.total_rebate_res;
                        return
                    }
                })
                return sums
            },
            game_data_changepage(page) {
                this.get_game_data(this.game_data.row, page);
            },
            get_game_data(row, page = 1) {
                if (row.game !== '合计') {
                    this.game_data.row = row;
                    this.game_data.page = page;
                    this.game_data.title = `${this.day_data.row.timetext} ${row.game}`;
                    this.axios.post("/order/close_order_list",
                        {
                            user_id: this.$store.state.userinfo.user_id,
                            start_time: this.day_data.row.time,
                            end_time: this.day_data.row.time * 1 + 60 * 60 * 24,
                            game_id: row.game_id,
                            page: page,
                        }
                    ).then((result) => {
                        if (result.data.status == 1 && result.data.msg === "请求成功") {
                            this.day_data.show = false;
                            this.game_data.show = true;
                            this.game_data.list = result.data.res.list;
                            this.game_data.res = result.data.res;
                        }
                    })
                }
            },
            get_day_data(row) {
                this.day_data.row = row;
                this.day_data.title = row.timetext;
                this.axios.post("/order/close_order_lottery",
                    {
                        user_id: this.$store.state.userinfo.user_id,
                        start_time: row.time,
                        end_time: row.time * 1 + 60 * 60 * 24,
                    }
                ).then((result) => {
                    if (result.data.status == 1 && result.data.msg === "请求成功") {
                        this.day_data.show = true;
                        let data = result.data.res;
                        this.day_data.list = data.list
                        this.day_data.list.push({
                            a_rebate: data.total_a_rebate,
                            balance: data.total_balance,
                            bonus: data.total_bonus,
                            count: data.total_count,
                            game: "合计",
                            game_id: "total",
                            rebate_res: data.total_rebate_res,
                            tuishui: data.total_tuishui,
                        })
                    }
                })
            },
            getdata(data) {
                this.lastweek = [];
                this.now_week = [];
                for (const key in data.lastweek) {
                    if (key < 8) {
                        data.lastweek[key].timetext = this.$filters.time(data.lastweek[key].time, '报表注单');
                        this.lastweek.push(data.lastweek[key]);
                    }
                }
                this.lastweek.push(
                    {
                        a_rebate: "",
                        balance: data.lastweek.totil_balance,
                        bonus: data.lastweek.bonus,
                        count: data.lastweek.totil_num,
                        res: data.lastweek.totil_res,
                        timetext: '上周',
                        tuishui: data.lastweek.totil_tuishui,
                    }
                );
                for (const key in data.now_week) {
                    if (key < 8) {
                        data.now_week[key].timetext = this.$filters.time(data.now_week[key].time, '报表注单');
                        this.now_week.push(data.now_week[key]);
                    }
                }
                this.now_week.push(
                    {
                        a_rebate: "",
                        balance: data.now_week.totil_balance,
                        bonus: data.now_week.bonus,
                        count: data.now_week.totil_num,
                        res: data.now_week.totil_res,
                        timetext: '本周',
                        tuishui: data.now_week.totil_tuishui,
                    }
                );
            },
        },
    }
</script>

<style>
    .report .el-table .el-table__body-wrapper .el-table__body tbody tr:last-child {
        color: #f56c6c;
        font-weight: bold;
    }
    .report .el-table__footer-wrapper tbody td {
        color: #f56c6c;
        font-weight: bold;
    }
    .mx-title {
        font-size: 16px;
        font-weight: bold;
        padding: 10px;
        background: #fff;
    }
    .backbtn {
        float: right;
    }
</style>