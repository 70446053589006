<template>
    <div class="cqssc-d1-5">
        <el-row :gutter="10">
            <el-col :span="24" v-for="item in balls" :key="item.index">
                <div class="bet-title">
                    <span>{{item.ballname}}</span>
                </div>
                <el-row class="bet-items" :gutter="6">
                    <el-col :span="8" v-for="i in item.betclass" :key="i.index">
                        <div class="bet-items-info" :style="{backgroundColor:i.bg}" @click="click_item(i)">
                            <span :class="!isNaN(i.name)?'last-nums-cqssc':'cn-name'">{{i.name}}</span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <span v-show="i.bg=='#ffef3a'" class="select-icon"><Select style="width: 24px; height: 24px;" /></span>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <!-- 总和、龙虎 -->
        <el-row>
            <el-col :span="24" v-for="item in sum" :key="item.index">
                <div class="bet-title">
                    <span>{{item.ballname}}</span>
                </div>
                <el-row class="bet-items" :gutter="6">
                    <el-col :span="8" v-for="i in item.betclass" :key="i.index">
                        <div class="bet-items-info" :style="{backgroundColor:i.bg}" @click="click_item(i)">
                            <span class="bet-name">{{i.name}}</span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <span v-show="i.bg=='#ffef3a'" class="select-icon"><Select style="width: 24px; height: 24px;" /></span>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <!-- 前三，中三，后三 -->
        <el-row :gutter="6">
            <el-col :span="24" v-for="item in front" :key="item.index">
                <div class="bet-title">
                    <span>{{item.ballname}}</span>
                </div>
                <div class="bet-items">
                    <el-row :gutter="6">
                        <el-col :span="8" v-for="i in item.betclass" :key="i.index">
                            <div class="bet-items-info" :style="{backgroundColor:i.bg}" @click="click_item(i)">
                                <span class="bet-name">{{i.name}}</span>
                                <span class="bet-lose">{{i.static_bonus}}</span>
                                <span v-show="i.bg=='#ffef3a'" class="select-icon"><Select style="width: 24px; height: 24px;" /></span>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </el-col>
        </el-row>

        <el-row :gutter="6">
            <el-col :span="24" v-for="item in center" :key="item.index">
                <div class="bet-title">
                    <span>{{item.ballname}}</span>
                </div>
                <div class="bet-items">
                    <el-row :gutter="6">
                        <el-col :span="8" v-for="i in item.betclass" :key="i.index">
                            <div class="bet-items-info" :style="{backgroundColor:i.bg}" @click="click_item(i)">
                                <span class="bet-name">{{i.name}}</span>
                                <span class="bet-lose">{{i.static_bonus}}</span>
                                <span v-show="i.bg=='#ffef3a'" class="select-icon"><Select style="width: 24px; height: 24px;" /></span>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </el-col>
        </el-row>

        <el-row :gutter="6">
            <el-col :span="24" v-for="item in back" :key="item.index">
                <div class="bet-title">
                    <span>{{item.ballname}}</span>
                </div>
                <div class="bet-items">
                    <el-row :gutter="6">
                        <el-col :span="8" v-for="i in item.betclass" :key="i.index">
                            <div class="bet-items-info" :style="{backgroundColor:i.bg}" @click="click_item(i)">
                                <span class="bet-name">{{i.name}}</span>
                                <span class="bet-lose">{{i.static_bonus}}</span>
                                <span v-show="i.bg=='#ffef3a'" class="select-icon"><Select style="width: 24px; height: 24px;" /></span>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                balls: [
                    {
                        ballname: "第一球",
                        betclass: [
                            { title: "第一球", bg: '#F2F3F8', name: "0", isSelected: false, static_bonus: "", val: "", subname: "1_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", bg: '#F2F3F8', name: "1", isSelected: false, static_bonus: "", val: "", subname: "1_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", bg: '#F2F3F8', name: "2", isSelected: false, static_bonus: "", val: "", subname: "1_0_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", bg: '#F2F3F8', name: "3", isSelected: false, static_bonus: "", val: "", subname: "1_0_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", bg: '#F2F3F8', name: "4", isSelected: false, static_bonus: "", val: "", subname: "1_0_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", bg: '#F2F3F8', name: "5", isSelected: false, static_bonus: "", val: "", subname: "1_0_5", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", bg: '#F2F3F8', name: "6", isSelected: false, static_bonus: "", val: "", subname: "1_0_6", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", bg: '#F2F3F8', name: "7", isSelected: false, static_bonus: "", val: "", subname: "1_0_7", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", bg: '#F2F3F8', name: "8", isSelected: false, static_bonus: "", val: "", subname: "1_0_8", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", bg: '#F2F3F8', name: "9", isSelected: false, static_bonus: "", val: "", subname: "1_0_9", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", bg: '#F2F3F8', name: "大", isSelected: false, static_bonus: "", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", bg: '#F2F3F8', name: "小", isSelected: false, static_bonus: "", val: "", subname: "0_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", bg: '#F2F3F8', name: "单", isSelected: false, static_bonus: "", val: "", subname: "0_0_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", bg: '#F2F3F8', name: "双", isSelected: false, static_bonus: "", val: "", subname: "0_0_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false }
                        ]
                    },
                    {
                        ballname: "第二球",
                        betclass: [
                            { title: "第二球", bg: '#F2F3F8', name: "0", isSelected: false, static_bonus: "", val: "", subname: "1_1_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", bg: '#F2F3F8', name: "1", isSelected: false, static_bonus: "", val: "", subname: "1_1_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", bg: '#F2F3F8', name: "2", isSelected: false, static_bonus: "", val: "", subname: "1_1_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", bg: '#F2F3F8', name: "3", isSelected: false, static_bonus: "", val: "", subname: "1_1_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", bg: '#F2F3F8', name: "4", isSelected: false, static_bonus: "", val: "", subname: "1_1_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", bg: '#F2F3F8', name: "5", isSelected: false, static_bonus: "", val: "", subname: "1_1_5", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", bg: '#F2F3F8', name: "6", isSelected: false, static_bonus: "", val: "", subname: "1_1_6", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", bg: '#F2F3F8', name: "7", isSelected: false, static_bonus: "", val: "", subname: "1_1_7", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", bg: '#F2F3F8', name: "8", isSelected: false, static_bonus: "", val: "", subname: "1_1_8", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", bg: '#F2F3F8', name: "9", isSelected: false, static_bonus: "", val: "", subname: "1_1_9", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", bg: '#F2F3F8', name: "大", isSelected: false, static_bonus: "", val: "", subname: "0_1_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", bg: '#F2F3F8', name: "小", isSelected: false, static_bonus: "", val: "", subname: "0_1_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", bg: '#F2F3F8', name: "单", isSelected: false, static_bonus: "", val: "", subname: "0_1_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", bg: '#F2F3F8', name: "双", isSelected: false, static_bonus: "", val: "", subname: "0_1_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false }
                        ]
                    },
                    {
                        ballname: "第三球",
                        betclass: [
                            { title: "第三球", bg: '#F2F3F8', name: "0", isSelected: false, static_bonus: "", val: "", subname: "1_2_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三球", bg: '#F2F3F8', name: "1", isSelected: false, static_bonus: "", val: "", subname: "1_2_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三球", bg: '#F2F3F8', name: "2", isSelected: false, static_bonus: "", val: "", subname: "1_2_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三球", bg: '#F2F3F8', name: "3", isSelected: false, static_bonus: "", val: "", subname: "1_2_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三球", bg: '#F2F3F8', name: "4", isSelected: false, static_bonus: "", val: "", subname: "1_2_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三球", bg: '#F2F3F8', name: "5", isSelected: false, static_bonus: "", val: "", subname: "1_2_5", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三球", bg: '#F2F3F8', name: "6", isSelected: false, static_bonus: "", val: "", subname: "1_2_6", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三球", bg: '#F2F3F8', name: "7", isSelected: false, static_bonus: "", val: "", subname: "1_2_7", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三球", bg: '#F2F3F8', name: "8", isSelected: false, static_bonus: "", val: "", subname: "1_2_8", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三球", bg: '#F2F3F8', name: "9", isSelected: false, static_bonus: "", val: "", subname: "1_2_9", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三球", bg: '#F2F3F8', name: "大", isSelected: false, static_bonus: "", val: "", subname: "0_2_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三球", bg: '#F2F3F8', name: "小", isSelected: false, static_bonus: "", val: "", subname: "0_2_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三球", bg: '#F2F3F8', name: "单", isSelected: false, static_bonus: "", val: "", subname: "0_2_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三球", bg: '#F2F3F8', name: "双", isSelected: false, static_bonus: "", val: "", subname: "0_2_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false }
                        ]
                    },
                    {
                        ballname: "第四球",
                        betclass: [
                            { title: "第四球", bg: '#F2F3F8', name: "0", isSelected: false, static_bonus: "", val: "", subname: "1_3_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第四球", bg: '#F2F3F8', name: "1", isSelected: false, static_bonus: "", val: "", subname: "1_3_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第四球", bg: '#F2F3F8', name: "2", isSelected: false, static_bonus: "", val: "", subname: "1_3_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第四球", bg: '#F2F3F8', name: "3", isSelected: false, static_bonus: "", val: "", subname: "1_3_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第四球", bg: '#F2F3F8', name: "4", isSelected: false, static_bonus: "", val: "", subname: "1_3_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第四球", bg: '#F2F3F8', name: "5", isSelected: false, static_bonus: "", val: "", subname: "1_3_5", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第四球", bg: '#F2F3F8', name: "6", isSelected: false, static_bonus: "", val: "", subname: "1_3_6", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第四球", bg: '#F2F3F8', name: "7", isSelected: false, static_bonus: "", val: "", subname: "1_3_7", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第四球", bg: '#F2F3F8', name: "8", isSelected: false, static_bonus: "", val: "", subname: "1_3_8", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第四球", bg: '#F2F3F8', name: "9", isSelected: false, static_bonus: "", val: "", subname: "1_3_9", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第四球", bg: '#F2F3F8', name: "大", isSelected: false, static_bonus: "", val: "", subname: "0_3_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第四球", bg: '#F2F3F8', name: "小", isSelected: false, static_bonus: "", val: "", subname: "0_3_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第四球", bg: '#F2F3F8', name: "单", isSelected: false, static_bonus: "", val: "", subname: "0_3_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第四球", bg: '#F2F3F8', name: "双", isSelected: false, static_bonus: "", val: "", subname: "0_3_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false }
                        ]
                    },
                    {
                        ballname: "第五球",
                        betclass: [
                            { title: "第五球", bg: '#F2F3F8', name: "0", isSelected: false, static_bonus: "", val: "", subname: "1_4_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第五球", bg: '#F2F3F8', name: "1", isSelected: false, static_bonus: "", val: "", subname: "1_4_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第五球", bg: '#F2F3F8', name: "2", isSelected: false, static_bonus: "", val: "", subname: "1_4_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第五球", bg: '#F2F3F8', name: "3", isSelected: false, static_bonus: "", val: "", subname: "1_4_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第五球", bg: '#F2F3F8', name: "4", isSelected: false, static_bonus: "", val: "", subname: "1_4_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第五球", bg: '#F2F3F8', name: "5", isSelected: false, static_bonus: "", val: "", subname: "1_4_5", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第五球", bg: '#F2F3F8', name: "6", isSelected: false, static_bonus: "", val: "", subname: "1_4_6", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第五球", bg: '#F2F3F8', name: "7", isSelected: false, static_bonus: "", val: "", subname: "1_4_7", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第五球", bg: '#F2F3F8', name: "8", isSelected: false, static_bonus: "", val: "", subname: "1_4_8", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第五球", bg: '#F2F3F8', name: "9", isSelected: false, static_bonus: "", val: "", subname: "1_4_9", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第五球", bg: '#F2F3F8', name: "大", isSelected: false, static_bonus: "", val: "", subname: "0_4_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第五球", bg: '#F2F3F8', name: "小", isSelected: false, static_bonus: "", val: "", subname: "0_4_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第五球", bg: '#F2F3F8', name: "单", isSelected: false, static_bonus: "", val: "", subname: "0_4_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第五球", bg: '#F2F3F8', name: "双", isSelected: false, static_bonus: "", val: "", subname: "0_4_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false }
                        ]
                    },
                ],
                /**总和，龙虎 */
                sum: [
                    {
                        ballname: "总和",
                        betclass: [
                            { title: "总和", bg: '#F2F3F8', name: "总和大", isSelected: false, static_bonus: "", val: "", subname: "0_5_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "总和", bg: '#F2F3F8', name: "总和小", isSelected: false, static_bonus: "", val: "", subname: "0_5_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "总和", bg: '#F2F3F8', name: "总和单", isSelected: false, static_bonus: "", val: "", subname: "0_5_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "总和", bg: '#F2F3F8', name: "总和双", isSelected: false, static_bonus: "", val: "", subname: "0_5_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    },
                ],
                /**前三 */
                front: [
                    {
                        ballname: "前三",
                        betclass: [
                            { title: "前三", bg: '#F2F3F8', name: "豹子", isSelected: false, static_bonus: "", val: "", subname: "2_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "前三", bg: '#F2F3F8', name: "顺子", isSelected: false, static_bonus: "", val: "", subname: "2_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "前三", bg: '#F2F3F8', name: "对子", isSelected: false, static_bonus: "", val: "", subname: "2_0_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "前三", bg: '#F2F3F8', name: "半顺", isSelected: false, static_bonus: "", val: "", subname: "2_0_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "前三", bg: '#F2F3F8', name: "杂六", isSelected: false, static_bonus: "", val: "", subname: "2_0_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    },
                ],
                /**中三 */
                center: [
                    {
                        ballname: "中三",
                        betclass: [
                            { title: "中三", bg: '#F2F3F8', name: "豹子", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "中三", bg: '#F2F3F8', name: "顺子", isSelected: false, static_bonus: "", val: "", subname: "3_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "中三", bg: '#F2F3F8', name: "对子", isSelected: false, static_bonus: "", val: "", subname: "3_0_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "中三", bg: '#F2F3F8', name: "半顺", isSelected: false, static_bonus: "", val: "", subname: "3_0_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "中三", bg: '#F2F3F8', name: "杂六", isSelected: false, static_bonus: "", val: "", subname: "3_0_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    }
                ],
                /**后三 */
                back: [
                    {
                        ballname: "后三",
                        betclass: [
                            { title: "后三", bg: '#F2F3F8', name: "豹子", isSelected: false, static_bonus: "", val: "", subname: "4_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "后三", bg: '#F2F3F8', name: "顺子", isSelected: false, static_bonus: "", val: "", subname: "4_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "后三", bg: '#F2F3F8', name: "对子", isSelected: false, static_bonus: "", val: "", subname: "4_0_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "后三", bg: '#F2F3F8', name: "半顺", isSelected: false, static_bonus: "", val: "", subname: "4_0_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "后三", bg: '#F2F3F8', name: "杂六", isSelected: false, static_bonus: "", val: "", subname: "4_0_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    }
                ]
            }
        },
        methods: {
            //选取投注项
            click_item(item) {
                if (item.isSelected) {
                    item.isSelected = false;
                    item.bg = '#F2F3F8';
                } else {
                    item.bg = '#ffef3a';
                    item.isSelected = true;
                }
                return item
            },
            //快速下注
            fast_sub_bet() {
                let list = [];
                for (let i = 0; i < this.balls.length; i++) {
                    for (let j = 0; j < this.balls[i].betclass.length; j++) {
                        if (this.balls[i].betclass[j].isSelected) {
                            list.push(this.balls[i].betclass[j]);
                        }
                    }
                }
                //总和
                for (let i = 0; i < this.sum.length; i++) {
                    for (let j = 0; j < this.sum[i].betclass.length; j++) {
                        if (this.sum[i].betclass[j].isSelected) {
                            list.push(this.sum[i].betclass[j]);
                        }
                    }
                }
                //前三
                for (let i = 0; i < this.front.length; i++) {
                    for (let j = 0; j < this.front[i].betclass.length; j++) {
                        if (this.front[i].betclass[j].isSelected) {
                            list.push(this.front[i].betclass[j]);
                        }
                    }
                }
                //中三
                for (let i = 0; i < this.center.length; i++) {
                    for (let j = 0; j < this.center[i].betclass.length; j++) {
                        if (this.center[i].betclass[j].isSelected) {
                            list.push(this.center[i].betclass[j]);
                        }
                    }
                }
                //后三
                for (let i = 0; i < this.back.length; i++) {
                    for (let j = 0; j < this.back[i].betclass.length; j++) {
                        if (this.back[i].betclass[j].isSelected) {
                            list.push(this.back[i].betclass[j]);
                        }
                    }
                }
                // console.log(list)
                return list;
            },
            get_lose() {
                let lose_data = this.$store.state.lose_data;
                for (let i = 0; i < this.balls.length; i++) {
                    for (let j = 0; j < this.balls[i].betclass.length; j++) {
                        lose_data.forEach(item => {
                            if (item.title == this.balls[i].betclass[j].title && item.name == this.balls[i].betclass[j].name) {
                                this.balls[i].betclass[j].static_bonus = item.rate;
                                this.balls[i].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.balls[i].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                }
                //总和龙虎
                for (let i = 0; i < this.sum.length; i++) {
                    for (let j = 0; j < this.sum[i].betclass.length; j++) {
                        lose_data.forEach(item => {
                            if (item.title == this.sum[i].betclass[j].title && item.name == this.sum[i].betclass[j].name) {
                                this.sum[i].betclass[j].static_bonus = item.rate;
                                this.sum[i].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.sum[i].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                }
                //前三
                for (let i = 0; i < this.front.length; i++) {
                    for (let j = 0; j < this.front[i].betclass.length; j++) {
                        lose_data.forEach(item => {
                            if (item.title == this.front[i].betclass[j].title && item.name == this.front[i].betclass[j].name) {
                                this.front[i].betclass[j].static_bonus = item.rate;
                                this.front[i].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.front[i].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                }
                //中三
                for (let i = 0; i < this.center.length; i++) {
                    for (let j = 0; j < this.center[i].betclass.length; j++) {
                        lose_data.forEach(item => {
                            if (item.title == this.center[i].betclass[j].title && item.name == this.center[i].betclass[j].name) {
                                this.center[i].betclass[j].static_bonus = item.rate;
                                this.center[i].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.center[i].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                }
                //后三
                for (let i = 0; i < this.back.length; i++) {
                    for (let j = 0; j < this.back[i].betclass.length; j++) {
                        lose_data.forEach(item => {
                            if (item.title == this.back[i].betclass[j].title && item.name == this.back[i].betclass[j].name) {
                                this.back[i].betclass[j].static_bonus = item.rate;
                                this.back[i].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.back[i].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                }
            },
            /**检查是否满足快捷输入 */
            check_fast_val(val) {
                if (val) {
                    return val;
                }
                else {
                    val = this.$parent.get_fast();
                    return val;
                }
            },
            /**重填，重置 */
            reset() {
                for (let i = 0; i < this.balls.length; i++) {
                    for (let j = 0; j < this.balls[i].betclass.length; j++) {
                        this.balls[i].betclass[j].val = "";
                        this.balls[i].betclass[j].isSelected = false;
                        this.balls[i].betclass[j].bg = "#F2F3F8";
                    }
                }
                //总和
                for (let i = 0; i < this.sum.length; i++) {
                    for (let j = 0; j < this.sum[i].betclass.length; j++) {
                        this.sum[i].betclass[j].val = "";
                        this.sum[i].betclass[j].isSelected = false;
                        this.sum[i].betclass[j].bg = "#F2F3F8";
                    }
                }
                //前三
                for (let i = 0; i < this.front.length; i++) {
                    for (let j = 0; j < this.front[i].betclass.length; j++) {
                        this.front[i].betclass[j].val = "";
                        this.front[i].betclass[j].isSelected = false;
                        this.front[i].betclass[j].bg = "#F2F3F8";
                    }
                }
                //中三
                for (let i = 0; i < this.center.length; i++) {
                    for (let j = 0; j < this.center[i].betclass.length; j++) {
                        this.center[i].betclass[j].val = "";
                        this.center[i].betclass[j].isSelected = false;
                        this.center[i].betclass[j].bg = "#F2F3F8";
                    }
                }
                //后三
                for (let i = 0; i < this.back.length; i++) {
                    for (let j = 0; j < this.back[i].betclass.length; j++) {
                        this.back[i].betclass[j].val = "";
                        this.back[i].betclass[j].isSelected = false;
                        this.back[i].betclass[j].bg = "#F2F3F8";
                    }
                }
            },
            /**提交，下注 */
            sub_bet() {
                let list = [];
                for (let i = 0; i < this.balls.length; i++) {
                    for (let j = 0; j < this.balls[i].betclass.length; j++) {
                        if (this.balls[i].betclass[j].isSelected) {
                            list.push(this.balls[i].betclass[j]);
                        }
                    }
                }
                //总和
                for (let i = 0; i < this.sum.length; i++) {
                    for (let j = 0; j < this.sum[i].betclass.length; j++) {
                        if (this.sum[i].betclass[j].isSelected) {
                            list.push(this.sum[i].betclass[j]);
                        }
                    }
                }
                //前三
                for (let i = 0; i < this.front.length; i++) {
                    for (let j = 0; j < this.front[i].betclass.length; j++) {
                        if (this.front[i].betclass[j].isSelected) {
                            list.push(this.front[i].betclass[j]);
                        }
                    }
                }
                //中三
                for (let i = 0; i < this.center.length; i++) {
                    for (let j = 0; j < this.center[i].betclass.length; j++) {
                        if (this.center[i].betclass[j].isSelected) {
                            list.push(this.center[i].betclass[j]);
                        }
                    }
                }
                //后三
                for (let i = 0; i < this.back.length; i++) {
                    for (let j = 0; j < this.back[i].betclass.length; j++) {
                        if (this.back[i].betclass[j].isSelected) {
                            list.push(this.back[i].betclass[j]);
                        }
                    }
                }
                // console.log(list)
                return list;
            },
            /**重复上次下单 */
            repeat_last_bet(list) {
                // console.log(list)
                for (let i = 0; i < list.length; i++) {
                    for (let j = 0; j < this.balls.length; j++) {
                        for (let k = 0; k < this.balls[j].betclass.length; k++) {
                            if (this.balls[j].betclass[k].title == list[i].title && this.balls[j].betclass[k].name == list[i].name) {
                                this.balls[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                }
                //总和
                for (let j = 0; j < this.sum.length; j++) {
                    for (let k = 0; k < this.sum[j].betclass.length; k++) {
                        if (this.sum[j].betclass[k].title == list[i].title && this.sum[j].betclass[k].name == list[i].name) {
                            this.sum[j].betclass[k].val = list[i].val;
                        }
                    }
                }
                //前三
                for (let j = 0; j < this.front.length; j++) {
                    for (let k = 0; k < this.front[j].betclass.length; k++) {
                        if (this.front[j].betclass[k].title == list[i].title && this.front[j].betclass[k].name == list[i].name) {
                            this.front[j].betclass[k].val = list[i].val;
                        }
                    }
                }
                //中三
                for (let j = 0; j < this.center.length; j++) {
                    for (let k = 0; k < this.center[j].betclass.length; k++) {
                        if (this.center[j].betclass[k].title == list[i].title && this.center[j].betclass[k].name == list[i].name) {
                            this.center[j].betclass[k].val = list[i].val;
                        }
                    }
                }
                //后三
                for (let j = 0; j < this.back.length; j++) {
                    for (let k = 0; k < this.back[j].betclass.length; k++) {
                        if (this.back[j].betclass[k].title == list[i].title && this.back[j].betclass[k].name == list[i].name) {
                            this.back[j].betclass[k].val = list[i].val;
                        }
                    }
                }
            },
        },
    }
</script>

<style scoped>
    .cn-name {
        color: #333;
        line-height: 20px;
        height: 20px;
        width: 20px;
        margin-top: 0.2rem;
        margin-left: 10px;
        border: 0;
        float: left;
    }
</style>
