<template>
<div class="cqssc-lm">
    <!-- 1-5 -->
    <el-row>
        <el-col :span="24" v-for="item in balls" :key="item.index">
            <div class="bet-title">
                <span>{{item.ballname}}</span>
            </div>
            <el-row class="bet-items" :gutter="6">
                <el-col :span="8" v-for="i in item.betclass" :key="i.index">
                    <div class="bet-items-info" :style="{backgroundColor:i.bg}" @click="click_item(i)">
                        <span class="bet-name">{{i.name}}</span>
                        <span class="bet-lose">{{i.static_bonus}}</span>
                    <!-- <el-input v-model="i.val" size="mini" v-enter-number v-on:focus="i.val=check_fast_val(i.val)"></el-input> -->
                    </div>
                </el-col>
            </el-row>
        </el-col>
    </el-row>
    <!-- 总和 -->
    <el-row >
        <el-col :span="24" v-for="item in sum" :key="item.index">
            <div class="bet-title">
                <span>{{item.ballname}}</span>
            </div>
            <el-row class="bet-items-one" :gutter="6">
                <el-col :span="8" v-for="i in item.betclass" :key="i.index" >
                    <div class="bet-items-info" :style="{backgroundColor:i.bg}" @click="click_item(i)">
                        <span class="bet-name">{{i.name}}</span>
                        <span class="bet-lose">{{i.static_bonus}}</span>
                        <!-- <el-input v-model="i.val" size="mini" v-enter-number v-on:focus="i.val=check_fast_val(i.val)"></el-input> -->
                    </div>
                </el-col>
            </el-row>
        </el-col>
    </el-row>
    <!-- 龙虎 -->
    <el-row >
        <el-col :span="24" v-for="item in long_hu" :key="item.index">
            <div class="bet-title">
                <span>{{item.ballname}}</span>
            </div>
            <el-row class="bet-items" :gutter="6">
                <el-col :span="8" v-for="i in item.betclass" :key="i.index">
                    <div class="bet-items-info" :style="{backgroundColor:i.bg}" @click="click_item(i)">
                        <span class="bet-name">{{i.name}}</span>
                        <span class="bet-lose">{{i.static_bonus}}</span>
                    <!-- <el-input v-model="i.val" size="mini" v-enter-number v-on:focus="i.val=check_fast_val(i.val)"></el-input> -->
                    </div>
                </el-col>
            </el-row>
        </el-col>
    </el-row>
</div>
</template>

<script>
import { link } from 'fs';
export default {
    data() {
        return {
            balls: [
                {
                    ballname: "第一球",
                    betclass: [
                        { title: "第一球", bg:'#F2F3F8', name: "大", isSelected: false, static_bonus: "", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "第一球", bg:'#F2F3F8', name: "小", isSelected: false, static_bonus: "", val: "", subname: "0_0_1", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "第一球", bg:'#F2F3F8', name: "单", isSelected: false, static_bonus: "", val: "", subname: "0_0_2", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "第一球", bg:'#F2F3F8', name: "双", isSelected: false, static_bonus: "", val: "", subname: "0_0_3", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false}
                    ]
                },
                {
                    ballname: "第二球",
                    betclass: [
                        { title: "第二球", bg:'#F2F3F8', name: "大", isSelected: false, static_bonus: "", val: "", subname: "0_1_0", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "第二球", bg:'#F2F3F8', name: "小", isSelected: false, static_bonus: "", val: "", subname: "0_1_1", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "第二球", bg:'#F2F3F8', name: "单", isSelected: false, static_bonus: "", val: "", subname: "0_1_2", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "第二球", bg:'#F2F3F8', name: "双", isSelected: false, static_bonus: "", val: "", subname: "0_1_3", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false}
                    ]
                },
                {
                    ballname: "第三球",
                    betclass: [
                        { title: "第三球", bg:'#F2F3F8', name: "大", isSelected: false, static_bonus: "", val: "", subname: "0_2_0", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "第三球", bg:'#F2F3F8', name: "小", isSelected: false, static_bonus: "", val: "", subname: "0_2_1", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "第三球", bg:'#F2F3F8', name: "单", isSelected: false, static_bonus: "", val: "", subname: "0_2_2", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "第三球", bg:'#F2F3F8', name: "双", isSelected: false, static_bonus: "", val: "", subname: "0_2_3", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false}
                    ]
                },
            ],
            /**总和，龙虎 */
            sum: [
                {
                    ballname: "总和",
                    betclass: [
                        { title: "总和", bg:'#F2F3F8', name: "总和大", isSelected: false, static_bonus: "", val: "", subname: "0_5_0", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "总和", bg:'#F2F3F8', name: "总和小", isSelected: false, static_bonus: "", val: "", subname: "0_5_1", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "总和", bg:'#F2F3F8', name: "总和单", isSelected: false, static_bonus: "", val: "", subname: "0_5_2", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "总和", bg:'#F2F3F8', name: "总和双", isSelected: false, static_bonus: "", val: "", subname: "0_5_3", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        
                    ]
                }
            ],
            long_hu: [
                {
                    ballname: "龙虎1vs2",
                    betclass: [
                        { title: "龙虎1vs2",bet_title:'万千', bg:'#F2F3F8',  name: "龙", isSelected: false, static_bonus: "", val: "", subname: "14_0_0", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "龙虎1vs2",bet_title:'万千', bg:'#F2F3F8',  name: "和", isSelected: false, static_bonus: "", val: "", subname: "14_0_1", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "龙虎1vs2",bet_title:'万千', bg:'#F2F3F8',  name: "虎", isSelected: false, static_bonus: "", val: "", subname: "14_0_2", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                    ]
                },
                {
                    ballname: "龙虎1vs3",
                    betclass: [
                        { title: "龙虎1vs3",bet_title:'万百', bg:'#F2F3F8',  name: "龙", isSelected: false, static_bonus: "", val: "", subname: "14_1_0", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "龙虎1vs3",bet_title:'万百', bg:'#F2F3F8',  name: "和", isSelected: false, static_bonus: "", val: "", subname: "14_1_1", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "龙虎1vs3",bet_title:'万百', bg:'#F2F3F8',  name: "虎", isSelected: false, static_bonus: "", val: "", subname: "14_1_2", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                    ]
                },
                {
                    ballname: "龙虎2vs3",
                    betclass: [
                        { title: "龙虎2vs3",bet_title:'千百', bg:'#F2F3F8',  name: "龙", isSelected: false, static_bonus: "", val: "", subname: "14_4_0", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "龙虎2vs3",bet_title:'千百', bg:'#F2F3F8',  name: "和", isSelected: false, static_bonus: "", val: "", subname: "14_4_1", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "龙虎2vs3",bet_title:'千百', bg:'#F2F3F8',  name: "虎", isSelected: false, static_bonus: "", val: "", subname: "14_4_2", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                    ]
                },
            ]
        }
    },
    created(){
        // this.get_lose();
    },
    methods: {
        //选取投注项
        click_item(item){
            if(item.isSelected){
                item.isSelected=false;
                item.bg='#F2F3F8';
            }else{
                item.bg='#E6A23C';
                item.isSelected=true;
            }
            return item
        },
        //快速下注
        fast_sub_bet(){
            let list=[];
            //1-5
            for(let i=0;i<this.balls.length;i++){
                for(let j=0;j<this.balls[i].betclass.length;j++){
                    if(this.balls[i].betclass[j].isSelected){
                        list.push(this.balls[i].betclass[j]);
                    }
                }
            }
            //总和
            for(let i=0;i<this.sum.length;i++){
                for(let j=0;j<this.sum[i].betclass.length;j++){
                    if(this.sum[i].betclass[j].isSelected){
                        list.push(this.sum[i].betclass[j]);
                    }
                }
            }
            //龙虎
            for(let i=0;i<this.long_hu.length;i++){
                for(let j=0;j<this.long_hu[i].betclass.length;j++){
                    if(this.long_hu[i].betclass[j].isSelected){
                        list.push(this.long_hu[i].betclass[j]);
                    }
                }
            }
            // console.log(list)
            return list;
        },
        /**显示赔率等数据 */
        get_lose(){
            let bonus_high=this.$store.state.lottery.lose_data.bonus_high;
            let others=this.$store.state.lottery.lose_data.others;
            let static_bonus=this.$store.state.lottery.lose_data.static_bonus;
            //1-5
            for(let i=0;i<this.balls.length;i++){
                for(let j=0;j<this.balls[i].betclass.length;j++){
                    this.balls[i].betclass[j].static_bonus=static_bonus[j];
                    this.balls[i].betclass[j].straightbet_high=others[j].straightbet_high;
                    this.balls[i].betclass[j].straightbet_low=others[j].straightbet_low;
                }
                static_bonus=static_bonus.slice(4);
                others=others.slice(4);
            }
            //总和
            for(let i=0;i<this.sum.length;i++){
                for(let j=0;j<this.sum[i].betclass.length;j++){
                    this.sum[i].betclass[j].static_bonus=static_bonus[j];
                    this.sum[i].betclass[j].straightbet_high=others[j].straightbet_high;
                    this.sum[i].betclass[j].straightbet_low=others[j].straightbet_low;
                }
                static_bonus=static_bonus.slice(4);
                others=others.slice(4);
            }
            //龙虎
            for(let i=0;i<this.long_hu.length;i++){
                for(let j=0;j<this.long_hu[i].betclass.length;j++){
                    this.long_hu[i].betclass[j].static_bonus=static_bonus[j];
                    this.long_hu[i].betclass[j].straightbet_high=others[j].straightbet_high;
                    this.long_hu[i].betclass[j].straightbet_low=others[j].straightbet_low;
                }
                static_bonus=static_bonus.slice(3);
                others=others.slice(3);
            }
        },
        /**检查是否满足快捷输入 */
        check_fast_val(val){
            if(val){
                return val;
            }
            else{
                val=this.$parent.get_fast();
                return val;
            }
        },
        /**重填，重置 */
        reset(){
            //1-5
            for(let i=0;i<this.balls.length;i++){
                for(let j=0;j<this.balls[i].betclass.length;j++){
                    this.balls[i].betclass[j].val="";
                    this.balls[i].betclass[j].isSelected=false;
                    this.balls[i].betclass[j].bg="#F2F3F8";
                }
            }
            //总和
            for(let i=0;i<this.sum.length;i++){
                for(let j=0;j<this.sum[i].betclass.length;j++){
                    this.sum[i].betclass[j].val="";
                    this.sum[i].betclass[j].isSelected=false;
                    this.sum[i].betclass[j].bg="#F2F3F8";
                }
            }
            //龙虎
            for(let i=0;i<this.long_hu.length;i++){
                for(let j=0;j<this.long_hu[i].betclass.length;j++){
                    this.long_hu[i].betclass[j].val="";
                    this.long_hu[i].betclass[j].isSelected=false;
                    this.long_hu[i].betclass[j].bg="#F2F3F8";
                }
            }
        },
        /**提交，下注 */
        sub_bet(){
            let list=[];
            //1-5
            for(let i=0;i<this.balls.length;i++){
                for(let j=0;j<this.balls[i].betclass.length;j++){
                    if(this.balls[i].betclass[j].isSelected){
                        list.push(this.balls[i].betclass[j]);
                    }
                }
            }
            //总和
            for(let i=0;i<this.sum.length;i++){
                for(let j=0;j<this.sum[i].betclass.length;j++){
                    if(this.sum[i].betclass[j].isSelected){
                        list.push(this.sum[i].betclass[j]);
                    }
                }
            }
            //龙虎
            for(let i=0;i<this.long_hu.length;i++){
                for(let j=0;j<this.long_hu[i].betclass.length;j++){
                    if(this.long_hu[i].betclass[j].isSelected){
                        list.push(this.long_hu[i].betclass[j]);
                    }
                }
            }
            // console.log(list)
            return list;
        },
        /**重复上次下单 */
        repeat_last_bet(list) {
            // console.log(list)
            for(let i=0;i<list.length;i++){
                //1-5
                for(let j=0;j<this.balls.length;j++){
                    for(let k=0;k<this.balls[j].betclass.length;k++){
                        if(this.balls[j].betclass[k].title==list[i].title&&this.balls[j].betclass[k].name==list[i].name){
                            this.balls[j].betclass[k].val=list[i].val;
                        }
                    }
                }
                //总和
                for(let j=0;j<this.sum.length;j++){
                    for(let k=0;k<this.sum[j].betclass.length;k++){
                        if(this.sum[j].betclass[k].title==list[i].title&&this.sum[j].betclass[k].name==list[i].name){
                            this.sum[j].betclass[k].val=list[i].val;
                        }
                    }
                }
            }
        },
    },
}
</script>

