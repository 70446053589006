<template>
    <div class="bjpk10-d7_d10">
        <!-- 第七名 -->
        <el-row>
            <el-col :span="24" v-for="item in seventh" :key="item.index">
                <div class="bet-title">
                    <span>{{item.ballname}}</span>
                </div>
                <el-row class="bet-items" :gutter="6">
                    <el-col :span="8" v-for="i in item.betclass" :key="i.index">
                        <div class="bet-items-info" :style="{backgroundColor:i.bg}" @click="click_item(i)">
                            <span class="bet-name pk10-nums">
                                <span v-if="i.name==1" class="pk10_num1">1</span>
                                <span v-else-if="i.name==2" class="pk10_num2">2</span>
                                <span v-else-if="i.name==3" class="pk10_num3">3</span>
                                <span v-else-if="i.name==4" class="pk10_num4">4</span>
                                <span v-else-if="i.name==5" class="pk10_num5">5</span>
                                <span v-else-if="i.name==6" class="pk10_num6">6</span>
                                <span v-else-if="i.name==7" class="pk10_num7">7</span>
                                <span v-else-if="i.name==8" class="pk10_num8">8</span>
                                <span v-else-if="i.name==9" class="pk10_num9">9</span>
                                <span v-else-if="i.name==10" class="pk10_num10">10</span>
                                <div v-else>{{i.name}}</div>
                            </span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <span v-show="i.bg=='#ffef3a'" class="select-icon">
                                <Select style="width: 24px; height: 24px;" />
                            </span>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <!-- 第八名 -->
        <el-row>
            <el-col :span="24" v-for="item in eighth" :key="item.index">
                <div class="bet-title">
                    <span>{{item.ballname}}</span>
                </div>
                <el-row class="bet-items" :gutter="6">
                    <el-col :span="8" v-for="i in item.betclass" :key="i.index">
                        <div class="bet-items-info" :style="{backgroundColor:i.bg}" @click="click_item(i)">
                            <span class="bet-name pk10-nums">
                                <span v-if="i.name==1" class="pk10_num1">1</span>
                                <span v-else-if="i.name==2" class="pk10_num2">2</span>
                                <span v-else-if="i.name==3" class="pk10_num3">3</span>
                                <span v-else-if="i.name==4" class="pk10_num4">4</span>
                                <span v-else-if="i.name==5" class="pk10_num5">5</span>
                                <span v-else-if="i.name==6" class="pk10_num6">6</span>
                                <span v-else-if="i.name==7" class="pk10_num7">7</span>
                                <span v-else-if="i.name==8" class="pk10_num8">8</span>
                                <span v-else-if="i.name==9" class="pk10_num9">9</span>
                                <span v-else-if="i.name==10" class="pk10_num10">10</span>
                                <div v-else>{{i.name}}</div>
                            </span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <span v-show="i.bg=='#ffef3a'" class="select-icon">
                                <Select style="width: 24px; height: 24px;" />
                            </span>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <!-- 第九名 -->
        <el-row>
            <el-col :span="24" v-for="item in ninth" :key="item.index">
                <div class="bet-title">
                    <span>{{item.ballname}}</span>
                </div>
                <el-row class="bet-items" :gutter="6">
                    <el-col :span="8" v-for="i in item.betclass" :key="i.index">
                        <div class="bet-items-info" :style="{backgroundColor:i.bg}" @click="click_item(i)">
                            <span class="bet-name pk10-nums">
                                <span v-if="i.name==1" class="pk10_num1">1</span>
                                <span v-else-if="i.name==2" class="pk10_num2">2</span>
                                <span v-else-if="i.name==3" class="pk10_num3">3</span>
                                <span v-else-if="i.name==4" class="pk10_num4">4</span>
                                <span v-else-if="i.name==5" class="pk10_num5">5</span>
                                <span v-else-if="i.name==6" class="pk10_num6">6</span>
                                <span v-else-if="i.name==7" class="pk10_num7">7</span>
                                <span v-else-if="i.name==8" class="pk10_num8">8</span>
                                <span v-else-if="i.name==9" class="pk10_num9">9</span>
                                <span v-else-if="i.name==10" class="pk10_num10">10</span>
                                <div v-else>{{i.name}}</div>
                            </span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <span v-show="i.bg=='#ffef3a'" class="select-icon">
                                <Select style="width: 24px; height: 24px;" />
                            </span>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <!-- 第十名 -->
        <el-row>
            <el-col :span="24" v-for="item in tenth" :key="item.index">
                <div class="bet-title">
                    <span>{{item.ballname}}</span>
                </div>
                <el-row class="bet-items" :gutter="6">
                    <el-col :span="8" v-for="i in item.betclass" :key="i.index">
                        <div class="bet-items-info" :style="{backgroundColor:i.bg}" @click="click_item(i)">
                            <span class="bet-name pk10-nums">
                                <span v-if="i.name==1" class="pk10_num1">1</span>
                                <span v-else-if="i.name==2" class="pk10_num2">2</span>
                                <span v-else-if="i.name==3" class="pk10_num3">3</span>
                                <span v-else-if="i.name==4" class="pk10_num4">4</span>
                                <span v-else-if="i.name==5" class="pk10_num5">5</span>
                                <span v-else-if="i.name==6" class="pk10_num6">6</span>
                                <span v-else-if="i.name==7" class="pk10_num7">7</span>
                                <span v-else-if="i.name==8" class="pk10_num8">8</span>
                                <span v-else-if="i.name==9" class="pk10_num9">9</span>
                                <span v-else-if="i.name==10" class="pk10_num10">10</span>
                                <div v-else>{{i.name}}</div>
                            </span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <span v-show="i.bg=='#ffef3a'" class="select-icon">
                                <Select style="width: 24px; height: 24px;" />
                            </span>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                /**第七名*/
                seventh: [
                    {
                        ballname: "第七名",
                        betclass: [
                            { title: "第七名", bg: '#F2F3F8', name: "01", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第七名", bg: '#F2F3F8', name: "02", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第七名", bg: '#F2F3F8', name: "03", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第七名", bg: '#F2F3F8', name: "04", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第七名", bg: '#F2F3F8', name: "05", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第七名", bg: '#F2F3F8', name: "06", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第七名", bg: '#F2F3F8', name: "07", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第七名", bg: '#F2F3F8', name: "08", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第七名", bg: '#F2F3F8', name: "09", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第七名", bg: '#F2F3F8', name: "10", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第七名", bg: '#F2F3F8', name: "大", isSelected: false, static_bonus: "", val: "", subname: "4_0_41", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第七名", bg: '#F2F3F8', name: "小", isSelected: false, static_bonus: "", val: "", subname: "4_0_42", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第七名", bg: '#F2F3F8', name: "单", isSelected: false, static_bonus: "", val: "", subname: "4_0_43", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第七名", bg: '#F2F3F8', name: "双", isSelected: false, static_bonus: "", val: "", subname: "4_0_44", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    }
                ],
                /**第八名*/
                eighth: [
                    {
                        ballname: "第八名",
                        betclass: [
                            { title: "第八名", bg: '#F2F3F8', name: "01", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第八名", bg: '#F2F3F8', name: "02", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第八名", bg: '#F2F3F8', name: "03", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第八名", bg: '#F2F3F8', name: "04", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第八名", bg: '#F2F3F8', name: "05", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第八名", bg: '#F2F3F8', name: "06", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第八名", bg: '#F2F3F8', name: "07", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第八名", bg: '#F2F3F8', name: "08", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第八名", bg: '#F2F3F8', name: "09", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第八名", bg: '#F2F3F8', name: "10", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第八名", bg: '#F2F3F8', name: "大", isSelected: false, static_bonus: "", val: "", subname: "4_0_45", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第八名", bg: '#F2F3F8', name: "小", isSelected: false, static_bonus: "", val: "", subname: "4_0_46", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第八名", bg: '#F2F3F8', name: "单", isSelected: false, static_bonus: "", val: "", subname: "4_0_47", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第八名", bg: '#F2F3F8', name: "双", isSelected: false, static_bonus: "", val: "", subname: "4_0_48", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    }
                ],
                /**第九名*/
                ninth: [
                    {
                        ballname: "第九名",
                        betclass: [
                            { title: "第九名", bg: '#F2F3F8', name: "01", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第九名", bg: '#F2F3F8', name: "02", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第九名", bg: '#F2F3F8', name: "03", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第九名", bg: '#F2F3F8', name: "04", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第九名", bg: '#F2F3F8', name: "05", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第九名", bg: '#F2F3F8', name: "06", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第九名", bg: '#F2F3F8', name: "07", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第九名", bg: '#F2F3F8', name: "08", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第九名", bg: '#F2F3F8', name: "09", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第九名", bg: '#F2F3F8', name: "10", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第九名", bg: '#F2F3F8', name: "大", isSelected: false, static_bonus: "", val: "", subname: "4_0_49", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第九名", bg: '#F2F3F8', name: "小", isSelected: false, static_bonus: "", val: "", subname: "4_0_50", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第九名", bg: '#F2F3F8', name: "单", isSelected: false, static_bonus: "", val: "", subname: "4_0_51", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第九名", bg: '#F2F3F8', name: "双", isSelected: false, static_bonus: "", val: "", subname: "4_0_52", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    }
                ],
                /**第十名*/
                tenth: [
                    {
                        ballname: "第十名",
                        betclass: [
                            { title: "第十名", bg: '#F2F3F8', name: "01", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第十名", bg: '#F2F3F8', name: "02", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第十名", bg: '#F2F3F8', name: "03", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第十名", bg: '#F2F3F8', name: "04", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第十名", bg: '#F2F3F8', name: "05", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第十名", bg: '#F2F3F8', name: "06", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第十名", bg: '#F2F3F8', name: "07", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第十名", bg: '#F2F3F8', name: "08", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第十名", bg: '#F2F3F8', name: "09", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第十名", bg: '#F2F3F8', name: "10", isSelected: false, static_bonus: "", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第十名", bg: '#F2F3F8', name: "大", isSelected: false, static_bonus: "", val: "", subname: "4_0_53", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第十名", bg: '#F2F3F8', name: "小", isSelected: false, static_bonus: "", val: "", subname: "4_0_54", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第十名", bg: '#F2F3F8', name: "单", isSelected: false, static_bonus: "", val: "", subname: "4_0_55", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第十名", bg: '#F2F3F8', name: "双", isSelected: false, static_bonus: "", val: "", subname: "4_0_56", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    }
                ],
            }
        },
        methods: {
            //选取投注项
            click_item(item) {
                if (item.isSelected) {
                    item.isSelected = false;
                    item.bg = '#F2F3F8';
                } else {
                    item.bg = '#ffef3a';
                    item.isSelected = true;
                }
                return item
            },
            //快速下注
            fast_sub_bet() {
                let list = [];
                //第七名
                for (let i = 0; i < this.seventh.length; i++) {
                    for (let j = 0; j < this.seventh[i].betclass.length; j++) {
                        if (this.seventh[i].betclass[j].isSelected) {
                            list.push(this.seventh[i].betclass[j]);
                        }
                    }
                }
                //第八名
                for (let i = 0; i < this.eighth.length; i++) {
                    for (let j = 0; j < this.eighth[i].betclass.length; j++) {
                        if (this.eighth[i].betclass[j].isSelected) {
                            list.push(this.eighth[i].betclass[j]);
                        }
                    }
                }
                //第九名
                for (let i = 0; i < this.ninth.length; i++) {
                    for (let j = 0; j < this.ninth[i].betclass.length; j++) {
                        if (this.ninth[i].betclass[j].isSelected) {
                            list.push(this.ninth[i].betclass[j]);
                        }
                    }
                }
                //第十名
                for (let i = 0; i < this.tenth.length; i++) {
                    for (let j = 0; j < this.tenth[i].betclass.length; j++) {
                        if (this.tenth[i].betclass[j].isSelected) {
                            list.push(this.tenth[i].betclass[j]);
                        }
                    }
                }
                // console.log(list)
                return list;
            },
            /**显示赔率等数据 */
            get_lose() {
                let lose_data = this.$store.state.lose_data;
                //第七名
                for (let i = 0; i < this.seventh.length; i++) {
                    for (let j = 0; j < this.seventh[i].betclass.length; j++) {
                        lose_data.forEach(item => {
                            if (item.title == this.seventh[i].betclass[j].title && item.name == this.seventh[i].betclass[j].name) {
                                this.seventh[i].betclass[j].static_bonus = item.rate;
                                this.seventh[i].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.seventh[i].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                }
                //第八名
                for (let i = 0; i < this.eighth.length; i++) {
                    for (let j = 0; j < this.eighth[i].betclass.length; j++) {
                        lose_data.forEach(item => {
                            if (item.title == this.eighth[i].betclass[j].title && item.name == this.eighth[i].betclass[j].name) {
                                this.eighth[i].betclass[j].static_bonus = item.rate;
                                this.eighth[i].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.eighth[i].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                }
                //第九名
                for (let i = 0; i < this.ninth.length; i++) {
                    for (let j = 0; j < this.ninth[i].betclass.length; j++) {
                        lose_data.forEach(item => {
                            if (item.title == this.ninth[i].betclass[j].title && item.name == this.ninth[i].betclass[j].name) {
                                this.ninth[i].betclass[j].static_bonus = item.rate;
                                this.ninth[i].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.ninth[i].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                }
                //第十名
                for (let i = 0; i < this.tenth.length; i++) {
                    for (let j = 0; j < this.tenth[i].betclass.length; j++) {
                        lose_data.forEach(item => {
                            if (item.title == this.tenth[i].betclass[j].title && item.name == this.tenth[i].betclass[j].name) {
                                this.tenth[i].betclass[j].static_bonus = item.rate;
                                this.tenth[i].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.tenth[i].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                }
            },
            /**检查是否满足快捷输入 */
            check_fast_val(val) {
                if (val) {
                    return val;
                }
                else {
                    val = this.$parent.get_fast();
                    return val;
                }
            },
            /**重填，重置 */
            reset() {
                //第七名
                for (let i = 0; i < this.seventh.length; i++) {
                    for (let j = 0; j < this.seventh[i].betclass.length; j++) {
                        this.seventh[i].betclass[j].val = "";
                        this.seventh[i].betclass[j].isSelected = false;
                        this.seventh[i].betclass[j].bg = "#F2F3F8";
                    }
                }
                //第八名
                for (let i = 0; i < this.eighth.length; i++) {
                    for (let j = 0; j < this.eighth[i].betclass.length; j++) {
                        this.eighth[i].betclass[j].val = "";
                        this.eighth[i].betclass[j].isSelected = false;
                        this.eighth[i].betclass[j].bg = "#F2F3F8";
                    }
                }
                //第九名
                for (let i = 0; i < this.ninth.length; i++) {
                    for (let j = 0; j < this.ninth[i].betclass.length; j++) {
                        this.ninth[i].betclass[j].val = "";
                        this.ninth[i].betclass[j].isSelected = false;
                        this.ninth[i].betclass[j].bg = "#F2F3F8";
                    }
                }
                //第十名
                for (let i = 0; i < this.tenth.length; i++) {
                    for (let j = 0; j < this.tenth[i].betclass.length; j++) {
                        this.tenth[i].betclass[j].val = "";
                        this.tenth[i].betclass[j].isSelected = false;
                        this.tenth[i].betclass[j].bg = "#F2F3F8";
                    }
                }
            },
            /**提交，下注 */
            sub_bet() {
                let list = [];
                //第七名
                for (let i = 0; i < this.seventh.length; i++) {
                    for (let j = 0; j < this.seventh[i].betclass.length; j++) {
                        if (this.seventh[i].betclass[j].isSelected) {
                            list.push(this.seventh[i].betclass[j]);
                        }
                    }
                }
                //第八名
                for (let i = 0; i < this.eighth.length; i++) {
                    for (let j = 0; j < this.eighth[i].betclass.length; j++) {
                        if (this.eighth[i].betclass[j].isSelected) {
                            list.push(this.eighth[i].betclass[j]);
                        }
                    }
                }
                //第九名
                for (let i = 0; i < this.ninth.length; i++) {
                    for (let j = 0; j < this.ninth[i].betclass.length; j++) {
                        if (this.ninth[i].betclass[j].isSelected) {
                            list.push(this.ninth[i].betclass[j]);
                        }
                    }
                }
                //第十名
                for (let i = 0; i < this.tenth.length; i++) {
                    for (let j = 0; j < this.tenth[i].betclass.length; j++) {
                        if (this.tenth[i].betclass[j].isSelected) {
                            list.push(this.tenth[i].betclass[j]);
                        }
                    }
                }
                // console.log(list)
                return list;
            },
            /**重复上次下单 */
            repeat_last_bet(list) {
                // console.log(list)
                for (let i = 0; i < list.length; i++) {
                    //第七名
                    for (let j = 0; j < this.seventh.length; j++) {
                        for (let k = 0; k < this.seventh[j].betclass.length; k++) {
                            if (this.seventh[j].betclass[k].title == list[i].title && this.seventh[j].betclass[k].name == list[i].name) {
                                this.seventh[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                    //第八名
                    for (let j = 0; j < this.eighth.length; j++) {
                        for (let k = 0; k < this.eighth[j].betclass.length; k++) {
                            if (this.eighth[j].betclass[k].title == list[i].title && this.eighth[j].betclass[k].name == list[i].name) {
                                this.eighth[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                    //第九名
                    for (let j = 0; j < this.ninth.length; j++) {
                        for (let k = 0; k < this.ninth[j].betclass.length; k++) {
                            if (this.ninth[j].betclass[k].title == list[i].title && this.ninth[j].betclass[k].name == list[i].name) {
                                this.ninth[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                    //第十名
                    for (let j = 0; j < this.tenth.length; j++) {
                        for (let k = 0; k < this.tenth[j].betclass.length; k++) {
                            if (this.tenth[j].betclass[k].title == list[i].title && this.tenth[j].betclass[k].name == list[i].name) {
                                this.tenth[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                }
            },
        },
    }
</script>